import { Button, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { UserContext } from 'components/UserContextProvider';
import { useCallback, useContext } from 'react';

import { IProviderDashboardInfo } from 'api/admin';
import './providerList.less';

interface IProviderList {
  list: IProviderDashboardInfo[];
  setActiveTabKey: (key: string) => void;
}

const CourseItemCount =
  (
    handleClickCount: (providerId: string, activeTabKey: string) => void,
    countKey: 'reviewingCount' | 'publishedCount' | 'draftCount',
    activeTabKey: string
  ) =>
  (item: IProviderDashboardInfo) => {
    if (item[countKey] > 0) {
      return (
        <Button type="link" onClick={() => handleClickCount(item.providerId, activeTabKey)} style={{ padding: 0 }}>
          {item[countKey]}
        </Button>
      );
    } else {
      return <span>{item[countKey]}</span>;
    }
  };

const ProviderList = ({ list, setActiveTabKey }: IProviderList) => {
  const { changeProvider } = useContext(UserContext);

  const handleClickCount = useCallback(
    (providerId: string, activeTabKey: string) => {
      changeProvider(providerId);
      setActiveTabKey(activeTabKey);
    },
    [changeProvider, setActiveTabKey]
  );

  const columns: ColumnType<IProviderDashboardInfo>[] = [
    {
      title: 'Name',
      dataIndex: 'providerName',
      width: '60%',
    },
    {
      title: 'Awaiting review',
      render: CourseItemCount(handleClickCount, 'reviewingCount', 'reviewing'),
    },
    {
      title: 'Published',
      render: CourseItemCount(handleClickCount, 'publishedCount', 'published'),
    },
    {
      title: 'Draft',
      render: CourseItemCount(handleClickCount, 'draftCount', 'drafts'),
    },
  ];

  return (
    <>
      <Table rowKey="providerId" dataSource={list} columns={columns} className="provider-list-table" />
    </>
  );
};

export default ProviderList;
