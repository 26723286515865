export const checkArrayIsEmpty = (arr: any[]) => {
  return arr?.length > 0;
};

export const getDistinctValues = <T>(
  objArray: Array<any>,
  propName: string,
  transformDistinct?: (current: T[], propValue: any, item: any) => T[]
): Array<T> => {
  if (objArray) {
    if (transformDistinct) {
      return Array.from(
        new Set(
          objArray.reduce((t: T[], c: any) => {
            return transformDistinct(t, c[propName], c);
          }, [])
        )
      ).sort();
    } else {
      return Array.from(new Set(objArray.map((c: any) => c[propName]))).sort();
    }
  }
  return [];
};

export const filterList = (list: any[], filterText: string | null | undefined, searchFields: string[]) => {
  if (!filterText || filterText.trim() === '') {
    return list;
  }
  return (
    list?.filter((item: any) =>
      // any of the search fields contain the search text
      searchFields.some((field) => item[field] && item[field].toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
    ) || []
  );
};
