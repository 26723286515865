import { CheckOutlined, CloseOutlined, EditOutlined, EyeOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import { useSafeState, useDebounce } from 'ahooks';
import { Button, Col, Input, Row, Select, Table, notification } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import API from 'api';
import ActionMenu from 'components/common/ActionMenu';
import StatusIndicator, { STATUS_TYPE } from 'components/common/statusIndicator/StatusIndicator';
import useUserList from 'components/hooks/useUserList';
import { UserContext } from 'components/UserContextProvider';
import { isEmpty } from 'lodash';
import { DISPLAY_USER_STATUS, IUserWithProvider, USER_STATUS } from 'model/user';
import { AlignType } from 'rc-table/lib/interface';
import { useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserRole, UserRoleDisplay } from 'utils/UserUtils';
import { DeactivateUserModal } from './modals/DeactivateUser';
import { EditRoleModal } from './modals/EditRole';
import { ReactivateUserModal } from './modals/ReactivateUser';
import { ResendInvitationModal } from './modals/ResendInvitation';
import './User.less';
import CustomPrevNextButtonRender from 'components/ui/pagination/CustomPrevNextButtonRender';

const { Search } = Input;
const { Option } = Select;

export const UserList = () => {
  const navigate = useNavigate();

  const {
    list,
    loading,
    deactivateUser,
    reactivateUser,
    updateUserRole,
    addOrUpdate,
    searchUser,
    resendInvitationUser,
    mutate,
    searchText,
    setSearchText
  } = useUserList(true);

  const { listProvider, role, isAdmin, currentProvider, selectedOptions, setSelectedOptions } = useContext(UserContext);

  const debouncedTextVal = useDebounce(searchText, {
    wait: 300,
  });

  const userManagementAPI = role === UserRole.ADMIN ? API.Admin : UserRole.PROVIDER_ADMIN ? API.Provider : undefined;

  const [options, setOptions] = useSafeState<DefaultOptionType[]>([]);

  const [selectedUser, setSelectedUser] = useSafeState<IUserWithProvider>({} as IUserWithProvider);
  const [selectedOptionForUpdateUserRole, setSelectedOptionForUpdateUserRole] = useSafeState<DefaultOptionType>(
    {} as DefaultOptionType
  );
  const [openUpdateUserRoleModal, toggleUpdateUserRoleModal] = useSafeState(false);
  const [openDeactivateUserModal, toggleDeactivateUserModal] = useSafeState(false);
  const [openReactiveUserModal, toggleReactiveUserModal] = useSafeState(false);
  const [openResendInvitationUserModal, toggleResendInvitationUserModal] = useSafeState(false);

  const [isUpdatingUser, setIsUpdatingUser] = useSafeState(false);
  const [errorMessage, setUpdateRoleErrorMessage] = useSafeState('');
  const isUsingSelectedOptions = useRef(true);

  useEffect(() => {
    // Each time an option change. We need to clear the error
    setUpdateRoleErrorMessage('');
  }, [selectedOptionForUpdateUserRole]);

  useEffect(() => {
    setOptions(
      listProvider.map((provider) => ({ value: provider.id, label: provider.extId ? provider.extId : provider.id }))
    );
  }, [listProvider]);

  useEffect(() => {
    if (isUsingSelectedOptions.current) {
      handleChangeSelectedOptions();
      isUsingSelectedOptions.current = false;
      return;
    }

    if (currentProvider) {
      if (selectedOptions?.length) {
        const newOptions = listProvider.map((provider) => ({
          label: provider.extId ? provider.extId : provider.id,
          value: provider.id,
          isSelected: false,
        }));

        setOptions(newOptions);
        setSelectedOptions([]);
      }

      searchUser(searchText, [currentProvider]);
    }
  }, [currentProvider]);

  useEffect(() => {
    handleChangeSelectedOptions();
  }, [selectedOptions]);

  useEffect(() => {
    handleSearchUser(debouncedTextVal);
  }, [debouncedTextVal]);

  const handleSearchUser = (textVal: string) => {
    let mappingOptions = selectedOptions.map((item) => item.value);

    if (currentProvider) {
      mappingOptions = [...mappingOptions, currentProvider];
    }

    searchUser(textVal, mappingOptions as string[]);
  };

  const handleChangeSelectedOptions = () => {
    // Each time there is a changes in the selected options,
    // we need to update the options in order to render the green tick
    if (currentProvider === '') {
      const newOptions = listProvider.map((provider) => {
        const result = selectedOptions.find((selectedOption) => selectedOption.value === provider.id);

        return {
          label: provider.extId ? provider.extId : provider.id,
          value: provider.id,
          isSelected: Boolean(result),
        };
      });

      setOptions(newOptions);

      // Filter list current user
      const mappingOptions = selectedOptions.map((item) => item.value);
      searchUser(searchText, mappingOptions as string[]);
    }
  }

  const updateRoleSelectedUser = async () => {
    setIsUpdatingUser(true);

    const [data, error] = await updateUserRole(selectedUser.id, selectedOptionForUpdateUserRole.value as string);
    if (!error) {
      addOrUpdate({
        ...selectedUser!,
        role: data.role as UserRole,
      });

      notification.success({ message: 'User role updated' });
      setSelectedUser({} as IUserWithProvider);
      toggleUpdateUserRoleModal(false);
    } else {
      if (error.message.toLowerCase().includes('select a different role')) {
        setUpdateRoleErrorMessage(error.message);
      } else {
        notification.error({ message: 'Failed to edit user role. Please try again.' });
      }
    }

    setIsUpdatingUser(false);
  };

  const onCloseUpdateUserRoleModal = () => {
    setSelectedUser({} as IUserWithProvider);
    toggleUpdateUserRoleModal(false);
    setUpdateRoleErrorMessage('');
  };

  const reactivateSelectedUser = async () => {
    if (!selectedUser) return;
    setIsUpdatingUser(true);

    const [, error] = await reactivateUser(selectedUser.username);
    if (!error) {
      if (userManagementAPI) {
        toggleReactiveUserModal(false); // to prevent the ReactivateUserModal from re-rendering because of the below API call.
        const [data] = await userManagementAPI.getUser(selectedUser.id);
        addOrUpdate({
          ...selectedUser!,
          status: data?.status as USER_STATUS,
        });
      }
      notification.success({ message: 'User reactivated' });
    } else {
      toggleReactiveUserModal(false);
      notification.error({ message: 'Failed to deactivate user. Please try again.' });
    }

    setSelectedUser({} as IUserWithProvider);
    setIsUpdatingUser(false);
    mutate().then(() => {
      handleChangeSelectedOptions();
    });
  };

  const onCloseReactiveUserModal = () => {
    setSelectedUser({} as IUserWithProvider);
    toggleReactiveUserModal(false);
  };

  const deactivateSelectedUser = async () => {
    if (!selectedUser) return;
    setIsUpdatingUser(true);

    const [, error] = await deactivateUser(selectedUser.username);
    if (!error) {
      addOrUpdate({
        ...selectedUser!,
        status: USER_STATUS.INACTIVE,
      });
      notification.success({ message: 'User deactivated' });
    } else {
      notification.error({ message: error?.message ?? 'Failed to deactivate user. Please try again.' });
    }

    setSelectedUser({} as IUserWithProvider);
    toggleDeactivateUserModal(false);
    setIsUpdatingUser(false);
    mutate().then(() => {
      handleChangeSelectedOptions();
    });
  };

  const onCloseDeactivateUserModal = () => {
    setSelectedUser({} as IUserWithProvider);
    toggleDeactivateUserModal(false);
  };

  const resendInvitationToSelectedUser = async () => {
    const [_, error] = await resendInvitationUser(selectedUser.username);
    if (!error) {
      notification.success({ message: 'Email has been sent to the user' });
      onCloseResendInvitationUserModal();
    } else {
      notification.error({ message: 'Failed to send the email to the user. Please try again' });
    }
    mutate().then(() => {
      handleChangeSelectedOptions();
    });
  };

  const onCloseResendInvitationUserModal = () => {
    setSelectedUser({} as IUserWithProvider);
    toggleResendInvitationUserModal(false);
  };

  const onSelectProvider = (_: unknown, newOption: DefaultOptionType) => {
    const result = selectedOptions.find((option) => option.value === newOption.value);
    if (!result) {
      setSelectedOptions((prev) => [
        ...prev,
        {
          ...newOption,
        },
      ]);
    }
  };

  const normalizeRoleData = (role: string) => {
    if (role === 'Provider Editor') {
      return UserRole.PROVIDER_EDITOR;
    } else if (role === 'Dese Editor') {
      return UserRole.DESE_EDITOR;
    }

    return role as UserRole;
  };

  const providerColumn =
    role === UserRole.PROVIDER_ADMIN
      ? {}
      : {
        title: 'Provider',
        render: (user: IUserWithProvider) => {
          if (user.defaultProvider) {
            return listProvider.find((provider) => provider.id === user.defaultProvider)?.extId ?? '-';
          }
          return <div>{user.defaultProvider || '-'}</div>;
        },
        sorter: (user: IUserWithProvider, nextUser: IUserWithProvider) => {
          const currenUserProvider = listProvider.find((provider) => provider.id === user.defaultProvider);
          const nextUserProvider = listProvider.find((provider) => provider.id === nextUser.defaultProvider);

          // Due to some user's defaultProvider is undefined
          // Which means the second person's defaultProvider should be place back of this
          if (!currenUserProvider?.extId && nextUserProvider?.extId) {
            return -1;
          }

          // Which means the first person's defaultProvider should be place in-front of this
          if (!nextUserProvider?.extId && currenUserProvider?.extId) {
            return 1;
          }

          return currenUserProvider?.extId?.localeCompare(nextUserProvider?.extId ?? '') ?? -1;
        },
      };

  const columns: ColumnType<IUserWithProvider>[] = [
    {
      title: 'Email',
      defaultSortOrder: 'ascend' as SortOrder,
      render: (user: IUserWithProvider) => {
        const link = isAdmin ? `/admin/users/${user.id}` : `/provider/users/${user.id}`;

        return <Link to={link}>{user.email}</Link>;
      },
      sorter: (a: IUserWithProvider, b: IUserWithProvider) => {
        return a.email?.localeCompare(b.email);
      },
    },
    {
      title: 'Full name',
      render: (user: IUserWithProvider) => {
        return <div>{user.name || '-'}</div>;
      },
      sorter: (user: IUserWithProvider, nextUser: IUserWithProvider) => {
        // Due to some user's name is undefined
        // Which means the second person's name should be place back of this
        if (!user.name && nextUser.name) {
          return -1;
        }

        // Which means the first person's name should be place in-front of this
        if (!nextUser.name && user.name) {
          return 1;
        }

        return user.name?.localeCompare(nextUser.name);
      },
    },
    {
      title: 'Role',
      render: (user: IUserWithProvider) => {
        return <div>{UserRoleDisplay[user.role]}</div>;
      },
      sorter: (a: IUserWithProvider, b: IUserWithProvider) => {
        a.role = normalizeRoleData(a.role.toString());
        b.role = normalizeRoleData(b.role.toString());

        return UserRoleDisplay[a.role]?.localeCompare(UserRoleDisplay[b.role]);
      },
    },
    { ...providerColumn },
    {
      title: 'Status',
      render: (user: IUserWithProvider) => {
        let statusType = STATUS_TYPE.NONE;

        switch (user.status) {
          case USER_STATUS.PASSWORD_RESET_PENDING:
            statusType = STATUS_TYPE.PENDING;
            break;

          case USER_STATUS.ACTIVE:
            statusType = STATUS_TYPE.ACTIVE;
            break;

          case USER_STATUS.INACTIVE:
            statusType = STATUS_TYPE.INACTIVE;
            break;

          default:
            statusType = STATUS_TYPE.NONE;
            break;
        }

        return (
          <StatusIndicator
            status={statusType}
            text={<span className="status-indicator">{DISPLAY_USER_STATUS[user.status] || 'None'}</span>}
          />
        );
      },
      sorter: (a: IUserWithProvider, b: IUserWithProvider) => {
        return DISPLAY_USER_STATUS[a.status]?.localeCompare(DISPLAY_USER_STATUS[b.status]);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right' as AlignType,
      render: (user: IUserWithProvider) => {
        let actions: string[] = ['view'];
        if (role === UserRole.ADMIN || role === UserRole.PROVIDER_ADMIN) {
          actions.unshift('edit');
        }

        switch (user.status) {
          case USER_STATUS.PASSWORD_RESET_PENDING:
            actions.unshift('deactivate');
            actions.unshift('resendInvitation');
            break;
          case USER_STATUS.ACTIVE:
            actions.unshift('deactivate');
            break;

          case USER_STATUS.INACTIVE:
            actions.unshift('activate');
            break;

          default:
            actions = [];
            break;
        }

        return (
          <ActionMenu
            id={user.email}
            type="ellipsis"
            actions={actions.map((actionKey) => {
              switch (actionKey) {
                case 'view':
                  return {
                    label: 'View',
                    icon: <EyeOutlined />,
                    handler: () => {
                      // Redirect to detail page
                      if (isAdmin) {
                        navigate(`/admin/users/${user.id}`);
                      } else {
                        navigate(`/provider/users/${user.id}`);
                      }
                    },
                  };
                case 'activate':
                  return {
                    label: 'Reactivate',
                    icon: <SyncOutlined />,
                    handler: () => {
                      setSelectedUser(user);
                      toggleReactiveUserModal(true);
                    },
                  };
                case 'deactivate':
                  return {
                    label: 'Deactivate',
                    icon: <StopOutlined />,
                    handler: () => {
                      setSelectedUser(user);
                      toggleDeactivateUserModal(true);
                    },
                  };
                case 'edit':
                  return {
                    label: 'Edit',
                    icon: <EditOutlined />,
                    handler: () => {
                      // Displaying the defaultValue
                      setSelectedUser(user);
                      setSelectedOptionForUpdateUserRole({
                        label: UserRoleDisplay[user.role],
                        value: UserRoleDisplay[user.role],
                      });
                      toggleUpdateUserRoleModal(true);
                    },
                  };
                case 'resendInvitation':
                  return {
                    label: 'Resend invitation',
                    icon: <EditOutlined />,
                    handler: () => {
                      setSelectedUser(user);
                      toggleResendInvitationUserModal(true);
                    },
                  };
                default:
                  return {
                    label: '',
                    handler: () => { },
                  };
              }
            })}
            ariaLabel={`${user.email} actions menu`}
          />
        );
      },
    },
  ].filter((obj) => !isEmpty(obj));

  return (
    <>
      <Row gutter={8} style={{ marginTop: 8 }}>
        <Col style={{ paddingLeft: 0 }}>
          <Search onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
        </Col>
        <Col>
          {isAdmin && currentProvider === '' && (
            <Select value="Provider" defaultValue={'Provider'} onSelect={onSelectProvider} style={{ minWidth: 240 }}>
              {options?.map((option) => {
                return (
                  <Option
                    key={option.value?.toString()}
                    value={option.value}
                    label={option.label}
                    style={{ backgroundColor: option.isSelected && '#F0F8F7' }}
                  >
                    <Row align="middle" justify="space-between">
                      <Col>{option.label}</Col>
                      <Col>{option.isSelected ? <CheckOutlined style={{ color: '#10655E' }} /> : <></>}</Col>
                    </Row>
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 8, marginBottom: selectedOptions?.length && 24 }} align="middle">
        {selectedOptions.map((option: DefaultOptionType) => {
          return (
            <Col
              key={option.value}
              style={{
                border: '1px solid #D9D9D9',
                background: '#FAFAFA',
                padding: '2px 0px 2px 8px',
                width: 'fit-content',
                marginRight: 8,
              }}
            >
              <Row align="middle">
                <Col>
                  <span>{option.label}</span>
                </Col>
                <Col>
                  <Button
                    style={{ border: 'unset' }}
                    icon={<CloseOutlined style={{ color: 'black', fontSize: '12px' }} />}
                    size="small"
                    onClick={() => {
                      const options = selectedOptions.slice();
                      setSelectedOptions(options.filter((o) => o.value !== option.value));
                    }}
                  />
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
      <Table
        rowKey="id"
        dataSource={list}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          pageSizeOptions: [10, 20, 30, 50, 100],
          itemRender: CustomPrevNextButtonRender,
          showTotal: (total, range) => (
            <div style={{ position: 'absolute', left: 16 }}>{`${range[0]} - ${range[1]} of ${total} items`}</div>
          ),
        }}
        loading={loading}
      />
      {selectedUser && openUpdateUserRoleModal && (
        <EditRoleModal
          openModal={openUpdateUserRoleModal}
          onClose={onCloseUpdateUserRoleModal}
          onUpdate={updateRoleSelectedUser}
          selectedOption={selectedOptionForUpdateUserRole}
          setSelectedOption={setSelectedOptionForUpdateUserRole}
          selectedUser={selectedUser}
          role={role}
          errorMessage={errorMessage}
          isProcessing={isUpdatingUser}
        />
      )}
      {selectedUser && openDeactivateUserModal && (
        <DeactivateUserModal
          openModal={openDeactivateUserModal}
          onClose={onCloseDeactivateUserModal}
          onUpdate={deactivateSelectedUser}
          selectedUser={selectedUser}
        />
      )}
      {selectedUser && openReactiveUserModal && (
        <ReactivateUserModal
          openModal={openReactiveUserModal}
          onClose={onCloseReactiveUserModal}
          onUpdate={reactivateSelectedUser}
          selectedUser={selectedUser}
        />
      )}
      {selectedUser && openResendInvitationUserModal && (
        <ResendInvitationModal
          openModal={openResendInvitationUserModal}
          onClose={onCloseResendInvitationUserModal}
          onUpdate={resendInvitationToSelectedUser}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
};
