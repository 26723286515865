import { z } from 'zod';

import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const conditionsSchema = z
  .object({
    conditions: z
      .array(
        z.object({
          conditions: z
            .array(
              z.object({
                type: z.string().nonempty({ message: 'Required' }),
                description: z
                  .string()
                  .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, {
                    message: 'Required',
                  })
                  .refine((val) => removeHTMLTags(val).length <= 2000, {
                    message: 'Should not be more than 2000 characters',
                  }),
              })
            )
            .max(5, { message: 'You cannot have more than 5 conditions.' }),
        })
      )
      .optional(),
    conditionsError: z.void(),
  })
  .superRefine((val, ctx) => {
    const types = Array.from(new Set(val.conditions?.[0].conditions?.map((e) => e.type)));

    if (types[0] !== '' && types.length !== val.conditions?.[0].conditions.length && val.conditions) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['conditionsError'],
        message: 'You cannot have more than one occurrence of a requirement type.',
      });
    }
  });
