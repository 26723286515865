import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card, Col, Row } from 'antd';

import GenericInput from 'components/form/shared/GenericInput';
import { StateOptions } from './common/definitions';
import { IProfileSchema } from './schema';
import { enumToArray } from 'components/form/shared/FormHelpers';
import { ProviderType } from 'enums';

interface IProfileSummaryFormProps {
  readOnly?: boolean;
}

const ProfileSummaryForm: React.FC<IProfileSummaryFormProps> = ({ readOnly = false }) => {
  const {
    control,
    formState: { errors },
    getValues,
    ...rest
  } = useFormContext<IProfileSchema>();

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <GenericInput
              name="legalName"
              type="text"
              label="Legal name"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={100}
              errors={errors['legalName']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="tradingName"
              type="text"
              label="Trading name"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={100}
              errors={errors['tradingName']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="providerType"
              type="radio"
              disabled
              required
              label="Provider type"
              control={control}
              showCount
              options={enumToArray(ProviderType)}
              errors={errors['providerType']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="orgId"
              required
              disabled
              label="Provider code (TCSI\RTO)"
              control={control}
              showCount
              maxLength={5}
              errors={errors['orgId']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="extId"
              type="text"
              label="Abbreviated name"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={50}
              errors={errors['extId']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="providerAbout"
              type="quill"
              label="Description"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={5000}
              errors={errors['providerAbout']}
              {...rest}
            />
          </Col>
        </Row>
      </Card>

      <Card>
        <div>
          <h1>Address</h1>
        </div>

        <Row>
          <Col span={24}>
            <GenericInput
              name="address.addressLine1"
              type="text"
              label="Official street address"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['addressLine1'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="address.addressLine2"
              type="text"
              label="AddressLine2"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['addressLine2'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row style={{ gap: 10 }}>
          <Col span={6}>
            <GenericInput
              name="address.suburb"
              type="text"
              label="Suburb"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['suburb'] : undefined}
              {...rest}
            />
          </Col>
          <Col span={6}>
            <GenericInput
              name="address.state"
              type="select"
              label="State"
              options={StateOptions}
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['state'] : undefined}
              {...rest}
            />
          </Col>
          <Col span={6}>
            <GenericInput
              name="address.postCode"
              type="text"
              label="Postcode"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={10}
              errors={errors['address'] ? errors['address']['postCode'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row style={{ gap: 10 }}>
          <Col span={6}>
            <GenericInput
              name="address.latitude"
              type="text"
              label="Latitude"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['latitude'] : undefined}
              {...rest}
            />
          </Col>
          <Col span={6}>
            <GenericInput
              name="address.longitude"
              type="text"
              label="Longitude"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['longitude'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row style={{ gap: 10 }}>
          <Col span={6}>
            <GenericInput
              name="phoneNumber"
              type="text"
              label="Contact phone number"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={16}
              errors={errors['phoneNumber']}
              {...rest}
            />
          </Col>
          <Col span={6}>
            <GenericInput
              name="email"
              type="text"
              label="Contact email/URL"
              options={StateOptions}
              control={control}
              disabled={readOnly}
              showCount
              maxLength={100}
              errors={errors['email']}
              descriptionAfter="e.g. https://example.com"
              {...rest}
            />
          </Col>
          <Col span={6}>
            <GenericInput
              name="enquiryEmail"
              type="email"
              label="Enquiries Email"
              control={control}
              disabled={readOnly}
              required
              showCount
              maxLength={100}
              errors={errors['enquiryEmail']}
              descriptionAfter="e.g. support@sample.edu.au"
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="website"
              type="text"
              label="Website URL"
              control={control}
              disabled={readOnly}
              showCount
              maxLength={256}
              errors={errors['website']}
              descriptionAfter="e.g. https://example.com"
              {...rest}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default memo(ProfileSummaryForm);
