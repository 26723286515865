import { useBoolean } from 'ahooks';
import { Button, Divider, Row } from 'antd';
import { UserContext } from 'components/UserContextProvider';
import { useContext, useState } from 'react';
import CourseList from './CourseList';
import CreateCredentialModal from './CreateCredentialModal';
import { makeStyles } from 'utils/theme';
import BulkImportCredentialModal from './bulkImportCredential/BulkImportCredentialModal';

const useStyles = makeStyles()(() => ({
  btnImportCourse: {
    marginRight: 16,
  },
}));

const Courses = () => {
  const { classes } = useStyles();
  const { isAdmin, currentProvider } = useContext(UserContext);

  const [addingCourse, setAddingCourse] = useState(false);
  const [importingCourse, setImportingCourse] = useState(false);
  const [, { set: setAddCourseLoading }] = useBoolean(true);
  const [, { set: setImportCourseLoading }] = useBoolean(true);

  const closeAddCourseDialog = () => {
    setAddingCourse(false);
  };

  const closeImportCourseDialog = () => {
    setImportingCourse(false);
  };

  return (
    <>
      <Row justify="space-between" align="top">
        <h2 style={{ paddingTop: '1px' }}>Microcredentials</h2>
        <Row justify="end" align="top">
          <Button
            type="default"
            id="import-credential-button"
            className={classes.btnImportCourse}
            onClick={() => {
              setImportingCourse(true);
            }}
            disabled={isAdmin && !currentProvider}
          >
            Import
          </Button>
          <Button
            type="primary"
            id="create-credential-button"
            onClick={() => {
              setAddingCourse(true);
            }}
            disabled={isAdmin && !currentProvider}
          >
            Add microcredential
          </Button>
        </Row>
        <CreateCredentialModal
          visible={addingCourse}
          handleCancel={closeAddCourseDialog}
          setLoading={setAddCourseLoading}
        />
        <BulkImportCredentialModal
          visible={importingCourse}
          handleCancel={closeImportCourseDialog}
          setLoading={setImportCourseLoading}
        />
      </Row>

      <Divider />

      <CourseList />
    </>
  );
};

export default Courses;
