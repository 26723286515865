import 'config/configureAwsAmplify';
import { GlobalContextProvider } from 'contexts/GlobalContext';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.less';
import { AppRouters } from 'Routers';

ReactDOM.render(
  <HashRouter>
    <GlobalContextProvider>
      <AppRouters />
    </GlobalContextProvider>
  </HashRouter>,
  document.getElementById('root')
);
