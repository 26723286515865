import { memo, ReactNode, useState } from 'react';
import { Form, AutoComplete } from 'antd';
import { Controller, Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import GenericLabel from './GenericLabel';
import Search from 'antd/lib/input/Search';

type GenericAutoCompleteProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>;
  label?: string;
  placeholder?: string;
  descriptionBefore?: ReactNode | string;
  descriptionAfter?: ReactNode | string;
  errors?: FieldError;
  control: Control<any>;
  options?: { value: string | number; label: string }[];
  rules?: Record<string, unknown>;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
};

const GenericAutoComplete = ({
  name,
  label = '',
  placeholder,
  descriptionBefore,
  descriptionAfter,
  options,
  rules,
  errors,
  control,
  className,
  required = false,
  readOnly = false,
  disabled = false,

}: GenericAutoCompleteProps) => {
  const hasError = !!errors;

  const [selectedValue, setSelectedValue] = useState<string>('');

  const getValidationStatus = () => {
    if (hasError) {
      return 'error';
    }
    return undefined;
  };

  const getHelpMessage = () => (hasError ? errors?.message : undefined);


  return (
    <Form.Item
      label={<GenericLabel label={label} required={required} />}
      validateStatus={getValidationStatus()}
      help={getHelpMessage()}
    >
      {descriptionBefore ? <p>{descriptionBefore}</p> : undefined}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          if (field.value) {
            const filterProvider = options?.find((option) => option.value === field.value);
            if (filterProvider) {
              setSelectedValue(filterProvider.label);
            }
          }
          return (
            <AutoComplete
              {...field}
              options={options}
              className={className}
              placeholder={placeholder}
              disabled={disabled}
              value={selectedValue}
              onBlur={() => {
                field.onBlur();
                field.onChange(field.value);
              }}
              onChange={(value) => {
                setSelectedValue(value);
                field.onChange(value);
              }}
              onSelect={(value, option) => {
                field.onChange(value);
                setSelectedValue(option.label);
              }}
              filterOption={(inputValue, option) => {
                const optionData = option as any; //TODO: as OptionData | OptionGroupData
                if (optionData.searchValue && optionData.searchValue !== "") {
                  return optionData.searchValue.toLowerCase().startsWith(inputValue.toLowerCase());
                }

                return optionData.label.toLowerCase().includes(inputValue.toLowerCase());
              }}
            >
              <Search placeholder="Search" />
            </AutoComplete>
          )
        }}
      />
      {descriptionAfter ? <p style={{ color: 'rgba(170, 170,170)' }}>{descriptionAfter}</p> : undefined}
    </Form.Item>
  );
};

export default memo(GenericAutoComplete);
