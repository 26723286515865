import { memo } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { ICredentialSchema } from './schema';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStyles } from './common/useStyles';
import { enumToKeyValueArray } from 'components/form/shared/FormHelpers';
import { RecognitionTypeDropdown } from './common/reference';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';

const recognitionTypeOptions = enumToKeyValueArray(RecognitionTypeDropdown);

const CourseCreditRecognitionForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('5');

  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    ...formFields
  } = useFormContext<ICredentialSchema>();

  const rest = {
    ...formFields,
    disabled: isFormReadOnly,
  };

  const handleFieldDirty = () => setIsDirty(true);

  const {
    fields: recognitionFields,
    append: appendRecognition,
    remove: removeRecognition,
    move: moveRecognition,
  } = useFieldArray({
    control,
    name: `recognitions`,
  });

  const onAddClick = () => {
    if (recognitionFields.length >= 5) {
      return;
    }

    appendRecognition({
      type: '',
      title: '',
      description: '',
      logo: '',
    });

    handleFieldDirty();
  };

  return (
    <Card>
      <div>
        <h1>Credit & Recognition</h1>
      </div>
      {recognitionFields.map((recognition, index) => {
        return (
          <Card key={recognition.id} className={classes.credCard} size="small">
            <Space className={classes.credCard__actions}>
              <Button
                size="small"
                onClick={() => {
                  moveRecognition(index, index - 1);
                  handleFieldDirty();
                }}
                disabled={recognitionFields.length <= 1 || index === 0 || rest.disabled}
              >
                <ArrowUpOutlined />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  moveRecognition(index, index + 1);
                  handleFieldDirty();
                }}
                disabled={recognitionFields.length <= 1 || index === recognitionFields.length - 1 || rest.disabled}
              >
                <ArrowDownOutlined />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  removeRecognition(index);
                  handleFieldDirty();
                }}
                danger
                icon={<DeleteOutlined />}
                disabled={rest.disabled}
              />
            </Space>
            <Row key={recognition.id}>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      label="Type"
                      name={`recognitions[${index}].type`}
                      control={control}
                      required
                      descriptionBefore="Select a recognition type"
                      type="select"
                      options={recognitionTypeOptions}
                      errors={errors.recognitions && (errors.recognitions[index]?.type as FieldError)}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      label="Title of this recognition"
                      name={`recognitions[${index}].title`}
                      control={control}
                      showCount
                      required
                      maxLength={500}
                      errors={errors.recognitions && (errors.recognitions[index]?.title as FieldError)}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      label="Description of this recognition"
                      name={`recognitions[${index}].description`}
                      control={control}
                      showCount
                      required
                      type="quill"
                      maxLength={2000}
                      errors={errors.recognitions && (errors.recognitions[index]?.description as FieldError)}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      label="Logo URL"
                      name={`recognitions[${index}].logo`}
                      control={control}
                      showCount
                      descriptionAfter="e.g. https://example.com"
                      maxLength={256}
                      type="text"
                      errors={errors.recognitions && (errors.recognitions[index]?.logo as FieldError)}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        );
      })}
      <br />
      {recognitionFields.length < 5 && (
        <Button onClick={onAddClick} disabled={rest.disabled}>
          Add <PlusOutlined />
        </Button>
      )}
      {errors?.recognitionError?.message && (
        <div className={classes.errorText}>{errors?.recognitionError?.message}</div>
      )}
    </Card>
  );
};

export default memo(CourseCreditRecognitionForm);
