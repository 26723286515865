import { Auth } from '@aws-amplify/auth';
import API from 'api';

export enum UserRole {
  PROVIDER_ADMIN = 'ProviderAdmin',
  PROVIDER_EDITOR = 'ProviderEditor',
  DESE_EDITOR = 'DeseEditor',
  ADMIN = 'Admin',
}

export const ProviderRoleDisplay = {
  [UserRole.PROVIDER_ADMIN]: 'Provider Admin',
  [UserRole.PROVIDER_EDITOR]: 'Provider Editor',
}

export const UserRoleDisplay = {
  ...ProviderRoleDisplay,
  [UserRole.DESE_EDITOR]: 'Dese Editor',
  [UserRole.ADMIN]: 'UAC Admin'
}

export enum RoleNav {
  ProviderAdmin = '/provider',
  ProviderEditor = '/provider',
  DeseEditor = '/dese',
  Admin = '/admin',
}

export const getUserDefaultNavigation = (user: any): string => {
  // Default to Provider Editor
  const role = (user?.[0]?.role as UserRole) || UserRole.PROVIDER_EDITOR;
  return RoleNav[role];
};

export const signOut = async (providerId: string, callback: () => void) => {
  try {
    if (window.location.href.includes('/courses')) {
      const splitResult = window.location.href.split('/courses/');
      const [courseId, leftLink] = splitResult[splitResult.length - 1].split('/');

      if (splitResult?.[0].includes('/provider')) {
        await API.Cred2.unlock(providerId, courseId);
      } else {
        await API.Cred2.unlock(courseId, leftLink);
      }
    }

    await Auth.signOut();
    return callback();
  } catch (error) {
    console.error(error);
  }
};
