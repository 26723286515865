import { makeStyles } from 'utils/theme';

export const useStyles = makeStyles()(() => ({
  credCard: {
    marginTop: '1rem',

    'div.ant-card-body': {
      position: 'relative',
      backgroundColor: '#fafafa',
    },
  },
  credCard__actions: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 1,
  },
  errorText: {
    color: '#ff4d4f',
    marginTop: '1rem'
  }
}));
