import { handleAPIPromise, IError } from 'api/utils';
import axios from './_utils/axiosInstance';
import { IUser } from 'model/user';
import { ICampus, IPolicy, ProviderSetting, SummaryInfo } from 'types/provider';
import { IUserCreationRequest } from './admin';

export interface IDashboard {
  providerId: string;

  draftCount: number;
  publishedCount: number;
  reviewingCount: number;

  draftCreds: IDashboardCredItem[];
  reviewCreds: IDashboardCredItem[];
  publishedCreds: IDashboardCredItem[];
  noActiveSessionsCreds: IDashboardCredItem[];
}

export interface IDashboardCredItem {
  id: string;
  title: string;
  courseCode: string;
  updatedAt: number;
  publishedAt: number;
}

export interface IEnquirySummary {
  currentMonth: number;
  tillDate: number;
}

export interface IGeneralEnquiryInput {
  date: string;
}

export interface ICourseEnquiryInput {
  date: string;
  publishedOnly?: boolean;
  credId?: string;
}

const getUserInfo = async () => {
  return await handleAPIPromise<IUser>(axios.get(`/provider/user`));
};

const getUser = async (userID: string) => {
  return await handleAPIPromise(axios.get(`/provider/user/${userID}`));
};

const createUser = async (payload: IUserCreationRequest) => {
  return await handleAPIPromise(axios.post(`/provider/user`, payload));
};

const signInDeclaration = async () => {
  return await handleAPIPromise(axios.post(`/provider/user/sign-in-declaration`, {}));
};

const InitializeJsmAccount = async (payload: { email: string; displayName: string; providerId: string }) => {
  return await handleAPIPromise(axios.post(`/provider/user/init-jsm-account`, { ...payload }));
};

const changePassword = async (payload: {
  username: string;
  newPassword: string;
}): Promise<[string | undefined, IError | undefined]> => {
  return await handleAPIPromise(axios.post(`/provider/user/change-password`, { ...payload }));
};

const forgotPassword = async (username: string): Promise<[string | undefined, IError | undefined]> => {
  return await handleAPIPromise(axios.post(`/provider/user/forgot-password`, { username }));
};

const get = async (providerId: string) => {
  return await handleAPIPromise(axios.get(`/provider/${providerId}`));
};

const update = async (providerId: string, formData: SummaryInfo | ICampus[] | IPolicy[] | ProviderSetting | any) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}`, formData));
};

const dashboard = async (providerId: string): Promise<[IDashboard | undefined, IError | undefined]> => {
  const [data, error] = await handleAPIPromise(axios.get(`/provider/${providerId}/dashboard`)).then();
  return [data, error];
};

const generalEnquirySummary = async (
  providerId: string,
  payload: IGeneralEnquiryInput
): Promise<[IEnquirySummary | undefined, IError | undefined]> => {
  const [data, error] = await handleAPIPromise(axios.post(`/provider/${providerId}/enquiry/list`, payload));
  return [data, error];
};

const courseEnquirySummary = async (
  providerId: string,
  payload: ICourseEnquiryInput
): Promise<[IEnquirySummary | undefined, IError | undefined]> => {
  const [data, error] = await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/enquiry/list`, payload));
  return [data, error];
};

const uploadLogo = (id: string, logoDataBase64: string, isRect: boolean) => {
  return handleAPIPromise(
    axios.post(`/provider/${id}/uploadLogo${isRect ? '?rect=true' : ''}`, {
      logoData: logoDataBase64,
    })
  );
};

const generateSignedUrl = async (providerId: string, payload: { courseCodes: string[] }) => {
  return await handleAPIPromise<{ signedUrl: string }>(
    axios.post(`/provider/${providerId}/cred2/generate-signedUrl`, payload)
  );
};

const uploadCourseFileViaPreSignedUrl = async (preSignedUrl: string, payload: { body: File }) => {
  return await handleAPIPromise(axios.put(preSignedUrl, payload));
};

const ProviderAPI = {
  getUserInfo,
  getUser,
  createUser,
  signInDeclaration,
  changePassword,
  forgotPassword,
  get,
  update,
  dashboard,
  uploadLogo,
  InitializeJsmAccount,
  generalEnquirySummary,
  courseEnquirySummary,
  generateSignedUrl,
  uploadCourseFileViaPreSignedUrl,
};

export default ProviderAPI;
