import { z } from 'zod';
import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const teamSchema = z.object({
  academics: z
    .array(
      z.object({
        name: z.string().max(200).nonempty({ message: 'Required' }),
        title: z.string().max(200).nonempty({ message: 'Required' }),
        aboutP1: z
          .string()
          .nonempty('Required')
          .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
          .refine((val) => removeHTMLTags(val).length <= 1000, { message: 'Should not be more than 1000 characters' }),
        photo: z
          .union([
            z.string().max(256).url({ message: 'Should match format URL' }),
            z.literal('').transform((value) => (value === '' ? null : value)),
          ])
          .nullable()
          .optional(),
        url: z
          .union([
            z.string().max(256).url({ message: 'Should match format URL' }),
            z.literal('').transform((value) => (value === '' ? null : value)),
          ])
          .nullable()
          .optional(),
      })
    )
    .nullable()
    .optional(),
});
