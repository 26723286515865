import * as z from 'zod';

import { removeHTMLTags } from 'utils/validation';

export const glossarySchema = z.object({
  title: z.string().min(1, 'Required'),
  tableColumn1: z.string().min(1, 'Required'),
  tableColumn2: z.string().min(1, 'Required'),
  table: z.array(
    z.object({
      title: z.string().refine((val) => removeHTMLTags(val).length !== 0, {
        message: 'Required',
      }),
      description: z.string().refine((val) => removeHTMLTags(val).length !== 0, {
        message: 'Required',
      }),
    })
  ),
});

export type IGlossarySchema = z.infer<typeof glossarySchema>;
