import React, { useSafeState } from 'ahooks';
import Modal from 'antd/lib/modal/Modal';
import { memo, useContext } from 'react';
import { makeStyles } from 'utils/theme';
import * as XLSX from 'xlsx';
import { parseCourseDataJson } from './BulkImportCredentialModal.utils';
import { rawCourseJsonSchema } from './bulkCourseCreation.schema';
import { IBulkCourseCreationError } from './BulkImportCredentialModal.types';
import { ZodError } from 'zod';
import BeforeStartUpload from './BeforeStartUpload';
import UploadHasError from './UploadHasError';
import API from 'api';
import { ProviderContext } from 'components/ProviderContextProvider';
import { Button } from 'antd';
import axios from 'axios';
import UploadSuccessful from './UploadSuccessful';
import { findDuplicateCourses } from 'utils/course.utils';
import { isEmpty, remove } from 'lodash';

const useStyles = makeStyles()(() => ({
  modalWrapper: {
    '& .ant-modal-body': {
      padding: '0.5rem 1rem',
    },
  },
}));

const allowedFileFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const courseStartingRow = 2;

interface IBulkImportCredentialModalProps {
  visible: boolean;
  handleCancel: () => void;
  setLoading: (loading: boolean) => void;
}

const BulkImportCredentialModal = ({ visible, handleCancel, setLoading }: IBulkImportCredentialModalProps) => {
  const [file, setFile] = useSafeState<File | null>(null);
  const [errorMessage, setErrorMessage] = useSafeState<string>('');
  const [errorList, setErrorList] = useSafeState<IBulkCourseCreationError[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useSafeState<boolean>(false);
  const [isFilUploaded, setIsFileUploaded] = useSafeState<boolean | undefined>(undefined);
  const [totalUploadedCourseCount, setTotalUploadedCourseCount] = useSafeState<number | undefined>(undefined);

  const {
    provider: { providerId },
  } = useContext(ProviderContext);

  const { classes } = useStyles();

  const handleFileUploaded = (file: File | null) => {
    if (file === null) {
      setFile(null);
      return;
    }
    if (file?.type && allowedFileFormats.includes(file?.type)) {
      setFile(file);
    } else {
      setFile(null);
      setErrorMessage('Unsupported file type.');
    }
  };

  const handleSubmit = () => {
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
      const courseRawDataJson = parseCourseDataJson(workbook);

      const errorList: IBulkCourseCreationError[] = [];

      try {
        setIsLoading(true);

        // firstly try to find duplicate courseCodes from the template file
        const coursesCodesLookup = courseRawDataJson
          .map((course, index) => ({ index, courseCode: course['summary/courseCode']?.trim().toUpperCase() }))
          .filter((x) => x.courseCode!!);
        const duplicatedCourse = findDuplicateCourses(coursesCodesLookup, true);

        if (duplicatedCourse.length) {
          for (const { index, courseCode } of duplicatedCourse) {
            errorList.push({
              rowNumber: String(index + courseStartingRow),
              courseCode,
              message: 'Course code must be unique.',
            });
          }
        }

        let courseCodesLookupToCheck = [...coursesCodesLookup];

        remove(courseCodesLookupToCheck, (course) => {
          return duplicatedCourse.map((x) => x.index).includes(course.index);
        });

        const courseCodesToCheck = courseCodesLookupToCheck.map((x) => x.courseCode);

        const [urlData, error] = await API.Provider.generateSignedUrl(providerId!, {
          courseCodes: courseCodesToCheck,
        });

        if (error) {
          if (error.message.includes('Below courseCode(s) already exist in database.')) {
            const courseCodesAlreadyExists = error.message
              .replace('Below courseCode(s) already exist in database.', '')
              .trim()
              .split(',') as string[];

            for (const courseCode of courseCodesAlreadyExists) {
              const courseIndex = courseRawDataJson.findIndex(
                (course) => course['summary/courseCode']?.toUpperCase() === courseCode?.toUpperCase()
              );

              if (courseIndex === -1) continue;
              errorList.push({
                rowNumber: String(courseIndex + courseStartingRow),
                courseCode: !!courseCode ? courseCode : '',
                message: 'Course code must be unique.',
              });
            }
          }
        }
        rawCourseJsonSchema.parse(courseRawDataJson);

        // start uploading if no error
        if (!errorList.length && urlData?.signedUrl && file) {
          try {
            await axios.put(urlData?.signedUrl, file, { headers: { 'Content-Type': file.type } });
            setIsFileUploaded(true);
          } catch (error) {
            setErrorMessage('Something went wrong, please try again later.');
            setIsFileUploaded(false);
          } finally {
            setTotalUploadedCourseCount(courseRawDataJson.length);
          }
        }
      } catch (error) {
        const zodError = error as ZodError;
        if (zodError.errors?.length === 1) {
          const err = zodError.errors[0];
          if (isEmpty(err.path) && err.message === 'Array must contain at least 1 element(s)') {
            setErrorMessage('Invalid credential bulk import template.');
            setIsFileUploaded(false);
            return;
          }
        }
        for (const error of zodError.errors) {
          const { path, message } = error;

          const [rowNumber, field] = path;
          errorList.push({
            rowNumber: String(+rowNumber + courseStartingRow),
            courseCode: !!courseRawDataJson[+rowNumber]['summary/courseCode']
              ? String(courseRawDataJson[+rowNumber]['summary/courseCode'])
              : '',
            message: message.includes('Invalid enum value.')
              ? message.replace('Invalid enum value. Expected', `${String(field)} must be one of the following values:`)
              : message,
          });
        }
      } finally {
        setErrorList(errorList);
        setIsLoading(false);
      }
    };
    if (file) reader.readAsArrayBuffer(file);
  };

  const onHandleCancel = () => {
    handleCancel();
    setFile(null);
    setErrorMessage('');
    setErrorList(undefined);
    setIsFileUploaded(undefined);
  };

  const getModalTitle = () => {
    if (!errorList?.length && !errorMessage) {
      return isLoading ? 'Import in progress' : isFilUploaded ? 'File uploaded' : 'Import microcredentials';
    } else {
      return isLoading ? 'Import in progress' : 'Import failed';
    }
  };

  return (
    <Modal
      title={getModalTitle()}
      open={visible}
      onCancel={onHandleCancel}
      footer={[
        <Button key="back" type={isFilUploaded ? 'primary' : 'default'} onClick={onHandleCancel}>
          {!errorList?.length && !errorMessage && isFilUploaded === undefined ? 'Cancel' : 'Close'}
        </Button>,
        !errorList?.length && !errorMessage && isFilUploaded !== true && (
          <Button key="submit" type="primary" onClick={handleSubmit} disabled={!file} loading={isLoading}>
            Import
          </Button>
        ),
      ]}
      destroyOnClose
      maskClosable={false}
      className={classes.modalWrapper}
    >
      <div>
        {!errorList && !errorMessage && <BeforeStartUpload onFileUploaded={handleFileUploaded} />}
        {errorMessage && <UploadHasError errorMessage={errorMessage} />}
        {file && !!errorList?.length && <UploadHasError errorList={errorList} />}
        {isFilUploaded && !!totalUploadedCourseCount && (
          <>
            <UploadSuccessful totalCoursesCount={totalUploadedCourseCount} />
          </>
        )}
      </div>
    </Modal>
  );
};

export default memo(BulkImportCredentialModal);
