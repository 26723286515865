import { Button, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { signOut } from 'utils/UserUtils';

import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { UserContext } from 'components/UserContextProvider';

import './headerMenu.less';

const { Title } = Typography;

const HeaderMenu = () => {
  const navigate = useNavigate();

  const { currentProvider } = useContext(UserContext);

  return (
    <div className="container">
      <Title level={4}>{currentProvider}</Title>
      <div className="header-end">
        <Button
          type="link"
          href="https://uac.helpjuice.com/marketplace/secrets/9dAvPhMhiU9wIcL5Kdf-FQ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Centre
          <Icon component={ExternalLink} />
        </Button>
        <Button className="primary-sign-out" onClick={() => signOut(currentProvider, () => navigate('/'))} type="primary">
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default HeaderMenu;
