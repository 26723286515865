import moment from 'moment';

export const enumToArray = (myEnum: object) => {
  return Object.entries(myEnum).map(([key, value]) => ({ label: value, value }));
};

export const enumToKeyValueArray = (myEnum: object) => {
  return Object.entries(myEnum).map(([key, value]) => ({ label: key, value }));
};

// Inverted version of enumToKeyValueArray
export const enumToValueKeyArray = (myEnum: object) => {
  return Object.entries(myEnum).map(([key, value]) => ({ label: value, value: key }));
};

export const transformMomentToDate = (value: moment.Moment | string | null): string | null => {
  if (moment.isMoment(value)) {
    return value.format('YYYY-MM-DD');
  }
  return value;
};
