import { useSafeState } from 'ahooks';
import { Spin } from 'antd';
import { UserContext } from 'components/UserContextProvider';
import { useContext, useEffect, useState } from 'react';
import { formatEnquiryDate } from 'utils/date';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../api';
import ProviderAPI, { IDashboard } from '../../../api/provider';
import DashboardCourseList from './DashboardCourseList';
import DashboardEnquiryList, { IEnquirySummaryProps } from './DashboardEnquiryList';
import NoActiveSessionCourseList from './NoActiveSessionCourseList';
import Overview from './Overview';

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { currentProvider } = useContext(UserContext);
  const [data, setData] = useSafeState<IDashboard | undefined>();
  const [enquiryData, setEnquiryData] = useState<IEnquirySummaryProps | undefined>();

  const fetchEnquiryData = async () => {
    const [[generalEnquiryData, fetchGeneralEnquiryError], [courseEnquiryData, fetchCourseEnquiryError]] =
      await Promise.all([
        ProviderAPI.generalEnquirySummary(currentProvider, {
          date: formatEnquiryDate(Date.now()),
        }),
        ProviderAPI.courseEnquirySummary(currentProvider, {
          date: formatEnquiryDate(Date.now()),
          publishedOnly: true,
        }),
      ]);

    if (!fetchGeneralEnquiryError && !fetchCourseEnquiryError) {
      setEnquiryData({
        general: generalEnquiryData,
        credential: courseEnquiryData,
      });
    }
  };

  useEffect(() => {
    const resetLocation = () => {
      if (!location.pathname.endsWith('/provider')) {
        navigate('/provider');
      }
    };
    resetLocation();
  }, [location.pathname, navigate]);

  useEffect(() => {
    const provider = currentProvider;
    if (provider) {
      API.Provider.dashboard(provider).then(([data, error]) => {
        setData(data);
      });
      fetchEnquiryData();
    }
    return () => {
      setData(undefined);
    };
  }, [currentProvider]);

  if (!data) {
    return (
      <>
        <Spin />
      </>
    );
  }

  return (
    <>
      <h2>Dashboard</h2>
      <Overview
        draftCount={data.draftCount}
        publishedCount={data.publishedCount}
        reviewingCount={data.reviewingCount}
      />
      <br></br>
      <DashboardEnquiryList general={enquiryData?.general} credential={enquiryData?.credential} />
      <br></br>
      <DashboardCourseList />
      <br></br>
      <NoActiveSessionCourseList list={data.noActiveSessionsCreds} />
    </>
  );
};

export default Dashboard;
