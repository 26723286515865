import { Card, List } from "antd";
import API from "api";
import ListItems from "components/common/listItems/ListItems";
import { UserContext } from "components/UserContextProvider";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {IDashboardCredItem} from "api/provider";

const NoActiveSessionCourseList = (props: {
  list: IDashboardCredItem[]
}) => {
  const { currentProvider } = useContext(UserContext);
  return (
    <>
      <Card title="No active sessions" size="small" data-cy="no-active-sessions-card">
        <ListItems

          loader={async (pageIndex?: number, pageSize?: number) => {
            return {
              data: props.list ?? [],
              canLoadMore: false,
            };
          }}
          emptyText="Good news! There are no microcredentials without an active session"
          renderItem={(item: any) => {
            return (
              <List.Item>
                <Link to={`/provider/courses/${item.id}`}>{item.title}</Link>
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};

export default NoActiveSessionCourseList;
