import { memo, ReactNode } from 'react';
import { Form, Input, Select, Switch, Checkbox, Radio, DatePicker, InputNumber } from 'antd';
import { Controller, Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import ReactQuill from 'react-quill';
import GenericLabel from './GenericLabel';
import moment from 'moment';
import { removeHTMLTags } from 'utils/validation';
import { transformMomentToDate } from './FormHelpers';
import 'react-quill/dist/quill.snow.css';
import { makeStyles } from 'utils/theme';

const useStyles = makeStyles()(() => ({
  fieldInput: {
    minWidth: '4rem',
  },
  quillWithError: {
    border: '1px solid red !important',
    '& div.ql-toolbar.ql-snow': {
      borderTop: 'none !important',
      borderLeft: 'none !important',
      borderRight: 'none !important',
    },
    '& pre.ql-container.ql-snow': {
      border: 'none !important',
    },
  },
}));

type GenericInputProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>;
  label?: string;
  placeholder?: string;
  descriptionBefore?: ReactNode | string;
  descriptionAfter?: ReactNode | string;
  errors?: FieldError;
  control: Control<any>;
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'textarea'
    | 'select'
    | 'switch'
    | 'checkbox'
    | 'radio'
    | 'quill'
    | 'date'
    | 'price';
  options?: { value: string | number | boolean; label: string }[];
  rules?: Record<string, unknown>;
  required?: boolean;
  showCount?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  className?: string;
  step?: number;
  addonBefore?: ReactNode | string;
  handleFieldDirty?(): void;
};

const GenericInput = ({
  name,
  label = '',
  placeholder,
  descriptionBefore,
  descriptionAfter,
  type = 'text',
  options,
  rules,
  errors,
  control,
  ariaLabel,
  className,
  required = false,
  addonBefore,
  readOnly = false,
  disabled = false,
  showCount,
  maxLength,
  step = 0.01,
  handleFieldDirty,
}: GenericInputProps) => {
  const { css, cx, classes } = useStyles();
  const hasError = !!errors;

  const getValidationStatus = () => {
    if (hasError) {
      return 'error';
    }
    return undefined;
  };

  const getHelpMessage = () => (hasError ? errors?.message : undefined);

  const inputClassname = cx(className, classes.fieldInput);

  const renderInput = () => {
    switch (type) {
      case 'text':
      case 'password':
      case 'email':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <>
                <Input
                  type={type}
                  addonBefore={addonBefore}
                  readOnly={readOnly}
                  disabled={disabled}
                  showCount={showCount}
                  maxLength={maxLength}
                  aria-label={ariaLabel}
                  className={inputClassname}
                  aria-invalid={hasError}
                  aria-describedby={getHelpMessage()}
                  placeholder={placeholder}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    handleFieldDirty?.();
                  }}
                />
              </>
            )}
          />
        );
      case 'number':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <>
                <Input
                  type={type}
                  {...field}
                  readOnly={readOnly}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={(evt) => {
                    field.onChange(evt.target.value ? Number(evt.target.value) : null);
                    handleFieldDirty?.();
                  }}
                  addonBefore={addonBefore}
                  showCount={showCount}
                  maxLength={maxLength}
                  aria-label={ariaLabel}
                  className={inputClassname}
                  aria-invalid={hasError}
                  aria-describedby={getHelpMessage()}
                />
              </>
            )}
          />
        );
      case 'textarea':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  handleFieldDirty?.();
                }}
                autoSize={{ minRows: 4, maxRows: 40 }}
                readOnly={readOnly}
                placeholder={placeholder}
                disabled={disabled}
                showCount={showCount}
                maxLength={maxLength}
                aria-label={ariaLabel}
                className={inputClassname}
                aria-invalid={hasError}
                aria-describedby={getHelpMessage()}
              />
            )}
          />
        );
      case 'select':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Select
                disabled={disabled}
                options={options}
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  handleFieldDirty?.();
                }}
                aria-label={ariaLabel}
                className={inputClassname}
                aria-invalid={hasError}
                aria-describedby={getHelpMessage()}
                placeholder={placeholder}
              />
            )}
          />
        );
      case 'switch':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Switch
                {...field}
                disabled={disabled}
                aria-label={ariaLabel}
                className={inputClassname}
                aria-invalid={hasError}
                aria-describedby={getHelpMessage()}
                onChange={(event) => {
                  field.onChange(event);
                  handleFieldDirty?.();
                }}
              />
            )}
          />
        );
      case 'checkbox':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={disabled}
                defaultChecked={field.value}
                checked={field.value}
                aria-label={ariaLabel}
                className={inputClassname}
                aria-invalid={hasError}
                aria-describedby={getHelpMessage()}
                onChange={(event) => {
                  field.onChange(event);
                  handleFieldDirty?.();
                }}
              >
                {label}
              </Checkbox>
            )}
          />
        );
      case 'radio':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Radio.Group
                {...field}
                aria-label={ariaLabel}
                className={inputClassname}
                aria-invalid={hasError}
                aria-describedby={getHelpMessage()}
                disabled={disabled}
                onChange={(event) => {
                  field.onChange(event);
                  handleFieldDirty?.();
                }}
              >
                {options?.map((option) => (
                  <Radio key={option.value.toString()} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          />
        );
      case 'quill':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => {
              return (
                <>
                  <ReactQuill
                    theme="snow"
                    preserveWhitespace
                    placeholder={placeholder}
                    {...field}
                    onChange={(userInput: string, _, source) => {
                      if (source === 'api') {
                        return;
                      }
                      field.onChange(userInput);
                      handleFieldDirty?.();
                    }}
                    defaultValue={field.value}
                    // Since readOnly is disabled in quill
                    readOnly={disabled}
                    className={hasError ? cx(className, classes.quillWithError) : className}
                    aria-label={ariaLabel}
                    aria-invalid={hasError}
                    aria-describedby={getHelpMessage()}
                  />
                  {showCount && (
                    <div className={css({ color: 'rgba(171,171,171)', textAlign: 'right' })}>
                      {removeHTMLTags(field.value).length}/{maxLength}
                    </div>
                  )}
                </>
              );
            }}
          />
        );
      case 'date':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            aria-invalid={hasError}
            aria-describedby={getHelpMessage()}
            render={({ field }) => {
              const date = moment(field.value, 'YYYY-MM-DD');
              return (
                <DatePicker
                  onChange={(date, dateString) => {
                    if (date) {
                      const transformedDate = transformMomentToDate(date);
                      field.onChange(transformedDate);
                    } else {
                      field.onChange(undefined);
                    }
                    handleFieldDirty?.();
                  }}
                  defaultValue={date.isValid() ? date : undefined}
                  disabled={disabled}
                  inputReadOnly={readOnly}
                  placeholder={placeholder}
                />
              );
            }}
          />
        );
      case 'price':
        return (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <>
                <InputNumber
                  type={type}
                  {...field}
                  readOnly={readOnly}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={(value) => {
                    field.onChange(value || value === 0 ? Number(value) : null);
                    handleFieldDirty?.();
                  }}
                  addonBefore={addonBefore}
                  step={step}
                  maxLength={maxLength}
                  aria-label={ariaLabel}
                  className={inputClassname}
                  aria-invalid={hasError}
                  aria-describedby={getHelpMessage()}
                  precision={2}
                />
              </>
            )}
          />
        );
      default:
        return <p>Invalid input type</p>;
    }
  };

  return (
    <Form.Item
      label={type !== 'checkbox' && <GenericLabel label={label} required={required} />}
      validateStatus={getValidationStatus()}
      help={getHelpMessage()}
      valuePropName={['checkbox', 'switch'].includes(type?.toLowerCase()) ? 'checked' : undefined}
    >
      {descriptionBefore ? <div>{descriptionBefore}</div> : undefined}
      <Controller control={control} name={name} render={({ field }) => renderInput()} />
      {descriptionAfter ? <div className={css({ color: 'rgba(170, 170,170)' })}>{descriptionAfter}</div> : undefined}
    </Form.Item>
  );
};

export default memo(GenericInput);
