import { createContext, useContext } from 'react';

interface DirtyStateContextType {
  setIsDirty: (key: string, isDirty: boolean) => void;
  isDirtyStates: Record<string, boolean>;
}

const DirtyStateContext = createContext<DirtyStateContextType>({
  setIsDirty: () => {},
  isDirtyStates: {},
});

export const useDirtyStateContext = () => useContext(DirtyStateContext);

export default DirtyStateContext;
