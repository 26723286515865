import { useState, useEffect, useCallback, useRef } from 'react';
import { notification } from 'antd';

import API from 'api';
import { ICourseInfo } from 'api/cred2';
import { IProvider } from 'types/provider';

interface IProps {
  currentProvider: string;
  courseId?: string;
}

export const useCourseFetch = ({ currentProvider, courseId }: IProps) => {
  const [courseCode, setCourseCode] = useState<string>();
  const [courseData, setCourseDataData] = useState<any>();
  const [provider, setProvider] = useState<IProvider>();
  const [courseInfo, setCourseInfo] = useState<ICourseInfo | undefined>();

  const [loading, setLoading] = useState(true);
  const [loadFailed, setLoadFailed] = useState(false);
  const [reloadCount, setReload] = useState<number>(0);
  const [reloadFormCount, setReloadForm] = useState<number>(0);

  const [formsDirty, setFormsDirty] = useState<{ [k: string]: boolean }>({});

  const updateCourseInfo = useCallback(
    (courseInfo: ICourseInfo) => setCourseInfo((prev: ICourseInfo | undefined) => ({ ...(prev ?? {}), ...courseInfo })),
    []
  );

  const getCourseAndProvider = useCallback(async () => {
    if (!courseId) return setLoading(false);
    // console.log('Reload (3)');

    const [providerData] = await API.Provider.get(currentProvider);
    const [courseData, errorCourse] = await API.Cred2.get(currentProvider, courseId);

    if (errorCourse) {
      notification.error({ message: errorCourse.message });
      setLoadFailed(true);
    } else {
      setProvider(providerData);
      if (courseData != null) {
        setCourseCode(courseData.courseCode);
        setCourseDataData(courseData.data);
        setCourseInfo({
          courseCode: courseData.courseCode,
          published: courseData.published,
          publishedUrl: courseData.publishedUrl,
          status: courseData.status,
          history: courseData.history,
          comments: courseData.comments,
        });
      }
    }
    setLoading(false);
  }, [currentProvider, courseId]);

  let lastReloadFormCount = useRef<number>();
  useEffect(() => {
    // console.log('Reload (2)');
    getCourseAndProvider();
    lastReloadFormCount.current = reloadFormCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCount, reloadFormCount]);

  const clearTabFormDirty = useCallback((tab: string) => {
    setFormsDirty((prev) => ({ ...prev, [tab]: false }));
  }, []);

  const clearFormsDirty = useCallback(() => {
    setFormsDirty({});
  }, []);

  const lockCourse = async (providerId: string) => {
    const [resp, err] = await API.Cred2.lock(providerId, courseId as string);

    if (!err) {
      setCourseDataData({ ...courseData, locked: true, lockedBy: resp.data.lockedBy });
    }

    return err;
  };

  const unlockCourse = async (providerId: string) => {
    const [, err] = await API.Cred2.unlock(providerId, courseId as string);

    if (!err) {
      setCourseDataData({ ...courseData, locked: false, lockedBy: '' });
    }

    return err;
  };

  return {
    courseCode,
    courseData,
    provider,
    loading,
    loadFailed,
    courseInfo,
    updateCourseInfo,
    formsDirty,
    clearFormsDirty,
    clearTabFormDirty,
    reloadCount,
    reloadFormCount,
    lockCourse,
    unlockCourse,
    reload: (maintainForm: boolean = false) => {
      // console.log('Reload (1)');
      setReload((reloadCount) => reloadCount + 1);
      if (!maintainForm) {
        setReloadForm((reloadCount) => reloadCount + 1);
      }
    },
  };
};
