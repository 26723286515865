import { memo, useCallback, useEffect, useState } from 'react';
import { AutoComplete, Card, Col, Row, Spin } from 'antd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Search from 'antd/lib/input/Search';
import { DefaultOptionType } from 'antd/lib/select';
import { useParams } from 'react-router-dom';

import { ICredentialSchema } from './schema';
import useCourseList from 'components/hooks/useCourseList';
import { ICourse } from 'model/course';
import { CourseCardWithRemove } from 'components/form/components/courses/CourseCardWithRemove';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';

const CourseRelatedCredentialsForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('9');

  const {
    control,
    formState: { errors },
    setError,
    getValues,
    ...restContext
  } = useFormContext<ICredentialSchema>();

  const handleFieldDirty = () => setIsDirty(true);

  const rest = {
    ...restContext,
    disabled: isFormReadOnly,
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'relatedCredentials',
  });

  const [searchText, setSearchText] = useState<string>('');

  const [options, setOptions] = useState<DefaultOptionType[] | undefined>([]);

  const { list, loading } = useCourseList(true);

  const { courseId } = useParams();

  const searchResult = useCallback(
    (_: string) => {
      return list
        .filter((item: ICourse) => item.published && item.id !== courseId && fields.every((e) => e.value !== item.id))
        .map((item: ICourse) => ({ value: item.id, label: renderLabel(item), searchterms: item.title }));
    },
    [courseId, fields, list]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setOptions(searchResult(value));
    },
    [searchResult]
  );

  const handleOnChange = useCallback((text: string) => {
    setSearchText(text);
    handleFieldDirty();
  }, []);

  // Trigger search on first time
  useEffect(() => {
    handleSearch('');
  }, [handleSearch]);

  const onSelect = useCallback(
    (e: string) => {
      setSearchText('');
      if (fields.length >= 6) {
        setError('relatedCredentials', { type: 'custom', message: 'Cannot have more than 6 items' });
        return;
      } else {
        append({ value: e });
        handleFieldDirty();
      }
    },
    [append, fields.length, setError]
  );

  if (loading) {
    return <Spin />;
  }

  return (
    <Card>
      <div>
        <h1>Related Credentials</h1>
      </div>
      <Row>
        <Col span={24}>
          <Controller
            name="relatedCredentials"
            control={control}
            render={({ field }) => {
              return (
                <AutoComplete
                  {...field}
                  style={{ width: '100%' }}
                  aria-label="credential-search-dropdown"
                  options={options}
                  onSelect={onSelect}
                  value={searchText}
                  filterOption={(inputValue, option) =>
                    (option?.searchterms as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSearch={handleSearch}
                  onChange={handleOnChange}
                  notFoundContent={'No results found'}
                  {...rest}
                >
                  <Search placeholder="Search" onBlur={() => setError('relatedCredentials', {})} />
                </AutoComplete>
              );
            }}
          />

          {errors && <span style={{ color: 'red' }}>{errors['relatedCredentials']?.message}</span>}

          <div className="listContainer">
            {!(fields.length > 0) ? (
              <div className="noItemWarning">No related credentials added yet</div>
            ) : (
              <div>
                {fields.map((item) => {
                  const data = list.find((_item: ICourse) => _item.id === item.value);
                  if (!data) {
                    return null;
                  }
                  return CourseCardWithRemove(
                    data as any,
                    () => {
                      remove(fields.findIndex((e) => e.value === item.value));
                      handleFieldDirty();
                    },
                    false
                  );
                })}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const renderLabel = (itemProps: ICourse) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <span>{itemProps.title}</span>
    <span style={{ fontSize: '12px', color: '#6D7175' }}>{itemProps.courseCode}</span>
  </div>
);

export default memo(CourseRelatedCredentialsForm);
