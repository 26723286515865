import { FC } from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off-line.svg";
import { ReactComponent as PlaneIcon } from "assets/icons/plane-line.svg";

export const RePublishButton: FC<{
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}> = ({ onClick, loading, disabled }) => (
  <Button
    type="default"
    size="large"
    icon={<Icon component={PlaneIcon} />}
    onClick={onClick}
    tabIndex={0}
    loading={loading}
    disabled={disabled}
  >
    Re-publish
  </Button>
);

export const UnpublishButton: FC<{
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}> = ({ onClick, loading, disabled }) => (
  <Button
    type="default"
    size="large"
    icon={<Icon component={EyeOffIcon} />}
    onClick={onClick}
    tabIndex={0}
    loading={loading}
    disabled={disabled}
  >
    Unpublish
  </Button>
);
