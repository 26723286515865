import * as z from 'zod';

import { removeHTMLTags } from 'utils/validation';

export const accessibilitySchema = z.object({
  title: z.string().min(1, 'Required'),
  body: z.string().refine((val) => removeHTMLTags(val).length !== 0, {
    message: 'Required',
  }),
});

export type IAccessibilitySchema = z.infer<typeof accessibilitySchema>;
