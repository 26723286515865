import { DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Menu, Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { ReactNode, useEffect, useState } from "react";

import { handleMenuBlur } from "utils/accessibility";

import { simpleUniqueId } from "utils/string";
import useSelectedIdSet from "../hooks/useSelectedIdsSet";

interface IDropDownFilterProps<Type> {
  id?: string;
  selectedValues: Array<Type>;
  placeholder: string;
  options: Array<Type>;
  multiSelect?: boolean;
  keyName?: string;
  labelName?: string;
  returnObject?: boolean;
  onChange: (filter: Array<Type>) => void;
  renderOption?: (item: Type) => ReactNode;
}

function DropDownFilter<Type>({
  selectedValues,
  options,
  onChange,
  renderOption,
  keyName,
  labelName,
  returnObject = false,
  id = simpleUniqueId(),
  multiSelect = true,
  placeholder = "Filter",
}: IDropDownFilterProps<Type>) {
  // multi-select
  const { addItem, removeItem, isChecked, setSelectedIds } =
    useSelectedIdSet<Type>(selectedValues, options);

  const toggleCheckbox = (e: CheckboxChangeEvent) => {
    const newList = e.target.checked
      ? addItem(e.target.value)
      : removeItem(e.target.value);

    // Do not move this onChange to useEffect hook unless you want a render loop.
    if (returnObject) {
      onChange(
        Array.from(newList).map((id: any) =>
          options.find((o) => (keyName ? (o as any)[keyName] : o) === id)
        ) as Type[]
      );
    } else {
      onChange(Array.from(newList));
    }
  };

  // sync internal selected ids set
  useEffect(() => {
    setSelectedIds(new Set(selectedValues));
  }, [setSelectedIds, selectedValues]);

  // single-select
  const [selectedRadio, setSelectedRadio] = useState<Type>();
  const toggleRadio = (e: any) => {
    setSelectedRadio(e.target.value);
    onChange(e.target.value);
  };

  // renderers
  const [filterMenuVisible, setFilterMenuVisible] = useState<boolean>(false);

  const handleFilterMenuOpenChange = (visible: boolean) => {
    setFilterMenuVisible(visible);
  };

  const optionRenderer = (option: Type) => {
    if (renderOption) {
      return renderOption(option);
    }
    const value = keyName ? (option as any)[keyName] : option;
    const label = labelName ? (option as any)[labelName] : option;
    if (multiSelect) {
      return (
        <Checkbox
          value={value}
          checked={isChecked(value)}
          onChange={toggleCheckbox}
        >
          {label || <i>None</i>}
        </Checkbox>
      );
    }
    return <Radio value={value}>{label}</Radio>;
  };

  const renderFilterOptions = () => {
    const optionsListRendered = options.map((option: any, i: number) => {
      return <Menu.Item key={i}>{optionRenderer(option)}</Menu.Item>;
    });

    return (
      <Menu
        id={`${id}-menu`}
        tabIndex={-1}
        onBlur={(e) => handleMenuBlur(e, setFilterMenuVisible)}
      >
        {multiSelect && optionsListRendered}
        {!multiSelect && (
          <Radio.Group onChange={toggleRadio} value={selectedRadio}>
            {optionsListRendered}
          </Radio.Group>
        )}
      </Menu>
    );
  };

  return (
    <div id={id}>
      {/* Dropdown.Button's accessibility is bad! We have to roll our own menu button. */}
      <Dropdown
        open={filterMenuVisible}
        onOpenChange={handleFilterMenuOpenChange}
        overlay={renderFilterOptions()}
        trigger={["click"]}
        getPopupContainer={() => {
          // pop up in same container for keyboard accessibility
          return document.getElementById(id) as HTMLElement;
        }}
        disabled={!options || options.length === 0}
      >
        <Button
          aria-haspopup="true"
          aria-expanded={filterMenuVisible}
          aria-label={`${placeholder} filter`}
          aria-controls={`${id}-menu`}
        >
          {placeholder} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}

export default DropDownFilter;
