import { memo } from 'react';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { ICredentialSchema } from './schema';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { SkillName } from './Course.types';
import { useStyles } from './common/useStyles';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';
import { SectionTitleRequired } from './common/utils';

const CourseQualitySkillsForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('7');

  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    ...fields
  } = useFormContext<ICredentialSchema>();

  const handleFieldDirty = () => setIsDirty(true);

  const rest = {
    ...fields,
    disabled: isFormReadOnly,
  };

  const {
    fields: qualityFields,
    append: appendQuality,
    remove: removeQuality,
    move: moveQuality,
  } = useFieldArray({
    control,
    name: `quality`,
  });

  const {
    fields: skillsFields,
    append: appendSkills,
    remove: removeSkills,
    move: moveSkills,
  } = useFieldArray({
    control,
    name: `skills`,
  });

  const addQuality = () => {
    appendQuality({ title: '', desc: '', logo: undefined, url: undefined });
    handleFieldDirty();
  };

  const addSkill = () => {
    appendSkills({ name: '' } as any);
    handleFieldDirty();
  };

  return (
    <>
      <Card>
        <Typography.Title level={5}>Quality & Skills</Typography.Title>
        <Typography.Paragraph>
          The assurance that microcredentials are developed and delivered in an educationally sound manner. This may be
          a statement of the quality assurance processes applied to the microcredential (eg provider or CRICOS codes;
          developed with, or in conjunction with, industry partners), an indication of the relevant regulator, or a
          description of the approach to academic integrity and assessment.
        </Typography.Paragraph>
        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Quality
        </Typography.Title>
        {qualityFields.map((quality, index) => {
          return (
            <Card key={quality.id} className={classes.credCard} size="small">
              <Space className={classes.credCard__actions}>
                <Button
                  size="small"
                  onClick={() => {
                    moveQuality(index, index - 1);
                    handleFieldDirty();
                  }}
                  disabled={qualityFields.length <= 1 || index === 0}
                >
                  <ArrowUpOutlined />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    moveQuality(index, index + 1);
                    handleFieldDirty();
                  }}
                  disabled={qualityFields.length <= 1 || index === qualityFields.length - 1}
                >
                  <ArrowDownOutlined />
                </Button>
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    removeQuality(index);
                    handleFieldDirty();
                  }}
                />
              </Space>
              <Row>
                <Col span={24}>
                  <GenericInput
                    showCount
                    maxLength={500}
                    name={`quality[${index}].title`}
                    label="Quality indicator title"
                    control={control}
                    required
                    errors={errors?.quality && errors.quality?.[index] && errors.quality[index]?.title}
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    showCount
                    maxLength={1000}
                    type="textarea"
                    name={`quality[${index}].desc`}
                    label="Description"
                    control={control}
                    required
                    errors={errors.quality && errors.quality[index] && errors.quality[index]?.desc}
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    showCount
                    maxLength={256}
                    name={`quality[${index}].logo`}
                    label="Logo URL"
                    control={control}
                    descriptionAfter="e.g. https://example.com"
                    errors={errors.quality && errors.quality[index] && errors.quality[index]?.logo}
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    showCount
                    maxLength={256}
                    name={`quality[${index}].url`}
                    label="Quality profile URL"
                    control={control}
                    descriptionAfter="e.g. https://example.com"
                    errors={errors.quality && errors.quality[index] && errors.quality[index]?.url}
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
            </Card>
          );
        })}
        <br />
        <Button htmlType="button" onClick={addQuality} disabled={rest.disabled}>
          Add <PlusOutlined />
        </Button>
      </Card>
      <Card>
        <SectionTitleRequired title="Skills" />
        <Typography.Paragraph>Select a skill that is relevant to this microcredential</Typography.Paragraph>
        {skillsFields.map((skill, index) => {
          return (
            <Card key={skill.id} className={classes.credCard} size="small">
              <Space className={classes.credCard__actions}>
                <Button
                  size="small"
                  onClick={() => {
                    moveSkills(index, index - 1);
                    handleFieldDirty();
                  }}
                  disabled={skillsFields.length <= 1 || index === 0 || rest.disabled}
                >
                  <ArrowUpOutlined />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    moveSkills(index, index + 1);
                    handleFieldDirty();
                  }}
                  disabled={skillsFields.length <= 1 || index === skillsFields.length - 1 || rest.disabled}
                >
                  <ArrowDownOutlined />
                </Button>
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    removeSkills(index);
                    handleFieldDirty();
                  }}
                  disabled={rest.disabled}
                />
              </Space>

              <Row>
                <Col span={24}>
                  <GenericInput
                    name={`skills[${index}].name`}
                    label="Skill name"
                    control={control}
                    type="select"
                    options={Object.values(SkillName).map((skill) => ({ label: skill, value: skill }))}
                    errors={errors.skills && errors.skills[index] && errors.skills[index]?.name}
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
            </Card>
          );
        })}
        {errors.skills && <Typography.Paragraph type="danger">{errors.skills.message}</Typography.Paragraph>}
        <br />
        {skillsFields.length < 3 && (
          <Button htmlType="button" onClick={addSkill} disabled={rest.disabled}>
            Add <PlusOutlined />
          </Button>
        )}
      </Card>
    </>
  );
};

export default memo(CourseQualitySkillsForm);
