import { Card, Col, Row, Statistic } from 'antd';
import { enquiryStatisticValueStyle, statisticStyle } from './style';
import './Dashboard.less'

const EnquiryStaticItem = (item: { title: string; value: number; cardTitle?: string }) => {
  return (
    <Card title={item.cardTitle} size="small">
      <Card.Grid hoverable={false} style={{ width: '100%' }}>
        <Statistic
          title={item.title}
          value={item.value}
          valueStyle={enquiryStatisticValueStyle}
          style={statisticStyle}
        />
      </Card.Grid>
    </Card>
  );
};

const enquiryCardAction = () => {
  return (
    <span style={{ margin: '8px 16px', textAlign: 'left' }}>
      Check your nominated enquiries email address to view all
    </span>
  );
};

interface IEnquiryData {
  currentMonth: number;
  tillDate: number;
}

export interface IEnquirySummaryProps {
  general: IEnquiryData | undefined;
  credential: IEnquiryData | undefined;
}

const DashboardEnquiryList = ({ general, credential }: IEnquirySummaryProps) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Card title="Provider enquiries" size="small" actions={[enquiryCardAction()]} className="card-wrapper">
            <Card.Grid hoverable={false} style={{ padding: 0, width: '100%', boxShadow: 'none' }}>
              <Row>
                <Col span={12}>
                  <EnquiryStaticItem title={'This month'} value={general?.currentMonth ?? 0} />
                </Col>
                <Col span={12}>
                  <EnquiryStaticItem title={'Total to Date'} value={general?.tillDate ?? 0} />
                </Col>
              </Row>
            </Card.Grid>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Credential enquiries" size="small" actions={[enquiryCardAction()]} className="card-wrapper">
            <Card.Grid hoverable={false} style={{ padding: 0, width: '100%', boxShadow: 'none' }}>
              <Row>
                <Col span={12}>
                  <EnquiryStaticItem title={'This month'} value={credential?.currentMonth ?? 0} />
                </Col>
                <Col span={12}>
                  <EnquiryStaticItem title={'Total to Date'} value={credential?.tillDate ?? 0} />
                </Col>
              </Row>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardEnquiryList;
