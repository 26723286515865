import { Card, Spin } from 'antd';
import { useCourseActions } from 'hooks';

import {
  ApproveModal,
  ConfirmModal,
  DeleteModal,
  PublishModal,
  RejectModal,
  UnpublishModal,
} from 'components/ui/modals';

import './CourseActions.less';
import { COURSE_ACTION, COURSE_STATUS } from 'model/course';
import { ICourseInfo } from 'api/cred2';
import { SummaryActions } from 'components/provider/course/summaryCard/summaryActions/SummaryActions';
import { SummaryHistory } from 'components/provider/course/summaryCard/summaryHistory/SummaryHistory';
import { SummaryComments } from 'components/provider/course/summaryCard/summaryComments/SummaryComments';
import { useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';
import { CourseCodeModal } from 'components/ui/modals/course/CourseCodeModal';
import API from 'api';
import { errorNotification, successNotification } from 'utils';

const CourseActions: FC<{
  courseId: string;
  courseInfo: ICourseInfo | undefined;
  currentProvider: string;
  loading: boolean;
  isAdmin: boolean;
  locked: boolean;
  onPreview: () => void;
  onSave: () => void;
  onSubmit: () => void;
  previewPathName: string;
  title: string;
  canSubmit: boolean;
  onReloadRequest: (maintainForm?: boolean) => void;
  openSubmitModal: boolean;
  toggleSubmitModal: (show: boolean) => void;
  onCancelEdit: () => void;
  onDeleteMicrocredential: (callback: () => void) => void;
}> = ({
  courseId,
  courseInfo,
  currentProvider,
  loading,
  isAdmin,
  onPreview,
  onSave,
  onSubmit,
  canSubmit,
  previewPathName,
  title,
  onReloadRequest,
  openSubmitModal,
  toggleSubmitModal,
  locked,
  onCancelEdit,
  onDeleteMicrocredential,
}) => {
  const navigate = useNavigate();

  /// Handle action related to course state
  const {
    sendAction,
    sendReject,
    cancelAndUnpublish,
    openApproveModal,
    openDeleteModal,
    openRejectModal,
    openUnpublishModal,
    openPublishModal,
    openCourseCodeModal,
    toggleApproveModal,
    toggleDeleteModal,
    toggleRejectModal,
    toggleUnpublishModal,
    togglePublishModal,
    toggleCourseCodeModal,
    updatingStatus,
  } = useCourseActions({ currentProvider, courseId, title });

  const [noActiveSession, setNoActiveSession] = useState<boolean>(false);

  if (loading) return <Spin />;

  const closeAllModals = () => {
    /// toggle all modal false
    toggleApproveModal(false);
    toggleDeleteModal(false);
    toggleRejectModal(false);
    toggleSubmitModal(false);
    toggleUnpublishModal(false);
    togglePublishModal(false);
  };

  /// reload cred or navigate back to list
  const handleSubmitAction = async (action: COURSE_ACTION) => {
    if (action === COURSE_ACTION.CancelAndUnpublish) {
      await cancelAndUnpublish();
    } else if (action === COURSE_ACTION.RePublish) {
      const [data, error] = await API.Cred2.republish(currentProvider, courseId!, {
        force: noActiveSession,
      });

      if (data.code === "BLANK_FIELD_EDUCATION") {
        errorNotification('Field of Education is blank. Please edit the credential and resubmit for approval');
        closeAllModals();
        return;
      }
      
      if (error) {
        // Check if fieldOfEducation is empty or not?
        errorNotification('Failed to re-publish course. Please try again.');
        closeAllModals();
        return;
      }

      if (data.code === 'NO_ACTIVE_SESSION') {
        setNoActiveSession(true);
        return;
      } else {
        setNoActiveSession(false);
        successNotification(
          <>
            <strong>{title}</strong> re-published.
          </>
        );
      }
    } else {
      await sendAction(action);
    }

    if (action === COURSE_ACTION.Cancel) {
      onCancelEdit()
    }

    if (action === COURSE_ACTION.Delete) {
      onDeleteMicrocredential(() => {
        navigate(isAdmin ? '/admin/courses' : '/provider/courses', { replace: true });
      });
    } else {
      // unpublish alone should not clear/reset editing form
      onReloadRequest(action === COURSE_ACTION.Unpublish);
      closeAllModals();
    }
  };

  const handleReviewSubmit = () => onSubmit();

  const handleReject = async (reason: string) => {
    await sendReject(reason);
    onReloadRequest();
    closeAllModals();
  };

  return (
    <>
      <Card className="course-action-card">
        <SummaryActions
          onAction={handleSubmitAction}
          {...{
            canSubmit,
            courseInfo,
            onPreview,
            onSave,
            previewPathName,
            toggleCourseCodeModal,
            toggleApproveModal,
            toggleDeleteModal,
            toggleRejectModal,
            toggleSubmitModal,
            toggleUnpublishModal,
            togglePublishModal,
            updatingStatus,
            handleReviewSubmit,
            locked,
          }}
        />
        <SummaryHistory history={courseInfo?.history} />
        <SummaryComments comments={courseInfo?.comments} />
      </Card>
      <DeleteModal
        courseName={title}
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={() =>
          handleSubmitAction(courseInfo?.status === COURSE_STATUS.Edit ? COURSE_ACTION.Cancel : COURSE_ACTION.Delete)
        }
        isModalVisible={openDeleteModal}
        status={courseInfo?.status}
      />
      <UnpublishModal
        confirmLoading={updatingStatus}
        courseName={title}
        onCancel={() => toggleUnpublishModal(false)}
        onDraftDeleted={() => handleSubmitAction(COURSE_ACTION.CancelAndUnpublish)}
        onConfirm={() => handleSubmitAction(COURSE_ACTION.Unpublish)}
        isModalVisible={openUnpublishModal}
        status={courseInfo?.status}
      />
      <PublishModal
        confirmLoading={updatingStatus}
        courseName={title}
        onCancel={() => {
          togglePublishModal(false);
          setNoActiveSession(false);
        }}
        onConfirm={() => handleSubmitAction(COURSE_ACTION.RePublish)}
        isModalVisible={openPublishModal}
        noActiveSessionConfirm={noActiveSession}
        status={courseInfo?.status}
      />
      <ApproveModal
        onCancel={() => toggleApproveModal(false)}
        onConfirm={() => handleSubmitAction(COURSE_ACTION.Approve)}
        isModalVisible={openApproveModal}
        title={title}
      />
      <RejectModal
        onCancel={() => toggleRejectModal(false)}
        onConfirm={handleReject}
        isModalVisible={openRejectModal}
      />
      <ConfirmModal
        title="Submit for review"
        onCancel={() => toggleSubmitModal(false)}
        isModalVisible={openSubmitModal}
        onConfirm={() => handleSubmitAction(COURSE_ACTION.RequestReview)}
      >
        <>
          <p>The Microcred Seeker team will review this microcredential before it can be published.</p>
          <p>
            Make sure all information is complete and images are appropriate and licensed. You cannot make changes after
            you submit.
          </p>
        </>
      </ConfirmModal>
      <CourseCodeModal
        open={openCourseCodeModal}
        toggleModal={toggleCourseCodeModal}
        credId={courseId}
        providerId={currentProvider}
        onReloadRequest={onReloadRequest}
      />
    </>
  );
};

export default CourseActions;
