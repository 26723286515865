import { z } from 'zod';
import { DeliveryModeType } from '../Course.types';
import dayjs from 'dayjs';

export const applySchema = z.object({
  apply: z.object({
    url: z.string().max(256).url({ message: 'Should match format URL' }).nonempty('Required'),
  }),
});

export const sessionsSchema = z.object({
  sessions: z
    .array(
      z.discriminatedUnion('flexibleIndicator', [
        z.object({
          commencementDate: z.string().optional().nullable(),
          endDate: z.string().optional().nullable(),
          closingDate: z.string().optional().nullable(),
          flexibleIndicator: z.literal(true),
          status: z.string().max(20).optional(),
          deliveryMode: z.object({
            type: z.enum([DeliveryModeType.ONLINE, DeliveryModeType.ON_CAMPUS, DeliveryModeType.MULTI_MODAL]),
            desc: z.string().max(5000).optional(),
          }),
          campusId: z.string().optional(),
        }),
        z.object({
          commencementDate: z
            .string()
            .nullable()
            .refine((val) => val && val.trim().length > 0, 'Required'),
          endDate: z
            .string()
            .nullable()
            .refine((val) => val && val.trim().length > 0, 'Required'),
          closingDate: z.string().optional().nullable(),
          flexibleIndicator: z.literal(false),
          status: z.string().max(20).nullable().optional(),
          deliveryMode: z.object({
            type: z.enum([DeliveryModeType.ONLINE, DeliveryModeType.ON_CAMPUS, DeliveryModeType.MULTI_MODAL]),
            desc: z.string().max(5000).nullable().optional(),
          }),
          campusId: z.string().nullable().optional(),
        }),
      ])
    )
    .min(1, 'Please enter at least one session')
    .superRefine((val, ctx) => {
      val.forEach((e, index) => {
        if (e.endDate && e.commencementDate && dayjs(e.endDate).isBefore(dayjs(e.commencementDate))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'endDate'],
            message: 'End date cannot be before the start date.',
          });
        }

        if (e.closingDate && e.endDate && dayjs(e.endDate).isBefore(dayjs(e.closingDate))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'closingDate'],
            message: 'Closing date cannot be later than the end date.',
          });
        }
      });
    }),
});

export const enrolmentSchema = sessionsSchema.merge(applySchema);
