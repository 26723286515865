import { FC, useState, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Card } from 'antd';
import dayjs from 'dayjs';

import './SummaryHistory.less';
import { ICourseHistory } from 'api/cred2';
import { COURSE_ACTION } from 'model/course';

const DATE_TEXT: { [key: string]: string } = {
  Create: 'Created',
  Edit: 'Start editing',
  Cancel: 'Canceled editing',
  RequestReview: 'Submitted for review',
  UpdateContent: 'Last updated',
  Approve: 'Approved',
  Reject: 'Rejected',
  Publish: 'Published',
  Unpublish: 'Unpublished',
  RePublish: 'Republished',
};
interface SummaryHistoryProps {
  history?: ICourseHistory[] | [];
}

export const SummaryHistory: FC<SummaryHistoryProps> = ({ history = [] }) => {
  const [uniqueHistory, setUniqueHistory] = useState<ICourseHistory[]>([]);

  const getUniqueHistory = useCallback(
    () =>
      history
        ?.sort((a, b) => a.createdAt - b.createdAt)
        ?.filter(
          (history) =>
            ![COURSE_ACTION.Approve, COURSE_ACTION.Edit, COURSE_ACTION.Cancel].includes(history.action as COURSE_ACTION)
        )
        .reduce((acc: ICourseHistory[], value): ICourseHistory[] => {
          const found = acc.find((_history: ICourseHistory) => _history.action === value.action);
          if (!found) {
            acc.push(value);
          } else if (value.createdAt > found.createdAt) {
            const ix = acc.indexOf(found);
            acc[ix] = value;
          }
          return acc;
        }, []) || [],
    [history]
  );

  useEffect(() => {
    if (!history) {
      return;
    }
    setUniqueHistory(getUniqueHistory);
  }, [history, getUniqueHistory]);

  if (isEmpty(uniqueHistory)) {
    return null;
  }

  return (
    <Card.Grid hoverable={false} className="summary-history">
      <h5>History</h5>
      <ul>
        {uniqueHistory.map(({ action, createdAt }: ICourseHistory, index: number) => {
          return (
            <li key={`${action}_${index}`}>
              {DATE_TEXT[action] ?? action} <span>{dayjs(createdAt).format('DD MMMM YYYY HH:mm:ss')}</span>
            </li>
          );
        })}
      </ul>
    </Card.Grid>
  );
};
