import { Card, Typography, Row, Col } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { useFormContext } from 'react-hook-form';
import { IProfileSchema } from './schema';

const ProfileSettingsForm = () => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IProfileSchema>();

  return (
    <Card>
      <Typography.Title level={5}>Authorisation</Typography.Title>
      <Row>
        <Col span={24}>
          <GenericInput
            name="allowApproveAndReject"
            type="checkbox"
            label="Allow provider to publish microcredentials without approval from MicroCred Seeker administrators"
            control={control}
            errors={errors['allowApproveAndReject']}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="allowEditProfile"
            type="checkbox"
            label="Allow provider to edit Provider Profile information"
            control={control}
            errors={errors['allowEditProfile']}
            {...rest}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileSettingsForm;
