import RequestResetPassword from 'components/auth/RequestResetPassword';
import AuthLayout from 'layouts/AuthLayout';
import React from 'react';

const ResetPassword = () => {
  return (
    <AuthLayout>
      <RequestResetPassword />
    </AuthLayout>
  );
};

export default ResetPassword;
