import { Menu } from 'antd';
import { MenuItem } from 'model/ui';
import { Link, useLocation } from 'react-router-dom';

const menuItems: MenuItem[] = [
  {
    key: 'admin-dashboard',
    label: <Link to="/admin">Dashboard</Link>,
  },
  {
    key: 'admin-courses',
    label: <Link to="/admin/courses">Microcredentials</Link>,
  },
  {
    key: 'admin-profile',
    label: <Link to="/admin/profile">Profile</Link>,
  },
  {
    key: 'admin-static-page',
    label: <Link to="/admin/static-page">Pages</Link>,
  },
  {
    key: 'admin-reports',
    label: <Link to="/admin/reports">Reports</Link>,
  },
  {
    key: 'admin-users',
    label: <Link to="/admin/users">Users</Link>,
  },
];

const LeftMenu = () => {
  const location = useLocation();
  const pathUrl = location.pathname.replace('/admin/', 'admin-').split('/')[0];
  const selectedKeys = menuItems
    .map(({ key }) => key)
    .filter((key) => {
      if (!pathUrl) {
        return key === 'admin-dashboard';
      }
      if (key) {
        return pathUrl === key;
      }
      return !pathUrl;
    });

  return (
    <>
      <div className="logo-container">
        <img src="/images/logo-white.svg" alt="provider-portal-logo" />
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} items={menuItems} />
    </>
  );
};

export default LeftMenu;
