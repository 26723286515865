import { z } from 'zod';
import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const outcomeSchema = z
  .array(
    z.object({
      title: z
        .string()
        .nonempty('Required')
        .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
        .refine((val) => removeHTMLTags(val).length <= 1000, {
          message: 'Should not be more than 1000 characters',
        }),
    })
  )
  .min(1, 'Please add at least one outcome');
