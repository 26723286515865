import { Footer } from 'components/auth/Footer';
import { Header } from 'components/auth/Header';
import React from 'react';

import './AuthLayout.less';

interface AuthLayoutProps {
  children: JSX.Element;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className="auth-container">
      <section className="login-container">
        <Header />
        {children}
        <Footer />
      </section>
    </div>
  );
};

export default AuthLayout;
