import { Card, Statistic } from "antd";
import StatusIndicator, {
  STATUS_TYPE,
} from "components/common/statusIndicator/StatusIndicator";

const statisticStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const statisticValueStyle: React.CSSProperties = {
  fontSize: 30,
  fontWeight: 600,
};

const Overview = (props: {
  draftCount: number,
  publishedCount: number,
  reviewingCount: number,
}) => {
  const {draftCount, publishedCount, reviewingCount} = props;

  return (
    <>
      <Card title="Overview" size="small">
        <Card.Grid hoverable={false}>
          <Statistic
            title={
              <StatusIndicator status={STATUS_TYPE.INACTIVE} text="Draft" />
            }
            value={draftCount}
            valueStyle={statisticValueStyle}
            style={statisticStyle}
          />
        </Card.Grid>
        <Card.Grid hoverable={false}>
          <Statistic
            title={
              <StatusIndicator status={STATUS_TYPE.ACTIVE} text="Published" />
            }
            value={publishedCount}
            valueStyle={statisticValueStyle}
            style={statisticStyle}
          />
        </Card.Grid>
        <Card.Grid hoverable={false}>
          <Statistic
            title={
              <StatusIndicator
                status={STATUS_TYPE.PENDING}
                text="Awaiting review"
              />
            }
            value={reviewingCount}
            valueStyle={statisticValueStyle}
            style={statisticStyle}
          />
        </Card.Grid>
      </Card>
    </>
  );
};

export default Overview;
