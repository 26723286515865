import React, { memo, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface CustomFileInputProps {
  onFileChange: (file: File | null) => void;
  shouldClearFile: boolean;
}

const CustomFileInput = ({ onFileChange, shouldClearFile }: CustomFileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldClearFile && inputRef.current) {
      inputRef.current.value = '';
      onFileChange(null);
    }
  }, [shouldClearFile, onFileChange]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onFileChange(event.target.files[0]);
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div>
      <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      <label htmlFor="customFileInput">
        <Button icon={<UploadOutlined />} type="default" onClick={handleButtonClick}>
          Select File
        </Button>
      </label>
    </div>
  );
};

export default memo(CustomFileInput);
