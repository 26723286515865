export const location = {
  type: 'object',
  properties: {
    addressLine1: {
      maxLength: 100,
      type: 'string',
    },
    addressLine2: {
      maxLength: 100,
      type: 'string',
    },
    suburb: {
      maxLength: 100,
      type: 'string',
    },
    state: {
      maxLength: 50,
      type: 'string',
      enumNames: [
        'Australian Capital Territory',
        'New South Wales',
        'Northern Territory',
        'Queensland',
        'South Australia',
        'Tasmania',
        'Victoria',
        'Western Australia',
      ],
      enum: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
    },
    postCode: {
      maxLength: 10,
      type: 'string',
      pattern: '^[0-9]+$',
    },
    // https://stackoverflow.com/a/31408260
    latitude: {
      maxLength: 100,
      type: ['string', 'null'],
      pattern: '^(\\+|-)?((?:[0-9]*)(?:(?:\\.[0-9]*)?))$',
    },
    longitude: {
      maxLength: 100,
      type: ['string', 'null'],
      pattern: '^(\\+|-)?((?:[0-9]*)(?:(?:\\.[0-9]*)?))$',
    },
  },
  additionalProperties: false,
  required: ['addressLine1', 'suburb', 'state', 'postCode'],
};

export const campus = {
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      maxLength: 100,
      type: 'string',
    },
    location: {
      $ref: '#/definitions/location',
    },
  },
};

export enum States {
  'ACT' = 'Australian Capital Territory',
  'NSW' = 'New South Wales',
  'NT' = 'Northern Territory',
  'QLD' = 'Queensland',
  'SA' = 'South Australia',
  'TAS' = 'Tasmania',
  'VIC' = 'Victoria',
  'WA' = 'Western Australia',
}

export const StateOptions = [
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'NT', label: 'Northern Territory' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
];
