import { memo } from 'react';
import { Button, Card, Col, Form, Row, Space, Typography } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { IAboutSchema } from './schema/about';
import GenericInput from 'components/form/shared/GenericInput';
import { useStyles } from './useStyles';
import GenericErrorMessage from 'components/form/shared/GenericErrorMessage';

const StaticPageAboutForm = () => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IAboutSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'list',
  });

  const { classes } = useStyles();

  return (
    <Form layout="vertical">
      <h1>
        List <Typography.Text type="danger">*</Typography.Text>
      </h1>
      <div>
        {fields.map((field, index) => (
          <Card className={classes.card} key={field.id}>
            <Space className={classes.cardAction}>
              <Button
                size="small"
                icon={<ArrowUpOutlined />}
                disabled={fields.length === 1 || index === 0}
                onClick={() => move(index, index - 1)}
              />
              <Button
                size="small"
                icon={<ArrowDownOutlined />}
                disabled={fields.length === 1 || index === fields.length - 1}
                onClick={() => move(index, index + 1)}
              />
              <Button size="small" danger icon={<DeleteOutlined />} onClick={() => remove(index)} />
            </Space>
            <Col span={22}>
              <Row>
                <Col span={24}>
                  <GenericInput
                    name={`list.${index}.title`}
                    label="Title"
                    control={control}
                    errors={errors['list']?.[index]?.title}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    type="quill"
                    name={`list.${index}.description`}
                    label="Description"
                    errors={errors['list']?.[index]?.description}
                    control={control}
                    {...rest}
                  />
                </Col>
              </Row>
              <h1>List</h1>
              <NestedListAboutForm index={index} />
            </Col>
          </Card>
        ))}
        {errors.list?.message && <GenericErrorMessage message={errors.list.message} />}
        <Button
          htmlType="button"
          className={classes.addButton}
          onClick={() => {
            append({ title: '', description: '', list: [] });
          }}
        >
          Add <PlusOutlined />
        </Button>
      </div>
    </Form>
  );
};

type INestedListAboutForm = {
  index: number;
};

const NestedListAboutForm = ({ index }: INestedListAboutForm) => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IAboutSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `list.${index}.list`,
  });
  const { classes } = useStyles();

  return (
    <>
      {fields.map((field, nestedIndex) => (
        <Card className={classes.card} key={field.id}>
          <Space className={classes.cardAction}>
            <Button
              size="small"
              icon={<ArrowUpOutlined />}
              disabled={fields.length === 1 || nestedIndex === 0}
              onClick={() => move(nestedIndex, nestedIndex - 1)}
            />
            <Button
              size="small"
              icon={<ArrowDownOutlined />}
              disabled={fields.length === 1 || nestedIndex === fields.length - 1}
              onClick={() => move(nestedIndex, nestedIndex + 1)}
            />
            <Button size="small" danger icon={<DeleteOutlined />} onClick={() => remove(nestedIndex)} />
          </Space>
          <Row>
            <Col span={22}>
              <GenericInput
                name={`list.${index}.list.${nestedIndex}.title`}
                label="Title"
                control={control}
                errors={errors?.['list']?.[index]?.list?.[nestedIndex]?.title}
                {...rest}
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <GenericInput
                type="quill"
                name={`list.${index}.list.${nestedIndex}.description`}
                label="Description"
                errors={errors?.['list']?.[index]?.list?.[nestedIndex]?.description}
                control={control}
                {...rest}
              />
            </Col>
          </Row>
        </Card>
      ))}
      <Button
        htmlType="button"
        className={classes.addButton}
        onClick={() => {
          append({ title: '', description: '' });
        }}
      >
        Add <PlusOutlined />
      </Button>
    </>
  );
};

export default memo(StaticPageAboutForm);
