import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Typography, Space, Button, Row, Col } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { IProfileSchema } from './schema';
import { useStyles } from 'components/provider/course/form/common/useStyles';

interface IProfilePoliciesForm {
  readOnly?: boolean;
}

const ProfilePoliciesForm: React.FC<IProfilePoliciesForm> = ({ readOnly = false }) => {
  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IProfileSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'policies',
  });

  const onAddClick = () => {
    append({
      desc: '',
      type: '',
    });
  };

  return (
    <Card>
      <Typography.Title level={5}>Policies</Typography.Title>
      {fields.map((field, index) => (
        <Card key={field.id} className={classes.credCard} size="small">
          <Space className={classes.credCard__actions}>
            <Button
              size="small"
              icon={<ArrowUpOutlined />}
              disabled={fields.length === 1 || index === 0 || readOnly}
              onClick={() => move(index, index - 1)}
            />
            <Button
              size="small"
              icon={<ArrowDownOutlined />}
              disabled={fields.length === 1 || index === fields.length - 1 || readOnly}
              onClick={() => move(index, index + 1)}
            />
            <Button size="small" danger icon={<DeleteOutlined />} onClick={() => remove(index)} disabled={readOnly}/>
          </Space>

          <Row>
            <Col span={22}>
              <GenericInput
                label="Policy type"
                name={`policies.${index}.type` as const}
                required
                type="select"
                options={[
                  { value: 'Privacy Policy', label: 'Privacy Policy' },
                  { value: 'Admission Policy', label: 'Admission Policy' },
                  { value: 'English Language Policy', label: 'English Language Policy' },
                ]}
                control={control}
                disabled={readOnly}
                errors={errors?.policies ? (errors.policies[index]?.type as FieldError) : undefined}
                {...rest}
              />
              <GenericInput
                label="Policy URL"
                descriptionAfter="e.g. https://example.com"
                name={`policies.${index}.desc` as const}
                required
                control={control}
                disabled={readOnly}
                showCount
                maxLength={256}
                errors={errors?.policies ? errors.policies[index]?.desc : undefined}
                {...rest}
              />
            </Col>
          </Row>
        </Card>
      ))}
      {errors.policies?.[0]?.duplicateError?.message && (
        <>
          <br />
          <Typography.Text type="danger">{errors.policies?.[0]?.duplicateError?.message}</Typography.Text>
          <br />
        </>
      )}
      {fields.length < 3 && (
        <>
          <br />
          <Button htmlType="button" onClick={onAddClick} disabled={readOnly}>
            Add <PlusOutlined />
          </Button>
        </>
      )}
    </Card>
  );
};

export default ProfilePoliciesForm;
