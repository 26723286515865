import { FC, useContext } from 'react';
import { ProviderContext } from 'components/ProviderContextProvider';
import { Card, Button } from 'antd';

import {
  ApproveButton,
  DeleteButton,
  DiscardDraftButton,
  PreviewButton,
  RejectButton,
  SaveButton,
} from './components/buttons';
import SummaryAlert from './components/SummaryAlert';

import './SummaryActions.less';
import { COURSE_ACTION, COURSE_STATUS } from 'model/course';
import Icon from '@ant-design/icons';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { ICourseInfo } from 'api/cred2';
import {
  EditButton,
  EditCourseCodeButton,
  SubmitForReviewButton,
} from 'components/provider/course/summaryCard/summaryActions/components/buttons/EditButton';
import {
  RePublishButton,
  UnpublishButton,
} from 'components/provider/course/summaryCard/summaryActions/components/buttons/UnpublishButton';
import { UserContext } from 'components/UserContextProvider';

export const SummaryActions: FC<{
  courseInfo: ICourseInfo | undefined;
  onAction: (action: COURSE_ACTION) => Promise<void>;
  onPreview: () => void;
  onSave: () => void;
  canSubmit: boolean;
  previewPathName: string;
  toggleCourseCodeModal: (show?: boolean) => void;
  toggleApproveModal: (show?: boolean) => void;
  toggleDeleteModal: (show?: boolean) => void;
  toggleRejectModal: (show?: boolean) => void;
  toggleUnpublishModal: (show?: boolean) => void;
  togglePublishModal: (show?: boolean) => void;
  handleReviewSubmit: () => void;
  updatingStatus: boolean;
  locked: boolean;
}> = ({
  courseInfo,
  onAction,
  onPreview,
  onSave,
  canSubmit,
  previewPathName,
  toggleApproveModal,
  toggleDeleteModal,
  toggleRejectModal,
  togglePublishModal,
  toggleUnpublishModal,
  toggleCourseCodeModal,
  updatingStatus,
  handleReviewSubmit,
  locked,
}) => {
  const { isAdmin } = useContext(UserContext);
  const {
    provider: { allowApproveAndReject = false },
  } = useContext(ProviderContext);
  const status = courseInfo?.status;
  const courseCode = courseInfo?.courseCode;

  if (!status) {
    return <Card.Grid hoverable={false} className="summary-card-actions"></Card.Grid>;
  }

  if ([COURSE_STATUS.Approved].includes(status)) {
    return null;
  }

  const hasPublishedPage =
    status === COURSE_STATUS.Published ||
    ((status === COURSE_STATUS.Edit || status === COURSE_STATUS.Review) && courseInfo?.published);

  return (
    <Card.Grid hoverable={false} className="summary-card-actions">
      <SummaryAlert status={status} canSubmit={canSubmit} />
      {courseCode === '__REQ' && (
        <EditCourseCodeButton onClick={() => toggleCourseCodeModal(true)} loading={updatingStatus} disabled={locked} />
      )}

      {[COURSE_STATUS.New, COURSE_STATUS.Edit].includes(status) && (
        <SaveButton onClick={onSave} loading={updatingStatus} disabled={locked} />
      )}

      {[COURSE_STATUS.Published, COURSE_STATUS.Unpublished, COURSE_STATUS.PublishError].includes(status) && (
        <>
          <EditButton onEdit={() => onAction(COURSE_ACTION.Edit)} loading={updatingStatus} disabled={locked} />
        </>
      )}

      {[COURSE_STATUS.Edit, COURSE_STATUS.New].includes(status) && (
        <SubmitForReviewButton onClick={handleReviewSubmit} loading={updatingStatus} disabled={locked} />
      )}

      {[COURSE_STATUS.Edit].includes(status) && (
        <DiscardDraftButton onClick={() => toggleDeleteModal(true)} loading={updatingStatus} disabled={locked} />
      )}

      {COURSE_STATUS.Review.includes(status) && (isAdmin || allowApproveAndReject) && (
        <>
          <ApproveButton
            updatingStatus={updatingStatus}
            onClick={() => {
              toggleApproveModal(true);
            }}
            disabled={locked}
          />
          <RejectButton
            updatingStatus={updatingStatus}
            onClick={() => {
              toggleRejectModal(true);
            }}
            disabled={locked}
          />
        </>
      )}
      <div className="preview-delete-actions">
        {[COURSE_STATUS.New, COURSE_STATUS.Edit, COURSE_STATUS.Review, COURSE_STATUS.Unpublished].includes(status) && (
          <PreviewButton pathname={previewPathName} onPreview={onPreview} disabled={locked} />
        )}

        {[COURSE_STATUS.Unpublished].includes(status) && !courseInfo?.published && (
          <RePublishButton onClick={() => togglePublishModal(true)} loading={updatingStatus} disabled={locked} />
        )}

        {([COURSE_STATUS.Published].includes(status) ||
          ([COURSE_STATUS.Review, COURSE_STATUS.Edit].includes(status) && courseInfo?.published)) && (
          <UnpublishButton onClick={toggleUnpublishModal} loading={updatingStatus} disabled={locked} />
        )}

        {[COURSE_STATUS.New].includes(status) && (
          <DeleteButton onClick={() => toggleDeleteModal(true)} disabled={locked} />
        )}
      </div>

      {hasPublishedPage && (
        <div>
          <Button
            className="published-course-link"
            type="link"
            href={`${process.env.REACT_APP_MARKETPLACE_BASE_URL}/explore/details/${courseInfo?.publishedUrl}`}
            target="_blank"
          >
            Visit published page <Icon component={ExternalLink} />
          </Button>
        </div>
      )}
    </Card.Grid>
  );
};
