import React, { FC } from 'react';
import { Layout } from 'antd';

import './DashboardLayout.less';

const { Header, Footer, Sider, Content } = Layout;

interface DashboardLayoutProps {
  LeftMenu: FC;
  HeaderMenu: FC;
  ContentBody: JSX.Element;
}

const DashboardLayout = ({ LeftMenu, HeaderMenu, ContentBody }: DashboardLayoutProps) => {
  return (
    <Layout id="app-dashboard-container">
      <Sider>
        <LeftMenu />
      </Sider>
      <Layout>
        <Header>
          <HeaderMenu />
        </Header>
        <Content>{ContentBody}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
