import { z } from 'zod';

import { aboutSchema } from './about';
import { accessibilitySchema } from './accessibility';
import { backgroundSchema } from './background';
import { contactUsSchema } from './contactUs';
import { copyrightSchema } from './copyright';
import { disclaimerSchema } from './disclaimer';
import { faqsSchema } from './faqs';
import { glossarySchema } from './glossary';
import { privacySchema } from './privacy';
import { usefulLinksSchema } from './usefulLinks';

export const staticPageSchemaLookup: Record<string, z.AnyZodObject | z.ZodEffects<any>> = {
  about: aboutSchema,
  accessibility: accessibilitySchema,
  background: backgroundSchema,
  'contact-us': contactUsSchema,
  copyright: copyrightSchema,
  disclaimer: disclaimerSchema,
  faqs: faqsSchema,
  glossary: glossarySchema,
  privacy: privacySchema,
  'useful-links': usefulLinksSchema,
};
