export type CredentialType = ICredential | ICredentialUpdate;

/**
 * @additionalProperties false
 */
export interface ICredential {
  /**
   * @maxLength 300
   */
  title: string;
  /**
   * @pattern "^[A-Z0-9]+$"
   */
  courseCode?: string;
  level: CourseLevel;
  /**
   * @maxLength 50
   */
  language: string;
  /**
   * @minimum 0.0
   */
  price: number;
  /**
   * @nullable true
   */
  duration?: IDuration;
  /**
   * @maxLength 1000
   * @nullable true
   */
  commitment?: string;
  /**
   * @maxLength 5000
   * @nullable true
   */
  frameworkDesc?: string;
  /**
   * @maxLength 1000
   * @nullable true
   */
  keywords?: string;
  /**
   * @nullable true
   */
  deliveryDesc?: IDeliveryDesc;
  /**
   * @nullable true
   */
  discountDesc?: IDiscountDesc;
  /**
   * @nullable true
   */
  expiration?: IExpiration;
  /**
   * @nullable true
   */
  about?: IAbout;
  /**
   * @nullable true
   */
  syllabuses?: ISyllabus[];
  /**
   * @nullable true
   */
  outcome?: IOutcome[];
  /**
   * @nullable true
   */
  conditions?: IConditionProfile[];
  /**
   * @nullable true
   */
  assessments?: IAssessment[];
  /**
   * @nullable true
   */
  academics?: IAcademic[];
  /**
   * @nullable true
   */
  quality?: IQuality[];
  /**
   * @nullable true
   */
  recognitions?: IRecognition[];
  /**
   * @nullable true
   */
  sessions?: ISession[];
  /**
   * @nullable true
   */
  apply?: IApply;
  /**
   * @nullable true
   */
  industries?: IndustryName[];
  /**
   * @nullable true
   */
  skills?: ISkill[];
  /**
   * @nullable true
   */
  fieldOfEducations?: FieldOfEducationName;
  /**
   * @nullable true
   */
  courseRelationships?: ICourseRelationship[];
  /**
   * @nullable true
   */
  policies?: IPolicy[];
  /**
   * @nullable true
   */
  priceGstIndicator?: boolean;
  /**
   * @nullable true
   */
  creditIndicator?: boolean;
  /**
   * @nullable true
   */
  stackableIndicator?: boolean;
  /**
   * @nullable true
   */
  discountIndicator?: boolean;

  // interests?: IInterest[];
  // requireEquipments?: IEquipment[];
}

/**
 * @minProperties 1
 * @additionalProperties false
 */
export type ICredentialUpdate = Partial<ICredential>;

export interface ISkill {
  /**
   * @maxLength 500
   */
  name: string;
}
export interface IDuration {
  /**
   * @minimum 1
   * @maximum 1000
   */
  value: number;
  unit: UnitName;
  /**
   * @nullable true
   */
  totalDays?: number;
}
export interface IFieldOfEdu {
  /**
   * @maxLength 100
   */
  group: string;
  /**
   * @maxLength 100
   */
  subgroup: string;
  /**
   * @maxLength 5000
   */
  description: string;
}
export interface IOutcome {
  /**
   * @maxLength 2000
   */
  title: string;
}
export interface ILevel {
  name: CourseLevel;
  /**
   * @maxLength 5000
   */
  desc?: string;
}

export interface IIndustry {
  name: IndustryName;
}
export interface IExpiration {
  /**
   * @maxLength 200
   * @nullable true
   */
  expirationP1?: string;
  // Deprecated:
  /**
   * @maxLength 5000
   * @nullable true
   */
  expirationP2?: string;
}
export interface IDeliveryDesc {
  /**
   * @maxLength 500
   * @nullable true
   */
  deliveryDescP1?: string;
  // Deprecated:
  /**
   * @maxLength 5000
   * @nullable true
   */
  deliveryDescP2?: string;
  // Deprecated:
  /**
   * @maxLength 5000
   * @nullable true
   */
  deliveryDescP3?: string;
}
export interface IDiscountDesc {
  /**
   * @maxLength 500
   * @nullable true
   */
  discountDescP1?: string;
  // Deprecated:
  /**
   * @maxLength 5000
   * @nullable true
   */
  discountDescP2?: string;
}
export interface IApply {
  /**
   * @maxLength 5000
   */
  applyDesc: string;
  /**
   * @maxLength 50
   */
  text: string;
  /**
   * @maxLength 256
   * @format "uri"
   */
  url: string;
  /**
   * @maxLength 1
   * @nullable true
   */
  applyMode?: string;
}
export interface ISession {
  /**
   * @format "date"
   * @nullable true
   */
  commencementDate?: string;
  /**
   * @format "date"
   * @nullable true
   */
  endDate?: string;
  flexibleIndicator: boolean;
  /**
   * @maxLength 20
   * @nullable true
   */
  status?: string;
  deliveryMode: IDeliveryMode;
  /**
   * @nullable true
   */
  campusId?: string;
}

export interface IDeliveryMode {
  type: DeliveryModeType;
  /**
   * @maxLength 5000
   */
  desc?: string;
}
export interface ILocation {
  /**
   * @maxLength 50
   */
  name: string;
  /**
   * @maxLength 100
   */
  streetAddress?: string;
  /**
   * @maxLength 20
   */
  suburb?: string;
  /**
   * @maxLength 20
   */
  state?: string;
  postCode?: number;
}
export interface IRecognition {
  /**
   * @maxLength 500
   */
  title: string;
  /**
   * @maxLength 2000
   * @nullable true
   */
  description?: string;
  type: RecognitionType;
  /**
   * @maxLength 256
   * @format "uri"
   * @nullable true
   */
  logo?: string;
}
export interface IQuality {
  /**
   * @maxLength 500
   */
  title: string;
  /**
   * @maxLength 1000
   * @nullable true
   */
  desc?: string;
  /**
   * @maxLength 256
   * @nullable true
   */
  logo?: string;
  /**
   * @maxLength 256
   * @format "uri"
   * @nullable true
   */
  url?: string;
}
export interface IAcademic {
  /**
   * @maxLength 50
   * @nullable true
   */
  providerId?: string;
  /**
   * @maxLength 200
   */
  name: string;
  /**
   * @maxLength 200
   */
  title: string;
  /**
   * @maxLength 1000
   */
  aboutP1: string;
  /**
   * @maxLength 5000
   * @nullable true
   */
  aboutP2?: string;
  /**
   * @maxLength 256
   * @nullable true
   */
  photo?: string;
  /**
   * @maxLength 256
   * @format "uri"
   * @nullable true
   */
  url?: string;
}
export interface ICourseRelationship {
  relationshipType: ICourseRelationshipType;
  /**
   * @maxLength 100
   * @nullable true
   */
  courseInternalId?: string;
  /**
   * @maxLength 100
   * @nullable true
   */
  courseExternalName?: string;
  /**
   * @maxLength 5000
   * @nullable true
   */
  courseExternalDesc?: string;
  /**
   * @maxLength 256
   * @nullable true
   */
  courseExternalLogo?: string;
  /**
   * @maxLength 256
   * @nullable true
   */
  courseExternalLinkText?: string;
  /**
   * @maxLength 256
   * @nullable true
   */
  courseExternalLinkUrl?: string;
}
interface ICourseRelationshipType {
  /**
   * @maxLength 100
   */
  name: RelationshipTypeName;
  /**
   * @maxLength 5000
   */
  desc: string;
}
export interface IAssessment {
  /**
   * @maxLength 500
   */
  title: string;
  /**
   * @maxLength 2000
   * @nullable true
   */
  desc?: string;
  /**
   * @nullable true
   */
  tasks?: IAssessmentTask[];
}
export interface IAssessmentTask {
  /**
   * @maxLength 1000
   */
  desc: string;
}
export interface IConditionProfile {
  conditions: ICondition[];
}
export interface ICondition {
  /**
   * @maxLength 2000
   */
  description: string;
  type: ConditionType;
}
export interface ISyllabus {
  /**
   * @maxLength 200
   */
  title: string;
  /**
   * @maxLength 1000
   */
  description: string;
}
export interface IAbout {
  /**
   * @maxLength 200
   */
  tagline: string;
  /**
   * @maxLength 5000
   */
  descriptionP1: string;
  /**
   * @maxLength 5000
   * @nullable true
   */
  descriptionP2?: string;
  /**
   * @maxLength 5000
   * @nullable true
   */
  descriptionP3?: string;
}

export enum SkillName {
  AGRICULTURE_AND_ANIMALS = 'Agriculture and animals',
  ARCHIVING_RECORDING_AND_TRANSLATING = 'Archiving, recording, and translating',
  ART_AND_ENTERTAINMENT = 'Art and entertainment',
  BUSINESS_OPERATIONS_AND_FINANCIAL_ACTIVITIES = 'Business operations and financial activities',
  CLEANING_AND_MAINTENANCE = 'Cleaning and maintenance',
  COMMUNICATION_AND_COLLABORATION = 'Communication and collaboration',
  CONSTRUCTION = 'Construction',
  CUSTOMER_SERVICE = 'Customer service',
  DATA_ANALYTICS_AND_DATABASES = 'Data, analytics, and databases',
  DIGITAL_TECHNOLOGIES_AND_ELECTRONICS = 'Digital technologies and electronics',
  ENVIRONMENTAL_MANAGEMENT = 'Environmental management',
  FASHION_GROOMING_AND_COSMETICS = 'Fashion, grooming, and cosmetics',
  FOOD_SERVICES = 'Food services',
  HEALTH_AND_CARE = 'Health and care',
  HUMAN_RESOURCES = 'Human resources',
  MATERIAL_TRANSPORTATION = 'Material transportation',
  OPERATING_PROCEDURES_AND_PROCESSES = 'Operating procedures and processes',
  PERFORMANCE_EVALUATION_AND_EFFICIENCY_IMPROVEMENT = 'Performance evaluation and efficiency improvement',
  PRODUCTION_PROCESSES_AND_MACHINERY = 'Production processes and machinery',
  QUALITY_CONTROL_AND_INSPECTIONS = 'Quality control and inspections',
  RECORDS_DOCUMENTATION_REPORTS_AND_RESEARCH = 'Records, documentation, reports and research',
  RECREATION_AND_SPORTING_EVENTS = 'Recreation and sporting events',
  SAFETY_AND_HAZARD_MANAGEMENT = 'Safety and hazard management',
  SALES_AND_MARKETING = 'Sales and marketing',
  SCIENCE_AND_MATHEMATICS = 'Science and mathematics',
  SECURITY_AND_EMERGENCY_SERVICES = 'Security and emergency services',
  TEACHING_AND_EDUCATION = 'Teaching and education',
  VEHICLE_OPERATION = 'Vehicle operation',
  WORK_RELATED_ACTIVITIES_PREPARATION = 'Work activities preparation',
}

export enum UnitName {
  HOUR = 'Hour',
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
}

export enum RelationshipTypeName {
  CREDIT = 'CREDIT',
  STACKABLE = 'STACKABLE',
}

export enum RelationshipTypeDesc {
  CREDIT = 'Indicate courses which the referenced credential can receive courses for',
  STACKABLE = 'Indicate courses within Marketplace which the referenced microcredential can form a set with',
}

export enum DeliveryModeType {
  ONLINE = 'ONLINE',
  ON_CAMPUS = 'ON-CAMPUS',
  MULTI_MODAL = 'MULTI-MODAL',
}

export enum DeliveryMode {
  ONLINE = 'Online',
  ON_CAMPUS = 'On-campus',
  MULTI_MODAL = 'Multi-modal',
}

export enum DeliveryModeDropdown {
  ONLINE = 'Online',
  "ON-CAMPUS" = 'On-campus',
  "MULTI-MODAL" = 'Multi-modal',
}

export enum Action {
  SAVE = 'save',
  EDIT = 'edit',
  SUBMIT = 'submit',
  REQ_CHANGE = 'request_change',
  APPROVE = 'approve',
  UNPUBLISH = 'unpublish',
}

export enum CourseLevel {
  NOVICE = 'Novice',
  ADVANCED_BEGINNER = 'Advanced Beginner',
  COMPETENT = 'Competent',
  PROFICIENT = 'Proficient',
  EXPERT = 'Expert',
}

export enum ConditionType {
  ENTRY_CRITERIA = 'ENTRY-CRITERIA',
  PREREQUISITE = 'PREREQUISITE',
  INHERENT_REQ = 'INHERENT-REQ',
  GUIDANCE_NOTE = 'GUIDANCE-NOTE',
  OTHER = 'OTHER',
}

export enum RecognitionType {
  CREDIT = 'CREDIT',
  AWARD = 'AWARD',
  CERTIFICATE = 'CERTIFICATE',
  STACKABLE = 'STACKABLE',
  OTHER = 'OTHER',
}

export enum IndustryName {
  ACCOMMODATION_AND_FOOD_SERVICES = 'Accommodation and Food Services',
  ADMINISTRATIVE_AND_SUPPORT_SERVICES = 'Administrative and Support Services',
  AGRICULTURE_FORESTRY_AND_FISHING = 'Agriculture, Forestry and Fishing',
  ARTS_AND_RECREATION_SERVICES = 'Arts and Recreation Services',
  CONSTRUCTION = 'Construction',
  EDUCATION_AND_TRAINING = 'Education and Training',
  ELECTRICITY_GAS_WATER_AND_WASTE_SERVICES = 'Electricity, Gas, Water and Waste Services',
  FINANCIAL_AND_INSURANCE_SERVICES = 'Financial and Insurance Services',
  HEALTH_CARE_AND_SOCIAL_ASSISTANCE = 'Health Care and Social Assistance',
  INFORMATION_MEDIA_AND_TELECOMMUNICATIONS = 'Information Media and Telecommunications',
  MANUFACTURING = 'Manufacturing',
  MINING = 'Mining',
  OTHER_SERVICES = 'Other Services',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES = 'Professional, Scientific and Technical Services',
  PUBLIC_ADMINISTRATION_AND_SAFETY = 'Public Administration and Safety',
  RENTAL_HIRING_AND_REAL_ESTATE_SERVICES = 'Rental, Hiring and Real Estate Services',
  RETAIL_TRADE = 'Retail Trade',
  TRANSPORT_POSTAL_AND_WAREHOUSING = 'Transport, Postal and Warehousing',
  WHOLESALE_TRADE = 'Wholesale Trade',
}

export interface IPolicy {
  type: PolicyType;
  /**
   * @maxLength 5000
   */
  desc: string;
}

export enum PolicyType {
  PRIVACY_POLICY = 'Privacy Policy',
  ADMISSION_POLICY = 'Admission Policy',
  ENGLISH_LANGUAGE_POLICY = 'English Language Policy',
}

export enum FieldOfEducationName {
  AGRICULTURE_ENVIRONMENT_AND_RELATED_STUDIES = 'Agriculture, Environment & Related Studies',
  ARCHITECTURE_AND_BUILDING = 'Architecture & Building',
  CREATIVE_ARTS = 'Creative Arts',
  EDUCATION = 'Education',
  ENGINEERING_AND_RELATED_TECHNOLOGIES = 'Engineering & Related Technologies',
  HEALTH = 'Health',
  INFORMATION_TECHNOLOGY = 'Information Technology',
  MANAGEMENT_AND_COMMERCE = 'Management & Commerce',
  NATURAL_AND_PHYSICAL_SCIENCES = 'Natural & Physical Sciences',
  SOCIETY_AND_CULTURE = 'Society & Culture',
  TOURISM_HOSPITALITY_AND_PERSONAL_SERVICES = 'Tourism, Hospitality & Personal Services',
  MIXED_FIELD_PROGRAM = 'Mixed Field Programs',
}
