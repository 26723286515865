import { ReactComponent as PlaneIcon } from 'assets/icons/plane-line.svg';
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleFilled,
  LockOutlined,
} from '@ant-design/icons';
import { Alert, Col, notification, Row, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import API from 'api';
import ActionMenu from 'components/common/ActionMenu';
import ListFilter from 'components/common/listFilter/ListFilter';
import { STATUS_TYPE, StatusIndicator } from 'components/common/statusIndicator/StatusIndicator';
import useCourseList from 'components/hooks/useCourseList';
import { DeleteModal, PublishModal, UnpublishModal } from 'components/ui/modals';
import { UserContext } from 'components/UserContextProvider';
import { COURSE_STATUS, DISPLAY_COURSE_STATUS, ICourse } from 'model/course';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { errorNotification, successNotification } from 'utils';

import './CourseList.less';
import Logo from 'components/common/logo/Logo';
import { useSafeState } from 'ahooks';
import { has } from 'lodash';
import CustomPrevNextButtonRender from 'components/ui/pagination/CustomPrevNextButtonRender';

const CourseList = () => {
  const { currentProvider, isAdmin, mapProvider, userInfo } = useContext(UserContext);
  const navigate = useNavigate();
  const { list, loading, error, addOrUpdate, deleted, revalidate } = useCourseList(true);

  const [filteredList, setFilteredList] = useSafeState<any[]>([]);
  const [selectedCourse, setSelectedCourse] = useSafeState<ICourse>();
  const [updatingStatus, setUpdatingStatus] = useSafeState(false);
  const [openDeleteModal, toggleDeleteModal] = useSafeState(false);
  const [openRepublishModal, toggleRepublishModal] = useSafeState(false);
  const [openUnpublishModal, toggleUnpublishModal] = useSafeState(false);

  const viewCourse = useCallback(
    async (course: ICourse) => {
      // Lock this course so that it can't be touch by anyone
      const providerId = course.provider || currentProvider;

      if (!course.locked && (course.status === COURSE_STATUS.Edit || course.status === COURSE_STATUS.New)) {
        await API.Cred2.lock(providerId, course.id);
      }

      if (isAdmin) {
        navigate(`/admin/courses/${providerId}/${course.id}`);
      } else {
        navigate(`/provider/courses/${course.id}`);
      }
    },
    [isAdmin, currentProvider, navigate]
  );

  const discardDraft = useCallback(
    (course: ICourse) => {
      setSelectedCourse(course);
      toggleDeleteModal(true);
    },
    [setSelectedCourse, toggleDeleteModal]
  );

  const actionDelete = useCallback(
    (course: ICourse) => {
      setSelectedCourse(course);
      toggleDeleteModal(true);
    },
    [setSelectedCourse, toggleDeleteModal]
  );

  const editCourse = async (course: ICourse) => {
    const providerId = currentProvider || course?.provider;

    // redirects user to course page if course status is new or edit
    if ([COURSE_STATUS.Edit, COURSE_STATUS.New].includes(course.status)) {
      if (!course.locked) {
        await API.Cred2.lock(providerId, course.id);
      }

      const link = isAdmin ? `/admin/courses/${providerId}/${course.id}` : `/provider/courses/${course.id}`;
      return navigate(link, { replace: true });
    }

    if (providerId) {
      setUpdatingStatus(true);
      const [, error] = await API.Cred2.edit(providerId, course!.id);

      setUpdatingStatus(false);
      if (!error) {
        viewCourse(course);
      } else {
        notification['error']({
          message: `Cannot update status. Error: ${error.message}`,
        });
      }
    }
  };

  const unpublishAndDiscardDraft = async () => {
    const providerId = currentProvider || selectedCourse?.provider;

    if (providerId) {
      const [, cancelError] = await API.Cred2.cancelEdit(providerId, selectedCourse!.id);

      if (cancelError) {
        errorNotification('Failed to discard draft. Please try again');
        return;
      }

      const [, unpublishError] = await API.Cred2.unpublish(providerId, selectedCourse!.id);

      if (unpublishError) {
        errorNotification(`Cannot update status. Error: ${unpublishError.message}`);
        return;
      }

      successNotification(
        <>
          <strong>{selectedCourse?.title}</strong> unpublished and draft discarded.
        </>
      );
    }
    revalidate();
    toggleUnpublishModal(false);
  };

  const deleteCourse = async () => {
    const providerId = currentProvider || selectedCourse?.provider;
    if (providerId) {
      setUpdatingStatus(true);

      const isEdit = selectedCourse?.status === COURSE_STATUS.Edit;

      const [data, error] = isEdit
        ? await API.Cred2.cancelEdit(providerId, selectedCourse!.id)
        : await API.Cred2.deleteDraft(providerId, selectedCourse!.id);

      setUpdatingStatus(false);
      if (!error) {
        successNotification(
          isEdit ? (
            'Draft discarded.'
          ) : (
            <>
              <strong>{selectedCourse?.title || 'Course'}</strong> deleted.
            </>
          )
        );
        if (isEdit) {
          addOrUpdate({
            ...selectedCourse!,
            status: data.status,
          });
        } else {
          deleted(selectedCourse!);
        }
        toggleDeleteModal(false);
      } else {
        errorNotification(
          isEdit
            ? 'Failed to discard draft. Please try again'
            : `Failed to delete ${selectedCourse?.title || 'course'}. Please try again.`
        );
      }
    }
  };

  const unpublishCourse = useCallback(
    (course: ICourse) => {
      setSelectedCourse(course);
      toggleUnpublishModal(true);
    },
    [setSelectedCourse, toggleUnpublishModal]
  );

  const unpublish = async () => {
    const providerId = currentProvider || selectedCourse?.provider;
    if (providerId) {
      setUpdatingStatus(true);

      const [data, error] = await API.Cred2.unpublish(providerId, selectedCourse!.id);

      setUpdatingStatus(false);
      if (!error) {
        notification['success']({
          message: (
            <>
              <strong>{selectedCourse?.title}</strong> unpublished.
            </>
          ),
        });
        addOrUpdate({
          ...selectedCourse!,
          status: data.status,
          published: data?.published,
        });
        toggleUnpublishModal(false);
      } else {
        notification['error']({
          message: `Cannot update status. Error: ${error.message}`,
        });
      }
    }
  };

  const publishCourse = useCallback(
    (course: ICourse) => {
      setSelectedCourse(course);
      toggleRepublishModal(true);
    },
    [setSelectedCourse, toggleRepublishModal]
  );

  const publish = async () => {
    const providerId = currentProvider || selectedCourse?.provider;
    if (providerId) {
      setUpdatingStatus(true);

      const [data, error] = await API.Cred2.republish(providerId, selectedCourse!.id);

      setUpdatingStatus(false);
      if (!error) {
        notification['success']({
          message: 'Course has successfully been published',
        });
        addOrUpdate({
          ...selectedCourse!,
          status: data.status,
        });
        toggleRepublishModal(false);
      } else {
        if (typeof error.message === 'string' && error.message.startsWith('Data validation failed: ')) {
          try {
            const errors = JSON.parse(error.message.replace('Data validation failed: ', ''));

            if (Array.isArray(errors) && errors.length === 1) {
              if (
                typeof errors[0] === 'object' &&
                has(errors[0], 'params.missingProperty') &&
                errors[0].params.missingProperty === 'fieldOfEducation'
              ) {
                notification['error']({
                  message:
                    'This course is missing "Field of education". Please edit and submit this course for re-approval',
                });
                return;
              }
            } else if (Array.isArray(errors) && errors.length > 1) {
              notification['error']({
                message: 'Failed to re-publish course. Please try again.',
              });
              return;
            }
          } catch (error) {
            console.log(error);
          }
        }

        notification['error']({
          message: `Cannot update status. Error: ${error.message}`,
        });
      }
    }
  };

  const columns: ColumnType<ICourse>[] = [
    {
      title: 'Title',
      width: '50%',
      render: (item: ICourse) => {
        const providerId = item.provider || currentProvider;
        const link = isAdmin ? `/admin/courses/${providerId}/${item.id}` : `/provider/courses/${item.id}`;
        if (isAdmin) {
          const provider = mapProvider[providerId];
          if (!provider) {
            return <> </>;
          }
          return (
            <>
              <Row align="middle" style={{ margin: '8px 8px 0px 8px' }}>
                <Col>
                  <Logo src={provider.logo} size={24} padding="0px" altText={`${provider.name} icon`} />
                </Col>
                <Col style={{ marginLeft: '16px' }}>
                  <Link
                    to={link}
                    onClick={async () => {
                      // If the item is not locked by any user -> lock it
                      if (!item.locked && (item.status === COURSE_STATUS.Edit || item.status === COURSE_STATUS.New)) {
                        await API.Cred2.lock(provider.id as string, item.id);
                      }
                    }}
                  >
                    {item.title}
                  </Link>
                  <div className="footnote">{provider.tradingName}</div>
                </Col>
              </Row>
              {item.status === COURSE_STATUS.Edit && item?.published && (
                <>
                  <InfoCircleFilled style={{ color: '#096DD9' }} /> Last published version is live. Changes have been
                  saved as draft.
                </>
              )}
              {item.locked && !item.lockedBy?.includes(userInfo.sub) ? (
                <div style={{ marginLeft: 8 }}>
                  <LockOutlined /> Locked for editing by {item.lockedBy?.split('#')?.[1]}
                </div>
              ) : (
                <></>
              )}
            </>
          );
        }

        return (
          <>
            <Link
              to={link}
              onClick={async () => {
                if (!item.locked && (item.status === COURSE_STATUS.Edit || item.status === COURSE_STATUS.New)) {
                  await API.Cred2.lock(providerId as string, item.id);
                }
              }}
            >
              {item.title}
            </Link>
            <br></br>
            {item.status === COURSE_STATUS.Edit && item?.published && (
              <>
                <InfoCircleFilled style={{ color: '#096DD9' }} /> Last published version is live. Changes have been
                saved as draft.
              </>
            )}
            {item.locked &&
            !(userInfo.name ? item.lockedBy?.includes(userInfo.name) : item.lockedBy?.includes(userInfo.username)) ? (
              <div>
                <LockOutlined /> Locked for editing by {item.lockedBy?.split('#')?.[1]}
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sorter: (a: ICourse, b: ICourse) => {
        return a.title.localeCompare(b.title);
      },
    },
    {
      title: 'Code',
      dataIndex: 'courseCode',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        let statusType = STATUS_TYPE.NONE;
        switch (status) {
          case COURSE_STATUS.Published:
            statusType = STATUS_TYPE.ACTIVE;
            break;
          case COURSE_STATUS.PublishError:
          case COURSE_STATUS.Unpublished:
          case COURSE_STATUS.Inactive:
            statusType = STATUS_TYPE.INACTIVE;
            break;
          case COURSE_STATUS.Review:
          case COURSE_STATUS.Approved:
            statusType = STATUS_TYPE.PENDING;
            break;
          case COURSE_STATUS.New:
          case COURSE_STATUS.Edit:
            statusType = STATUS_TYPE.NONE;
            break;
        }

        return (
          <StatusIndicator
            status={statusType}
            text={<span className="status-indicator">{DISPLAY_COURSE_STATUS[status as COURSE_STATUS] || 'None'}</span>}
          />
        );
      },
      sorter: (a: ICourse, b: ICourse) => {
        return DISPLAY_COURSE_STATUS[a.status].localeCompare(DISPLAY_COURSE_STATUS[b.status]);
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return formatDate(createdAt);
      },
      sorter: (a: ICourse, b: ICourse) => {
        return a.createdAt.localeCompare(b.createdAt);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right' as AlignType,
      render: (course: ICourse) => {
        let actions: string[] = [];
        switch (course.status) {
          case COURSE_STATUS.New:
            actions = ['edit', 'delete'];
            break;
          case COURSE_STATUS.Edit:
            actions = ['edit', 'discard'];
            course?.published && actions.push('deactivate');
            break;
          case COURSE_STATUS.Review:
            actions = ['view'];
            break;
          case COURSE_STATUS.Approved:
            actions = ['view'];
            break;
          case COURSE_STATUS.Inactive:
            actions = ['reactivate', 'edit'];
            break;
          case COURSE_STATUS.Published:
            actions = ['view', 'edit', 'deactivate'];
            break;
          case COURSE_STATUS.Unpublished:
            actions = ['view', 'edit'];
            break;
          case COURSE_STATUS.PublishError:
            actions = ['view', 'edit'];
            break;
        }

        const isCourseLocked = course.locked && !course.lockedBy?.includes(userInfo.sub);

        return (
          <ActionMenu
            id={course.id}
            type="ellipsis"
            actions={actions.map((actionKey) => {
              switch (actionKey) {
                case 'edit':
                  return {
                    label: 'Edit',
                    icon: <EditOutlined />,
                    handler: () => editCourse(course),
                    disabled: isCourseLocked,
                  };
                case 'discard':
                  return {
                    label: 'Discard draft',
                    icon: <DeleteOutlined />,
                    danger: true,
                    handler: () => discardDraft(course),
                    disabled: isCourseLocked,
                  };
                case 'delete':
                  return {
                    label: 'Delete',
                    icon: <DeleteOutlined />,
                    danger: true,
                    handler: () => actionDelete(course),
                    disabled: isCourseLocked,
                  };
                case 'deactivate':
                  return {
                    label: 'Unpublish',
                    icon: <EyeInvisibleOutlined />,
                    handler: () => unpublishCourse(course),
                    disabled: isCourseLocked,
                  };
                case 'reactivate':
                  return {
                    label: 'Publish',
                    icon: <PlaneIcon style={{ width: 14, height: 14 }} />,
                    handler: () => publishCourse(course),
                    disabled: isCourseLocked,
                  };
                case 'view':
                  return {
                    label: 'View',
                    icon: <EyeOutlined />,
                    handler: () => viewCourse(course),
                    disabled: isCourseLocked,
                  };
                default:
                  return {
                    label: '',
                    handler: () => {},
                  };
              }
            })}
            ariaLabel={`${course.title} actions menu`}
          />
        );
      },
    },
  ];

  const handleListFiltered = (newList: any[]) => {
    setFilteredList(newList);
  };

  return (
    <>
      {error && <Alert type="error" showIcon={true} message="Cannot load courses" style={{ marginBottom: 20 }} />}
      <Row>
        <Col span={12}>
          <ListFilter
            list={list}
            placeholder="Search courses"
            searchFilterProps={['title', 'code']}
            onFilter={handleListFiltered}
          />
        </Col>
      </Row>
      <Table
        rowKey="id"
        dataSource={filteredList}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          pageSizeOptions: [10, 20, 30, 50, 100],
          itemRender: CustomPrevNextButtonRender,
          showTotal: (total, range) => (
            <div style={{ position: 'absolute', left: 16 }}>{`${range[0]} - ${range[1]} of ${total} items`}</div>
          ),
        }}
        loading={loading}
      />
      {selectedCourse && openDeleteModal && (
        <DeleteModal
          courseName={selectedCourse.title}
          onCancel={() => toggleDeleteModal(false)}
          onConfirm={deleteCourse}
          isModalVisible={openDeleteModal}
          status={selectedCourse.status}
        />
      )}
      {selectedCourse && openUnpublishModal && (
        <UnpublishModal
          courseName={selectedCourse.title}
          onCancel={() => toggleUnpublishModal(false)}
          onDraftDeleted={unpublishAndDiscardDraft}
          onConfirm={unpublish}
          isModalVisible={openUnpublishModal}
          status={selectedCourse.status}
          confirmLoading={updatingStatus}
        />
      )}
      {selectedCourse && openRepublishModal && (
        <PublishModal
          courseName={selectedCourse.title}
          onCancel={() => toggleRepublishModal(false)}
          onConfirm={publish}
          isModalVisible={openRepublishModal}
          confirmLoading={updatingStatus}
          status={selectedCourse.status}
        />
      )}
    </>
  );
};

export default CourseList;
