import { FC } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as PlaneIcon } from 'assets/icons/plane-line.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-line.svg';

export const SubmitForReviewButton: FC<{
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}> = ({ onClick, loading, disabled }) => {
  return (
    <Button
      type="primary"
      size="large"
      className="summary-card-actions__submit-edit"
      onClick={onClick}
      icon={<Icon component={PlaneIcon} />}
      tabIndex={0}
      loading={loading}
      disabled={disabled}
    >
      {'Submit for review'}
    </Button>
  );
};

export const EditButton: FC<{
  onEdit: () => void;
  loading: boolean;
  disabled: boolean;
}> = ({ onEdit, loading, disabled }) => {
  return (
    <Button
      type="primary"
      size="large"
      className="summary-card-actions__submit-edit"
      onClick={onEdit}
      loading={loading}
      icon={<Icon component={EditIcon} />}
      tabIndex={0}
      disabled={disabled}
    >
      {'Edit'}
    </Button>
  );
};

export const EditCourseCodeButton: FC<{
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}> = ({ onClick, loading, disabled }) => {
  return (
    <Button
      type="primary"
      size="large"
      className="summary-card-actions__submit-edit"
      onClick={onClick}
      loading={loading}
      icon={<Icon component={EditIcon} />}
      tabIndex={0}
      disabled={disabled}
    >
      {'Edit Course Code'}
    </Button>
  );
};
