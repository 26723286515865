import useSWR from 'swr';
import axios from 'api/_utils/axiosInstance';
import { useBoolean, useSafeState } from 'ahooks';
import { useContext } from 'react';
import { UserContext } from 'components/UserContextProvider';
import { Button, Card, Col, Row, Spin } from 'antd';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ActionMenu from 'components/common/ActionMenu';
import Logo from 'components/common/logo/Logo';
import Profile from 'components/provider/profile/Profile';
import CreateProfileModal from './components/CreateProfileModal';
import OffboardProviderModal from './modals/OffboardProvider';
import useOffboardingProvider from './hooks/useOffboardingProvider';

import './Profiles.less';
import { IProvider } from 'types/provider';

const Profiles = () => {
  const { currentProvider, changeProvider } = useContext(UserContext);

  const [profiles, setProfiles] = useSafeState([]);
  const [loading, { set: setLoading }] = useBoolean(true);
  const [openCreateModal, toggleCreateModal] = useSafeState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useSafeState({
    providerId: '',
    tradingName: '',
  });

  useSWR(loading ? `admin/provider` : null, axios, {
    keepPreviousData: true,
    onSuccess: ({ data }) => {
      const { items } = data;
      const sortedProvider = items.sort((a: IProvider, b: IProvider) => a.tradingName?.toString().localeCompare(b.tradingName?.toString() ?? ""))

      setProfiles(sortedProvider);
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setLoading(false);
    },
  });

  const {
    openOffBoardModal,
    deletingProvider,
    handleOpenOffboard,
    handleCloseOffboard,
    onConfirm,
    fetchProviderNumbers,
  } = useOffboardingProvider({ setSelectedProfile, selectedProfile, getProviders: () => setLoading(true) });

  if (loading) return <Spin />;

  if (currentProvider) {
    return <Profile profiles={profiles as []} setProfiles={setProfiles} />;
  }

  return (
    <>
      <div className="profile-header">
        <h2>Profile</h2>
        <Button type="primary" size="middle" onClick={() => toggleCreateModal(true)}>
          Create profile
        </Button>
      </div>
      {profiles.map(({ id, logo, tradingName, extId }) => (
        <Card key={id} style={{ marginBottom: 20 }}>
          <Row align="middle" justify="space-between">
            <Col>
              <Row align="middle">
                <Col>
                  <Logo src={logo} size={40} padding="0px" altText={`${tradingName} icon`} />
                </Col>
                <Col style={{ marginLeft: '16px' }}>
                  <Button
                    type="link"
                    onClick={() => {
                      changeProvider(id);
                    }}
                    style={{ fontWeight: 500, padding: 0 }}
                  >
                    {tradingName}
                  </Button>
                  <div>{extId}</div>
                </Col>
              </Row>
            </Col>
            <Col>
              <ActionMenu
                type="ellipsis"
                actions={[
                  {
                    label: 'Edit',
                    icon: <EditOutlined />,
                    handler: () => {
                      changeProvider(id);
                    },
                  },
                  {
                    label: 'Offboard',
                    icon: <CloseCircleOutlined />,
                    handler: () => handleOpenOffboard({ tradingName, providerId: id }),
                    danger: true,
                  },
                ]}
              />
            </Col>
          </Row>
        </Card>
      ))}
      <CreateProfileModal
        visible={openCreateModal}
        handleCancel={() => toggleCreateModal(false)}
        setLoading={setLoading}
      />
      {selectedProfile.providerId && (
        <OffboardProviderModal
          onClose={handleCloseOffboard}
          openModal={openOffBoardModal}
          onConfirm={onConfirm}
          providerData={selectedProfile}
          confirmLoading={deletingProvider}
          fetchProviderNumbers={fetchProviderNumbers}
        />
      )}
    </>
  );
};

export default Profiles;
