import { Key, memo } from 'react';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { useFormContext, useFieldArray, FieldError } from 'react-hook-form';
import { ICourseFormSchema } from './schema';
import GenericInput from 'components/form/shared/GenericInput';
import { useStyles } from './common/useStyles';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { enumToValueKeyArray } from 'components/form/shared/FormHelpers';
import { DeliveryModeDropdown, DeliveryModeType } from './Course.types';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';
import { SectionTitleRequired } from './common/utils';

const flexibleIndicatorOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const CourseEnrolmentForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('8');

  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    ...formFields
  } = useFormContext<ICourseFormSchema>();

  const handleFieldDirty = () => setIsDirty(true);

  const rest = {
    ...formFields,
    disabled: isFormReadOnly,
  };

  const {
    fields: sessionsFields,
    append: appendSessions,
    remove: removeSessions,
    move: moveSessions,
  } = useFieldArray({
    control,
    name: 'sessions',
  });

  const { fields: campuses } = useFieldArray({
    control,
    name: 'campuses',
  });
  const campusOptions = [{ value: ' ', label: 'N/A' }].concat(
    campuses?.map((campus: { name: string }) => ({ value: campus.name, label: campus.name })) || []
  );

  const addSession = () => {
    appendSessions({
      closingDate: undefined,
      flexibleIndicator: true,
      status: undefined,
      deliveryMode: {
        type: DeliveryModeType.ONLINE,
        desc: undefined,
      },
      campusId: undefined,
    });
    handleFieldDirty();
  };

  return (
    <Card>
      <div>
        <h1>Enrolment information</h1>
      </div>
      <Row>
        <Col span={24}>
          <GenericInput
            name="apply.url"
            descriptionAfter="e.g. https://example.com"
            label="Course URL"
            control={control}
            required
            showCount
            maxLength={256}
            errors={errors['apply'] ? errors['apply']['url'] : undefined}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <div>
        <SectionTitleRequired title="Sessions" />
      </div>
      {errors.sessions && <Typography.Paragraph type="danger">{errors.sessions.message}</Typography.Paragraph>}
      {sessionsFields.map((item: { id: Key | null | undefined }, index: number) => {
        const flexibleIndicator = watch(`sessions.${index}.flexibleIndicator`);

        return (
          <Card key={item.id} className={classes.credCard} size="small">
            <Space className={classes.credCard__actions}>
              <Button
                size="small"
                icon={<ArrowUpOutlined />}
                disabled={sessionsFields.length === 1 || index === 0 || rest.disabled}
                onClick={() => {
                  moveSessions(index, index - 1);
                  handleFieldDirty();
                }}
              />
              <Button
                size="small"
                icon={<ArrowDownOutlined />}
                disabled={sessionsFields.length === 1 || index === sessionsFields.length - 1 || rest.disabled}
                onClick={() => {
                  moveSessions(index, index + 1);
                  handleFieldDirty();
                }}
              />
              <Button
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  removeSessions(index);
                  handleFieldDirty();
                }}
                disabled={rest.disabled}
              />
            </Space>
            <Row>
              <Col span={24}>
                <GenericInput
                  label="Type"
                  name={`sessions.${index}.deliveryMode.type`}
                  type="select"
                  options={enumToValueKeyArray(DeliveryModeDropdown)}
                  control={control}
                  required
                  errors={errors.sessions && (errors.sessions[index]?.deliveryMode?.type as FieldError | undefined)}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  label="Campus"
                  name={`sessions.${index}.campusId`}
                  type="select"
                  options={campusOptions}
                  control={control}
                  errors={errors.sessions && errors.sessions[index]?.campusId}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  required
                  label="Flexible delivery options available"
                  name={`sessions.${index}.flexibleIndicator`}
                  type="select"
                  options={flexibleIndicatorOptions}
                  control={control}
                  errors={errors.sessions && errors.sessions[index]?.flexibleIndicator}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            {!flexibleIndicator && (
              <>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      required
                      label="Start date"
                      name={`sessions[${index}].commencementDate`}
                      type="date"
                      control={control}
                      errors={errors.sessions && errors.sessions[index]?.commencementDate}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      required
                      label="End date"
                      name={`sessions[${index}].endDate`}
                      type="date"
                      control={control}
                      errors={errors.sessions && errors.sessions[index]?.endDate}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col span={24}>
                <GenericInput
                  label="Enrolment closing date"
                  name={`sessions[${index}].closingDate`}
                  type="date"
                  control={control}
                  errors={errors.sessions && errors.sessions[index]?.closingDate}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
          </Card>
        );
      })}
      <br />
      <Button htmlType="button" onClick={addSession} disabled={rest.disabled}>
        Add <PlusOutlined />
      </Button>
    </Card>
  );
};

export default memo(CourseEnrolmentForm);
