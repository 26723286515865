import { useToggle } from "ahooks";
import { SetStateAction, useContext } from "react";
import API from 'api';
import { errorNotification, successNotification } from 'utils';
import { UserContext } from "components/UserContextProvider";

interface IUseOffboardingProvider {
  selectedProfile: { providerId: string; tradingName: string; }
  setSelectedProfile: { (value: SetStateAction<{ providerId: string; tradingName: string; }>): void }
  getProviders: () => void;
}

export default function useOffboardingProvider({ setSelectedProfile, selectedProfile, getProviders }: IUseOffboardingProvider) {
  const { reload } = useContext(UserContext);
  const [openOffBoardModal, { set: toggleOffBoardModal }] = useToggle(false);
  const [deletingProvider, { toggle: toggleDeletingProvider }] = useToggle(false);

  const fetchProviderNumbers = async (providerId: string) => {
    if (!providerId) {
      return
    }

    const [data, error] = await API.Admin.offboardProvider(selectedProfile.providerId, true)

    if (error) {
      return console.error(error);
    }

    return {
      courses: data?.deleteCourses?.total || 0,
      users: data?.deleteUsers?.total || 0
    }
  }

  const handleOpenOffboard = (providerData: { tradingName: string; providerId: string }) => {
    setSelectedProfile((prev) => ({ ...prev, ...providerData }));
    toggleOffBoardModal(true);
  };

  const handleCloseOffboard = () => {
    setSelectedProfile({
      providerId: '',
      tradingName: ''
    });
    toggleOffBoardModal(false);
  };

  const onConfirm = async () => {
    toggleDeletingProvider();
    const [, error] = await API.Admin.offboardProvider(selectedProfile.providerId, false);

    if (error) {
      console.error(error);
      errorNotification('Failed to offboard provider. Please try again.')
    } else {
      handleCloseOffboard();
      successNotification(`${selectedProfile.tradingName} offboarded`)
      getProviders();
      reload()
    }

    toggleDeletingProvider();
  };

  return {
    openOffBoardModal,
    deletingProvider,
    toggleOffBoardModal,
    toggleDeletingProvider,
    handleOpenOffboard,
    handleCloseOffboard,
    onConfirm,
    fetchProviderNumbers
  }
}
