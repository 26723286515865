import { useSafeState } from 'ahooks';
import API from 'api';
import useSWR from 'swr';
import ProviderAPI from 'api/provider';
import { IError } from 'api/utils';
import { Auth } from 'aws-amplify';
import { GlobalContext, MessageType } from 'contexts/GlobalContext';
import { FC, ComponentType, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import IdleTimer from 'utils/idleTimer';
import { UserRole } from 'utils/UserUtils';

export interface PrivateRouteProps<T = any> {
  Component: ComponentType<T>;
  roles?: UserRole[];
}

const inactiveTimer = 60 * 60 * 1000; //expire after 60 minutes

const PrivateRoute: FC<PrivateRouteProps> = ({ Component, roles, ...rest }) => {
  const { data } = useSWR('UNLOCK_COURSE_KEY');
  const location = useLocation();
  const [searchParams] = useSearchParams()
  const returnUrl = location.pathname;

  const [, setIsLoading] = useSafeState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // initialize _expiredTime before the IdleTimer starts working
    localStorage.setItem('_expiredTime', (Date.now() + inactiveTimer).toString());

    const timeOutOrExpired = async () => {
      if (window.location.href.includes('/courses')) {
        const splitResult = window.location.href.split('/courses/');
        const [providerId, courseId] = splitResult[splitResult.length - 1].split('/');

        // If the couseId is not exist
        // Which mean we're in the ProviderEditor account
        if (!courseId) {
          await API.Cred2.unlock(data, providerId);
        } else {
          // data is courseId
          await API.Cred2.unlock(providerId, courseId);
        }
      }

      localStorage.removeItem('_expiredTime');
      Auth.signOut();
      navigate('/');
    };

    const timer = new IdleTimer({
      timeout: inactiveTimer,
      onTimeout: () => {
        timeOutOrExpired();
      },
      onExpired: () => {
        timeOutOrExpired();
      },
    });

    return () => {
      timer.cleanUp();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const { currentAuthenticatedUser, setCurrentAuthenticatedUser, temporaryUserForNewPassword, setMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    const checkUserPermission = async () => {
      // on refresh, refetch the user's info
      try {
        if (!currentAuthenticatedUser && !temporaryUserForNewPassword) {
          const [user, error] = await ProviderAPI.getUserInfo();
          if (error) throw error;
          setCurrentAuthenticatedUser(user); // will trigger re-check because currentAuthenticatedUser is in dependencies list
        } else {
          const { role } = currentAuthenticatedUser || {};

          if (!role && !temporaryUserForNewPassword) throw new Error('Unauthorized role');

          if (!!role && !roles?.includes(role)) {
            switch (role) {
              case UserRole.PROVIDER_EDITOR:
              case UserRole.PROVIDER_ADMIN:
                navigate('/provider/');
                break;
              case UserRole.DESE_EDITOR: {
                navigate('/dese/');
                break;
              }
              case UserRole.ADMIN: {
                navigate('/admin/');
                break;
              }
            }
          }
        }
      } catch (error) {
        let targetUrl = !!returnUrl ? `/?returnUrl=${returnUrl}` : ``;
        // If the user click from the pending email link
        // We should attach a query param to the returnUrl
        if (searchParams.get("fromEmail") && targetUrl) {
          targetUrl += "?fromEmail=true"
        }

        navigate(targetUrl);
        console.error(error);
      }
    };
    checkUserPermission();
  }, [currentAuthenticatedUser, navigate, returnUrl, roles, setCurrentAuthenticatedUser, temporaryUserForNewPassword]);

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
      } catch (error) {
        if (!temporaryUserForNewPassword) {
          setCurrentAuthenticatedUser(undefined);
          navigate('/');
        }

        if ((error as IError | undefined)?.message === 'User is disabled.') {
          setMessage({
            type: MessageType.ERROR,
            content:
              'User account is disabled. Please contact your organisation administrator to reactivate your account.',
          });
          navigate('/');
        }

        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getUser();
  }, [setCurrentAuthenticatedUser, navigate, setIsLoading, temporaryUserForNewPassword, setMessage]);

  return <Component {...rest} />;
};

export default PrivateRoute;
