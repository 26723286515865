import { Route, Routes } from 'react-router-dom';

import AdminApp from 'pages/admin/AdminApp';
import DeseApp from 'pages/dese/DeseApp';
import ProviderApp from 'pages/provider/ProviderApp';
import SignIn from 'pages/auth/SignIn';
import CompleteNewPassword from 'pages/auth/CompleteNewPassword';
import ResetPassword from 'pages/auth/ResetPassword';

import PrivateRoute from 'components/auth/PrivateRoute';
import AdminDashboard from 'components/admin/dashboard/Dashboard';
import ProviderDashboard from 'components/provider/dashboard/Dashboard';
import Profiles from 'components/admin/profile/Profiles';
import { StaticPage } from 'components/common/static-page/StaticPage';
import { StaticPagesDetails } from 'components/common/static-page/StaticPagesDetails';
import { Course } from 'components/provider/course/Course';
import Courses from 'components/provider/course/Courses';
import Profile from 'components/provider/profile/Profile';
import NotFound from 'components/common/404';

import { UserRole } from 'utils/UserUtils';

import './Routers.less';

import ReportListPage from 'components/common/reports/ReportListPage';
import { UserDetail } from 'components/admin/user/UserDetail';
import { Users } from 'components/admin/user/Users';

export const AppRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/new-password"
        element={
          <PrivateRoute
            Component={CompleteNewPassword}
            roles={[UserRole.DESE_EDITOR, UserRole.ADMIN, UserRole.PROVIDER_EDITOR, UserRole.PROVIDER_ADMIN]}
          />
        }
      />
      <Route path="/admin/*" element={<PrivateRoute Component={AdminApp} roles={[UserRole.ADMIN]} />} />
      <Route
        path="/provider/*"
        element={<PrivateRoute Component={ProviderApp} roles={[UserRole.PROVIDER_EDITOR, UserRole.PROVIDER_ADMIN]} />}
      />
      <Route
        path="/dese/*"
        element={<PrivateRoute Component={DeseApp} roles={[UserRole.DESE_EDITOR]} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export const AdminRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/courses/:providerId/:courseId" element={<Course />} />
      <Route path="/profile/:providerId" element={<Profile />} />
      <Route path="/profile" element={<Profiles />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/:userId" element={<UserDetail />} />
      <Route path="/reports" element={<ReportListPage />} />
      <Route path="/static-page" element={<StaticPage />} />
      <Route path="/static-page/:id" element={<StaticPagesDetails />} />
      <Route path="*" element={<AdminDashboard />} />
    </Routes>
  );
};

export const DeseRouters = () => {
  return (
    <Routes>
      <Route path="/static-page" element={<StaticPage />} />
      <Route path="/static-page/:id" element={<StaticPagesDetails />} />
      <Route path="/reports" element={<ReportListPage />} />
      <Route path="*" element={<StaticPage />} />
    </Routes>
  );
};

export const ProviderRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<ProviderDashboard />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/courses/:courseId" element={<Course />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/users" element={<PrivateRoute Component={Users} roles={[UserRole.PROVIDER_ADMIN]} />} />
      <Route
        path="/users/:userId"
        element={<PrivateRoute Component={UserDetail} roles={[UserRole.PROVIDER_ADMIN]} />}
      />
      <Route path="*" element={<ProviderDashboard />} />
    </Routes>
  );
};
