import { memo } from 'react';
import { Card, Col, Row, Space, Button } from 'antd';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { ICredentialSchema } from './schema';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useStyles } from './common/useStyles';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';

const CourseTeamForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('6');

  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    ...fields
  } = useFormContext<ICredentialSchema>();

  const rest = {
    ...fields,
    disabled: isFormReadOnly,
  };

  const handleFieldDirty = () => setIsDirty(true);

  const {
    fields: academicFields,
    append: appendAcademic,
    remove: removeAcademic,
    move: moveAcademic,
  } = useFieldArray({
    control,
    name: `academics`,
  });

  const onAddClick = () => {
    appendAcademic({ name: '', title: '', aboutP1: '', photo: null, url: null });
    handleFieldDirty();
  };

  return (
    <Card>
      <div>
        <h1>Academics</h1>
      </div>
      {academicFields.map((academic, index) => {
        return (
          <Card key={academic.id} className={classes.credCard} size="small">
            <Space className={classes.credCard__actions}>
              <Button
                size="small"
                icon={<ArrowUpOutlined />}
                disabled={academicFields.length === 1 || index === 0 || rest.disabled}
                onClick={() => {
                  moveAcademic(index, index - 1);
                  handleFieldDirty();
                }}
              />
              <Button
                size="small"
                icon={<ArrowDownOutlined />}
                disabled={academicFields.length === 1 || index === academicFields.length - 1 || rest.disabled}
                onClick={() => {
                  moveAcademic(index, index + 1);
                  handleFieldDirty();
                }}
              />
              <Button
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  removeAcademic(index);
                  handleFieldDirty();
                }}
              />
            </Space>
            <Row>
              <Col span={24}>
                <GenericInput
                  name={`academics[${index}].name`}
                  label="Name"
                  control={control}
                  showCount
                  maxLength={200}
                  required
                  errors={errors.academics?.[index]?.name}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  name={`academics[${index}].title`}
                  label="Title"
                  control={control}
                  showCount
                  maxLength={200}
                  required
                  errors={errors.academics?.[index]?.title}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  label="About"
                  name={`academics[${index}].aboutP1` as const}
                  control={control}
                  required
                  showCount
                  maxLength={1000}
                  type="textarea"
                  errors={errors.academics?.[index]?.aboutP1 as FieldError}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  name={`academics[${index}].photo`}
                  label="Photo URL"
                  control={control}
                  showCount
                  maxLength={256}
                  descriptionAfter="e.g. https://example.com"
                  errors={errors.academics?.[index]?.photo}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <GenericInput
                  name={`academics[${index}].url`}
                  label="Profile URL"
                  control={control}
                  showCount
                  maxLength={256}
                  descriptionAfter="e.g. https://example.com"
                  errors={errors.academics?.[index]?.url}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
          </Card>
        );
      })}
      <br />
      <Button htmlType="button" onClick={onAddClick} disabled={rest.disabled}>
        Add <PlusOutlined />
      </Button>
    </Card>
  );
};

export default memo(CourseTeamForm);
