import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Modal, Row, Space, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/lib/typography';

import { errorNotification, infoNotification } from 'utils';
import { StaticPageAPI, StaticPageData, StaticPageDataContent } from 'api/static-page';
import BackLink from 'components/common/backLink/BackLink';
import { capitalise } from 'utils/string';
import { useCallbackPrompt } from 'hooks';
import { UserRole } from 'utils/UserUtils';

import './PagesDetails.less';
import { GlobalContext } from 'contexts/GlobalContext';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { staticPageSchemaLookup } from './form/schema';
import { staticPageFormLookup } from './form';

export const StaticPagesDetails = () => {
  const [page, setPage] = useState<StaticPageData>();

  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const shouldSubmit = useRef(true);

  const { currentAuthenticatedUser } = useContext(GlobalContext);

  const baseRoutes = currentAuthenticatedUser?.role === UserRole.ADMIN ? '/admin' : '/dese';

  const shouldBackRef = useRef(false);

  const { id } = useParams();

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(staticPageSchemaLookup[id as keyof typeof staticPageSchemaLookup]),
  });

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(methods.formState.isDirty);

  const navigate = useNavigate();

  // update currenUrl based on user role.
  useEffect(() => {
    const checkCurrentRoute = () => {
      navigate(`${baseRoutes}/static-page/${id}`);
    };
    checkCurrentRoute();
  }, [baseRoutes, currentAuthenticatedUser, navigate, id]);

  useEffect(() => {
    if (id) {
      StaticPageAPI.get(id).then(([data, errors]) => {
        if (errors) {
          errorNotification('Failed to load.');
        } else {
          setPage(data);
          methods.reset(data?.data);
        }
      });
    }
  }, [id, methods]);

  const onPublish = () => {
    triggerForm(false)();
    queueMicrotask(() => {
      setIsConfirmModalOpen(false);
    });
  };

  // Discard modal
  const onShowDiscardModal = () => {
    if (methods.formState.isDirty) {
      setIsDiscardModalOpen(true);
    }
  };

  const handleDiscardModalOk = () => {
    setIsDiscardModalOpen(false);
    methods.reset(page?.data);
  };

  const handleCancelDiscardModal = () => {
    setIsDiscardModalOpen(false);
  };

  const triggerForm =
    (shouldBack: boolean = false) =>
    () => {
      shouldBackRef.current = shouldBack;
      methods.handleSubmit(onSaveAndPublish as SubmitHandler<FieldValues>)();
    };

  const onBackToPage = () => navigate(`${baseRoutes}/static-page`, { replace: false });

  const onSaveAndPublish = async (formData: StaticPageDataContent) => {
    // onSubmit fn logic copied here
    if (id) {
      const [data, errors] = await StaticPageAPI.update(id, formData);
      if (errors) {
        errorNotification('Failed to publish. Please try again.');
      } else {
        infoNotification('Changes have been published on the MicroCred Seeker website.');
        setPage((prevState) => ({
          ...(prevState as StaticPageData),
          data: data?.data || (prevState as StaticPageData).data,
        }));
        methods.reset(data?.data);
        if (shouldBackRef.current) {
          confirmNavigation();
        }
      }
    } else {
      shouldSubmit.current = true;
      setIsConfirmModalOpen(true);
    }
  };

  const onCancelConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  if (!page) return <Spin />;

  return (
    <>
      <div>
        <BackLink text="Pages" onClick={onBackToPage} />
        <Row style={{ marginTop: '15px' }} justify="space-between" align="top">
          <h2>{capitalise(page.id.replace('-', ' '))}</h2>
          <Space>
            <Button type="default" disabled={!methods.formState.isDirty} onClick={onShowDiscardModal}>
              Discard changes
            </Button>
            <Button type="primary" disabled={!methods.formState.isDirty} onClick={() => setIsConfirmModalOpen(true)}>
              Save and publish
            </Button>
          </Space>
        </Row>
        <Card>
          <FormProvider {...methods}>{staticPageFormLookup[id as keyof typeof staticPageFormLookup]}</FormProvider>
        </Card>
      </div>
      {/* Discard changes modal */}
      <Modal
        maskClosable={false}
        className="discard-modal"
        title="Discard changes"
        open={isDiscardModalOpen}
        footer={[
          <Button key="back" onClick={handleCancelDiscardModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" danger onClick={handleDiscardModalOk}>
            Discard changes
          </Button>,
        ]}
        onCancel={handleCancelDiscardModal}
      >
        <Typography.Text>
          Are you sure you want to discard your changes? Unpublished changes will be lost. You cannot undo this action.
        </Typography.Text>
      </Modal>
      {/* Publish modal */}
      <Modal
        maskClosable={false}
        title="Unpublished changes"
        open={showPrompt}
        footer={[
          <Button key="back" onClick={cancelNavigation}>
            Cancel
          </Button>,
          <Button key="publish" onClick={triggerForm(true)}>
            Publish
          </Button>,
          <Button key="submit" type="primary" onClick={confirmNavigation}>
            Discard changes
          </Button>,
        ]}
        onCancel={cancelNavigation}
      >
        <Typography.Text>
          You have unpublished changes. Are you sure you want leave this page? All unpublished changes will be lost.
        </Typography.Text>
      </Modal>
      {/* Confirm modal */}
      <Modal
        maskClosable={false}
        title="Publish changes"
        open={isConfirmModalOpen}
        footer={[
          <Button key="back" onClick={onCancelConfirmModal}>
            Cancel
          </Button>,
          <Button key="publish" type="primary" onClick={onPublish}>
            Publish
          </Button>,
        ]}
        onCancel={onCancelConfirmModal}
      >
        <Typography.Text>
          These changes will be made available immediately on MicroCred Seeker. Click Publish to confirm.
        </Typography.Text>
      </Modal>
    </>
  );
};
