import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import { Button, Card, notification, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import StatusIndicator, { STATUS_TYPE } from 'components/common/statusIndicator/StatusIndicator';
import useUserList from 'components/hooks/useUserList';
import { UserContext } from 'components/UserContextProvider';
import { DISPLAY_USER_STATUS, IUserWithProvider, USER_STATUS } from 'model/user';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserRole, UserRoleDisplay } from 'utils/UserUtils';
import { DeactivateUserModal } from './modals/DeactivateUser';
import { EditRoleModal } from './modals/EditRole';
import { ReactivateUserModal } from './modals/ReactivateUser';
import API from 'api';
import './User.less';
import { formatDateTime } from 'utils/date';
import { ResendInvitationModal } from './modals/ResendInvitation';

export const UserDetail = () => {
  const { isAdmin, role } = useContext(UserContext);
  const { isFetchingUser, deactivateUser, reactivateUser, updateUserRole, user, resendInvitationUser } =
    useUserList(true);

  const [currentUser, setCurrentUser] = useSafeState<IUserWithProvider>({} as IUserWithProvider);
  const [openUpdateUserRoleModal, toggleUpdateUserRoleModal] = useSafeState(false);
  const [openDeactivateUserModal, toggleDeactivateUserModal] = useSafeState(false);
  const [openReactiveUserModal, toggleReactiveUserModal] = useSafeState(false);
  const [openResendInvitationUserModal, toggleResendInvitationUserModal] = useSafeState(false);

  const [selectedOptionForUpdateUserRole, setSelectedOptionForUpdateUserRole] = useSafeState<DefaultOptionType>(
    {} as DefaultOptionType
  );
  const [isProcessing, setIsProcessing] = useSafeState(false);
  const [errorMessage, setErrorMessage] = useSafeState('');

  const userManagementAPI = role === UserRole.ADMIN ? API.Admin : UserRole.PROVIDER_ADMIN ? API.Provider : undefined;

  useEffect(() => {
    if (!isFetchingUser) {
      setCurrentUser(user);
    }
  }, [isFetchingUser]);

  const renderUserStatus = () => {
    let statusType = STATUS_TYPE.NONE;

    switch (currentUser?.status) {
      case USER_STATUS.PASSWORD_RESET_PENDING:
        statusType = STATUS_TYPE.PENDING;
        break;

      case USER_STATUS.ACTIVE:
        statusType = STATUS_TYPE.ACTIVE;
        break;

      case USER_STATUS.INACTIVE:
        statusType = STATUS_TYPE.INACTIVE;
        break;

      default:
        statusType = STATUS_TYPE.NONE;
        break;
    }

    return (
      <StatusIndicator
        status={statusType}
        text={
          <span className="status-indicator">{DISPLAY_USER_STATUS[currentUser?.status as USER_STATUS] || 'None'}</span>
        }
      />
    );
  };

  const updateRoleSelectedUser = async () => {
    setIsProcessing(true);
    const [, error] = await updateUserRole(currentUser.id, selectedOptionForUpdateUserRole.value as string);
    if (!error) {
      notification.success({ message: 'User role updated' });

      setCurrentUser({
        ...currentUser,
        role: selectedOptionForUpdateUserRole.value as UserRole,
      });
      toggleUpdateUserRoleModal(false);
    } else {
      setErrorMessage(error.message);
    }

    setIsProcessing(false);
  };

  const onCloseUpdateUserRoleModal = () => {
    toggleUpdateUserRoleModal(false);
  };

  const reactivateSelectedUser = async () => {
    const [, error] = await reactivateUser(currentUser.username);
    if (!error) {
      notification.success({ message: 'User reactivated' });
    } else {
      notification.error({ message: 'Failed to deactivate user. Please try again.' });
    }

    toggleReactiveUserModal(false);

    if (userManagementAPI) {
      const [data] = await userManagementAPI.getUser(currentUser.id);
      setCurrentUser({
        ...currentUser,
        status: data?.status as USER_STATUS,
      });
    }
  };

  const onCloseReactiveUserModal = () => {
    toggleReactiveUserModal(false);
  };

  const deactivateSelectedUser = async () => {
    const [, error] = await deactivateUser(currentUser.username);
    if (!error) {
      notification.success({ message: 'User deactivated' });
    } else {
      notification.error({ message: 'Failed to deactivate user. Please try again.' });
    }

    setCurrentUser({
      ...currentUser,
      status: USER_STATUS.INACTIVE,
    });
    toggleDeactivateUserModal(false);
  };

  const onCloseDeactivateUserModal = () => {
    toggleDeactivateUserModal(false);
  };

  const onCloseResendInvitationUserModal = () => {
    toggleResendInvitationUserModal(false);
  };

  const resendInvitationToSelectedUser = async () => {
    const [_, error] = await resendInvitationUser(currentUser.username);
    if (!error) {
      notification.success({ message: 'Email has been sent to the user' });
      onCloseResendInvitationUserModal()
    } else {
      notification.error({ message: 'Failed to send the email to the user. Please try again' });
    }
  };

  const renderUserDetailActions = () => {
    let actions: string[] = [];

    switch (currentUser?.status) {
      case USER_STATUS.PASSWORD_RESET_PENDING:
        actions = ['deactivate', 'resendInvitation'];
        break;
      case USER_STATUS.ACTIVE:
        actions = ['deactivate'];
        break;
      case USER_STATUS.INACTIVE:
        actions = ['reactivate'];
        break;
      default:
        break;
    }

    const actionButton: Record<string, JSX.Element | React.ReactNode> = {
      deactivate: (
        <Button type="primary" onClick={() => toggleDeactivateUserModal(true)}>
          Deactivate
        </Button>
      ),
      reactivate: (
        <Button type="primary" onClick={() => toggleReactiveUserModal(true)}>
          Reactivate
        </Button>
      ),
      resendInvitation: (
        <Button type="primary" onClick={() => toggleResendInvitationUserModal(true)}>
          Resend invitation
        </Button>
      ),
    };

    return actions.map((action) => <React.Fragment key={action}>{actionButton[action]}</React.Fragment>);
  };

  const handleToggleEditRoleModal = () => {
    setErrorMessage('');
    setSelectedOptionForUpdateUserRole({
      label: UserRoleDisplay[currentUser.role],
      value: currentUser.role,
    });
    toggleUpdateUserRoleModal(true);
  };

  if (isFetchingUser) {
    return <>Loading...</>;
  }

  return (
    <>
      <Link className="to-courses-link" to={isAdmin ? '/admin/users' : '/provider/users'}>
        <Space>
          <ArrowLeftOutlined />
          <span>Users</span>
        </Space>
      </Link>
      <div className="card-title" style={{ justifyContent: 'space-between' }}>
        <h2 className="heading-3">{currentUser.name}</h2>
        <div className="action-wrapper">{renderUserDetailActions()}</div>
      </div>

      <Card>
        <h3>User details</h3>

        <h4>Full name</h4>
        <p>{currentUser?.name || '-'}</p>

        <h4>Status</h4>
        <div style={{ marginBottom: 12 }}>{renderUserStatus()}</div>

        <h4>Email</h4>
        <p>{currentUser?.email}</p>

        <h4>Role</h4>
        <p>
          <span>{UserRoleDisplay[currentUser.role]}</span>

          {role === UserRole.ADMIN || role === UserRole.PROVIDER_ADMIN ? (
            <span style={{ marginLeft: 4 }}>
              <Button type="link" onClick={handleToggleEditRoleModal}>
                Edit role
              </Button>
            </span>
          ) : (
            <></>
          )}
        </p>

        <h4>Organisation</h4>
        <p>{currentUser?.provider?.tradingName || '-'}</p>

        <h4>User ID</h4>
        <p>{currentUser?.id}</p>

        <h4>Created</h4>
        <p>{currentUser?.userCreateDate ? formatDateTime(currentUser.userCreateDate) : '-'}</p>
      </Card>

      <EditRoleModal
        openModal={openUpdateUserRoleModal}
        onClose={onCloseUpdateUserRoleModal}
        onUpdate={updateRoleSelectedUser}
        selectedOption={selectedOptionForUpdateUserRole}
        setSelectedOption={setSelectedOptionForUpdateUserRole}
        selectedUser={currentUser}
        role={role}
        errorMessage={errorMessage}
        isProcessing={isProcessing}
      />
      {openDeactivateUserModal && (
        <DeactivateUserModal
          openModal={openDeactivateUserModal}
          onClose={onCloseDeactivateUserModal}
          onUpdate={deactivateSelectedUser}
          selectedUser={currentUser}
        />
      )}
      {openReactiveUserModal && (
        <ReactivateUserModal
          openModal={openReactiveUserModal}
          onClose={onCloseReactiveUserModal}
          onUpdate={reactivateSelectedUser}
          selectedUser={currentUser}
        />
      )}
      {openResendInvitationUserModal && (
        <ResendInvitationModal
          openModal={openResendInvitationUserModal}
          onClose={onCloseResendInvitationUserModal}
          onUpdate={resendInvitationToSelectedUser}
          selectedUser={currentUser}
        />
      )}
    </>
  );
};
