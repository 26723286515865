import { z } from 'zod';
import { SkillName } from '../Course.types';
import { isURL, transformEmptyStringToUndefined } from './utils';

const qualitySchema = z.object({
  quality: z
    .array(
      z.object({
        title: z.string().max(500).nonempty({ message: 'Required' }),
        desc: z.string().max(1000).nonempty({ message: 'Required' }),
        logo: z
          .string()
          .max(256)
          .transform(transformEmptyStringToUndefined)
          .optional()
          .nullable()
          .refine(
            (value) => {
              return !value || isURL(value);
            },
            {
              message: 'Should match format URL',
            }
          ),
        url: z
          .string()
          .max(256)
          .transform(transformEmptyStringToUndefined)
          .optional()
          .nullable()
          .refine(
            (value) => {
              return !value || isURL(value);
            },
            {
              message: 'Should match format URL',
            }
          ),
      })
    )
    .nullable()
    .optional(),
});

const skillSchema = z.object({
  skills: z
    .array(
      z.object({
        name: z.union([
          z.enum([
            // Forced to hard code due to zod bug.
            SkillName.AGRICULTURE_AND_ANIMALS,
            SkillName.ARCHIVING_RECORDING_AND_TRANSLATING,
            SkillName.ART_AND_ENTERTAINMENT,
            SkillName.BUSINESS_OPERATIONS_AND_FINANCIAL_ACTIVITIES,
            SkillName.CLEANING_AND_MAINTENANCE,
            SkillName.COMMUNICATION_AND_COLLABORATION,
            SkillName.CONSTRUCTION,
            SkillName.CUSTOMER_SERVICE,
            SkillName.DATA_ANALYTICS_AND_DATABASES,
            SkillName.DIGITAL_TECHNOLOGIES_AND_ELECTRONICS,
            SkillName.ENVIRONMENTAL_MANAGEMENT,
            SkillName.FASHION_GROOMING_AND_COSMETICS,
            SkillName.FOOD_SERVICES,
            SkillName.HEALTH_AND_CARE,
            SkillName.HUMAN_RESOURCES,
            SkillName.MATERIAL_TRANSPORTATION,
            SkillName.OPERATING_PROCEDURES_AND_PROCESSES,
            SkillName.PERFORMANCE_EVALUATION_AND_EFFICIENCY_IMPROVEMENT,
            SkillName.PRODUCTION_PROCESSES_AND_MACHINERY,
            SkillName.QUALITY_CONTROL_AND_INSPECTIONS,
            SkillName.RECORDS_DOCUMENTATION_REPORTS_AND_RESEARCH,
            SkillName.RECREATION_AND_SPORTING_EVENTS,
            SkillName.SAFETY_AND_HAZARD_MANAGEMENT,
            SkillName.SALES_AND_MARKETING,
            SkillName.SCIENCE_AND_MATHEMATICS,
            SkillName.SECURITY_AND_EMERGENCY_SERVICES,
            SkillName.TEACHING_AND_EDUCATION,
            SkillName.VEHICLE_OPERATION,
            SkillName.WORK_RELATED_ACTIVITIES_PREPARATION,
          ]),
          z.string().nonempty('Required'),
        ]),
      })
    )
    .min(1, 'Please add at least one skill')
    .max(3, 'You cannot have more than 3 skills')
    .nullable()
    .refine((skills) => new Set(skills ? skills.map((skill) => skill.name) : []).size === skills?.length ?? 0, {
      message: 'You cannot have more than one occurrence of a skill.',
    }),
});

export const qualitySkillsSchema = qualitySchema.merge(skillSchema);
