import { memo } from 'react';
import { makeStyles } from 'utils/theme';

interface GenericLabelProps {
  label: string;
  required?: boolean;
}

const GenericLabel = ({ label, required }: GenericLabelProps) => {
  const { classes } = useStyles();

  if (!label) return <></>;

  return required ? (
    <div>
      <span className={classes.label}>{label}</span>&nbsp;<span className={classes.asterisk}>*</span>
    </div>
  ) : (
    <span className={classes.label}>{label}</span>
  );
};

const useStyles = makeStyles()(() => ({
  asterisk: {
    color: 'red',
  },
  label: {
    fontWeight: 500,
  },
}));

export default memo(GenericLabel);
