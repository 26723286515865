import { IUser, IUserForChallenge } from 'model/user';
import React, { createContext, memo, useState } from 'react';

export enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

export interface Message {
  type?: MessageType;
  content?: string;
}

export interface IGlobalContext {
  message: Message;
  setMessage: (message: Message) => void;
  currentAuthenticatedUser: IUser | undefined;
  setCurrentAuthenticatedUser: (currentAuthenticatedUser: IUser | undefined) => void;
  temporaryUserForNewPassword: IUserForChallenge | undefined;
  setTemporaryUserForNewPassword: (temporaryUserForNewPassword: IUserForChallenge | undefined) => void;
}

const initGlobalContextValues: IGlobalContext = {
  message: {},
  setMessage: (message: Message) => {},
  currentAuthenticatedUser: undefined,
  setCurrentAuthenticatedUser: (currentAuthenticatedUser: IUser | undefined) => {},
  temporaryUserForNewPassword: undefined,
  setTemporaryUserForNewPassword: (temporaryUserForNewPassword: IUserForChallenge | undefined) => {},
};

export const GlobalContext = createContext<IGlobalContext>(initGlobalContextValues);

export const GlobalContextProvider = memo(({ children }: { children: any }): JSX.Element => {
  const setMessage = (message: Message) => {
    setState((prevState: IGlobalContext) => ({
      ...prevState,
      message,
    }));
  };

  const setCurrentAuthenticatedUser = (currentAuthenticatedUser: IUser | undefined) => {
    setState((prevState: IGlobalContext) => ({
      ...prevState,
      currentAuthenticatedUser,
    }));
  };

  const setTemporaryUserForNewPassword = (temporaryUserForNewPassword: IUserForChallenge | undefined) => {
    setState((prevState: IGlobalContext) => ({
      ...prevState,
      temporaryUserForNewPassword,
    }));
  };

  const [state, setState] = useState({
    message: {},
    setMessage,
    currentAuthenticatedUser: undefined,
    setCurrentAuthenticatedUser,
    temporaryUserForNewPassword: undefined,
    setTemporaryUserForNewPassword,
  } as IGlobalContext);

  return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>;
});
