import Icon from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import { Button, Row, Space } from 'antd';
import API from 'api';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { useNavigate } from 'react-router-dom';

import ProviderSelect from './ProviderSelect';

import './HeaderMenu.less';

const HeaderMenu = () => {
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      if (window.location.href.includes('/courses')) {
        const splitResult = window.location.href.split('/courses/');
        const [providerId, courseId] = splitResult[splitResult.length - 1].split('/');
  
        await API.Cred2.unlock(providerId, courseId);
      }
    
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row align="middle" justify="space-between" className='header-container'>
        <ProviderSelect />
        <Space size={40}>
          <Button
            id="help-center-link"
            type="link"
            href="https://uac.helpjuice.com/marketplace/secrets/9dAvPhMhiU9wIcL5Kdf-FQ/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help Centre <Icon component={ExternalLink} />
          </Button>
          <Button className='primary-sign-out' type="primary" onClick={signOut}>
            Sign out
          </Button>
        </Space>
      </Row>
    </>
  );
};

export default HeaderMenu;
