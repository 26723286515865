import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { StateOptions } from './common/definitions';
import { IProfileSchema } from './schema';
import { useStyles } from 'components/provider/course/form/common/useStyles';

interface IProfileCampusesForm {
  readOnly?: boolean;
}

const ProfileCampusesForm: React.FC<IProfileCampusesForm> = ({ readOnly = false }) => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IProfileSchema>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'campuses',
  });

  const { classes } = useStyles();

  const onAddClick = () => {
    append({
      name: '',
      location: {
        addressLine1: '',
        addressLine2: undefined,
        suburb: '',
        postCode: '',
        state: '',
        latitude: undefined,
        longitude: undefined,
      },
    });
  };

  return (
    <Card>
      <Typography.Title level={5}>Campuses</Typography.Title>
      {fields.map((field, index) => (
        <Card key={field.id} className={classes.credCard} size="small">
          <Space className={classes.credCard__actions}>
            <Button
              size="small"
              icon={<ArrowUpOutlined />}
              disabled={fields.length === 1 || index === 0 || readOnly}
              onClick={() => move(index, index - 1)}
            />
            <Button
              size="small"
              icon={<ArrowDownOutlined />}
              disabled={fields.length === 1 || index === fields.length - 1 || readOnly}
              onClick={() => move(index, index + 1)}
            />
            <Button size="small" danger icon={<DeleteOutlined />} disabled={readOnly} onClick={() => remove(index)} />
          </Space>

          <Row>
            <Col span={22}>
              <GenericInput
                label="Campus name"
                name={`campuses.${index}.name` as const}
                required
                control={control}
                disabled={readOnly}
                showCount
                maxLength={100}
                errors={errors?.campuses ? errors.campuses[index]?.name : undefined}
                {...rest}
              />
              <Typography.Title level={5}>Location</Typography.Title>
              <GenericInput
                label="Street address"
                name={`campuses.${index}.location.addressLine1` as const}
                required
                control={control}
                disabled={readOnly}
                showCount
                maxLength={100}
                errors={errors?.campuses ? errors.campuses[index]?.location?.addressLine1 : undefined}
                {...rest}
              />
              <GenericInput
                label="AddressLine2"
                name={`campuses.${index}.location.addressLine2` as const}
                control={control}
                disabled={readOnly}
                showCount
                maxLength={100}
                errors={errors?.campuses ? errors.campuses[index]?.location?.addressLine2 : undefined}
                {...rest}
              />

              <Row style={{ gap: 10 }}>
                <Col span={6}>
                  <GenericInput
                    label="Suburb"
                    name={`campuses.${index}.location.suburb` as const}
                    control={control}
                    disabled={readOnly}
                    required
                    showCount
                    maxLength={100}
                    errors={errors?.campuses ? errors.campuses[index]?.location?.suburb : undefined}
                    {...rest}
                  />
                </Col>
                <Col span={6}>
                  <GenericInput
                    label="State"
                    options={StateOptions}
                    name={`campuses.${index}.location.state` as const}
                    type="select"
                    control={control}
                    disabled={readOnly}
                    required
                    errors={errors?.campuses ? errors.campuses[index]?.location?.state : undefined}
                    {...rest}
                  />
                </Col>
                <Col span={6}>
                  <GenericInput
                    label="Postcode"
                    name={`campuses.${index}.location.postCode` as const}
                    maxLength={10}
                    showCount
                    control={control}
                    disabled={readOnly}
                    required
                    errors={errors?.campuses ? errors.campuses[index]?.location?.postCode : undefined}
                    {...rest}
                  />
                </Col>
              </Row>

              <Row style={{ gap: 10 }}>
                <Col span={6}>
                  <GenericInput
                    label="Latitude"
                    name={`campuses.${index}.location.latitude` as const}
                    control={control}
                    disabled={readOnly}
                    showCount
                    maxLength={100}
                    errors={errors?.campuses ? errors.campuses[index]?.location?.latitude : undefined}
                    {...rest}
                  />
                </Col>
                <Col span={6}>
                  <GenericInput
                    label="Longitude"
                    name={`campuses.${index}.location.longitude` as const}
                    control={control}
                    disabled={readOnly}
                    showCount
                    maxLength={100}
                    errors={errors?.campuses ? errors.campuses[index]?.location?.longitude : undefined}
                    {...rest}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
      <br />
      <Button htmlType="button" onClick={onAddClick} disabled={readOnly}>
        Add <PlusOutlined />
      </Button>
    </Card>
  );
};

export default ProfileCampusesForm;
