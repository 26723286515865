import axios from './_utils/axiosInstance';
import { IError, handleAPIPromise } from './utils';

const generateCourseDump = async (): Promise<[string | undefined, IError | undefined]> => {
  return await handleAPIPromise<string>(axios.get(`/dese/report/course-dump`));
};
const DeseAPI = {
  generateCourseDump,
};

export default DeseAPI;
