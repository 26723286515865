import { Amplify, I18n } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
});

I18n.putVocabulariesForLanguage('en', {
  Username: 'Enter your username',
  Password: 'Enter your password',
});
