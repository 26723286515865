import { handleAPIPromise } from 'api/utils';
import axios from './_utils/axiosInstance';

export interface StaticPageListItem {
  id?: string;
  name?: string;
  updatedAt?: number;

  title?: string;
  description?: string;
  list?: StaticPageListItem[];
}

export interface StaticPageData {
  id: string;
  path: string;
  data: StaticPageDataContent;
  schema: {
    schema: Record<string, string | number | boolean | Record<string, string | number | boolean>[]>;
  };
}

export interface StaticPageDataContent {
  htmlTitle?: string;
  list: StaticPageListItem[];
}

export interface StaticPageDataContentItem {
  description: string;
  list?: StaticPageDataContentItem[];
  title?: string;
}

export abstract class StaticPageAPI {
  static async list() {
    return await handleAPIPromise<StaticPageListItem[]>(axios.get('/provider/static-page'));
  }

  static async get(id: string) {
    return await handleAPIPromise<StaticPageData>(axios.get(`/provider/static-page/${id}`));
  }

  static async update(id: string, data: StaticPageDataContent) {
    return await handleAPIPromise(
      axios.post(`/provider/static-page/${id}`, {
        data,
      })
    );
  }
}
