import * as z from 'zod';

import { removeHTMLTags } from 'utils/validation';

export const copyrightSchema = z.object({
  title: z.string().min(1, 'Required'),
  body: z.string().refine((val) => removeHTMLTags(val).length !== 0, {
    message: 'Required',
  }),
});

export type ICopyrightSchema = z.infer<typeof copyrightSchema>;
