export enum RecognitionType {
  CREDIT = 'Credit',
  AWARD = 'Award',
  CERTIFICATE = 'Certificate',
  STACKABLE = 'Stackable',
  OTHER = 'Other',
}

export enum RecognitionTypeDropdown {
  Credit = 'CREDIT',
  Award = 'AWARD',
  Certificate = 'CERTIFICATE',
  Stackable = 'STACKABLE',
  Other = 'OTHER',
}

export const courseLevels = [
  {
    name: 'Novice',
    description:
      'A novice is just learning the basics of a subject, along with fundamental concepts and specific rules of action. They are unable to exercise discretionary judgment and have rigid adherence to taught rules or plans.',
  },
  {
    name: 'Advanced Beginner',
    description:
      'The advanced beginner is beginning to connect relevant contexts to the rules and facts they are learning. Adding to their knowledge of facts, they are recognizing previously undefined facts and increasing their knowledge of relevant concepts, vocabulary, and principles. Individuals at this level may have no sense of practical priority. All aspects of work may be treated separately and will likely have equal importance.',
  },
  {
    name: 'Competent',
    description:
      'A competent performer is beginning to recognize more context-free principles and concepts as well as situational elements. A person at this level is able to select rules or perspectives appropriate to the situation, taking responsibility for the approach.',
  },
  {
    name: 'Proficient',
    description:
      'A proficient performer has experience making situational discriminations that enables the recognition of problems and the best approaches for solving the problems. At this stage, intuitive reactions replace reasoned responses. A person at this level implements decisions based on intuitive understanding because of their experiences.',
  },
  {
    name: 'Expert',
    description:
      'The expert can see what needs to be achieved and how to achieve it. This level of performer can make more refined and subtle discriminations than a proficient performer, tailoring approach and method to each situation based on this level of skill. The major difference between individuals in the stages of proficiency and expertise is that what experts do almost always works. Proficient people still make mistakes; experts provide an almost perfect performance.',
  },
];

export const conditionTypes = [
  { id: 'ENTRY-CRITERIA', name: 'Entry Criteria' },
  { id: 'GUIDANCE-NOTE', name: 'Guidance note' },
  { id: 'INHERENT-REQ', name: 'Inherent requirement' },
  { id: 'OTHER', name: 'Other' },
  { id: 'PREREQUISITE', name: 'Prerequisite' },
];

export const STATUS_TAGS: { [key: string]: string } = {
  New: 'default',
  Edit: 'default',
  Submitted: 'processing',
  Review: 'processing',
  Approved: 'green',
  Unpublished: 'warning',
  Inactive: 'warning',
  PublishError: 'error',
  Published: 'green'
}
