import { UserContext, UserContextProvider } from 'components/UserContextProvider';
import { Checkbox, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import API from 'api';
import HeaderMenu from 'components/provider/layout/HeaderMenu';
import LeftMenu from 'components/provider/layout/LeftMenu';
import { ProviderContextProvider } from 'components/ProviderContextProvider';
import { ConfirmModal } from 'components/ui/modals';

import { useState, useContext } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import { ProviderRouters } from 'Routers';

const ProviderApp = () => {
  const { signedInDeclaration, reload } = useContext(UserContext);

  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [signing, setSigning] = useState<boolean>(false);

  const checkboxChange = (e: CheckboxChangeEvent) => {
    setCheckboxChecked(e.target.checked);
  };

  const confirmSignInDeclaration = async () => {
    setSigning(true);
    const [, error] = await API.Provider.signInDeclaration();
    if (error) {
      notification['error']({
        message: error.message,
      });
      setSigning(false);
      return;
    }

    reload();
    setSigning(false);
  };

  return (
    <DashboardLayout
      LeftMenu={LeftMenu}
      HeaderMenu={HeaderMenu}
      ContentBody={
        <>
          <ProviderRouters />
          {!signedInDeclaration && (
            <ConfirmModal
              isModalVisible={!signedInDeclaration}
              title="Welcome to MicroCred Seeker"
              confirmText="Agree and continue"
              submitButtonProps={{ disabled: !checkboxChecked }}
              cancelButtonProps={{ style: { display: 'none' } }}
              onConfirm={confirmSignInDeclaration}
              closable={false}
              confirmLoading={signing}
            >
              <p>Before you continue, you must agree to the terms of using MicroCred Seeker.</p>
              <Checkbox onChange={checkboxChange} id="agree-checkbox">
                All content, images and videos uploaded are appropriate and licensed.
              </Checkbox>
            </ConfirmModal>
          )}
        </>
      }
    />
  );
};

const ProviderAppWithProvider = () => {
  return (
    <UserContextProvider>
      <ProviderContextProvider>
        <ProviderApp />
      </ProviderContextProvider>
    </UserContextProvider>
  );
};

export default ProviderAppWithProvider;
