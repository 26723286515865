import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { STATUS_TYPE, StatusIndicator } from 'components/common/statusIndicator/StatusIndicator';
import { COURSE_STATUS, DISPLAY_COURSE_STATUS } from 'model/course';

import './CourseCardWithRemove.less';

export const CourseCardWithRemove = (
  item: { title: string; id: string; courseCode: string; status: string },
  removeCourse: (id: string) => void,
  disabled: boolean
) => {
  let statusType = STATUS_TYPE.NONE;

  switch (item.status) {
    case COURSE_STATUS.Published:
    case COURSE_STATUS.Approved:
      statusType = STATUS_TYPE.ACTIVE;
      break;
    case COURSE_STATUS.PublishError:
    case COURSE_STATUS.Unpublished:
    case COURSE_STATUS.Inactive:
      statusType = STATUS_TYPE.INACTIVE;
      break;
    case COURSE_STATUS.Review:
      statusType = STATUS_TYPE.PENDING;
      break;
  }

  return (
    <div key={item.id} className="courseCardContainer">
      <div className="courseCardContainer__text">
        <span>{item.title}</span>
        <span className="courseCardContainer__text__sub-text">{item.courseCode}</span>
      </div>
      <div className="courseCardContainer__status-text">
        <StatusIndicator status={statusType} text={DISPLAY_COURSE_STATUS[item.status as COURSE_STATUS]} />
      </div>
      <div className="courseCardContainer__remove-container">
        <Button
          icon={<CloseOutlined style={{ color: 'red', fontSize: '12px' }} />}
          size="large"
          onClick={() => removeCourse(item.id)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
