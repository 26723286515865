import { useSafeState } from 'ahooks';
import useSWR from 'swr';
import ProviderAPI from 'api/admin';
import { UserContext } from 'components/UserContextProvider';
import { IUserWithProvider } from 'model/user';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserRole } from 'utils/UserUtils';
import axios from 'api/_utils/axiosInstance';
import { IError } from 'api/utils';

let cache: IUserWithProvider[] = [];

function useCourseList(forceReload: boolean = false) {
  const { userId } = useParams();
  const { currentProvider, role, selectedOptions } = useContext(UserContext);

  const [user, setUser] = useSafeState<IUserWithProvider>({} as IUserWithProvider);
  const [users, setUsers] = useSafeState<IUserWithProvider[]>(cache);
  const [error, setError] = useSafeState<IError | string>();
  const [searchText, setSearchText] = useSafeState('');

  // We can't use isLoading in SWR because
  // isLoading will not be triggered to true when the fetch function change.
  // We could use remove cached, so that the SWR's isLoading will be set to true
  // But that will be like against the SWR
  const [isFetchingUser, setIsFetchingUser] = useSafeState(true);

  const adminOrProviderUrl = (providerId: string | undefined) => {
    if (providerId) {
      return { url: `/provider/${providerId}/user` };
    }

    return { url: 'admin/user' };
  };

  const getUserAdminOrProviderUrl = () => {
    if (role === UserRole.ADMIN) {
      return `admin/user/${userId}`;
    } else if (role === UserRole.PROVIDER_ADMIN) {
      return `/provider/user/${userId}`;
    } else {
      return null;
    }
  };

  useSWR(() => (!!userId ? getUserAdminOrProviderUrl() : null), axios, {
    onError: (error: IError) => {
      setError(error);
      setIsFetchingUser(false);
    },
    onSuccess: ({ data }: { data: IUserWithProvider }) => {
      setUser(data);
      setIsFetchingUser(false);
    },
  });

  const { isLoading: isFetchingUsers, mutate } = useSWR(
    adminOrProviderUrl(currentProvider),
    ({ url }) => axios.get(url),
    {
      onError: (err: IError) => {
        setError(err);
      },
      onSuccess: ({ data }: { data: IUserWithProvider[] }) => {
        if (data) {
          const fetchedUsers = data.map((user: IUserWithProvider) => ({
            ...user,
            searchName: user.name?.trim().toLowerCase(),
            searchEmail: user.email?.trim().toLowerCase(),
          }));

          cache = fetchedUsers;

          // After fetching the user list
          // Filter it again with the searchText and selected provider
          // If the currentProvider is '' (which mean the dropdown value is 'All providers')
          // Then sending an empty array provider selected to ignore it
          const mappingOptions = selectedOptions.map((item) => item.value);
          searchUser(searchText, currentProvider === '' ? mappingOptions as string[] : [currentProvider]);
        }
      },
    }
  );

  const addOrUpdate = (data: IUserWithProvider) => {
    const index = users.findIndex((c: IUserWithProvider) => c.email === data.email);
    const newList = [...users];
    if (index === -1) {
      newList.push(data);
    } else {
      newList.splice(index, 1, data);
    }
    newList.sort((c1: IUserWithProvider, c2: IUserWithProvider) => (c1.email < c2.email ? -1 : 1));
    setUsers(newList);

    const usersIndexInCache = cache.findIndex((cUser) => cUser.email === data.email);
    cache[usersIndexInCache] = data;
  };

  const deleted = (data: IUserWithProvider) => {
    const deleteIndex = users.findIndex((c: IUserWithProvider) => c.email === data.email);
    const newList = [...users];
    newList.splice(deleteIndex, 1);
    setUsers(newList);
    cache = newList;
  };

  const deactivateUser = async (userId: string) => {
    return await ProviderAPI.deactivateUser(userId, role);
  };

  const updateUserRole = async (userId: string, newRole: string) => {
    return await ProviderAPI.updateUserRole(userId, role, newRole);
  };

  const searchUser = async (text: string, providers: string[]) => {
    const sText = text.trim().toLowerCase();

    const result = cache
      .map((user: IUserWithProvider) => {
        if (user.searchName?.includes(sText) || user.searchEmail?.includes(sText)) {
          if (providers.length) {
            if (providers.includes(user.defaultProvider)) {
              return user;
            }
          } else {
            return user;
          }
        }

        if (providers.length && providers.includes(user.defaultProvider) && sText === '') {
          return user;
        }
      })
      .filter(Boolean) as IUserWithProvider[];

    setSearchText(sText);
    setUsers(result);
  };

  const reactivateUser = async (username: string, dryRun?: boolean, ignoreCurrentUser = false) => {
    return await ProviderAPI.activateUser(username, role, dryRun, ignoreCurrentUser);
  };

  const resendInvitationUser = async (username: string) => {
    return await ProviderAPI.resendInvitation(username);
  };

  return {
    list: users,
    loading: isFetchingUsers,
    error: error,
    addOrUpdate,
    deleted,
    deactivateUser,
    updateUserRole,
    reactivateUser,
    searchUser,
    user,
    resendInvitationUser,
    mutate,
    isFetchingUser,
    searchText,
    setSearchText
  };
}

export default useCourseList;
