import Modal from 'antd/lib/modal/Modal';
import { FC } from 'react';

interface Props {
  children: React.ReactNode;
  confirmLoading?: boolean;
  isModalVisible: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  confirmText?: string;
  submitButtonProps?: any;
  cancelButtonProps?: any;
  closable?: boolean;
  footer?: null;
}

export const ConfirmModal: FC<Props> = ({
  children,
  confirmLoading = false,
  isModalVisible,
  onConfirm,
  onCancel,
  title,
  confirmText,
  submitButtonProps,
  cancelButtonProps,
  closable,
  ...props
}) => {

  return (
    <Modal
      title={title}
      open={isModalVisible}
      onOk={onConfirm}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      okButtonProps={{ type: 'primary', ...submitButtonProps }}
      cancelButtonProps={{ ...cancelButtonProps }}
      maskClosable={false}
      okText={confirmText ?? title}
      closable={closable}
      {...props}
    >
      {children}
    </Modal>
  );
};
