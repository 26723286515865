import { Select } from 'antd';
import { UserContext } from 'components/UserContextProvider';
import { HTMLAttributes, useCallback, useContext } from 'react';
import { IProvider } from 'types/provider';
import './providerSelect.less';

const ProviderSelect = ({ ...props }: HTMLAttributes<HTMLElement>) => {
  const { currentProvider, listProvider, changeProvider } = useContext(UserContext);

  const providerSelect = useCallback(
    (providerId?: string) => {
      if (!providerId) {
        return changeProvider('');
      }

      return changeProvider(providerId);
    },
    [changeProvider]
  );

  const onProviderSelect = (selectedProvider: string) => {
    providerSelect(selectedProvider);
  };

  if (!listProvider?.length) {
    return null;
  }

  return (
    <div {...props}>
      {listProvider.length > 0 && (
        <Select
          id="providerSelect"
          placeholder="Select provider"
          onChange={onProviderSelect}
          value={currentProvider}
          aria-label="select provider"
          bordered={false}
          dropdownMatchSelectWidth={false}
          style={{ padding: 0 }}
          className="provider-select"
        >
          <Select.Option key={'_all_providers'} value={''}>
            All providers
          </Select.Option>
          {listProvider.map((provider: IProvider) => {
            return (
              <Select.Option key={provider.id} value={provider.id}>
                {provider.extId}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </div>
  );
};

export default ProviderSelect;
