import { z } from 'zod';

import { campusesSchema } from './campuses';
import { overviewSchema } from './overview';
import { policiesSchema } from './policies';
import { settingsSchema } from './settings';

export const profileSchema = overviewSchema.merge(campusesSchema).merge(policiesSchema).merge(settingsSchema);

export type IProfileSchema = z.infer<typeof profileSchema>;
