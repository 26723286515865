import { UserRole } from 'utils/UserUtils';
import { z } from 'zod';

export const userModalSchema = z
  .object({
    email: z.string().email({message: 'Please enter valid email address.'}).nonempty({ message: 'Required' }),
    name: z.string().nonempty({ message: 'Required' }),
    role: z.enum([UserRole.PROVIDER_ADMIN, UserRole.PROVIDER_EDITOR, UserRole.DESE_EDITOR, UserRole.ADMIN]),
    defaultProvider: z.string().optional(),
  })
  .refine(
    (data) => {
      // Check if role is DESE_EDITOR or ADMIN
      if (data.role === UserRole.DESE_EDITOR || data.role === UserRole.ADMIN) {
        return true;
      }

      // If role is not DESE_EDITOR or ADMIN, check if defaultProvider is provided and non-empty
      return data.defaultProvider && data.defaultProvider !== '';
    },
    {
      message: 'Required',
      path: ['defaultProvider'],
    }
  );

export type ICreateUserSchema = z.infer<typeof userModalSchema>;
