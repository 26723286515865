import { memo, useEffect } from 'react';
import { CourseLevel, FieldOfEducationName, IndustryName, UnitName } from './Course.types';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import GenericLabel from 'components/form/shared/GenericLabel';
import { enumToArray } from 'components/form/shared/FormHelpers';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useStyles } from './common/useStyles';
import { ICredentialSchema } from './schema';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';

const CourseSummaryForm = ({ isFormReadOnly }: CredFormProps) => {
  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    ...fields
  } = useFormContext<ICredentialSchema>();

  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('1');

  const rest = {
    ...fields,
    disabled: isFormReadOnly,
  };

  const {
    fields: industryFields,
    append,
    remove,
    move,
  } = useFieldArray<any>({
    control,
    name: 'industries',
  });

  const handleFieldDirty = () => setIsDirty(true);

  const watchedDiscountIndicator = watch('discountIndicator');

  useEffect(() => {
    if (!watchedDiscountIndicator) {
      // reset field when discount indicator is false
      setValue('discountDesc.discountDescP1', '');
    }
  }, [watchedDiscountIndicator]);

  const addIndustry = () => {
    // Defaults to empty value
    append('');
    handleFieldDirty();
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <GenericInput
            name="courseCode"
            label="Code"
            disabled
            readOnly
            control={control}
            required
            showCount
            maxLength={30}
            errors={errors['courseCode']}
            handleFieldDirty={handleFieldDirty}
            {...fields}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="title"
            label="Title"
            control={control}
            required
            showCount
            maxLength={300}
            errors={errors['title']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="level"
            label="Level"
            descriptionBefore="Select a level that is relevant to this microcredential"
            type="select"
            control={control}
            options={enumToArray(CourseLevel)}
            required
            errors={errors['level']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <GenericLabel label="Duration" required />
          <Row>
            <Col span={12}>
              <GenericInput
                name="duration.unit"
                label="Unit"
                type="select"
                control={control}
                options={enumToArray(UnitName)}
                required
                errors={errors['duration'] ? errors['duration']['unit'] : undefined}
                handleFieldDirty={handleFieldDirty}
                {...rest}
              />
            </Col>
            <Col span={12}>
              <GenericInput
                name="duration.value"
                label="Value"
                type="number"
                control={control}
                required
                errors={errors['duration'] ? errors['duration']['value'] : undefined}
                handleFieldDirty={handleFieldDirty}
                {...rest}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="commitment"
            label="Commitment"
            control={control}
            required
            showCount
            maxLength={1000}
            errors={errors['commitment']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="language"
            label="Language"
            control={control}
            required
            showCount
            maxLength={50}
            errors={errors['language']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericInput
            name="fieldOfEducation"
            label="Field of Education"
            descriptionBefore="Select a field of education that is relevant to this microcredential"
            type="select"
            control={control}
            options={enumToArray(FieldOfEducationName)}
            required
            errors={errors['fieldOfEducation']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GenericLabel label="Industry" required />
        </Col>
        <Col span={24}>
          <p>Select an industry that is relevant to this microcredential</p>
        </Col>
        <Col span={24}>
          {industryFields.map((industry: Record<'id', string>, index: number) => {
            return (
              <Card key={index} className={classes.credCard}>
                <Space className={classes.credCard__actions}>
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    disabled={industryFields.length === 1 || index === 0 || rest.disabled}
                    onClick={() => {
                      move(index, index - 1);
                      handleFieldDirty();
                    }}
                  />
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    disabled={industryFields.length === 1 || index === industryFields.length - 1 || rest.disabled}
                    onClick={() => {
                      move(index, index + 1);
                      handleFieldDirty();
                    }}
                  />
                  <Button
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      remove(index);
                      handleFieldDirty();
                    }}
                    disabled={rest.disabled}
                  />
                </Space>
                <GenericInput
                  key={industry.id}
                  name={`industries[${index}]`}
                  type="select"
                  control={control}
                  options={enumToArray(IndustryName)}
                  errors={errors['industries'] ? (errors['industries'][`${index}`] as FieldError) : undefined}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Card>
            );
          })}
          {errors.industries && <Typography.Paragraph type="danger">{errors.industries.message}</Typography.Paragraph>}
          <br />
          {industryFields.length < 3 && !rest.disabled && (
            <Button onClick={addIndustry}>
              Add <PlusOutlined />
            </Button>
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={10}>
          <GenericInput
            name="price"
            label="Price"
            descriptionBefore="Please enter the total price for this microcredential"
            type="price"
            control={control}
            required
            addonBefore="AUD$"
            errors={errors['price']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>

      {watchedDiscountIndicator && (
        <Row>
          <Col span={24}>
            <GenericInput
              name="discountDesc.discountDescP1"
              label="Discount"
              type="textarea"
              control={control}
              placeholder="Enter information regarding any discounts (if applicable)"
              required
              showCount
              maxLength={500}
              errors={errors['discountDesc'] ? errors['discountDesc']['discountDescP1'] : undefined}
              handleFieldDirty={handleFieldDirty}
              {...rest}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={10}>
          <GenericInput
            name="discountIndicator"
            label="Discount available?"
            type="checkbox"
            control={control}
            errors={errors['discountIndicator']}
            handleFieldDirty={handleFieldDirty}
            {...rest}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default memo(CourseSummaryForm);
