import { FC } from "react";
import { Button } from "antd";

interface SubmitEditButtonProps {
  onClick: () => void
  updatingStatus: boolean;
  disabled: boolean;
}

const RejectButton: FC<SubmitEditButtonProps> = ({
  onClick,
  updatingStatus,
  disabled
}) => (
  <Button
    size="large"
    onClick={onClick}
    loading={updatingStatus}
    danger
    disabled={disabled}
  >
    Reject
  </Button>
);

export default RejectButton;
