export const GENERIC_VALIDATION_ERROR_MESSAGE =
  'Either some mandatory fields are missing and/or validations are failing. Please check the fields to view errors.';

/* Regex */
export const alphanumericRegex = /^[a-zA-Z0-9]+$/;
export const sessionDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

/* FOR images */
export const UPLOAD_LOGO_RECTANGLE_WIDTH = 300;
export const UPLOAD_LOGO_SQUARE_WIDTH = 200;
export const UPLOAD_LOGO_HEIGHT = 200;
export const UPLOAD_LOGO_SUPPORTED_TYPES_REGEX = /^image\/(png|jpeg|jpg){1}$/gi;
