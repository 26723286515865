export const preprocessRequiredStringValue = (
  arg: unknown,
  undefinedReplacementValue = '',
  nullReplacementValue = ''
): unknown => {
  switch (typeof arg) {
    case 'string':
      // removes line breaks and trims string
      return arg.trim().replace(/\r?\n|\r/g,'');
    case 'undefined':
      return undefinedReplacementValue;
    case 'object':
      return arg === null ? nullReplacementValue : arg;
    default:
      return arg;
  }
};

export const preprocessTrimStringValue = (arg: unknown): unknown => {
  return typeof arg === 'string' ? arg.trim() : arg;
};
