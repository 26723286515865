import { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  try {
    const session = await Auth.currentSession();
    if (config.headers && session) {
      config.headers['Authorization'] = 'Bearer ' + session.getIdToken().getJwtToken();
    }
  } catch (error) {
    // rethrow error if it is not because of the user is not loggedIn and try to access a public URL
    if (!(config.url?.includes('forgot-password') && (error as string).toLowerCase().includes('no current user'))) {
      throw error;
    }
  }
  return Promise.resolve(config);
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.log(`onRequestError`, error);
  return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  return axiosInstance;
}
