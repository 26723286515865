import { FC } from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link-line.svg";

interface PreviewButtonProps {
  pathname: string;
  onPreview: () => void,
  disabled: boolean;
  loading?: boolean
}

const PreviewButton: FC<PreviewButtonProps> = ({ onPreview, disabled, loading }) => (
  <Button
    type="default"
    size="large"
    icon={<Icon component={ExternalLinkIcon} />}
    tabIndex={0}
    onClick={onPreview}
    disabled={disabled}
    {...loading && {loading}} 
  >
    Preview
  </Button>
);

export default PreviewButton;
