import { groupBy } from 'lodash';

export interface CourseCodeLookup {
  index: number;
  courseCode: string;
}

export const findDuplicateCourses = (
  courses: CourseCodeLookup[],
  skipFirstOccurrence = false
): Array<{ index: number; courseCode: string }> => {
  const groupedCourses = groupBy(courses, (course) => course.courseCode.toLowerCase());
  const duplicateCourses: Array<{ index: number; courseCode: string }> = [];

  for (const courseCode in groupedCourses) {
    if (groupedCourses[courseCode].length > 1) {
      // slice(1) to Skip the first occurrence
      const duplicates = !!skipFirstOccurrence ? groupedCourses[courseCode].slice(1) : groupedCourses[courseCode];
      for (const course of duplicates) {
        duplicateCourses.push({ index: course.index, courseCode: course.courseCode });
      }
    }
  }

  return duplicateCourses;
};
