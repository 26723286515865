import { FC } from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as DeleteBinIcon } from "assets/icons/delete-bin-line.svg";


interface DiscardDraftButtonProps {
  onClick: () => void;
  loading: boolean,
  disabled: boolean;
}

const DiscardDraftButton: FC<DiscardDraftButtonProps> = ({
  onClick, loading, disabled
}) => {
  return (
    <Button
      type="default"
      size="large"
      style={{ fontSize: "14px" }}
      onClick={onClick}
      icon={<Icon component={DeleteBinIcon} />}
      tabIndex={0}
      loading={loading}
      disabled={disabled}
    >
      Discard draft
    </Button>
  );
};

export default DiscardDraftButton;
