import { z } from 'zod';
import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const assessmentsSchema = z.object({
  assessments: z
    .array(
      z.object({
        title: z.string().nonempty('Required').max(500, 'Should not be more than 500 characters'),
        desc: z
          .string()
          .nonempty('Required')
          .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
          .refine((val) => removeHTMLTags(val).length <= 2000, { message: 'Should not be more than 2000 characters' }),
        tasks: z
          .array(
            z.object({
              desc: z.string().nonempty('Required').max(1000, 'Should not be more than 1000 characters'),
            })
          )
          .optional(),
      })
    )
    .min(1, 'Please add at least one assessment'),
});
