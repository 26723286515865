import { useBoolean, useResetState, useSafeState, useUpdateEffect } from 'ahooks';
import { ConfirmModal } from 'components/ui/modals';
import { Checkbox, Spin } from 'antd';
import { useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

interface IOffboardProviderModal {
  onClose: () => void;
  openModal: boolean;
  onConfirm: () => void;
  confirmLoading: boolean;
  providerData: {
    providerId: string;
    tradingName: string;
  };
  fetchProviderNumbers: (providerId: string) => Promise<void | { courses: number; users: number }>;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

export default function OffboardProviderModal({
  onClose,
  openModal,
  onConfirm,
  confirmLoading,
  providerData = { providerId: '', tradingName: 'this provider' },
  fetchProviderNumbers,
}: IOffboardProviderModal) {
  const [loading, { set: setLoading }] = useBoolean(false);
  const [agree, setAgree] = useSafeState(false);
  const [numOfCoursesAndUsers, setNumOfCoursesAndUsers, resetNumOfCoursesAndUsers] = useResetState({
    courses: 0,
    users: 0,
  });

  useUpdateEffect(() => {
    setAgree(false);
  }, [onClose]);

  useMemo(() => {
    setLoading(true);
    fetchProviderNumbers(providerData.providerId)
      .then((res) => {
        if (!res) {
          resetNumOfCoursesAndUsers();
          return;
        }
        setNumOfCoursesAndUsers(res);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgree = () => setAgree(!agree);

  const modalProps: {
    title: string;
    onCancel: () => void;
    isModalVisible: boolean;
    onConfirm: () => void;
    confirmLoading: boolean;
    submitButtonProps: {
      style: unknown;
      disabled: boolean;
    };
    confirmText: string;
    footer?: null;
  } = {
    title: 'Offboarding provider',
    onCancel: onClose,
    isModalVisible: openModal,
    onConfirm: onConfirm,
    confirmLoading: confirmLoading,
    submitButtonProps: {
      style: { backgroundColor: '#D72C0D', borderColor: '#D72C0D' },
      disabled: !agree || confirmLoading,
    },
    confirmText: 'Offboard provider',
  };

  if (loading) {
    modalProps.footer = null;
  }

  return (
    <ConfirmModal {...modalProps}>
      {loading ? (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '198px'}}>
        <Spin indicator={loadingIcon} style={{paddingBottom: '24px'}}/>
        <p >Loading...</p>
        </div>
      ) : (
        <>
          <p>
            Are you sure you want to offboard <strong>{providerData.tradingName}</strong>? This provider and all
            associated data will be permanently deleted. You cannot undo this action.
          </p>
          <p>
            <span>
              <strong>{numOfCoursesAndUsers.courses}</strong> microcredential(s) will be deleted
            </span>
            <br />
            <span>
              <strong>{numOfCoursesAndUsers.users}</strong> user(s) will be deleted
            </span>
          </p>
          <p>
            <Checkbox checked={agree} onChange={handleAgree} name={'offboarding-agreement-checkbox'}>
              I understand the consequences of offboarding this provider
            </Checkbox>
          </p>
        </>
      )}
    </ConfirmModal>
  );
}
