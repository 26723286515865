import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import { Typography } from 'antd';
import { COURSE_STATUS } from 'model/course';

interface Props {
  confirmLoading?: boolean;
  courseName?: string;
  onCancel: () => void;
  onDraftDeleted: () => void;
  onConfirm: () => void;
  isModalVisible: boolean;
  status?: string;
  title?: string;
}

export const UnpublishModal: FC<Props> = ({
  confirmLoading = false,
  courseName = 'this course',
  onCancel,
  onDraftDeleted,
  onConfirm,
  isModalVisible,
  status = '',
}) => {
  return (
    <Modal
      title="Unpublish microcredential"
      open={isModalVisible}
      onOk={onConfirm}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <React.Fragment key="discard-and-unpublish">
          {['Edit'].includes(status) && <Button onClick={onDraftDeleted}>Unpublish and discard draft</Button>}
        </React.Fragment>,
        <Button key="submit" onClick={onConfirm} type="primary" loading={confirmLoading}>
          Unpublish
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to unpublish <strong>{courseName}</strong>? Users will no longer be able to view this page
        on the website.
      </p>
      {['Edit'].includes(status) && <p>Your draft version will be still be available to edit.</p>}
    </Modal>
  );
};

export const PublishModal: FC<{
  confirmLoading?: boolean;
  courseName?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isModalVisible: boolean;
  title?: string;
  noActiveSessionConfirm?: boolean;
  status: COURSE_STATUS | undefined;
}> = ({ confirmLoading = false, courseName = 'this course', onCancel, onConfirm, isModalVisible, ...props }) => {
  return (
    <Modal
      title="Publish microcredential"
      open={isModalVisible}
      onOk={onConfirm}
      maskClosable={false}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" onClick={onConfirm} type="primary" loading={confirmLoading}>
          Publish
        </Button>,
      ]}
    >
      <div>
        Are you sure you want to {[COURSE_STATUS.Unpublished, COURSE_STATUS.Edit].includes(props.status as COURSE_STATUS) && 're-'}
        publish <strong>{courseName}</strong>?
      </div>
      {props.noActiveSessionConfirm && (
        <Typography.Text type={'danger'}>
          <strong>No Active sessions are available for this microcredential. Do you still want to continue?</strong>
        </Typography.Text>
      )}
    </Modal>
  );
};
