import { Typography, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IBackLinkProps {
  href?: string;
  text: string;
  onClick?: () => void;
}

export const BackLink = ({ href, text, onClick }: IBackLinkProps) => {
  const navigate = useNavigate();

  const innerContent = (
    <Typography.Text type="secondary">
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'middle',
        }}
      >
        <ArrowLeftOutlined
          style={{
            marginRight: '6px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        />
        {text}
      </div>
    </Typography.Text>
  );

  if (onClick) {
    return (
      <Button type="link" onClick={onClick} aria-label="Back" style={{ padding: 0 }}>
        {innerContent}
      </Button>
    );
  }

  return (
    <>
      {href && (
        <Link to={href} aria-label={`Back to ${text}`} style={{ textDecoration: 'none' }}>
          {innerContent}
        </Link>
      )}
      {!href && (
        <Button type="link" onClick={() => navigate(-1)} aria-label="Back" style={{ padding: 0 }}>
          {innerContent}
        </Button>
      )}
    </>
  );
};

export default BackLink;
