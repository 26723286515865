import { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Form, Modal, Row, Space, Typography, notification } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from 'utils/theme';
import { UserContext } from 'components/UserContextProvider';
import { ICreateUserSchema, userModalSchema } from 'components/provider/user/form/schema';
import { UserRole, UserRoleDisplay, ProviderRoleDisplay } from 'utils/UserUtils';
import { enumToValueKeyArray } from 'components/form/shared/FormHelpers';
import GenericAutoComplete from 'components/form/shared/GenericAutoComplete';
import API from 'api';
import useUserList from 'components/hooks/useUserList';

interface ICreateUserModal {
  visible: boolean;
  handleCancel: () => void;
  setLoading: (loading: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  modalWrapper: {
    '& .ant-modal-body': {
      padding: '0.5rem 24px',
    },
  },
  formWrapper: {
    '& .ant-form-item-label': {
      paddingBottom: 0,
    },
    '& .ant-form-item': {
      marginBottom: 12,
    },
  },
}));

export const CreateUserModal = ({ visible, handleCancel, setLoading }: ICreateUserModal) => {
  const { reload, currentProvider, listProvider, role, isAdmin } = useContext(UserContext);
  const [canHaveProvider, setCanHaveProvider] = useState<boolean>(false);
  const { mutate } = useUserList(true);

  const { classes } = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    watch,
    setValue,
    reset,
    ...rest
  } = useForm<ICreateUserSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(userModalSchema),
  });

  const watchRole = watch('role');

  const listProviderOptions = useMemo(() => {
    if ([UserRole.ADMIN, UserRole.DESE_EDITOR].includes(watchRole)) {
      return [];
    }

    if ([UserRole.PROVIDER_ADMIN, UserRole.PROVIDER_EDITOR].includes(watchRole)) {
      return listProvider
        .map((provider) => ({
          value: provider.id!,
          label: provider.extId ? provider.extId : provider.id!,
          searchValue: `${provider.extId}-${provider.legalName}-${provider.name}-${provider.tradingName}`,
        }))
    }
    return [];
  }, [currentProvider, listProvider, watchRole]);

  useEffect(() => {
    if (!watchRole) {
      return;
    }
    if ([UserRole.ADMIN, UserRole.DESE_EDITOR].includes(watchRole)) {
      setValue('defaultProvider', undefined);
      setCanHaveProvider(false);
    } else {
      setCanHaveProvider(true);
    }

    if ([UserRole.PROVIDER_ADMIN, UserRole.PROVIDER_EDITOR].includes(watchRole)) {
      setValue('defaultProvider', currentProvider)
    }
  }, [setValue, watchRole, currentProvider]);

  useEffect(() => {
    if(currentProvider) {
      setValue('defaultProvider', currentProvider);
    }
  },[])

  const onSubmit = async (formData: ICreateUserSchema) => {
    setLoading(true);
    (role === UserRole.ADMIN ? API.Admin : API.Provider)
      .createUser(formData)
      .then((res) => {
        const [data, error] = res;
        if (error) {
          if (error.message.includes('UsernameExistsException')) {
            setError('email', {
              type: 'custom',
              message: 'User account already exists',
            });
          } else if (error.message.includes('Select a different role')) {
            setError('role', {
              type: 'custom',
              message: error.message,
            });
          } else {
            notification.error({
              message: 'Failed to invite user. Please try again.',
            });
          }
        }
        if (data) {
          notification.success({ message: 'User invited.' });
          handleCancel();
          reset();
          reload();
        }
      })
      .catch((error) => {
        notification.error({ message: 'Failed to invite user. Please try again.' });
        handleCancel();
        setLoading(true);
        reload();
      })
      .finally(() => {
        setLoading(false);
        mutate();
      });
  };

  const onCancel = () => {
    setCanHaveProvider(false)
    reset();
    handleCancel();
  };

  const listRolesOptions = isAdmin ? UserRoleDisplay : ProviderRoleDisplay

  return (
    <Modal
      title="Invite user"
      open={visible}
      cancelText="Cancel"
      okText="Invite user"
      onCancel={onCancel}
      onOk={handleSubmit(onSubmit)}
      destroyOnClose
      maskClosable={false}
      className={classes.modalWrapper}
    >
      <Form layout="vertical" className={classes.formWrapper}>
        <Space>
          <Typography.Paragraph>Invite users by email to join an organisation</Typography.Paragraph>
        </Space>
        <Row>
          <Col span={24}>
            <GenericInput
              name="email"
              type="text"
              label="Email address"
              control={control}
              required
              errors={errors['email']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="name"
              type="text"
              label="Full name"
              control={control}
              required
              errors={errors['name']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="role"
              type="select"
              label="Role"
              control={control}
              required
              errors={errors['role']}
              options={enumToValueKeyArray(listRolesOptions)}
              {...rest}
            />
          </Col>
        </Row>
        <Row hidden={!isAdmin}>
          <Col span={24}>
            <GenericAutoComplete
              name="defaultProvider"
              label="Organisation"
              control={control}
              required
              errors={errors['defaultProvider']}
              options={listProviderOptions}
              disabled={!canHaveProvider || !isAdmin}
              {...rest}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
