import API from 'api';
import { createContext, useContext, useCallback, useEffect } from 'react';
import { UserContext } from 'components/UserContextProvider';
import { IProvider } from 'types/provider';
import { useSafeState } from 'ahooks';

interface IProviderContext {
  provider: Partial<IProvider>;
  loading: boolean;
  reloadProvider: () => void;
}

const ProviderContext = createContext({
  provider: {} as IProvider,
  loading: true,
} as IProviderContext);

const ProviderContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentProvider } = useContext(UserContext);
  const [provider, setProvider] = useSafeState({});
  const [loading, setLoading] = useSafeState(true);

  const getProvider = useCallback(async () => {
    if (currentProvider) {
      const [data, error] = await API.Provider.get(currentProvider);
      if (!error) {
        setProvider(data);
      } else {
        // handle error
        console.error(`Error when fetching provider: ${error?.message}`);
      }
      setLoading(false);
    }
  }, [currentProvider]);

  useEffect(() => {
    setLoading(true);
    getProvider();
  }, [getProvider]);

  return (
    <ProviderContext.Provider
      value={{
        provider,
        loading,
        reloadProvider: getProvider,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export { ProviderContext, ProviderContextProvider };
