import HeaderMenu from 'components/admin/layout/HeaderMenu';
import LeftMenu from 'components/admin/layout/LeftMenu';
import { ProviderContextProvider } from 'components/ProviderContextProvider';
import { UserContextProvider } from 'components/UserContextProvider';

import DashboardLayout from 'layouts/DashboardLayout';
import { AdminRouters } from 'Routers';

const AdminApp = () => {
  return <DashboardLayout LeftMenu={LeftMenu} HeaderMenu={HeaderMenu} ContentBody={<AdminRouters />} />;
};

const AdminAppWithProvider = () => {
  return (
    <UserContextProvider>
      <ProviderContextProvider>
        <AdminApp />
      </ProviderContextProvider>
    </UserContextProvider>
  );
};

export default AdminAppWithProvider;
