import { ConfirmModal } from 'components/ui/modals';
import { IUserWithProvider } from 'model/user';

interface IDeactivateUserModalProps {
  openModal: boolean;
  selectedUser: IUserWithProvider;
  onClose: () => void;
  onUpdate: () => void;
}

export const DeactivateUserModal = (props: IDeactivateUserModalProps) => {
  const { openModal, selectedUser, onUpdate, onClose } = props;

  return (
    <ConfirmModal
      title="Deactivate user"
      onCancel={onClose}
      isModalVisible={openModal}
      onConfirm={onUpdate}
      submitButtonProps={{ style: { backgroundColor: '#D72C0D', borderColor: '#D72C0D' } }}
      confirmText="Deactivate"
    >
      <>
        Are you sure you want to deactivate <strong>{selectedUser.name}</strong>? This user will no longer be able to
        log in and access the MicroCred Seeker Provider Portal.
      </>
    </ConfirmModal>
  );
};
