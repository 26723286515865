import {FC} from "react";
import {Button} from "antd";

const SaveButton: FC<{
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
}> = ({onClick, loading, disabled}) => {

  return (
    <Button
      data-cy="save-changes"
      type="primary"
      size="large"
      className="summary-card-actions__submit-edit"
      onClick={onClick}
      tabIndex={0}
      loading={loading}
      disabled={disabled}
    >
      Save changes
    </Button>
  );
};

export default SaveButton;
