import { z } from 'zod';
import { alphanumericRegex } from './../../../../../constants';
import { CourseLevel, IndustryName, UnitName } from '../Course.types';
import { FieldOfEducationNames } from 'enums';

export const baseSummarySchema = z.object({
  courseCode: z
    .string()
    .nonempty({ message: 'Required' })
    .max(30, { message: 'Must have less than 30 characters' })
    .regex(alphanumericRegex, { message: 'Code must be alphanumeric' }),
  title: z
    .string()
    .nonempty({ message: 'Required' })
    .max(300, { message: 'Must have less than 300 characters' })
    .refine((val) => val.trim() === val, { message: 'Title cannot have leading or trailing whitespace' }),
  level: z.enum([
    CourseLevel.ADVANCED_BEGINNER,
    CourseLevel.COMPETENT,
    CourseLevel.EXPERT,
    CourseLevel.NOVICE,
    CourseLevel.PROFICIENT,
  ]),
  duration: z.object({
    unit: z.enum([UnitName.DAY, UnitName.HOUR, UnitName.MONTH, UnitName.WEEK]),
    value: z.number({ invalid_type_error: 'Required' }).min(1, 'Value must be greater than or equal to 1'),
  }),
  commitment: z
    .string()
    .nonempty({ message: 'Required' })
    .max(1000, { message: 'Must have less than 1000 characters' }),
  language: z.string().nonempty({ message: 'Required' }).max(50, { message: 'Must have less than 50 characters' }),
  fieldOfEducation: z.enum(
    [
      FieldOfEducationNames.AGRICULTURE_ENVIRONMENT_AND_RELATED_STUDIES,
      FieldOfEducationNames.ARCHITECTURE_AND_BUILDING,
      FieldOfEducationNames.CREATIVE_ARTS,
      FieldOfEducationNames.EDUCATION,
      FieldOfEducationNames.ENGINEERING_AND_RELATED_TECHNOLOGIES,
      FieldOfEducationNames.HEALTH,
      FieldOfEducationNames.INFORMATION_TECHNOLOGY,
      FieldOfEducationNames.MANAGEMENT_AND_COMMERCE,
      FieldOfEducationNames.MIXED_FIELD_PROGRAM,
      FieldOfEducationNames.NATURAL_AND_PHYSICAL_SCIENCES,
      FieldOfEducationNames.SOCIETY_AND_CULTURE,
      FieldOfEducationNames.TOURISM_HOSPITALITY_AND_PERSONAL_SERVICES,
    ],
    { invalid_type_error: 'Required', required_error: 'Required' }
  ),
  industries: z
    .array(
      z.union([
        z.enum([
          IndustryName.ACCOMMODATION_AND_FOOD_SERVICES,
          IndustryName.ADMINISTRATIVE_AND_SUPPORT_SERVICES,
          IndustryName.AGRICULTURE_FORESTRY_AND_FISHING,
          IndustryName.ARTS_AND_RECREATION_SERVICES,
          IndustryName.CONSTRUCTION,
          IndustryName.EDUCATION_AND_TRAINING,
          IndustryName.ELECTRICITY_GAS_WATER_AND_WASTE_SERVICES,
          IndustryName.FINANCIAL_AND_INSURANCE_SERVICES,
          IndustryName.HEALTH_CARE_AND_SOCIAL_ASSISTANCE,
          IndustryName.INFORMATION_MEDIA_AND_TELECOMMUNICATIONS,
          IndustryName.MANUFACTURING,
          IndustryName.MINING,
          IndustryName.OTHER_SERVICES,
          IndustryName.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES,
          IndustryName.PUBLIC_ADMINISTRATION_AND_SAFETY,
          IndustryName.RENTAL_HIRING_AND_REAL_ESTATE_SERVICES,
          IndustryName.RETAIL_TRADE,
          IndustryName.TRANSPORT_POSTAL_AND_WAREHOUSING,
          IndustryName.WHOLESALE_TRADE,
        ]),
        z.string().nonempty('Required'),
      ])
    )
    .min(1, 'Please add at least one industry')
    .max(3)
    .refine((industries) => new Set(industries).size === industries.length, {
      message: 'You cannot have more than one occurrence of an industry.',
    }),
  price: z
    .number({ invalid_type_error: 'Required' })
    .nonnegative({ message: 'Price must not be a negative value' })
    .min(0, 'Price must not be a negative value'),
});

export const discountSchema = z
  .object({
    discountIndicator: z.boolean().optional(),
    discountDesc: z
      .object({
        discountDescP1: z.string().max(500).optional().nullable(),
      })
      .nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.discountIndicator && (!data.discountDesc || !data.discountDesc.discountDescP1)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['discountDesc', 'discountDescP1'],
        message: 'Required',
      });
    }
  });

// MCREDMKTP-1623 - Work around to fix discount description validation issue
export const summarySchema = z.intersection(baseSummarySchema, discountSchema);
