import { z } from 'zod';
import { RecognitionTypeDropdown } from '../common/reference';
import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const creditsSchema = z
  .object({
    recognitions: z
      .array(
        z.object({
          title: z.string().nonempty({ message: 'Required' }).max(500, 'Should not be more than 500 characters'),
          description: z
            .string()
            .nonempty('Required')
            .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
            .refine((val) => removeHTMLTags(val).length <= 2000, {
              message: 'Should not be more than 2000 characters',
            }),
          type: z.union([
            z.enum([
              RecognitionTypeDropdown.Credit,
              RecognitionTypeDropdown.Award,
              RecognitionTypeDropdown.Certificate,
              RecognitionTypeDropdown.Stackable,
              RecognitionTypeDropdown.Other,
            ]),
            z.string().nonempty('Required'),
          ]),
          logo: z
            .string()
            .max(256, 'Should not be more than 256 characters')
            .url({ message: 'Should match format URL' })
            .optional()
            .or(z.literal(''))
            .transform((value) => (value === '' ? null : value))
            .nullable(),
        })
      )
      .max(5, 'You cannot have more than 5 recognitions')
      .optional(),
    recognitionError: z.void(),
  })
  .superRefine((val, ctx) => {
    const types = Array.from(new Set(val.recognitions?.map((e) => e.type)));
    if (types.length !== val?.recognitions?.length && val.recognitions) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['recognitionError'],
        message: 'You cannot have more than one occurrence of a recognition type.',
      });
    }
  });
