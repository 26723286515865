import { useState, useMemo } from 'react';

const useSelectedIdSet = <Type>(
  defaultSelected: Array<Type> = [],
  idList: Array<Type>
) => {
  const [selectedIds, setSelectedIds] = useState<Set<Type>>(
    new Set(defaultSelected)
  );

  const isChecked = (id: Type) => {
    return selectedIds.has(id);
  };

  const isAllSelected = useMemo(() => {
    return idList.length === selectedIds.size;
  }, [selectedIds, idList]);

  const addItem = (id: Type) => {
    const newList = new Set(selectedIds);
    newList.add(id);
    setSelectedIds(newList);
    return newList;
  };

  const removeItem = (id: Type) => {
    const newList = new Set(Array.from(selectedIds).filter((s) => s !== id));
    setSelectedIds(newList);
    return newList;
  };

  const toggleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds(new Set());
    } else {
      setSelectedIds(new Set(idList));
    }
  };

  return {
    selectedIds,
    setSelectedIds,
    addItem,
    removeItem,
    isChecked,
    isAllSelected,
    toggleSelectAll,
  };
};

export default useSelectedIdSet;
