import { makeStyles } from 'utils/theme';

export const useStyles = makeStyles()(() => ({
  card: {
    marginTop: '1.5rem',

    'div.ant-card-body': {
      position: 'relative',
      backgroundColor: '#FAFAFA',
      padding: '12px',
    },
  },
  cardAction: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 1,
  },

  addButton: {
    marginTop: '15px',
  },
}));
