import HeaderMenu from 'components/dese/layout/HeaderMenu';
import LeftMenu from 'components/dese/layout/LeftMenu';
import { UserContextProvider } from 'components/UserContextProvider';

import DashboardLayout from 'layouts/DashboardLayout';
import { DeseRouters } from 'Routers';

const DeseApp = () => {
  return <DashboardLayout LeftMenu={LeftMenu} HeaderMenu={HeaderMenu} ContentBody={<DeseRouters />} />;
};

const DeseAppWithProvider = () => {
  return (
    <UserContextProvider>
      <DeseApp />
    </UserContextProvider>
  );
};

export default DeseAppWithProvider;
