import { FC } from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as DeleteBinIcon } from "assets/icons/delete-bin-line.svg";

interface DeleteButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

const DeleteButton: FC<DeleteButtonProps> = ({ onClick, disabled }) => {
  return (
    <Button
      data-cy="delete-course"
      type="default"
      size="large"
      icon={<Icon component={DeleteBinIcon} />}
      onClick={onClick}
      tabIndex={0}
      disabled={disabled}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
