import { fallbackImage } from 'utils/file';

interface IOrgLogoProps {
  src?: string | null;
  style?: React.CSSProperties;
  altText?: string;
  size?: number;
  widthRatio?: number;
  padding?: string;
  className?: string;
}

export const Logo = (props: IOrgLogoProps) => {
  return (
    <div
      style={{
        height: props.size ?? 40,
        width: (props.size ?? 40) * (props.widthRatio ?? 1),
        border: '1px solid #eee',
        padding: props.padding ?? '6px',
        display: 'flex',
      }}
    >
      <img
        src={props.src && props.src !== '' ? props.src : fallbackImage}
        alt={`${props.altText || 'Logo'}`}
        width="100%"
        height="100%"
        style={{ alignSelf: 'center', objectFit: 'cover' }}
        className={props.className}
      />
    </div>
  );
};

export default Logo;
