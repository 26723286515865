import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import React, { FC, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import Typography from 'antd/lib/typography';

import './Modals.less';

interface IProps {
  confirmLoading?: boolean;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
  isModalVisible: boolean;
}

export const RejectModal: FC<IProps> = ({ confirmLoading = false, onCancel, onConfirm, isModalVisible }) => {
  const [comment, setComment] = useState<string>('');

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);

  const handleCancel = () => {
    setComment('');
    onCancel();
  };

  const handleReject = () => onConfirm(comment.trim());

  return (
    <Modal
      title="Reject approval"
      open={isModalVisible}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      className="reject"
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel} tabIndex={0}>
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={handleReject}
          type="primary"
          loading={confirmLoading}
          disabled={!comment.trim()}
          tabIndex={0}
          danger
        >
          Reject
        </Button>,
      ]}
    >
      <Typography.Text>
        Describe why this microcredential cannot be approved and published. The credential will be reverted to Draft,
        and can be updated and resubmitted.
      </Typography.Text>
      <br />
      <br />
      <Typography.Text>Reject reason</Typography.Text>
      <TextArea autoSize={{ minRows: 3 }} value={comment} onChange={handleOnChange} />
    </Modal>
  );
};
