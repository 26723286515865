import { Button, List, Row } from "antd";
import { IDashboardCredItem } from "api/provider";
import { useCallback, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { CourseItem } from "types/course";
import "./listItems.less";

export interface IListItems {
  emptyText?: string;
  loader?: (
    pageIndex?: number,
    pageSize?: number
  ) => Promise<{ data: any[]; canLoadMore?: boolean }>;
  // There is no way to fix this any problem
  // In order to fix this we have to define like a switch inside the logic of renderItem
  // But I think it's not a good idea and too risky
  renderItem: (item: IDashboardCredItem | NavigateFunction | any, index: number) => React.ReactNode;
}

const ListItems = ({ emptyText, loader, renderItem }: IListItems) => {
  const [initialised, setInitialised] = useState<boolean>(false);
  const [list, setList] = useState<CourseItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

  const load = useCallback(() => {
    if (loader) {
      setLoading(true);
      loader().then(({ data, canLoadMore }) => {
        setList((l) => [...l, ...data]);
        if (canLoadMore != null) {
          setCanLoadMore(canLoadMore);
        }
        setLoading(false);
      });
    }
  }, [loader]);

  useEffect(() => {
    if (!initialised) {
      load();
      setInitialised(true);
    }
  }, [load, initialised]);

  return (
    <List
      className="list-items-loader"
      dataSource={list}
      loading={loading}
      locale={{
        emptyText,
      }}
      loadMore={
        <>
          {!loading && canLoadMore && (
            <Row justify="center" style={{ marginTop: 12 }}>
              <Button onClick={load}>Load more</Button>
            </Row>
          )}
        </>
      }
      renderItem={renderItem}
      style={list.length > 0 ? { marginTop: -12 } : {}}
    />
  );
};

export default ListItems;
