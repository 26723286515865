import { Button, Table } from 'antd';
import React, { ReactNode, memo, useContext } from 'react';
import * as pako from 'pako';
import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import API from 'api';
import { errorNotification, successNotification } from 'utils';
import startCase from 'lodash/startCase';
import Papa from 'papaparse';
import { UserContext } from 'components/UserContextProvider';
import { UserRole } from 'utils/UserUtils';
import { useSafeState } from 'ahooks';
import { ICourseDumpProps } from 'api/admin';
import { makeStyles } from 'utils/theme';
import CustomPrevNextButtonRender from 'components/ui/pagination/CustomPrevNextButtonRender';

const useStyles = makeStyles()((theme) => ({
  tableWrapper: {
    '& .ant-table-tbody > tr > td:last-child': {
      paddingLeft: 14,
    },
  },
  actionButton: {
    color: theme.primaryColor,
    backgroundColor: 'transparent',
    padding: 0,
  },
  paginationTotal: {
    position: 'absolute',
    left: 16,
  },
}));

export interface IReportTableProps {
  title: string;
  description: string;
  action: ReactNode;
}

const columns: ColumnType<IReportTableProps>[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    width: '30%',
    defaultSortOrder: 'ascend' as SortOrder,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '50%',
    defaultSortOrder: 'ascend' as SortOrder,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: '20%',
    defaultSortOrder: 'ascend' as SortOrder,
  },
];

const onGenerateCourseDump = async (role: UserRole) => {
  const api = role === UserRole.ADMIN ? API.Admin : UserRole.DESE_EDITOR ? API.Dese : undefined;
  if (!api) return;
  const [data, error] = await api.generateCourseDump();

  if (error && error.message !== 'incorrect header check')
    errorNotification('Failed to generate report. Please try again.');

  if (data && !error) {
    try {
      // Decode base64 string to binary
      const decodedBase64 = atob(data);
      // Convert decoded string to Uint8Array
      const uintArray = new Uint8Array(decodedBase64.length);
      for (let i = 0; i < decodedBase64.length; i++) {
        uintArray[i] = decodedBase64.charCodeAt(i);
      }
      const decompressed = pako.ungzip(uintArray, { to: 'string' });

      const jsonData = JSON.parse(decompressed) as ICourseDumpProps[];

      // convert column names to startCase
      const fields = Object.keys(jsonData[0]).map((field) => startCase(field));

      // Transform each object in jsonData to have startCase keys
      const transformedData = jsonData.map((cred) => {
        const newObj = {} as ICourseDumpProps;
        for (const key in cred) {
          newObj[startCase(key)] = cred[key];
        }
        return newObj;
      });

      const csvData = Papa.unparse(
        {
          fields,
          data: transformedData,
        },
        {
          header: true,
        }
      );

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'course_data_report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      successNotification('Report generated');
    } catch (error) {
      console.log('error', error);
      errorNotification('Failed to generate report. Please try again.');
    }
  }
};

const getReportList = (
  role: UserRole,
  classesName: string,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return [
    {
      title: 'Course data report',
      description: 'Course data report',
      action: (
        <Button
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await onGenerateCourseDump(role);
            setIsLoading(false);
          }}
          type="text"
          className={classesName}
        >
          Generate Report
        </Button>
      ),
    },
  ];
};

const ReportListPage = () => {
  const { css, classes } = useStyles();

  const [isLoading, setIsLoading] = useSafeState<boolean>(false);
  const { role } = useContext(UserContext);

  return (
    <>
      <h2 className={css({ paddingBottom: '18px' })}>Reports</h2>
      <Table
        className={classes.tableWrapper}
        rowKey="title"
        dataSource={getReportList(role, classes.actionButton, isLoading, setIsLoading)}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          pageSizeOptions: [10, 20, 30, 50, 100],
          itemRender: CustomPrevNextButtonRender,
          showTotal: (total, range) => (
            <div className={classes.paginationTotal}>{`${range[0]} - ${range[1]} of ${total} items`}</div>
          ),
        }}
      />
    </>
  );
};

export default memo(ReportListPage);
