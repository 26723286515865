import React, { memo } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { makeStyles } from 'utils/theme';

const useStyles = makeStyles()(() => ({
  componentWrapper: {
    marginTop: 31,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: '#52C41A',
    fontSize: 42,
  },
  title: {
    marginTop: 11,
    fontSize: 20,
    fontWeight: 600,
  },
  message: {
    marginTop: 22,
    width: '84%',
    fontSize: 14,
    wordWrap: 'break-word',
    fontWeight: 400,
    marginBottom: 51,
  },
}));

const UploadSuccessful = ({ totalCoursesCount }: { totalCoursesCount: number }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.componentWrapper}>
      <div className={classes.errorIcon}>
        <CheckCircleFilled />
      </div>

      <div className={classes.title}>Your microcredentials are being imported.</div>
      <div className={classes.message}>
        We will send a notification email to your registered email address once the import is complete. Please refresh
        the page to view the changes.
      </div>
    </div>
  );
};

export default memo(UploadSuccessful);
