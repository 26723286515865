import { FC, useState } from "react";
import { Button, Card } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import "./SummaryComments.less";
import {ICourseComment} from "api/cred2";
interface SummaryCommentsProps {
  comments?: ICourseComment[];
}

export const SummaryComments: FC<SummaryCommentsProps> = ({ comments = [] }) => {
  const [showMore, setShowMore] = useState(false);

  if (isEmpty(comments)) return null;

  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Card.Grid hoverable={false} className="summary-comments">
      <h5>Comments from reviewer</h5>
      {comments?.slice(0, 1).map(({ createdAt, comment }, index) => (
        <div key={`comment_${index}`}>
          <h6>{dayjs(createdAt).format("DD MMMM YYYY, HH:mm")}</h6>
          <p>{comment}</p>
        </div>
      ))}
      {showMore &&
        comments?.slice(1).map(({ createdAt, comment }, index) => (
          <div key={`comment_${index}`}>
            <h6>{dayjs(createdAt).format("DD MMMM YYYY, HH:mm")}</h6>
            <p>{comment}</p>
          </div>
        ))}
      {!isEmpty(comments?.slice(1)) && (
        <Button onClick={handleShowMore} type="link">
          {!showMore
            ? `View ${comments?.length - 1} more comments`
            : "Hide comments"}
        </Button>
      )}
    </Card.Grid>
  );
};
