export function Footer() {
  const textContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5em',
  };

  return (
    <div style={textContainerStyle}>
      <p style={{ margin: 0 }}>&copy; All Rights Reserved.</p>
    </div>
  );
}
