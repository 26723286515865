import { ProviderType } from 'enums';
import { removeHTMLTags } from 'utils/validation';
import { z } from 'zod';

export const locationSchema = z.object({
  addressLine1: z.string().nonempty({ message: 'Required' }).max(100),
  addressLine2: z.string().max(100).optional(),
  suburb: z.string().nonempty({ message: 'Required' }).max(100),
  state: z.enum(['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']),
  postCode: z
    .string()
    .nonempty({ message: 'Required' })
    .max(10)
    .regex(/^[0-9]+$/, 'Accepts only numbers'),
  latitude: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) =>
        val === null ||
        val === undefined ||
        val?.trim() === '' ||
        /^[-+]?[1-9]\d*(\.\d+)?([,]\s*[-+]?[1-9]\d*(\.\d+)?)?$/.test(val),
      'allowed characters are 0-9 + - .'
    )
    .refine(
      (val) => val === null || val === undefined || val?.trim() === '' || val.length <= 100,
      'Must be 100 characters or less'
    )
    .transform((value) => (value === undefined || value?.trim() === '' ? null : value)),

  longitude: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) =>
        val === null ||
        val === undefined ||
        val?.trim() === '' ||
        /^[-+]?[1-9]\d*(\.\d+)?([,]\s*[-+]?[1-9]\d*(\.\d+)?)?$/.test(val),
      'allowed characters are 0-9 + - .'
    )
    .refine(
      (val) => val === null || val === undefined || val?.trim() === '' || val.length <= 100,
      'Must be 100 characters or less'
    )
    .transform((value) => (value === undefined || value?.trim() === '' ? null : value)),
});

export const emailOrUrlSchema = z.union([
  z.string().email().max(100, 'Email should not be more than 100 characters'),
  z.string().url().max(256, 'URL should not be more than 256 characters'),
]);

export const overviewSchema = z.object({
  legalName: z.string().max(100).optional(),
  tradingName: z.string().nonempty({ message: 'Required' }).max(100),
  providerType: z.enum([ProviderType.HEP, ProviderType.VET]),
  orgId: z
    .string()
    .regex(/^[0-9]+$/, 'Enter a valid provider code')
    .min(4, 'Must be at least 4 digits')
    .max(5),
  extId: z.string().nonempty({ message: 'Required' }).max(50),
  providerAbout: z
    .string()
    .refine(
      (value) => {
        // Validate string length is greater than 0
        return removeHTMLTags(value).length > 0;
      },
      {
        message: 'Required',
      }
    )
    .refine(
      (value) => {
        // Validate string length is less than or equal to 5000
        return removeHTMLTags(value).length <= 5000;
      },
      {
        message: 'Should not be more than 5000 characters',
      }
    ),
  address: locationSchema,
  phoneNumber: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) => val === null || val === undefined || val?.trim() === '' || /^[ 0-9\+\-\(\)]*$/.test(val),
      'Enter a valid phone number'
    )
    .refine(
      (val) => val === null || val === undefined || val?.trim() === '' || (val.length >= 6 && val.length <= 16),
      'Must be between 6 and 16 digits'
    )
    .transform((value) => (value === undefined || value?.trim() === '' ? null : value)),
  email: z
    .string()
    .optional()
    .nullable()
    .transform((value) => (typeof value === 'string' && value.trim() === '' ? undefined : value))
    .refine(
      (value) => {
        if (!value) return true;
        return emailOrUrlSchema.safeParse(value).success;
      },
      {
        message: 'Must be an email or URL',
      }
    ),
  enquiryEmail: z.string().nonempty({ message: 'Required' }).max(100).email('Must be an email'),
  website: z
    .string()
    .nullable()
    .optional()
    .transform((value) => (typeof value === 'string' && value.trim() === '' ? undefined : value))
    .refine(
      (value) => {
        if (value === undefined || value === null) {
          return true; // Allow undefined values (optional field)
        }

        // Check if the URL is valid and its length does not exceed 256 characters
        try {
          new URL(value); // try to parse the value in URL format
          return value.length <= 256;
        } catch (e) {
          return false;
        }
      },
      {
        message: 'Should match format URL',
      }
    ),
  logo: z.string().optional(),
  logoX: z.string().optional(),
});

export type OverviewSchema = z.infer<typeof overviewSchema>;

export const createProviderProfileSchema = overviewSchema.omit({ providerAbout: true });

export type CreateProviderProfileSchema = z.infer<typeof createProviderProfileSchema>;
