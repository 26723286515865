import { Row, Col, Button } from 'antd';
import { useState } from 'react';
import { UserList } from './UserList';
import CreateUserModal from './CreateUserModal';
import { useBoolean } from 'ahooks';

export const Users = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, { set: setLoading }] = useBoolean(true);

  const inviteUser = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <h2>Users</h2>
        </Col>
        <Col>
          <Button type="primary" onClick={inviteUser} data-cy="invite-user-button">
            Invite user
          </Button>
        </Col>
      </Row>

      <UserList />
      <CreateUserModal visible={isModalOpen} handleCancel={() => setIsModalOpen(false)} setLoading={setLoading} />
    </>
  );
};
