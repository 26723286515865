// antd's Tag component is not keyboard accessible.
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactElement } from 'react';

interface ITagProps<Type> {
  label: string | ReactElement;
  value: Type | any;
  closable?: boolean;
  onClose?: (value: Type) => void;
}

export const Tag: React.FunctionComponent<ITagProps<any>> = ({
  label,
  value,
  onClose,
  closable = true,
  ...props
}) => {
  return (
    <div className="uac-tag">
      {props.children}
      {!props.children && (
        <>
          <span>{label}</span>
          {closable && (
            <Button
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => onClose && onClose(value)}
              aria-label={`Remove ${label}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Tag;
