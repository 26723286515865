import * as z from 'zod';

import { removeHTMLTags } from 'utils/validation';

export const nestedListAboutSchema = z.array(
  z.object({
    title: z.string().max(200).optional(),
    description: z
      .string()
      .refine((val) => removeHTMLTags(val).length <= 5000, {
        message: 'Should not be more than 5000 characters',
      })
      .optional(),
  })
);

export const aboutSchema = z.object({
  list: z
    .array(
      z.object({
        title: z.string().max(200).optional(),
        description: z
          .string()
          .refine((val) => removeHTMLTags(val).length <= 5000, {
            message: 'Should not be more than 5000 characters',
          })
          .optional(),
        list: nestedListAboutSchema,
      })
    )
    .min(1, 'Please add at least one section.'),
});

export type INestedListAboutSchema = z.infer<typeof nestedListAboutSchema>;

export type IAboutSchema = z.infer<typeof aboutSchema>;
