import { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';

import './Modals.less';

interface Props {
  confirmLoading?: boolean;
  courseName?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isModalVisible: boolean;
  status?: string;
}

export const DeleteModal: FC<Props> = ({
  confirmLoading = false,
  isModalVisible,
  onConfirm,
  onCancel,
  courseName = 'this course',
  status = '',
}) => {
  return (
    <Modal
      title={status === 'Edit' ? 'Discard Draft' : 'Delete'}
      className="delete"
      open={isModalVisible}
      onOk={onConfirm}
      onCancel={onCancel}
      maskClosable={false}
      confirmLoading={confirmLoading}
      okButtonProps={{ type: 'primary', danger: true }}
      okText={status === 'Edit' ? 'Discard Draft' : 'Delete'}
    >
      {status === 'Edit' ? (
        <p>
          Are you sure you want to discard your draft? Unpublished changes will be lost. You cannot undo this action.
        </p>
      ) : (
        <p>
          Are you sure you want to delete <strong>{courseName}</strong>? You cannot undo this action.
        </p>
      )}
    </Modal>
  );
};
