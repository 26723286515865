import CompleteNewPasswordForm from 'components/auth/CompleteNewPasswordForm';
import AuthLayout from 'layouts/AuthLayout';
import React from 'react';

const CompleteNewPassword = () => {
  return (
    <AuthLayout>
      <CompleteNewPasswordForm />
    </AuthLayout>
  );
};

export default CompleteNewPassword;
