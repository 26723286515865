export enum AuthMessage {
  FORGOT_PASSWORD_SUBMIT_SUCCESS = 'Temporary password has been sent to your registered email address.',
  CHANGE_PASSWORD_SUBMIT_SUCCESS = 'Password has been changed successfully. Please login with your new password.',
  TEMPORARY_PASSWORD_EXPIRED = 'Temporary password has expired and must be reset by an administrator.',
}

export const AuthMessageTransformed = {
  [AuthMessage.TEMPORARY_PASSWORD_EXPIRED.toLowerCase()]:
    'Your temporary password has expired. Please use Reset password to request a new one.',
};

export const transformAuthError = (errorMessage?: string) => {
  if (!errorMessage || !errorMessage?.trim()) return errorMessage;
  const errorMessageKey = errorMessage.trim().toLowerCase();

  if (Object.keys(AuthMessageTransformed).some((key) => key === errorMessageKey)) {
    return AuthMessageTransformed[errorMessageKey];
  }
  return errorMessage;
};
