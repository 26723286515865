import { Alert } from 'antd';
import { Message } from 'contexts/GlobalContext';
import React from 'react';

import './AlertMessageBox.less';

interface AlertMessageBoxProps {
  message: Message;
  style?: Record<string, string>;
  closable?: boolean;
}

const AlertMessageBox = ({ message, closable, ...rest }: AlertMessageBoxProps) => {
  const { type, content } = message;

  return (
    <div className="alert-box" {...rest}>
      {type && content && <Alert type={type} message={content} closable={closable} showIcon />}
    </div>
  );
};

export default AlertMessageBox;
