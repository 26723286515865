import { memo } from 'react';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowDownOutlined, ArrowUpOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { ICredentialSchema } from './schema';
import { useStyles } from './common/useStyles';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';
import { SectionTitleRequired } from './common/utils';

const CourseOverviewForm = ({ isFormReadOnly }: CredFormProps) => {
  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    ...fields
  } = useFormContext<ICredentialSchema>();

  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('2');

  const handleFieldDirty = () => setIsDirty(true);

  const rest = {
    ...fields,
    disabled: isFormReadOnly,
  };

  const {
    fields: syllabusFields,
    append: appendSyllabus,
    remove: removeSyllabus,
    move: moveSyllabus,
  } = useFieldArray({
    control,
    name: 'syllabuses',
  });

  const {
    fields: outcomeFields,
    append: appendOutcome,
    remove: removeOutcome,
    move: moveOutcome,
  } = useFieldArray({
    control,
    name: 'outcome',
  });

  const addSyllabus = () => {
    appendSyllabus({ title: '', description: '' });
    handleFieldDirty();
  };

  const addOutcome = () => {
    appendOutcome({ title: '' });
    handleFieldDirty();
  };

  return (
    <>
      <Card>
        <div>
          <h1>About</h1>
        </div>
        <Row>
          <Col span={24}>
            <GenericInput
              name="about.tagline"
              descriptionAfter="Write a short description of this credential to attract the attention of learners"
              label="Tag line"
              control={control}
              required
              showCount
              maxLength={200}
              errors={errors['about'] ? (errors['about']['tagline'] as FieldError) : undefined}
              handleFieldDirty={handleFieldDirty}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="about.descriptionP1"
              type="quill"
              descriptionAfter="Describe the key aims and learning components of this microcredential: emphasise the benefits to learners"
              label="Description"
              control={control}
              placeholder="Detailed description of this microcredential"
              required
              showCount
              maxLength={5000}
              errors={errors['about'] ? (errors['about']['descriptionP1'] as FieldError) : undefined}
              handleFieldDirty={handleFieldDirty}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="deliveryDesc.deliveryDescP1"
              label="Delivery description"
              type="quill"
              control={control}
              placeholder="Explaining how course content will be delivered to the learners"
              required
              showCount
              maxLength={500}
              errors={errors['deliveryDesc'] ? (errors['deliveryDesc']['deliveryDescP1'] as FieldError) : undefined}
              handleFieldDirty={handleFieldDirty}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="expiration.expirationP1"
              label="Expiration"
              descriptionBefore="Description"
              control={control}
              placeholder="Enter information about the expiration of this credential (if applicable)"
              showCount
              maxLength={200}
              errors={errors['expiration'] ? errors['expiration']['expirationP1'] : undefined}
              handleFieldDirty={handleFieldDirty}
              {...rest}
            />
          </Col>
        </Row>
      </Card>
      <Card>
        <div>
          <SectionTitleRequired title="Syllabuses" />
        </div>
        {errors.syllabuses && <Typography.Paragraph type="danger">{errors.syllabuses.message}</Typography.Paragraph>}
        {syllabusFields.map((syllabus: Record<'id', string>, index: number) => {
          return (
            <Card className={classes.credCard} key={syllabus.id}>
              <Space className={classes.credCard__actions}>
                <Button
                  size="small"
                  icon={<ArrowUpOutlined />}
                  disabled={syllabusFields.length === 1 || index === 0 || rest.disabled}
                  onClick={() => {
                    moveSyllabus(index, index - 1);
                    handleFieldDirty();
                  }}
                />
                <Button
                  size="small"
                  icon={<ArrowDownOutlined />}
                  disabled={syllabusFields.length === 1 || index === syllabusFields.length - 1 || rest.disabled}
                  onClick={() => {
                    moveSyllabus(index, index + 1);
                    handleFieldDirty();
                  }}
                />
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    removeSyllabus(index);
                    handleFieldDirty();
                  }}
                  disabled={rest.disabled}
                />
              </Space>
              <Row>
                <Col span={24}>
                  <GenericInput
                    key={syllabus.id}
                    label="Title"
                    name={`syllabuses[${index}].title` as const}
                    control={control}
                    required
                    showCount
                    maxLength={200}
                    errors={
                      errors['syllabuses'] && errors['syllabuses'][`${index}`]
                        ? errors['syllabuses'][`${index}`]?.title
                        : undefined
                    }
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    key={syllabus.id}
                    label="Description"
                    type="quill"
                    name={`syllabuses[${index}].description` as const}
                    control={control}
                    required
                    showCount
                    maxLength={1000}
                    errors={
                      errors['syllabuses'] && errors['syllabuses'][`${index}`]
                        ? errors['syllabuses'][`${index}`]?.description
                        : undefined
                    }
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
            </Card>
          );
        })}
        <br />
        <Button onClick={addSyllabus} disabled={rest.disabled}>
          Add <PlusOutlined />
        </Button>
      </Card>
      <Card>
        <Row>
          <div>
            <SectionTitleRequired title="Outcome" />
          </div>
          <Col span={24}>
            {errors.outcome && <Typography.Paragraph type="danger">{errors.outcome.message}</Typography.Paragraph>}
            {outcomeFields.map((outcome: Record<'id', string>, index: number) => {
              return (
                <Card key={outcome.id} className={classes.credCard}>
                  <Space className={classes.credCard__actions}>
                    <Button
                      onClick={() => {
                        moveOutcome(index, index - 1);
                        handleFieldDirty();
                      }}
                      disabled={outcomeFields.length <= 1 || index === 0 || rest.disabled}
                    >
                      <ArrowUpOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        moveOutcome(index, index + 1);
                        handleFieldDirty();
                      }}
                      disabled={outcomeFields.length <= 1 || index === outcomeFields.length - 1 || rest.disabled}
                    >
                      <ArrowDownOutlined />
                    </Button>
                    <Button
                      size="small"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeOutcome(index);
                        handleFieldDirty();
                      }}
                      disabled={rest.disabled}
                    />
                  </Space>
                  <Row>
                    <Col span={24}>
                      <GenericInput
                        key={outcome.id}
                        label="Describe the outcome"
                        type="quill"
                        name={`outcome[${index}].title` as const}
                        control={control}
                        showCount
                        maxLength={1000}
                        required
                        errors={
                          errors['outcome'] && errors['outcome'][`${index}`]
                            ? errors['outcome'][`${index}`]?.title
                            : undefined
                        }
                        handleFieldDirty={handleFieldDirty}
                        {...rest}
                      />
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <br />
            <Button onClick={addOutcome} disabled={rest.disabled}>
              Add <PlusOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default memo(CourseOverviewForm);
