import { z } from 'zod';
import { syllabusesSchema } from './syllabuses';
import { outcomeSchema } from './outcome';
import { removeHTMLTags, removeHTMLTagsAndWhitespaces } from 'utils/validation';

export const overviewSchema = z.object({
  about: z.object({
    tagline: z.string().nonempty({ message: 'Required' }).max(200, { message: 'Must have less than 200 characters' }),
    descriptionP1: z
      .string()
      .nonempty('Required')
      .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
      .refine((val) => removeHTMLTags(val).length <= 5000, { message: 'Should not be more than 5000 characters' }),
  }),
  deliveryDesc: z.object({
    deliveryDescP1: z
      .string()
      .nonempty('Required')
      .refine((val) => removeHTMLTagsAndWhitespaces(val).length > 0, { message: 'Required' })
      .refine((val) => removeHTMLTags(val).length <= 500, { message: 'Should not be more than 500 characters' }),
  }),
  expiration: z
    .object({
      expirationP1: z.string().optional(),
    })
    .nullable()
    .optional(),
  syllabuses: syllabusesSchema,
  outcome: outcomeSchema,
});
