import { useDirtyStateContext } from 'components/UseDirtyStateContext';
import { useCallback } from 'react';

const useUpdateDirtyState = (key: string) => {
  const { setIsDirty, isDirtyStates } = useDirtyStateContext();

  const handleIsDirtyState = useCallback(
    (value: boolean) => {
      setIsDirty(key, value);
    },
    [key, setIsDirty]
  );
  return { isDirtyStates: isDirtyStates[key] || false, handleIsDirtyState };
};

export default useUpdateDirtyState;
