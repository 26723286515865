import { Menu } from 'antd';
import { MenuItem } from 'model/ui';
import { Link, useLocation } from 'react-router-dom';

const menuItems: MenuItem[] = [
  {
    key: 'dese-static-page',
    label: <Link to="/dese/static-page">Pages</Link>,
  },
  {
    key: 'dese-reports',
    label: <Link to="/dese/reports">Reports</Link>,
  },
];

const LeftMenuDese = () => {
  const location = useLocation();
  const pathUrl = location.pathname.replace('/dese/', 'dese-').split('/')[0];
  const selectedKeys = menuItems
    .map(({ key }) => key)
    .filter((key) => {
      if (!pathUrl) {
        return key === 'dese-static-page';
      }
      if (key) {
        return pathUrl === key;
      }
      return !pathUrl;
    });

  return (
    <>
      <div className="logo-container">
        <img src="/images/logo-white.svg" alt="provider-portal-logo" />
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} items={menuItems} />
    </>
  );
};

export default LeftMenuDese;
