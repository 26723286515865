//prettier-ignore
export enum COURSE_STATUS {
  New = "New",                    // Draft New
  Review = "Review",
  Approved = "Approved",          // Approved, awaiting publish to ES
  PublishError = "PublishError",  // Error when trying to publish
  Published = "Published",        // Published on ES
  Inactive = "Inactive",          // After provider deactivate cred
  Unpublished = "Unpublished",    // Unpublished from ES
  Edit = "Edit",                  // Draft Edit after Published
}

//prettier-ignore
export enum COURSE_ACTION {
  Create = "Create",
  Delete = "Delete",                  // from state New
  Cancel = "Cancel",                  // from state Edit
  Edit = "Edit",                      // from state Edit
  RequestReview = "RequestReview",    // from state New | Edit
  UpdateContent = "UpdateContent",    // from state New | Edit
  Approve = "Approve",                // from state Review
  Reject = "Reject",                  // from state Review
  RePublish = "RePublish",                // from state Approved | Unpublished
  Unpublish = "Unpublish",            // from state Published | Edit
  Preview = "Preview",                // from state Published | Edit
  CancelAndUnpublish = "CancelAndUnpublish" // from state Edit and Published
}

//prettier-ignore
export enum DISPLAY_COURSE_STATUS {
  New = "Draft",                    // Draft New
  Review = "Awaiting approval",
  Approved = "Publishing",          // Approved, awaiting publish to ES
  PublishError = "Publish Error",   // Error when trying to publish
  Published = "Published",          // Published on ES
  Inactive = "Inactive",            // After provider deactivate cred
  Unpublished = "Unpublished",      // Unpublished from ES
  Edit = "Draft",                   // Draft Edit after Published
}

export enum FORM_ERRORS {
  Enum = 'enum',
  Required = "required",
  Format = "format",
  MaxLength = "maxLength",
  MinLength = "minLength",
  Pattern = "pattern",
  Type = "type",
  // This is a custom error
  // Only for editor to be filtered
  Invalid = "invalid",
  If = "if",
  Minimum = "minimum",
  Maximum = "maximum",
  MinItems = "minItems"
}

export interface ICourse {
  courseCode?: string;
  id: string;
  provider: string;
  title: string;
  code: string;
  level: string;
  status: COURSE_STATUS;
  createdAt: string;
  updatedAt: string;
  price: number;
  published?: boolean;

  locked?: boolean;
  lockedBy?: string;
}
