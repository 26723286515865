import { useState } from 'react';
import { notification } from 'antd';
import API from 'api';
import { COURSE_ACTION } from 'model/course';
import { errorNotification, successNotification } from 'utils';
import { has } from 'lodash';

interface IProps {
  currentProvider: string;
  courseId: string;
  title?: string;
}

const useCourseActions = ({ currentProvider, courseId, title }: IProps) => {
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openUnpublishModal, setUnpublishModal] = useState(false);
  const [openPublishModal, setPublishModal] = useState(false);
  const [openApproveModal, setApproveModal] = useState(false);
  const [openRejectModal, setRejectModal] = useState(false);
  const [openCourseCodeModal, setCourseCodeModal] = useState(false);

  const sendReject = async (reason: string): Promise<boolean> => {
    setUpdatingStatus(true);
    const [, error] = await API.Cred2.reject(currentProvider, courseId!, reason);
    setUpdatingStatus(false);
    if (!error) {
      notification['success']({
        message: `Draft has been rejected.`,
      });
      setRejectModal(false);
      return true;
    } else {
      notification['error']({
        message: `Error: ${error.message}`,
      });
      return false;
    }
  };

  const sendAction = async (
    action: COURSE_ACTION,
    props?: { force: boolean }
  ): Promise<{
    success: boolean;
  }> => {
    setUpdatingStatus(true);

    const [data, error] = await API.Cred2.action(currentProvider, courseId!, action);

    setUpdatingStatus(false);
    if (!error) {
      switch (action) {
        case COURSE_ACTION.Cancel:
          successNotification('Draft discarded.');
          break;
        case COURSE_ACTION.Delete:
          successNotification(
            <>
              <strong>{title}</strong> deleted.
            </>
          );
          break;
        case COURSE_ACTION.Unpublish:
          successNotification(
            <>
              <strong>{title}</strong> unpublished.
            </>
          );
          break;
        case COURSE_ACTION.RequestReview:
          successNotification(`Submitted for review.`);
          break;
        case COURSE_ACTION.RePublish:
          successNotification(
            <>
              <strong>{title}</strong> re-published.
            </>
          );
          break;
        case COURSE_ACTION.Approve:
          successNotification(
            <>
              <strong>{title}</strong> will be published soon.
            </>
          );
          break;
        case COURSE_ACTION.Edit:
          break;
        default:
          successNotification('Success.');
          break;
      }
      return { success: true };
    } else {
      switch (action) {
        case COURSE_ACTION.Cancel:
          errorNotification('Failed to discard draft. Please try again.');
          break;
        case COURSE_ACTION.Delete:
          errorNotification(
            <>
              Failed to delete <strong>{title}</strong>. Please try again.
            </>
          );
          break;
        case COURSE_ACTION.Unpublish:
          errorNotification(`Failed to unpublish. Please try again.`);
          break;
        case COURSE_ACTION.RequestReview:
          if (error) {
            const { message } = error
            const result = JSON.parse(message.replace("Data validation failed:", ""))
            
            if (result[0]?.message) {
              errorNotification(result[0]?.message);
            } else {
              errorNotification(`Failed to submit for review. Please try again.`);
            }
          }
          break;
        case COURSE_ACTION.RePublish:
          errorNotification(`Failed to re-publish course. Please try again.`);
          break;
        case COURSE_ACTION.Approve:
          if (
            Array.isArray(error.errors) &&
            has(error.errors[0], 'params.missingProperty') &&
            error.errors[0].params.missingProperty === 'fieldOfEducation'
          ) {
            errorNotification(
              `This course is missing "Field of education". Please reject, edit and submit this course for re-approval`
            );
          } else {
            errorNotification(`Failed to publish course. Please try again.`);
          }

          break;
        default:
          errorNotification(`Error: ${error.message}`);
          break;
      }
      return { success: false };
    }
  };

  const cancelAndUnpublish = async () => {
    setUpdatingStatus(true);
    const [, cancelError] = await API.Cred2.action(currentProvider, courseId!, COURSE_ACTION.Cancel);

    if (cancelError) {
      errorNotification('Failed to discard draft. Please try again');
      return false;
    }
    const [, unpublishError] = await API.Cred2.action(currentProvider, courseId!, COURSE_ACTION.Unpublish);
    setUpdatingStatus(false);
    if (!unpublishError) {
      successNotification(<>{title ? <strong>{title}</strong> : 'Course'} unpublished and draft discarded.</>);
      return true;
    } else {
      errorNotification('Failed to unpublish. Please try again');
      return false;
    }
  };

  return {
    sendAction: sendAction,
    sendReject: sendReject,
    cancelAndUnpublish: cancelAndUnpublish,
    openApproveModal,
    openDeleteModal,
    openRejectModal,
    openUnpublishModal,
    openPublishModal,
    openCourseCodeModal,
    toggleApproveModal: (show?: boolean) => setApproveModal(show ?? !openApproveModal),
    toggleDeleteModal: (show?: boolean) => setDeleteModal(show ?? !openDeleteModal),
    toggleRejectModal: (show?: boolean) => setRejectModal(show ?? !openRejectModal),
    toggleUnpublishModal: (show?: boolean) => setUnpublishModal(show ?? !openUnpublishModal),
    togglePublishModal: (show?: boolean) => setPublishModal(show ?? !openPublishModal),
    toggleCourseCodeModal: (show?: boolean) => setCourseCodeModal(show ?? !openCourseCodeModal),
    updatingStatus,
  };
};

export default useCourseActions;
