import React, { useEffect } from 'react';
import { Row, Spin } from 'antd';

import { StaticPageAPI, StaticPageListItem } from 'api/static-page';
import PagesList from './StaticPagesList';
import { errorNotification } from 'utils';
import { useSafeState } from 'ahooks';

export const StaticPage = () => {
  const [list, setList] = useSafeState<StaticPageListItem[]>();

  useEffect(() => {
    StaticPageAPI.list().then(([data, errors]) => {
      if (errors) {
        errorNotification('Failed to load list of static pages');
      } else {
        setList(data);
      }
    });
  }, [setList]);

  if (!list) return <Spin />;

  return (
    <div>
      <Row align="top">
        <h2>Pages</h2>
      </Row>

      <PagesList data={list} />
    </div>
  );
};
