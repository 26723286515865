import { useEffect } from 'react';
import { ConfirmModal } from 'components/ui/modals';
import { IUserWithProvider } from 'model/user';
import useUserList from 'components/hooks/useUserList';
import { useSafeState } from 'ahooks';
import { Alert } from 'antd';

interface IReactivateUserModalProps {
  openModal: boolean;
  selectedUser: IUserWithProvider;
  onClose: () => void;
  onUpdate: () => void;
}

export const ResendInvitationModal = ({ openModal, selectedUser, onUpdate, onClose }: IReactivateUserModalProps) => {
  const [isLoading, setIsLoading] = useSafeState(false);
  const [validationError, setValidationError] = useSafeState('');
  const { reactivateUser } = useUserList(true);

  useEffect(() => {
    const checkIfUserCanBeEnabled = async () => {
      setIsLoading(true);
      const [, error] = await reactivateUser(selectedUser.username, true, true);
      if (!error) {
        setValidationError('');
      } else {
        setValidationError(
          error.message?.replace('This user cannot be reactivated', 'Unable to resend invitation to this user')
        );
      }
      setIsLoading(false);
    };
    checkIfUserCanBeEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser.username, setValidationError]);

  return (
    <ConfirmModal
      title="Resend Invitation"
      onCancel={onClose}
      isModalVisible={openModal}
      onConfirm={onUpdate}
      submitButtonProps={{ disabled: !!validationError, loading: isLoading }}
    >
      {!!validationError && <Alert message={validationError} type="error" style={{ marginBottom: '1rem' }} showIcon />}
      <>
        Are you sure you want to resend the activation email to <strong>{selectedUser.name}</strong>? This user will
        receive a temporary password to login into the portal.
      </>
    </ConfirmModal>
  );
};
