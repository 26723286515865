import { useSafeState, useSessionStorageState } from 'ahooks';
import { Spin } from 'antd';
import API from 'api';
import { createContext, useCallback, useEffect, Dispatch, SetStateAction  } from 'react';
import { IProvider } from 'types/provider';
import { UserRole } from 'utils/UserUtils';
import { DefaultOptionType } from 'antd/lib/select';

type SetStateFunction<T> = Dispatch<SetStateAction<T>>;

interface IUserContext {
  currentProvider: string;
  changeProvider: (providerId: string) => void;
  fetchProviderList: () => void;
  listProvider: IProvider[];
  mapProvider: MapProvider;
  isAdmin: boolean;
  role: UserRole;
  signedInDeclaration: boolean;
  reload: () => void;
  userInfo: IUserInfo; 
  selectedOptions: DefaultOptionType[];
  setSelectedOptions: SetStateFunction<DefaultOptionType[]>;
}

interface MapProvider {
  [k: string]: IProvider;
}

interface IUserInfo {
  username: string;
  sub: string;
  name: string;
}

const UserContext = createContext({
  currentProvider: '',
  changeProvider: (_: string) => {},
  fetchProviderList: () => {},
  listProvider: [],
  mapProvider: {},
  isAdmin: true,
  role: UserRole.PROVIDER_EDITOR, // Default
  signedInDeclaration: false,
  reload: () => {},
  userInfo: {} as IUserInfo,
  selectedOptions: [] as DefaultOptionType[],
  setSelectedOptions: () => {},
} as IUserContext);

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [storageProvider, setStorageProvider] = useSessionStorageState('currentProvider', {
    defaultValue: '',
  });
  const [provider, setProvider] = useSafeState(storageProvider);
  const [listProvider, setListProvider] = useSafeState<any[]>([]);
  const [mapProvider, setMapProvider] = useSafeState<{ [k: string]: any }>({});
  const [loading, setLoading] = useSafeState(true);
  const [error, setError] = useSafeState('');
  const [isAdmin, setIsAdmin] = useSafeState<boolean>(false);
  const [role, setRole] = useSafeState<UserRole>(UserRole.PROVIDER_EDITOR);
  const [signedInDeclaration, setSignedInDeclaration] = useSafeState<boolean>(false);
  const [userInfo, setUserInfo] = useSafeState<IUserInfo>({} as IUserInfo);
  const [selectedOptions, setSelectedOptions] = useSafeState<DefaultOptionType[]>([]);

  const fetchProviderList = () => {
    API.Admin.list().then(([data_1, error_1]) => {
      if (!error_1) {
        setListProvider(data_1.items);
        const map = data_1.items.reduce((acc: { [k: string]: any }, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {});
        setMapProvider(map);
      } else {
        console.log('Error could not fetch provider list');
        setError('Cannot load provider list');
      }
    });
  };

  const load = useCallback(async () => {
    const [data, error] = await API.Provider.getUserInfo();
    if (data && !error) {
      setIsAdmin(data.isAdmin);
      setUserInfo({
        username: data.username,
        sub: data.sub,
        name: data.name,
      });
      setRole(data.role as UserRole);
      setSignedInDeclaration(!!data.signedInDeclaration);
      if (!data.isAdmin) {
        setProvider(data.defaultProvider);
      } else {
        await fetchProviderList();
      }
    } else {
      console.log('Error could not fetch user info');
      setError('Cannot load user information');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setError,
    setIsAdmin,
    setListProvider,
    setLoading,
    setMapProvider,
    setProvider,
    setRole,
    setSignedInDeclaration,
    setUserInfo,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const changeProvider = useCallback(
    (providerId: string) => {
      setProvider(providerId);
      setStorageProvider(providerId);
    },
    [setProvider, setStorageProvider]
  );

  if (loading) return <Spin />;

  if (error !== '') return <div>Error: {error}</div>;

  return (
    <UserContext.Provider
      value={{
        currentProvider: provider || '',
        listProvider,
        mapProvider,
        changeProvider: changeProvider,
        fetchProviderList: fetchProviderList,
        isAdmin,
        role,
        signedInDeclaration,
        reload: load,
        userInfo: userInfo,
        selectedOptions: selectedOptions,
        setSelectedOptions: setSelectedOptions
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
