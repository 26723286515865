import { UserRole } from 'utils/UserUtils';
import { IProviderBasicInfo } from 'types/provider';

export interface IUser {
  id: string;
  email: string;
  defaultProvider: string;
  isAdmin: boolean;
  role: UserRole;
  signedInDeclaration: boolean;
  name: string;
  provider: string;
  status: USER_STATUS;
  username: string;
  sub: string;
  userCreateDate?: string;
  searchName?: string;
  searchEmail?: string;
  jsmAccountId?: string;
  isJsmAccountPendingSetup?: boolean;
}

export type IUserWithProvider = Omit<IUser, 'provider'> & { provider?: IProviderBasicInfo };

export interface IUserForChallenge {
  username: string;
  pool: Pool;
  Session: string;
  client: Client2;
  signInUserSession: any;
  authenticationFlowType: string;
  storage: Storage2;
  keyPrefix: string;
  userDataKey: string;
  challengeName: string;
  challengeParam: ChallengeParam;
}

export interface Pool {
  userPoolId: string;
  clientId: string;
  client: Client;
  advancedSecurityDataCollectionFlag: boolean;
  storage: Storage;
}

export interface Client {
  endpoint: string;
  fetchOptions: FetchOptions;
}

export interface FetchOptions {}

export interface Storage {}

export interface Client2 {
  endpoint: string;
  fetchOptions: FetchOptions2;
}

export interface FetchOptions2 {}

export interface Storage2 {}

export interface ChallengeParam {
  userAttributes: UserAttributes;
  requiredAttributes: any[];
}

export interface UserAttributes {
  email_verified: string;
  name: string;
  email: string;
}

export enum USER_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PASSWORD_RESET_PENDING = 'Password reset pending',
}

export enum USER_STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  NONE = 'NONE',
}

//prettier-ignore
export enum DISPLAY_USER_STATUS {
  'Password reset pending' = 'Password reset pending',
  Active = 'Active',
  Inactive = 'Inactive',
}
