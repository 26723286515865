export enum ErrorTypes {
  MinItems = 'Please add at least one',
}

export enum PolicyType {
  PRIVACY_POLICY = 'Privacy Policy',
  ADMISSION_POLICY = 'Admission Policy',
  ENGLISH_LANGUAGE_POLICY = 'English Language Policy',
}

export enum State {
  ACT = 'ACT', //Australian Capital Territory
  NSW = 'NSW', //New South Wales
  NT = 'NT', //Northern Territory
  SA = 'SA', //South Australia
  QLD = 'QLD', //Queensland
  TAS = 'TAS', //Tasmania
  VIC = 'VIC', //Victoria
  WA = 'WA', //Western Australia
}

export enum ProviderType {
  HEP = 'HEP',
  VET = 'VET',
}

export enum FieldOfEducationNames {
  AGRICULTURE_ENVIRONMENT_AND_RELATED_STUDIES = 'Agriculture, Environment & Related Studies',
  ARCHITECTURE_AND_BUILDING = 'Architecture & Building',
  CREATIVE_ARTS = 'Creative Arts',
  EDUCATION = 'Education',
  ENGINEERING_AND_RELATED_TECHNOLOGIES = 'Engineering & Related Technologies',
  HEALTH = 'Health',
  INFORMATION_TECHNOLOGY = 'Information Technology',
  MANAGEMENT_AND_COMMERCE = 'Management & Commerce',
  NATURAL_AND_PHYSICAL_SCIENCES = 'Natural & Physical Sciences',
  SOCIETY_AND_CULTURE = 'Society & Culture',
  TOURISM_HOSPITALITY_AND_PERSONAL_SERVICES = 'Tourism, Hospitality & Personal Services',
  MIXED_FIELD_PROGRAM = 'Mixed Field Programs',
}
