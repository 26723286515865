export const enquiryStatisticValueStyle: React.CSSProperties = {
  fontSize: 30,
  fontWeight: 600,
  paddingBottom: 8,
};

export const statisticStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingTop: 8,
};
