import { Upload, Modal, message, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

import { UploadFile } from 'antd/lib/upload/interface';
import { getBase64 } from 'utils/file';
import { useSafeState } from 'ahooks';
import {
  UPLOAD_LOGO_RECTANGLE_WIDTH,
  UPLOAD_LOGO_SQUARE_WIDTH,
  UPLOAD_LOGO_HEIGHT,
  UPLOAD_LOGO_SUPPORTED_TYPES_REGEX,
} from '../../../constants';

interface IChangeLogoModalProps {
  title?: string;
  okText?: string;
  showModal: boolean;
  submitting?: boolean;
  isRect?: boolean;
  onCancel: () => void;
  onSave: (file: string) => Promise<[any | null | undefined, any | undefined]>;
  onSaved: (logoUrl: string) => void;
}

export const ChangeLogoModal = ({
  title = 'Change logo',
  okText = 'Change logo',
  showModal,
  isRect,
  onCancel,
  onSave,
  onSaved,
}: IChangeLogoModalProps) => {
  const [base64File, setBase64File] = useSafeState<string>('');
  const [logoValidated, setLogoValidated] = useSafeState<boolean>(false);
  const [uploading, setUploading] = useSafeState<boolean>(false);
  const [fileList, setFileList] = useSafeState<UploadFile[]>([]);
  const [hasError, setHasError] = useSafeState(false);

  const beforeUpload = () => {
    // prevent automatic posting of the image
    return false;
  };

  const resetForm = () => {
    setUploading(false);
    setLogoValidated(false);
    setBase64File('');
    setFileList([]);
    setHasError(false);
  };

  const validateFile = (file: UploadFile) => {
    if (!file) {
      setHasError(true);
      return false;
    }

    if (file.status === undefined) {
      if (!file.type?.match(UPLOAD_LOGO_SUPPORTED_TYPES_REGEX)) {
        message.error(`${file.name} is not a PNG or JPG file`);
        setHasError(true);
        return false;
      }
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();

      image.onload = () => {
        const { width, height } = image;
        const errorMessage = !isRect
          ? `Main logo should be ${UPLOAD_LOGO_SQUARE_WIDTH}x${UPLOAD_LOGO_HEIGHT} pixels.`
          : `Secondary logo should be ${UPLOAD_LOGO_RECTANGLE_WIDTH}x${UPLOAD_LOGO_HEIGHT} pixels.`;
        if (
          height !== UPLOAD_LOGO_HEIGHT ||
          (isRect && width !== UPLOAD_LOGO_RECTANGLE_WIDTH) ||
          (!isRect && width !== UPLOAD_LOGO_SQUARE_WIDTH)
        ) {
          notification.error({
            message: `Invalid logo size ${width} x ${height} pixels. ${errorMessage}`,
          });
          resetForm();
          setHasError(true);
          return false;
        }
      };

      image.src = event.target?.result as string;
    };

    reader.readAsDataURL(file as unknown as Blob);

    setHasError(false);
    return true;
  };

  const handleChange = (info: { file: UploadFile; fileList: UploadFile[] }) => {
    if (info.file.status === 'removed' || !validateFile(info.file)) {
      resetForm();
      return;
    }

    setFileList(info.fileList);
    getBase64(info.file, (res: string) => {
      setBase64File(res);
      setLogoValidated(true);
    });
  };

  const uploadLogo = async () => {
    setUploading(true);
    const [data, error] = await onSave(base64File);
    setUploading(false);

    if (data) {
      onSaved(data);
      message.success('Logo updated successfully.');
    } else {
      message.error('Cannot upload image ' + error?.message);
    }
  };

  useEffect(() => {
    if (!showModal) {
      resetForm();
    }
  }, [showModal]);

  return (
    <Modal
      title={title}
      open={showModal}
      onCancel={onCancel}
      onOk={uploadLogo}
      okText={okText}
      destroyOnClose={true}
      maskClosable={false}
      okButtonProps={{ disabled: !logoValidated || hasError }}
      confirmLoading={uploading}
    >
      <Upload.Dragger
        multiple={false}
        className={logoValidated ? 'hidden' : undefined}
        disabled={uploading}
        accept="image/png,image/jpg,image/jpeg"
        maxCount={1}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        listType="picture"
        fileList={fileList}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">
          <strong>Drag &amp; Drop</strong> file here or <strong>Click</strong> to open file dialog
        </p>
        <p className="ant-upload-hint">
          {isRect
            ? `${UPLOAD_LOGO_RECTANGLE_WIDTH} x ${UPLOAD_LOGO_HEIGHT}`
            : `${UPLOAD_LOGO_SQUARE_WIDTH} x ${UPLOAD_LOGO_HEIGHT}`}
          &nbsp;png recommended
        </p>
      </Upload.Dragger>
    </Modal>
  );
};

export default ChangeLogoModal;
