import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AlignType } from 'rc-table/lib/interface';

import { formatDate } from 'utils/date';
import ActionMenu from 'components/common/ActionMenu';
import { StaticPageListItem } from 'api/static-page';
import { UserRole } from 'utils/UserUtils';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect } from 'react';
import CustomPrevNextButtonRender from 'components/ui/pagination/CustomPrevNextButtonRender';

const StaticPagesList = ({ data }: { data: StaticPageListItem[] }) => {
  const navigate = useNavigate();
  const { currentAuthenticatedUser } = useContext(GlobalContext);

  const baseRoutes = currentAuthenticatedUser?.role === UserRole.ADMIN ? '/admin' : '/dese';

  // update currenUrl based on user role.
  useEffect(() => {
    const checkCurrentRoute = () => {
      navigate(`${baseRoutes}/static-page/`);
    };
    checkCurrentRoute();
  }, [baseRoutes, currentAuthenticatedUser, navigate]);

  const onEditPages = (id: string) => () => {
    navigate(`${baseRoutes}/static-page/${id}`, { replace: false });
  };

  const columns: ColumnType<StaticPageListItem>[] = [
    {
      title: 'Title',
      width: '70%',
      render: (item: StaticPageListItem) => {
        return (
          <>
            <Link to={`${baseRoutes}/static-page/${item.id}`}>{item.name}</Link>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sorter: <T extends StaticPageListItem>(a: T, b: T) => {
        return (a.name as string).localeCompare(b.name as string);
      },
    },
    {
      title: 'Last modified',
      dataIndex: 'updatedAt',
      render: (updatedAt: string) => {
        return formatDate(updatedAt);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right' as AlignType,
      render: (page: StaticPageListItem) => {
        return (
          <ActionMenu
            id={page.id}
            type="ellipsis"
            actions={[
              {
                label: 'Edit',
                icon: <EditOutlined />,
                handler: onEditPages(page.id as string),
              },
            ]}
            ariaLabel={`${page.name} actions menu`}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: [10, 20, 30, 50, 100],
          itemRender: CustomPrevNextButtonRender,
          showTotal: (total, range) => (
            <div style={{ position: 'absolute', left: 16 }}>{`${range[0]} - ${range[1]} of ${total} items`}</div>
          ),
        }}
      />
    </>
  );
};

export default StaticPagesList;
