import StaticPageBackgroundForm from './StaticPageBackgroundForm';
import StaticPageContactUsForm from './StaticPageContactUsForm';
import StaticPageCopyrightForm from './StaticPageCopyrightForm';
import StaticPageDisclaimerForm from './StaticPageDisclaimerForm';
import StaticPageFAQsForm from './StaticPageFAQsForm';
import StaticPageGlossaryForm from './StaticPageGlossaryForm';
import StaticPagePrivacyForm from './StaticPagePrivacyForm';
import StaticPageUsefulLinksForm from './StaticPageUsefulLinksForm';
import StaticPageAccessibilityForm from './StaticPageAccessibilityForm';
import StaticPageAboutForm from './StaticPageAboutForm';

export const StaticPagesDetailsForm = {
  Accessibility: StaticPageAccessibilityForm,
  Background: StaticPageBackgroundForm,
  ContactUs: StaticPageContactUsForm,
  Copyright: StaticPageCopyrightForm,
  Disclaimer: StaticPageDisclaimerForm,
  FAQs: StaticPageFAQsForm,
  Glossary: StaticPageGlossaryForm,
  Privacy: StaticPagePrivacyForm,
  UsefulLinks: StaticPageUsefulLinksForm,
  About: StaticPageAboutForm,
};

export const staticPageFormLookup: Record<string, JSX.Element> = {
  about: <StaticPagesDetailsForm.About />,
  accessibility: <StaticPagesDetailsForm.Accessibility />,
  background: <StaticPagesDetailsForm.Background />,
  'contact-us': <StaticPagesDetailsForm.ContactUs />,
  copyright: <StaticPagesDetailsForm.Copyright />,
  disclaimer: <StaticPagesDetailsForm.Disclaimer />,
  faqs: <StaticPagesDetailsForm.FAQs />,
  glossary: <StaticPagesDetailsForm.Glossary />,
  privacy: <StaticPagesDetailsForm.Privacy />,
  'useful-links': <StaticPagesDetailsForm.UsefulLinks />,
};
