import SignInForm from 'components/auth/SignInForm';
import AuthLayout from 'layouts/AuthLayout';
import React from 'react';

const SignIn = () => {
  return (
    <AuthLayout>
      <SignInForm />
    </AuthLayout>
  );
};

export default SignIn;
