import { Button, Form, Input, Typography } from 'antd';
import Icon from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import { Auth } from 'aws-amplify';
import { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ProviderAPI from 'api/provider';

import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';

import './authComponent.less';
import AlertMessageBox from './AlertMessageBox';
import { GlobalContext, MessageType } from 'contexts/GlobalContext';
import { transformAuthError } from './AuthMessageContants';
import { UserRole } from 'utils/UserUtils';
import { useSafeState } from 'ahooks';
import { IUserForChallenge } from 'model/user';
import { IUserForm } from 'model/auth';

const { Title } = Typography;
const LOCK_DOWN_TIME_IN_SECONDS = 30;

const SignInForm = () => {
  const [loading, setLoading] = useSafeState(false);

  const [seconds, setSeconds] = useSafeState(0);
  const [timerActivated, setTimerActivated] = useSafeState(false);
  const [buttonStatus, setButtonStatus] = useSafeState({
    disabled: false,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnUrl = searchParams.get('returnUrl');

  const { message, setMessage, setCurrentAuthenticatedUser, setTemporaryUserForNewPassword } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (seconds === 0) {
      setButtonStatus({ disabled: false });
      setTimerActivated(false);
    } else if (timerActivated) {
      timerId = setTimeout(() => {
        setSeconds((prevState) => prevState - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [timerActivated, setTimerActivated, seconds, setSeconds, setButtonStatus]);

  useEffect(() => {
    setButtonStatus({ disabled: false });
  }, [setButtonStatus]);

  const onFinish = async ({ username, password }: IUserForm) => {
    try {
      setLoading(true);
      const user = (await Auth.signIn(username.trim(), password.trim())) as IUserForChallenge;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setTemporaryUserForNewPassword(user);
        navigate('/new-password');
      } else {
        if (window.localStorage.getItem('_expiredTime')) {
          window.localStorage.removeItem('_expiredTime');
        }

        const [user, error] = await ProviderAPI.getUserInfo();
        if (error) throw error;

        if (user) {
          setCurrentAuthenticatedUser(user);
          if (user.isJsmAccountPendingSetup) {
            ProviderAPI.InitializeJsmAccount({
              email: user.email,
              displayName: user.name,
              providerId: user.defaultProvider,
            });
          }
          if (returnUrl) {
            navigate(returnUrl);
          } else {
            switch (user.role) {
              case UserRole.ADMIN:
                navigate(`/admin`);
                break;
              case UserRole.DESE_EDITOR:
                navigate(`/dese`);
                break;
              case UserRole.PROVIDER_ADMIN:
              case UserRole.PROVIDER_EDITOR:
                navigate(`/provider`);
                break;
            }
          }
        }
      }
      setMessage({});
      // Catch caluse variable type annotation must be any
    } catch (error: any) {
      if (error.message === 'Password attempts exceeded') {
        setButtonStatus({ disabled: true });
        setSeconds(LOCK_DOWN_TIME_IN_SECONDS);
        setTimerActivated(true);
      }

      if (error.name === 'NotAuthorizedException' && error.message === 'User is disabled.') {
        setMessage({
          type: MessageType.ERROR,
          content:
            'Error: User account is disabled. Please contact your organisation administrator to reactivate your account.',
        });
      } else {
        setMessage({ type: MessageType.ERROR, content: transformAuthError(error.message) });
      }
    } finally {
      setLoading(false);
    }
  };

  const { disabled } = buttonStatus;
  const btnLabel = !disabled ? 'Sign in' : 'Please retry in ';
  const lockMessage = seconds > 0 ? `(${seconds.toString().padStart(2, '0')}) seconds` : '';

  return (
    <div className="login-container__card">
      <Title className="title-signin" level={2}>
        Sign in to your Provider account
      </Title>
      <Form layout="vertical" initialValues={{ username: '', password: '' }} onFinish={onFinish}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Required' }]}>
          <Input
            id="email-input"
            name="email-input"
            aria-label="email input"
            className="border-radius-auth"
            size="large"
          />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Required' }]}>
          <Input.Password
            id="password-input"
            name="password-input"
            aria-label="password input"
            className="border-radius-auth"
            size="large"
          />
        </Form.Item>

        <AlertMessageBox message={message} closable style={{ marginBottom: '30px' }} />

        <Form.Item>
          <Button
            id="signin-button"
            className="border-radius-auth"
            size="large"
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            disabled={disabled}
          >
            {btnLabel} {lockMessage}
          </Button>
        </Form.Item>
      </Form>

      <div className="bottom-signin">
        <Button
          type="link"
          onClick={() => {
            setMessage({});
            navigate('/reset-password');
          }}
          tabIndex={0}
        >
          Reset your password
        </Button>
        <Link
          className="need-help-signin"
          href="https://uac.helpjuice.com/marketplace/secrets/9dAvPhMhiU9wIcL5Kdf-FQ/"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={0}
        >
          Need help?
          <Icon component={ExternalLink} className="arrow-icon" />
        </Link>
      </div>
    </div>
  );
};

export default SignInForm;
