import { Modal, notification } from 'antd';
import { FC, useState } from 'react';

import { updateCourseCode } from 'api/cred2';

export const CourseCodeModal: FC<{
  providerId: string;
  credId: string;
  open: boolean;
  toggleModal: (show: boolean) => void;
  onReloadRequest: () => void;
}> = (props) => {
  const [errors, setErrors] = useState();

  const submitForm = async ({ formData }: { formData: any }) => {
    // setUpdatingStatus(true);
    const [, err] = await updateCourseCode(props.providerId, props.credId, formData.courseCode);
    if (!err) {
      notification['info']({ message: 'Course Code updated' });
      props.toggleModal(false);
      props.onReloadRequest();
      // setUpdatingStatus(false);
    } else {
      err.rjsfErrors && setErrors(err.rjsfErrors);
      notification['error']({ message: err.message });
      // setUpdatingStatus(false);
    }
  };

  return (
    <Modal
      title={'Update Course Code'}
      destroyOnClose
      footer={null}
      open={props.open}
      okButtonProps={{ type: 'primary' }}
      maskClosable={false}
      onCancel={() => props.toggleModal(false)}
    >
      {/* <ThemedForm
        transformErrors={quickAddErrorsTransformer}
        schema={courseCodeSchema as JSONSchema7}
        uiSchema={courseCodeUiSchema}
        onSubmit={submitForm}
        extraErrors={errors}
        showErrorList={false}
        formData={{}}
      /> */}
    </Modal>
  );
};
