import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import { Row } from 'antd';
import CustomFileInput from 'components/form/shared/CustomFileInput';
import React, { memo } from 'react';
import { makeStyles } from 'utils/theme';

const useStyles = makeStyles()((theme) => ({
  titleText: {
    paddingTop: 22,
  },
  fileWrapper: {
    marginTop: 8,
    width: '100%',
    padding: 16,
    border: '1px solid #D9D9D9',
    display: 'flex',
    justifyContent: 'space-between',
  },
  templateDownloadLink: {
    color: theme.primaryColor,
    textDecoration: 'none',
    paddingLeft: 4,
    paddingRight: 4,
  },
  fileInnerContainer: {
    width: 420,
    height: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileNameText: {
    maxWidth: 400,
    fontSize: 14,
    color: theme.primaryColor,
    wordWrap: 'break-word',
  },
}));

const bulkCourseUploadTemplateDownloadUrl = `${
  process.env.REACT_APP_MARKETPLACE_PUBLIC_S3_URL ??
  `https://marketplace-static-content-prod.s3.ap-southeast-2.amazonaws.com/public`
}/shared/credential_bulk_upload_template.xlsx`;

interface BeforeStartUploadProps {
  onFileUploaded: (file: File | null) => void;
}

const BeforeStartUpload = ({ onFileUploaded }: BeforeStartUploadProps) => {
  const [file, setFile] = useSafeState<File | null>(null);
  const [clearFile, setClearFile] = useSafeState<boolean>(false);

  const { classes, css } = useStyles();

  const handleFileChange = (selectedFile: File | null) => {
    setFile(selectedFile);
    onFileUploaded(selectedFile);
    setClearFile(false);
  };

  return (
    <>
      <Row className={css({ paddingTop: 22 })}>
        Download{' '}
        <a href={`${bulkCourseUploadTemplateDownloadUrl}`} download className={classes.templateDownloadLink}>
          a MS-Excel template
        </a>{' '}
        to see the correct file format.
      </Row>
      <Row className={css({ paddingTop: 22, paddingBottom: 34 })}>
        <CustomFileInput onFileChange={handleFileChange} shouldClearFile={clearFile} />
        {file && (
          <Row className={classes.fileWrapper}>
            <Row>
              <PaperClipOutlined className={css({ alignSelf: 'center', marginRight: 8 })} />
              <div className={classes.fileNameText}>{file.name}</div>
            </Row>
            <DeleteOutlined
              className={css({ cursor: 'hand', alignSelf: 'center' })}
              onClick={() => {
                setFile(null);
                onFileUploaded(null);
                setClearFile(true);
              }}
            />
          </Row>
        )}
      </Row>
    </>
  );
};

export default memo(BeforeStartUpload);
