import React, { memo } from 'react';
import { IBulkCourseCreationError } from './BulkImportCredentialModal.types';
import { CloseCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { makeStyles } from 'utils/theme';
import { Button } from 'antd';
import Papa from 'papaparse';
import { sortBy } from 'lodash';

const useStyles = makeStyles()(() => ({
  componentWrapper: {
    marginTop: 31,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: '#D72C0D',
    fontSize: 42,
  },
  title: {
    marginTop: 11,
    fontSize: 20,
    fontWeight: 600,
  },
  message: {
    lineHeight: 4,
    fontSize: 14,
    fontWeight: 400,
  },
}));

interface IUploadHasErrorProps {
  errorList?: IBulkCourseCreationError[];
  errorMessage?: string;
}

const UploadHasError = ({ errorList, errorMessage }: IUploadHasErrorProps) => {
  const { classes, css } = useStyles();

  const handleDownloadErrorList = () => {
    // Convert JSON array to CSV string
    if (!errorList) return;
    const csv = Papa.unparse(sortBy(errorList, 'rowNumber'));

    // Create a Blob with the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'error_list.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.componentWrapper}>
      <div className={classes.errorIcon}>
        <CloseCircleFilled />
      </div>
      <div className={classes.title}>Import failed</div>
      {errorList?.length && (
        <>
          <div className={classes.message}>{`We found ${errorList.length} errors. Please resolve and try again.`}</div>
          <Button
            icon={<DownloadOutlined />}
            type="primary"
            className={css({ marginBottom: 51 })}
            onClick={handleDownloadErrorList}
          >
            Download error file
          </Button>
        </>
      )}
      {!!errorMessage && <div className={classes.message}>{errorMessage}</div>}
    </div>
  );
};

export default memo(UploadHasError);
