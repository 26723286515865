import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_TIMEZONE = 'Australia/Sydney';

export const formatDate = (dateTime?: string | number): string => {
  if (!dateTime) {
    return '';
  }
  return dayjs(dateTime).format('DD MMM YYYY');
};

export const isValidDate = (dateTime?: string | Date | number, format = 'YYYY-MM-DD') => {
  // parse date
  const ans = dayjs(dateTime, format, true);
  return ans.isValid();
};

export const parse = (d: string | Date | number, format = 'YYYY-MM-DD') => {
  // parse date
  let ans = dayjs(d, format, true);
  if (ans.isValid()) {
    return ans.tz(DEFAULT_TIMEZONE);
  }

  /// assume ISO string with timezone already
  ans = dayjs(d);
  return ans;
};

export const formatDateTime = (dateTime?: string | number): string => {
  if (!dateTime) {
    return '';
  }
  return dayjs(dateTime).format('DD MMM YYYY, hh:mm A');
};

export const formatEnquiryDate = (dateTime?: string | number): string => {
  if (!dateTime) {
    return '';
  }
  return dayjs(dateTime).format('YYYY-MM-DD');
};
