import { memo } from 'react';
import { useFieldArray, useFormContext, Control, FieldErrors, UseFormGetValues, FieldValue } from 'react-hook-form';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { PlusOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { ICredentialSchema } from './schema';
import { useStyles } from './common/useStyles';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';
import { SectionTitleRequired } from './common/utils';

const useAssessmentTasks = (control: Control<ICredentialSchema>, index: number) => {
  const {
    fields: taskFields,
    append: appendTask,
    remove: removeTask,
    move: moveTask,
  } = useFieldArray({
    control,
    name: `assessments.${index}.tasks`,
  });

  return {
    taskFields,
    appendTask,
    removeTask,
    moveTask,
  };
};

const NestedArray = ({
  nestIndex,
  control,
  errors,
  getValues,
  isFormReadOnly,
  ...fields
}: {
  nestIndex: number;
  control: Control<ICredentialSchema>;
  errors: FieldErrors<ICredentialSchema>;
  getValues: UseFormGetValues<FieldValue<any>>;
  isFormReadOnly: boolean;
}) => {
  const { classes } = useStyles();
  const { taskFields, appendTask, removeTask, moveTask } = useAssessmentTasks(control, nestIndex);

  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('3');

  const handleFieldDirty = () => setIsDirty(true);

  const addTask = () => {
    appendTask({ desc: '' });
    handleFieldDirty();
  };

  const rest = {
    ...fields,
    disabled: isFormReadOnly,
  };

  return (
    <>
      {taskFields.map((task, index) => {
        return (
          <Card key={task.id} className={classes.credCard} size="small">
            <Space className={classes.credCard__actions}>
              {taskFields.length > 1 && (
                <>
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    disabled={taskFields.length === 1 || index === 0 || rest.disabled}
                    onClick={() => {
                      moveTask(index, index - 1);
                      handleFieldDirty();
                    }}
                  />
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    disabled={taskFields.length === 1 || index === taskFields.length - 1 || rest.disabled}
                    onClick={() => {
                      moveTask(index, index + 1);
                      handleFieldDirty();
                    }}
                  />
                </>
              )}
              <Button
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  removeTask(index);
                  handleFieldDirty();
                }}
              />
            </Space>
            <Row>
              <Col span={24}>
                <GenericInput
                  label="Task description"
                  name={`assessments.${nestIndex}.tasks[${index}].desc` as const}
                  control={control}
                  required
                  showCount
                  maxLength={1000}
                  errors={errors?.assessments?.[nestIndex]?.tasks?.[index]?.desc}
                  handleFieldDirty={handleFieldDirty}
                  {...rest}
                />
              </Col>
            </Row>
          </Card>
        );
      })}
      <br />
      <Button onClick={addTask} disabled={rest.disabled}>
        Add <PlusOutlined />
      </Button>
    </>
  );
};

const CourseAssessmentsForm = ({ isFormReadOnly }: CredFormProps) => {
  const { classes } = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
    ...formFields
  } = useFormContext<ICredentialSchema>();

  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('3');

  const handleFieldDirty = () => setIsDirty(true);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'assessments',
  });

  const addAssessment = () => {
    append({ title: '', desc: '', tasks: [] });
    handleFieldDirty();
  };

  const rest = {
    ...formFields,
    disabled: isFormReadOnly,
  };

  return (
    <>
      <Card>
        <div>
          <Typography.Paragraph>
            The assessment element: the method and type of assessment (competency vs proficiency). Where assessment is
            onsite, the location/s will be stated.
          </Typography.Paragraph>
          <SectionTitleRequired title="Assessments" />
        </div>
        {errors.assessments && <Typography.Paragraph type="danger">{errors.assessments.message}</Typography.Paragraph>}
        {fields.map((assessment, index) => {
          return (
            <Card key={assessment.id} className={classes.credCard} size="small">
              <Space className={classes.credCard__actions}>
                <Button
                  size="small"
                  icon={<ArrowUpOutlined />}
                  disabled={fields.length === 1 || index === 0 || rest.disabled}
                  onClick={() => {
                    move(index, index - 1);
                    handleFieldDirty();
                  }}
                />
                <Button
                  size="small"
                  icon={<ArrowDownOutlined />}
                  disabled={fields.length === 1 || index === fields.length - 1 || rest.disabled}
                  onClick={() => {
                    move(index, index + 1);
                    handleFieldDirty();
                  }}
                />
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    remove(index);
                    handleFieldDirty();
                  }}
                  disabled={rest.disabled}
                />
              </Space>
              <Row>
                <Col span={24}>
                  <GenericInput
                    key={assessment.id}
                    label="Assessment title"
                    name={`assessments[${index}].title` as const}
                    control={control}
                    required
                    showCount
                    maxLength={500}
                    errors={
                      errors['assessments'] && errors['assessments'][index]
                        ? errors['assessments'][index]?.['title']
                        : undefined
                    }
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <GenericInput
                    key={assessment.id}
                    label="Assessment description"
                    name={`assessments[${index}].desc` as const}
                    control={control}
                    required
                    showCount
                    maxLength={2000}
                    type="quill"
                    errors={
                      errors['assessments'] && errors['assessments'][index]
                        ? errors['assessments'][index]?.desc
                        : undefined
                    }
                    handleFieldDirty={handleFieldDirty}
                    {...rest}
                  />
                </Col>
              </Row>

              <Typography.Title level={5}>Assessment tasks</Typography.Title>

              <NestedArray
                nestIndex={index}
                control={control}
                errors={errors}
                getValues={getValues}
                isFormReadOnly={isFormReadOnly}
                {...rest}
              />
            </Card>
          );
        })}
        <br />
        <Button onClick={addAssessment} disabled={rest.disabled}>
          Add <PlusOutlined />
        </Button>
      </Card>
    </>
  );
};

export default memo(CourseAssessmentsForm);
