import { alphanumericRegex } from '../../../../constants';
import * as z from 'zod';
import { CourseLevel, DeliveryModeType, IndustryName, SkillName, UnitName } from '../form/Course.types';
import { FieldOfEducationNames } from 'enums';
import { isValidDate } from 'utils/date';
import dayjs from 'dayjs';
import { preprocessRequiredStringValue } from 'utils/zodSchema.utils';

export const rawCourseJsonSchema = z
  .array(
    z.object({
      'summary/courseCode': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Course code is a required field and must not be empty.',
          })
          .nonempty({ message: 'Course code a required field and must not be empty.' })
          .max(30, { message: 'Course code must have less than 30 characters.' })
      ),
      'summary/title': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Title is a required field and must not be empty.',
          })
          .nonempty({ message: 'Title is a required field and must not be empty.' })
          .max(300, { message: 'Title must have less than 300 characters.' })
      ),
      'summary/level': z.enum(
        [
          CourseLevel.ADVANCED_BEGINNER,
          CourseLevel.COMPETENT,
          CourseLevel.EXPERT,
          CourseLevel.NOVICE,
          CourseLevel.PROFICIENT,
        ],
        {
          required_error: 'Level is a required field and must not be empty.',
          invalid_type_error: `Level must be one of the following values: ${Object.values(CourseLevel).join(',')}.`,
        }
      ),
      'summary/duration/unit': z.enum([UnitName.DAY, UnitName.HOUR, UnitName.MONTH, UnitName.WEEK], {
        required_error: 'Duration/unit is a required field and must not be empty.',
        invalid_type_error: `Duration/unit must be one of the following values: ${Object.values(UnitName).join(',')}.`,
      }),
      'summary/duration/value': z
        .number({
          required_error: 'Duration/value is a required field and must not be empty.',
          invalid_type_error: 'Duration/value must be a number.',
        })
        .min(1, 'Value must be greater than or equal to 1.'),
      'summary/commitment': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Commitment is a required field and must not be empty.',
          })
          .nonempty({ message: 'Commitment is a required field and must not be empty.' })
          .max(1000, { message: 'Commitment must have less than 1000 characters.' })
      ),
      'summary/language': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Language is a required field and must not be empty.',
          })
          .nonempty({ message: 'Language is a required field and must not be empty.' })
          .max(50, { message: 'Language must have less than 50 characters.' })
      ),
      'summary/fieldOfEducation': z.enum(
        [
          FieldOfEducationNames.AGRICULTURE_ENVIRONMENT_AND_RELATED_STUDIES,
          FieldOfEducationNames.ARCHITECTURE_AND_BUILDING,
          FieldOfEducationNames.CREATIVE_ARTS,
          FieldOfEducationNames.EDUCATION,
          FieldOfEducationNames.ENGINEERING_AND_RELATED_TECHNOLOGIES,
          FieldOfEducationNames.HEALTH,
          FieldOfEducationNames.INFORMATION_TECHNOLOGY,
          FieldOfEducationNames.MANAGEMENT_AND_COMMERCE,
          FieldOfEducationNames.MIXED_FIELD_PROGRAM,
          FieldOfEducationNames.NATURAL_AND_PHYSICAL_SCIENCES,
          FieldOfEducationNames.SOCIETY_AND_CULTURE,
          FieldOfEducationNames.TOURISM_HOSPITALITY_AND_PERSONAL_SERVICES,
        ],
        {
          required_error: 'FieldOfEducation is a required field and must not be empty.',
          invalid_type_error: `FieldOfEducation must be one of the following values: ${Object.values(
            FieldOfEducationNames
          ).join(',')}.`,
        }
      ),
      'summary/industries/0': z.enum(
        [
          IndustryName.ACCOMMODATION_AND_FOOD_SERVICES,
          IndustryName.ADMINISTRATIVE_AND_SUPPORT_SERVICES,
          IndustryName.AGRICULTURE_FORESTRY_AND_FISHING,
          IndustryName.ARTS_AND_RECREATION_SERVICES,
          IndustryName.CONSTRUCTION,
          IndustryName.EDUCATION_AND_TRAINING,
          IndustryName.ELECTRICITY_GAS_WATER_AND_WASTE_SERVICES,
          IndustryName.FINANCIAL_AND_INSURANCE_SERVICES,
          IndustryName.HEALTH_CARE_AND_SOCIAL_ASSISTANCE,
          IndustryName.INFORMATION_MEDIA_AND_TELECOMMUNICATIONS,
          IndustryName.MANUFACTURING,
          IndustryName.MINING,
          IndustryName.OTHER_SERVICES,
          IndustryName.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES,
          IndustryName.PUBLIC_ADMINISTRATION_AND_SAFETY,
          IndustryName.RENTAL_HIRING_AND_REAL_ESTATE_SERVICES,
          IndustryName.RETAIL_TRADE,
          IndustryName.TRANSPORT_POSTAL_AND_WAREHOUSING,
          IndustryName.WHOLESALE_TRADE,
        ],
        {
          required_error: 'Please provide at least one industry.',
          invalid_type_error: `Industry must be one of the following values: ${Object.values(IndustryName).join(',')}.`,
        }
      ),
      'summary/industries/1': z
        .enum(
          [
            IndustryName.ACCOMMODATION_AND_FOOD_SERVICES,
            IndustryName.ADMINISTRATIVE_AND_SUPPORT_SERVICES,
            IndustryName.AGRICULTURE_FORESTRY_AND_FISHING,
            IndustryName.ARTS_AND_RECREATION_SERVICES,
            IndustryName.CONSTRUCTION,
            IndustryName.EDUCATION_AND_TRAINING,
            IndustryName.ELECTRICITY_GAS_WATER_AND_WASTE_SERVICES,
            IndustryName.FINANCIAL_AND_INSURANCE_SERVICES,
            IndustryName.HEALTH_CARE_AND_SOCIAL_ASSISTANCE,
            IndustryName.INFORMATION_MEDIA_AND_TELECOMMUNICATIONS,
            IndustryName.MANUFACTURING,
            IndustryName.MINING,
            IndustryName.OTHER_SERVICES,
            IndustryName.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES,
            IndustryName.PUBLIC_ADMINISTRATION_AND_SAFETY,
            IndustryName.RENTAL_HIRING_AND_REAL_ESTATE_SERVICES,
            IndustryName.RETAIL_TRADE,
            IndustryName.TRANSPORT_POSTAL_AND_WAREHOUSING,
            IndustryName.WHOLESALE_TRADE,
          ],
          {
            required_error: 'Please provide at least one industry.',
            invalid_type_error: `Industry must be one of the following values: ${Object.values(IndustryName).join(
              ','
            )}.`,
          }
        )
        .optional(),
      'summary/industries/2': z
        .enum(
          [
            IndustryName.ACCOMMODATION_AND_FOOD_SERVICES,
            IndustryName.ADMINISTRATIVE_AND_SUPPORT_SERVICES,
            IndustryName.AGRICULTURE_FORESTRY_AND_FISHING,
            IndustryName.ARTS_AND_RECREATION_SERVICES,
            IndustryName.CONSTRUCTION,
            IndustryName.EDUCATION_AND_TRAINING,
            IndustryName.ELECTRICITY_GAS_WATER_AND_WASTE_SERVICES,
            IndustryName.FINANCIAL_AND_INSURANCE_SERVICES,
            IndustryName.HEALTH_CARE_AND_SOCIAL_ASSISTANCE,
            IndustryName.INFORMATION_MEDIA_AND_TELECOMMUNICATIONS,
            IndustryName.MANUFACTURING,
            IndustryName.MINING,
            IndustryName.OTHER_SERVICES,
            IndustryName.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES,
            IndustryName.PUBLIC_ADMINISTRATION_AND_SAFETY,
            IndustryName.RENTAL_HIRING_AND_REAL_ESTATE_SERVICES,
            IndustryName.RETAIL_TRADE,
            IndustryName.TRANSPORT_POSTAL_AND_WAREHOUSING,
            IndustryName.WHOLESALE_TRADE,
          ],
          {
            required_error: 'Please provide at least one industry.',
            invalid_type_error: `Industry must be one of the following values: ${Object.values(IndustryName).join(
              ','
            )}.`,
          }
        )
        .optional(),
      'summary/price': z
        .number({
          required_error: 'Price is a required field and must not be empty.',
          invalid_type_error: 'Price must be a number or a decimal number.',
        })
        .min(0),
      'summary/discountIndicator': z.boolean({
        required_error: 'DiscountIndicator is a required field and must not be empty.',
        invalid_type_error: 'DiscountIndicator must be one of the following values: true, false.',
      }),
      'summary/discountDesc/discountDesc': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({ required_error: 'DiscountDesc is a required field and must not be empty.' })
          .max(500, 'DiscountDesc must have less than 500 characters.')
          .optional()
      ),
      'overview/about/tagline': z.preprocess(
        preprocessRequiredStringValue,
        z

          .string({
            required_error: 'About tagline is a required field and must not be empty.',
          })
          .nonempty({ message: 'About tagline is a required field and must not be empty.' })
          .max(200, { message: 'About tagline must have less than 200 characters' })
      ),
      'overview/about/description': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'About description is a required field and must not be empty.',
          })
          .nonempty({ message: 'About description is a required field and must not be empty.' })
          .max(5000, { message: 'About description must have less than 5000 characters' })
      ),
      'overview/deliveryDesc/deliveryDesc': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'DeliveryDesc is a required field and must not be empty.',
          })
          .nonempty({ message: 'DeliveryDesc is a required field and must not be empty.' })
          .max(500, { message: 'DeliveryDesc must have less than 500 characters' })
      ),
      'overview/syllabuses/title': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'syllabuses title is a required field and must not be empty.',
          })
          .nonempty({ message: 'syllabuses title is a required field and must not be empty.' })
          .max(200, { message: 'syllabuses title must have less than 200 characters.' })
      ),
      'overview/syllabuses/description': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Syllabuses description is a required field and must not be empty.',
          })
          .nonempty({ message: 'Syllabuses description is a required field and must not be empty.' })
          .max(1000, { message: 'Syllabuses description must have less than 1000 characters.' })
      ),
      'overview/outcome/describe': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Outcome describe is a required field and must not be empty.',
          })
          .nonempty({ message: 'Outcome describe is a required field and must not be empty.' })
          .max(1000, { message: 'Outcome describe must have less than 1000 characters' })
      ),
      'assessment/assessments/title': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Assessments title is a required field and must not be empty.',
          })
          .nonempty({ message: 'Assessments title is a required field and must not be empty.' })
          .max(500, 'Assessments title must have less than 500 characters')
      ),
      'assessment/assessments/desc': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Assessments desc is a required field and must not be empty.',
          })
          .nonempty({ message: 'Assessments desc is a required field and must not be empty.' })
          .max(2000, 'Assessments desc must have less than 2000 characters')
      ),
      'qualitySkills/skills/name': z.enum(
        [
          SkillName.AGRICULTURE_AND_ANIMALS,
          SkillName.ARCHIVING_RECORDING_AND_TRANSLATING,
          SkillName.ART_AND_ENTERTAINMENT,
          SkillName.BUSINESS_OPERATIONS_AND_FINANCIAL_ACTIVITIES,
          SkillName.CLEANING_AND_MAINTENANCE,
          SkillName.COMMUNICATION_AND_COLLABORATION,
          SkillName.CONSTRUCTION,
          SkillName.CUSTOMER_SERVICE,
          SkillName.DATA_ANALYTICS_AND_DATABASES,
          SkillName.DIGITAL_TECHNOLOGIES_AND_ELECTRONICS,
          SkillName.ENVIRONMENTAL_MANAGEMENT,
          SkillName.FASHION_GROOMING_AND_COSMETICS,
          SkillName.FOOD_SERVICES,
          SkillName.HEALTH_AND_CARE,
          SkillName.HUMAN_RESOURCES,
          SkillName.MATERIAL_TRANSPORTATION,
          SkillName.OPERATING_PROCEDURES_AND_PROCESSES,
          SkillName.PERFORMANCE_EVALUATION_AND_EFFICIENCY_IMPROVEMENT,
          SkillName.PRODUCTION_PROCESSES_AND_MACHINERY,
          SkillName.QUALITY_CONTROL_AND_INSPECTIONS,
          SkillName.RECORDS_DOCUMENTATION_REPORTS_AND_RESEARCH,
          SkillName.RECREATION_AND_SPORTING_EVENTS,
          SkillName.SAFETY_AND_HAZARD_MANAGEMENT,
          SkillName.SALES_AND_MARKETING,
          SkillName.SCIENCE_AND_MATHEMATICS,
          SkillName.SECURITY_AND_EMERGENCY_SERVICES,
          SkillName.TEACHING_AND_EDUCATION,
          SkillName.VEHICLE_OPERATION,
          SkillName.WORK_RELATED_ACTIVITIES_PREPARATION,
        ],
        {
          required_error: 'Skills name is a required field and must not be empty.',
          invalid_type_error: `Skills name must be one of the following values: ${Object.values(SkillName).join(',')}.`,
        }
      ),
      'enrolment/apply/url': z.preprocess(
        preprocessRequiredStringValue,
        z.string().url({ message: 'Invalid url' }).max(256, 'Apply url must have less than 256 characters')
      ),
      'enrolment/sessions/deliveryMode/type': z.enum(
        [DeliveryModeType.ONLINE, DeliveryModeType.ON_CAMPUS, DeliveryModeType.MULTI_MODAL],
        {
          required_error: 'Sessions deliveryMode type is a required field and must not be empty.',
          invalid_type_error: `Sessions deliveryMode type must be one of the following values: ${Object.values(
            DeliveryModeType
          ).join(',')}.`,
        }
      ),
      'enrolment/sessions/flexibleIndicator': z.boolean({
        required_error: 'Sessions flexibleIndicator is a required field and must not be empty.',
        invalid_type_error: 'Sessions flexibleIndicator must be one of the following values: true, false.',
      }),
      'enrolment/sessions/flexibleStartDate': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Sessions flexibleStartDate is a required field and must not be empty.',
          })
          .optional()
      ),
      'enrolment/sessions/flexibleEndDate': z.preprocess(
        preprocessRequiredStringValue,
        z
          .string({
            required_error: 'Sessions flexibleEndDate is a required field and must not be empty.',
          })
          .optional()
      ),
    })
  )
  .min(1)
  .superRefine((data, ctx) => {
    for (let index = 0; index < data.length; index++) {
      const currentRow = data[index];
      const flexibleIndicator = currentRow['enrolment/sessions/flexibleIndicator'];
      const flexibleStartDate = currentRow['enrolment/sessions/flexibleStartDate']?.trim();
      const flexibleEndDate = currentRow['enrolment/sessions/flexibleEndDate']?.trim();
      const discountIndicator = currentRow['summary/discountIndicator'];
      const discountDesc = currentRow['summary/discountDesc/discountDesc']?.trim();
      const courseCode = currentRow['summary/courseCode']?.trim();

      if (courseCode && !alphanumericRegex.test(courseCode)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [index, 'summary/courseCode'],
          message: 'Code must be alphanumeric',
        });
      }

      if (discountIndicator === true && !discountDesc) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [index, 'summary/discountDesc/discountDesc'],
          message: 'discountDesc is required when discountIndicator is TRUE.',
        });
      }

      if (discountIndicator === false && discountDesc) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [index, 'summary/discountDesc/discountDesc'],
          message: 'discountDesc should be empty when discountIndicator is FALSE.',
        });
      }

      if (flexibleIndicator === true) {
        if (flexibleStartDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleStartDate'],
            message: 'Sessions flexibleStartDate should be empty when flexibleIndicator has been set to TRUE.',
          });
        }

        if (flexibleEndDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleEndDate'],
            message: 'Sessions flexibleEndDate should be empty when flexibleIndicator has been set to TRUE.',
          });
        }
      }

      // if enrolment/sessions/flexibleIndicator has been set to false
      if (flexibleIndicator === false) {
        // 01. flexibleStartDate is required
        if (!flexibleStartDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleStartDate'],
            message:
              'Sessions flexibleStartDate is a required field and must not be empty, when enrolment/sessions/flexibleIndicator has been set to FALSE',
          });
        }

        // 02. flexibleEndDate is required
        if (!flexibleEndDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleEndDate'],
            message:
              'Sessions flexibleEndDate is a required field and must not be empty, when enrolment/sessions/flexibleIndicator has been set to FALSE.',
          });
        }

        // 03. flexibleEndDate must be a valid date
        if (!isValidDate(flexibleStartDate, 'DD/MM/YYYY')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleStartDate'],
            message: 'Sessions flexibleStartDate is not a valid date.',
          });
        }

        // 04. flexibleEndDate must be a valid date
        if (!isValidDate(flexibleEndDate, 'DD/MM/YYYY')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleEndDate'],
            message: 'Sessions flexibleEndDate is not a valid date.',
          });
        }

        // 05. flexibleEndDate must be after flexibleStartDate
        if (flexibleStartDate && flexibleEndDate && dayjs(flexibleEndDate).isBefore(dayjs(flexibleStartDate))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'enrolment/sessions/flexibleEndDate'],
            message: 'Sessions flexibleEndDate must be after flexibleStartDate.',
          });
        }
      }
    }
  });

export type BulkCourseCreationRawData = z.infer<typeof rawCourseJsonSchema>;
