import { Row } from 'antd';

export enum STATUS_TYPE {
  ACTIVE = '#52C41A', // green
  INACTIVE = '#E82121', // red
  PENDING = '#FAAD14', // yellow
  NONE = '#D9D9D9', // grey
}

interface IStatusIndicatorProps {
  status: STATUS_TYPE;
  text: React.ReactFragment;
}

export const StatusIndicator = ({ status, text }: IStatusIndicatorProps) => {
  const indicatorStyle = {
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginRight: '8px',
    backgroundColor: status || STATUS_TYPE.NONE,
  };

  return (
    <Row align="middle">
      <div style={indicatorStyle}></div>
      <div>{text}</div>
    </Row>
  );
};

export default StatusIndicator;
