import { AxiosResponse } from 'axios';

export interface IError {
  code: string;
  message: string;
  errors?: any; // JsonSchema errors
  rjsfErrors?: any; // This is serverside error details use in rjsf

  /// backward compat
  response?: AxiosResponse;
}

export const handleAPIPromise = async <T = any>(
  promise: Promise<AxiosResponse>
): Promise<[T | undefined, IError | undefined]> => {
  try {
    const response = await promise;
    return [response.data, undefined];
    // Catch caluse variable type annotation must be any
  } catch (error: any) {
    const { code, message, errors, rjsfErrors } = error?.response?.data || {};
    /// simplify error return
    return [
      undefined,
      {
        code,
        message: message,
        errors,
        rjsfErrors,
        response: error?.reponse,
      },
    ];
  }
};
