import { PolicyType } from 'enums';
import { z } from 'zod';

const extractPolicyType = (item: any) => item.type;

export const policiesSchema = z.object({
  policies: z
    .object({
      type: z.union([z.enum([PolicyType.ADMISSION_POLICY, PolicyType.ENGLISH_LANGUAGE_POLICY, PolicyType.PRIVACY_POLICY]), z.string().nonempty({ message: 'Required' })]),
      desc: z.string().nonempty({ message: 'Required' }).url({ message: 'Should match format URL' }).max(256),
      duplicateError: z.void()
    })
    .array()
    .max(3)
    .optional()
    .refine(
      (policies) => new Set(policies ? policies.map(extractPolicyType) : []).size === (policies ?? []).length ?? true,
      {
        message: 'You cannot have more than one occurrence of a policy type.', path: [0,'duplicateError']
      }
    ),
});

export type PoliciesSchema = z.infer<typeof policiesSchema>;
