import API from 'api';
import LogoEditable from 'components/common/logo/LogoEditable';
import { useEffect, useState } from 'react';
import { IProvider } from 'types/provider';
import { makeStyles } from 'utils/theme';

export interface IProfileLogo {
  name: string;
  provider: string;
  readOnly: boolean;
  logoUrl?: string;
  heading: string;
  description?: React.ReactFragment;
  isRect?: boolean;
  onChange?: (logo?: string) => void;
  reloadProvider: () => void;
  onLogoUpdated: (name?: string, url?: string) => void;
}

const useStyles = makeStyles()((theme) => ({
  heading: {
    fontWeight: 500
  }
}));

const ProfileLogo = ({
  name,
  provider,
  logoUrl,
  description,
  isRect = false,
  onChange,
  readOnly,
  reloadProvider,
  onLogoUpdated,
  heading = ''
}: IProfileLogo) => {
  const { classes } = useStyles();
  const [logoRefreshId, setLogoRefreshId] = useState<string>();
  const [internalLogoUrl, setInternalLogoUrl] = useState<string | undefined>(logoUrl);

  const logoUpdated = (data: IProvider) => {
    let updatedLogo = data.logo;
    if (isRect) {
      updatedLogo = data.logoX;
    }

    onLogoUpdated(name, updatedLogo)
    setInternalLogoUrl(updatedLogo);
    onChange?.(updatedLogo);
    setLogoRefreshId(new Date().getTime().toString());
    reloadProvider();
  };

  const logoRemoved = () => {
    onChange?.('');
    setInternalLogoUrl('');
    setLogoRefreshId(new Date().getTime().toString());
    onLogoUpdated(name, undefined);
  };

  const uploadLogo = (base64File: string) => {
    return API.Provider.uploadLogo(provider, base64File, isRect);
  };

  const removeLogo = () => {
    const data: { [k: string]: unknown } = {};
    if (isRect) {
      data.logoX = null;
    } else {
      data.logo = null;
    }

    return API.Provider.update(provider, data);
  };

  useEffect(() => {
    setInternalLogoUrl(logoUrl);
  }, [logoUrl]);

  return (
    <>
    <div className={classes.heading}>{heading}</div>
      <div>{description}</div>
      <LogoEditable
        logoUrl={internalLogoUrl}
        refreshId={logoRefreshId}
        uploadText="Upload"
        removalText="Remove"
        modalTitle="Upload logo"
        modalOkText="Upload"
        removalModalTitle="Remove logo"
        removalConfirmationContent="The logo will be permanently removed. Are you sure?"
        uploadLogo={uploadLogo}
        onUpdate={logoUpdated}
        removeLogo={removeLogo}
        onRemove={logoRemoved}
        isRect={isRect}
        readOnly={readOnly}
      />
    </>
  );
};

export default ProfileLogo;
