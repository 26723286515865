import { useContext } from 'react';
import { Col, Form, Modal, Row, notification } from 'antd';
import API from 'api';
import { UserContext } from 'components/UserContextProvider';
import GenericInput from 'components/form/shared/GenericInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enumToArray } from 'components/form/shared/FormHelpers';
import { makeStyles } from 'utils/theme';
import { CourseLevel } from './form/Course.types';
import { ProviderContext } from 'components/ProviderContextProvider';
import { useNavigate } from 'react-router-dom';
import { baseSummarySchema } from './form/schema/summary';
import { z } from 'zod';

const createCredentialSchema = baseSummarySchema.pick({
  courseCode: true,
  title: true,
  level: true,
  language: true,
  price: true
}).required()

type CreateCredentialSchema  = z.infer<typeof createCredentialSchema>

interface ICreateCredentialModal {
  visible: boolean;
  handleCancel: () => void;
  setLoading: (loading: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  modalWrapper: {
    '& .ant-modal-body': {
      padding: '0.5rem 1rem',
    },
  },
  formWrapper: {
    '& .ant-form-item-label': {
      paddingBottom: 0,
    },
    '& .ant-form-item': {
      marginBottom: 12,
    },
  },
}));

export const CreateCredentialModal = ({ visible, handleCancel, setLoading }: ICreateCredentialModal) => {
  const { reload, isAdmin, currentProvider } = useContext(UserContext);
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { provider } = useContext(ProviderContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
    ...rest
  } = useForm<CreateCredentialSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(createCredentialSchema),
  });

  const onSubmit = async (formData: CreateCredentialSchema  ) => {
    const [data, err] = await API.Cred2.create(provider.providerId!, formData);

    if (err) {
      if (err.message === `Course code [${formData.courseCode}] already used`) {
        setError('courseCode', {
          type: 'custom',
          message: `Microcredential with code [${formData.courseCode}] already exists. Enter a unique code.`,
        });
      }
      notification['error']({ message: err.message });
      return;
    }

    if (isAdmin) {
      navigate(`/admin/courses/${currentProvider}/${data.id}`);
    } else {
      navigate(`/provider/courses/${data.id}`);
    }

    notification['info']({
      message: (
        <>
          <strong>{formData.title}</strong> created.
        </>
      ),
    });

    handleCancel();
    reset();
    setLoading(true);
    reload();
  };

  return (
    <Modal
      title="Add microcredential"
      open={visible}
      cancelText="Cancel"
      okText="Add microcredential"
      onCancel={() => {
        reset();
        handleCancel();
      }}
      onOk={handleSubmit(onSubmit)}
      destroyOnClose
      maskClosable={false}
      className={classes.modalWrapper}
    >
      <Form layout="vertical" className={classes.formWrapper}>
        <Row>
          <Col span={24}>
            <GenericInput
              name="courseCode"
              type="text"
              label="Code"
              control={control}
              required
              showCount
              maxLength={30}
              errors={errors['courseCode']}
              placeholder="e.g. ABC123"
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="title"
              type="text"
              label="Title"
              control={control}
              required
              showCount
              maxLength={300}
              errors={errors['title']}
              placeholder="Course title"
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="level"
              type="select"
              placeholder="-- select level --"
              options={enumToArray(CourseLevel)}
              label="Level"
              control={control}
              required
              showCount
              maxLength={30}
              errors={errors['level']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="language"
              label="Language"
              control={control}
              maxLength={50}
              required
              showCount
              errors={errors['language']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="price"
              type="number"
              label="Price"
              control={control}
              required
              errors={errors['price']}
              {...rest}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateCredentialModal;
