import { JSONSchema7 } from 'json-schema';
import { removeHTMLTags } from 'utils/validation';
import { z } from 'zod';

export const convertSchemaArray = (schema: any, propertyName: string) => {
  const { type } = schema;
  let newSchema = schema;
  switch (type) {
    case 'array':
      // add schema wrapper to fix rendering issue
      newSchema = {
        type: 'object',
        properties: {
          [propertyName]: schema,
        },
      };
      break;
    default:
      break;
  }
  return newSchema;
};

export const mergeSchemas = (formSchema: any, properties: string[], propertiesRequired: string[] = []) => {
  let newFormObject: {
    type: string;
    properties?: any;
    required?: string[];
  } = {
    type: 'object',
    properties: {},
    required: [],
  };

  newFormObject.properties = properties.reduce((formProperties: { [key: string]: any }, name: string) => {
    formProperties[name] = formSchema[name];
    return formProperties;
  }, {});

  if (propertiesRequired?.length > 0) {
    newFormObject.required = propertiesRequired;
  }

  return newFormObject as JSONSchema7;
};

export const isURL = (value: string): boolean => {
  try {
    z.string().url().parse(value);
    return true;
  } catch (_) {
    return false;
  }
};

export const transformEmptyStringToUndefined = (e: string) => (e === '' ? undefined : e);

export function hasCommonKey(obj1: object, obj2: object, exceptionKey: string[] = []) {
  const obj1Keys = Object.keys(obj1).filter((e) => !exceptionKey.includes(e));
  const obj2Keys = Object.keys(obj2).filter((e) => !exceptionKey.includes(e));

  return obj1Keys.some((key) => obj2Keys.includes(key));
}

export function filterProperties(obj: Record<any, any>) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively filter properties in nested objects or arrays
      obj[key] = filterProperties(obj[key]);

      // Remove the entire property if all nested properties are empty or false
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (!obj[key]) {
      // Remove properties that have empty or false values
      delete obj[key];
    }
  }
  return obj;
}
