import { Form } from 'antd';
import GenericInput from 'components/form/shared/GenericInput';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IPrivacySchema } from './schema/privacy';

const StaticPagePrivacyForm = () => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IPrivacySchema>();

  return (
    <Form layout="vertical">
      <GenericInput control={control} name="title" label="Title" required errors={errors['title']} {...rest} />
      <GenericInput
        control={control}
        name="body"
        label="Content"
        required
        errors={errors['body']}
        type="quill"
        descriptionAfter="Content of the page"
        {...rest}
      />
    </Form>
  );
};

export default memo(StaticPagePrivacyForm);
