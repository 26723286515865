import ProfileLogo from './ProfileLogo';
import { Card, Col, Form, notification, Row, Spin, Tabs } from 'antd';
import API from 'api';
import { ProviderContext } from 'components/ProviderContextProvider';
import { UserContext } from 'components/UserContextProvider';
import BackLink from 'components/common/backLink/BackLink';
import { useContext, useEffect, useRef, useState } from 'react';
import { TabItem } from 'model/ui';
import ProfileActions from './ProfileActions';
import { GENERIC_VALIDATION_ERROR_MESSAGE } from './../../../constants';
import ProfileCampusesForm from './form/ProfileCampusesForm';
import ProfilePoliciesForm from './form/ProfilePoliciesForm';
import ProfileSummaryForm from './form/ProfileSummaryForm';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import ProfileSettingsForm from './form/ProfileSettingsForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { IProfileSchema, profileSchema } from './form/schema';
import { debounce, isEqual } from 'lodash';
import { IProvider } from 'types/provider';
import { UPLOAD_LOGO_RECTANGLE_WIDTH, UPLOAD_LOGO_SQUARE_WIDTH, UPLOAD_LOGO_HEIGHT } from '../../../constants';
import React from 'react';

const Profile = ({
  profiles,
  setProfiles,
}: {
  profiles?: [];
  setProfiles?: React.Dispatch<React.SetStateAction<never[]>>;
}) => {
  const [isPreview, setIsPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);

  const formError = useRef<FieldErrors | undefined>();

  const { currentProvider, isAdmin, changeProvider, reload } = useContext(UserContext);
  const {
    provider: {
      providerAbout,
      legalName,
      tradingName,
      providerType,
      orgId,
      extId,
      address,
      phoneNumber,
      email,
      enquiryEmail,
      website,
      logo,
      logoX,
      campuses,
      policies,
      allowApproveAndReject,
      allowEditProfile,
      providerId,
    },
    loading,
    reloadProvider,
  } = useContext(ProviderContext);

  const methods = useForm<Partial<IProfileSchema>>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      providerAbout,
      legalName,
      tradingName,
      providerType,
      orgId,
      extId,
      address,
      phoneNumber,
      email,
      enquiryEmail,
      website,
      campuses,
      policies,
      allowApproveAndReject,
      allowEditProfile,
      logo,
      logoX,
    },
    resolver: zodResolver(profileSchema),
  });

  const debouncedGenericErrorNotification = debounce(() => {
    notification['error']({
      message: GENERIC_VALIDATION_ERROR_MESSAGE,
    });
  }, 500);

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0 && !isEqual(methods.formState.errors, formError.current)) {
      debouncedGenericErrorNotification();
      formError.current = methods.formState.errors;
    }
  }, [debouncedGenericErrorNotification, formError, methods.formState.errors]);

  useEffect(() => {
    if (!loading) {
      methods.reset({
        providerAbout,
        legalName,
        tradingName,
        providerType,
        orgId,
        extId,
        address,
        phoneNumber,
        email,
        enquiryEmail,
        website,
        campuses,
        policies,
        allowApproveAndReject,
        allowEditProfile,
      });

      // Methods.reset will not re-render the whole page
      // So in-order for the page to re-render and display the new values
      // We have to force re-render it using epoch
      setRerenderKey(Date.now());
    }
  }, [
    address,
    allowApproveAndReject,
    allowEditProfile,
    campuses,
    email,
    enquiryEmail,
    extId,
    legalName,
    loading,
    methods,
    providerType,
    orgId,
    phoneNumber,
    policies,
    providerAbout,
    tradingName,
    website,
    currentProvider,
  ]);

  // allow save null value to override previously save email/url
  const transformDataWithEmptyString = (data: Partial<IProfileSchema>): Partial<IProfileSchema> => {
    let result = { ...data };
    if (!result.email) {
      result.email = null;
    }

    if (!result.website) {
      result.website = null;
    }
    return result;
  };

  const previewProvider = async () => {
    if (providerId) {
      const [data] = await API.Cred2.generateProfilePreviewUrl(providerId);

      if (data) {
        window.open(data.previewUrl, '__blank');
      }
    }
    setIsPreview(false);
  };

  const onLogoUpdated = (name?: string, url?: string) => {
    if (name === 'logo' && profiles?.length) {
      const newProfileIndex = profiles?.findIndex((profile: IProvider) => profile?.id === currentProvider);
      if (newProfileIndex !== undefined && newProfileIndex > -1) {
        (profiles as IProvider[])[newProfileIndex].logo = '';

        if (setProfiles) {
          setProfiles(profiles as []);
        }
      }
      methods.setValue('logo', url);
    }

    if (name === 'logoX' && profiles?.length) {
      const newProfileIndex = profiles?.findIndex((profile: IProvider) => profile?.id === currentProvider);
      if (newProfileIndex !== undefined && newProfileIndex > -1) {
        (profiles as IProvider[])[newProfileIndex].logoX = '';

        if (setProfiles) {
          setProfiles(profiles as []);
        }

        methods.setValue('logo', url);
      }
    }
  };

  const onSubmit =
    (updateId: string | undefined, isPreview = false) =>
    async (data: Partial<IProfileSchema>) => {
      if (updateId) {
        // Set loading = true
        if (isPreview) {
          setIsPreview(true);
        }

        setIsSaving(true);

        const [, error] = await API.Provider.update(updateId, transformDataWithEmptyString(data));
        if (!error) {
          if (!isPreview) {
            notification['success']({ message: 'Successfully updated.' });
          }
          reloadProvider();
          reload();

          if (isPreview) {
            await previewProvider();
          }
        } else {
          const errorData = error.response?.data;

          if (!error.response?.data && error.code === 'BAD_REQUEST') {
            notification['error']({
              message: GENERIC_VALIDATION_ERROR_MESSAGE,
            });
          } else {
            notification['error']({
              message: `Could not update provider data. Error ${JSON.stringify(errorData)}`,
            });
          }
        }
        setIsSaving(false);
      }
    };

  if (loading) return <Spin />;

  const title = isAdmin && extId ? extId : 'Profile';

  const readOnly = !(isAdmin || allowEditProfile);

  const items: TabItem[] = [
    {
      key: 'summary',
      label: 'Summary',
      children: (
        <>
          <ProfileSummaryForm readOnly={readOnly} />
          <Card title="Logos" style={{ marginTop: 16 }}>
            <ProfileLogo
              heading="Main logo"
              name="logo"
              provider={currentProvider}
              reloadProvider={reloadProvider}
              logoUrl={logo}
              description={
                <>
                  {`Upload a main logo to display on your institution’s courses. This will help learners quickly recognise your institution as they browse courses. The expected dimension is ${UPLOAD_LOGO_SQUARE_WIDTH} * ${UPLOAD_LOGO_HEIGHT} pixels and acceptable formats are .jpg, .jpeg and .png(recommended)`}
                </>
              }
              readOnly={readOnly}
              onLogoUpdated={onLogoUpdated}
            />
            <ProfileLogo
              name="logoX"
              heading="Secondary logo"
              provider={currentProvider}
              reloadProvider={reloadProvider}
              logoUrl={logoX}
              isRect
              description={
                <>
                  {`Upload a secondary logo to display on the homepage. This will be the first logo learners will see when they enter the website. Choose a logo with your institution’s full name and emblem. The expected dimension is ${UPLOAD_LOGO_RECTANGLE_WIDTH} * ${UPLOAD_LOGO_HEIGHT} pixels and acceptable formats are .jpg, .jpeg and .png(recommended)`}
                </>
              }
              readOnly={readOnly}
              onLogoUpdated={onLogoUpdated}
            />
          </Card>
        </>
      ),
    },
    {
      key: 'campuses',
      label: 'Campuses',
      children: <ProfileCampusesForm readOnly={readOnly} />,
    },
    {
      key: 'policies',
      label: 'Policies',
      children: <ProfilePoliciesForm readOnly={readOnly}/>,
    },
  ];

  const adminTab = [
    {
      key: 'settings',
      label: 'Settings',
      className: 'settings-tab',
      children: <ProfileSettingsForm />,
    },
  ];

  return (
    <React.Fragment key={rerenderKey}>
      <FormProvider {...methods}>
        <Form layout="vertical">
          {isAdmin && <BackLink text="Profile" onClick={() => changeProvider('')} />}
          <h2>{title}</h2>

          <Row key={currentProvider} gutter={40}>
            <Col span={18}>
              <Tabs defaultActiveKey="summary" items={isAdmin ? items.concat(adminTab) : items} />
            </Col>
            <Col span={6}>
              <ProfileActions
                isSaving={isSaving}
                isPreview={isPreview}
                isDisableSave={readOnly}
                onPreview={methods.handleSubmit(onSubmit(providerId, true))}
                onSave={methods.handleSubmit(onSubmit(providerId))}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </React.Fragment>
  );
};

export default Profile;
