import React, { ReactNode } from 'react';

const CustomPrevNextButtonRender = (
  page: number,
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
  originalElement: ReactNode
): React.ReactNode => {
  if (type === 'page') {
    return React.cloneElement(originalElement as React.ReactElement<any>, {
      'aria-label': `page ${page}`,
    });
  }
  if (type === 'prev') {
    return React.cloneElement(originalElement as React.ReactElement<any>, {
      'aria-label': 'previous page',
    });
  }
  if (type === 'next') {
    return React.cloneElement(originalElement as React.ReactElement<any>, {
      'aria-label': 'next page',
    });
  }
  if (type === 'jump-prev') {
    document.querySelector('span.anticon-double-left')?.setAttribute('aria-label', 'open previous 5 pages link');
    document.querySelector('span.anticon-double-left + span')?.setAttribute('aria-hidden', 'true');

    const jumpPrev = document.querySelector('#prev-5pages');

    if (!jumpPrev) {
      const prev = document.createElement('span');
      prev.setAttribute('id', 'prev-5pages');
      prev.setAttribute('aria-label', 'open previous 5 pages link');
      document.querySelector('span.anticon-double-left + span')?.parentElement?.appendChild(prev);
    }
  }
  if (type === 'jump-next') {
    document.querySelector('span.anticon-double-right')?.setAttribute('aria-label', 'open next 5 pages link');
    document.querySelector('span.anticon-double-right + span')?.setAttribute('aria-hidden', 'true');

    const jumpNext = document.querySelector('#next-5pages');

    if (!jumpNext) {
      const next = document.createElement('span');
      next.setAttribute('id', 'next-5pages');
      next.setAttribute('aria-label', 'open next 5 pages link');
      document.querySelector('span.anticon-double-right + span')?.parentElement?.appendChild(next);
    }
  }
  return originalElement;
};

export default CustomPrevNextButtonRender;
