import { Button, Card, Form, Space, Typography } from 'antd';
import { memo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IUsefulLinksSchema } from './schema/usefulLinks';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStyles } from './useStyles';
import { IGlossarySchema } from './schema/glossary';

const StaticPageGlossaryForm = () => {
  const {
    control,
    formState: { errors },
    ...rest
  } = useFormContext<IGlossarySchema>();

  const {
    fields: tableRows,
    remove: removeTableRow,
    append: appendTableRow,
    move: moveTableRow,
  } = useFieldArray<IUsefulLinksSchema>({ control, name: 'table' });

  const { classes } = useStyles();

  return (
    <Form layout="vertical">
      <GenericInput control={control} name="title" label="Title" required errors={errors['title']} {...rest} />
      <GenericInput
        control={control}
        name="tableColumn1"
        label="TableColumn1"
        errors={errors['tableColumn1']}
        required
        {...rest}
      />

      <GenericInput
        control={control}
        name="tableColumn2"
        label="TableColumn2"
        required
        errors={errors['tableColumn2']}
        {...rest}
      />

      <Typography.Title level={5}>
        Table <Typography.Text type="danger">*</Typography.Text>
      </Typography.Title>
      {tableRows.map((row, index, currentArray) => {
        return (
          <Card key={row.id} className={classes.card}>
            <Space className={classes.cardAction}>
              <Button
                size="small"
                icon={<ArrowUpOutlined />}
                disabled={index === 0}
                onClick={() => {
                  moveTableRow(index, index - 1);
                }}
              />
              <Button
                size="small"
                icon={<ArrowDownOutlined />}
                disabled={index === currentArray.length - 1}
                onClick={() => {
                  moveTableRow(index, index + 1);
                }}
              />
              <Button icon={<DeleteOutlined />} size="small" danger onClick={() => removeTableRow(index)} />
            </Space>

            <GenericInput
              control={control}
              name={`table.${index}.title`}
              required
              type="quill"
              label="Title"
              errors={errors?.table ? errors?.table[index]?.title : undefined}
              {...rest}
            />
            <GenericInput
              control={control}
              name={`table.${index}.description`}
              required
              type="quill"
              label="Description"
              errors={errors?.table ? errors.table[index]?.description : undefined}
              {...rest}
            />
          </Card>
        );
      })}
      {errors?.table?.message && (
        <>
          <br />
          <Typography.Text type="danger">{errors.table.message}</Typography.Text>
          <br />
        </>
      )}

      <br />
      <Button type="primary" onClick={() => appendTableRow({ title: '', description: '' })}>
        Add
      </Button>
    </Form>
  );
};

export default memo(StaticPageGlossaryForm);
