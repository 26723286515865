import * as Cred2API from 'api/cred2';
import ProviderAPI from 'api/provider';
import AdminAPI from 'api/admin';
import DeseAPI from './dese';

const API = {
  Cred2: Cred2API,
  Provider: ProviderAPI,
  Admin: AdminAPI,
  Dese: DeseAPI,
};

export default API;
