import * as XLSX from 'xlsx';
import { IBulkCourseCreationData } from './BulkImportCredentialModal.types';
import { parse } from 'utils/date';
import { BulkCourseCreationRawData } from './bulkCourseCreation.schema';

// Retrieve raw course data from the uploaded course Excel file
export const parseCourseDataJson = (workbook: XLSX.WorkBook): BulkCourseCreationRawData => {
  if (!workbook) return [];

  // Only need to parse the first sheet
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];

  // Loop through the cells in the worksheet
  for (const cellAddress in worksheet) {
    // Check if the cell address is valid and starts with a letter (e.g., A1, B2, C3, ...)
    if (/^[A-Z]+\d+$/.test(cellAddress)) {
      // Get the cell
      const cell = worksheet[cellAddress];

      // Check if the cell type is 'n' (numeric) and has a date format
      if (cell.t === 'n' && cell.w && ['/', '-'].some((separator) => cell.w.includes(separator))) {
        // Parse the date
        const date = parse(cell.w, 'MM/DD/YY').format('YYYY-MM-DD');
        cell.v = date;
      }
    }
  }

  const courseRawDataJson = XLSX.utils.sheet_to_json(worksheet) as BulkCourseCreationRawData;
  return courseRawDataJson;
};

// convert the column name from the Excel file into nested object.
// from example: summary/courseCode will be parsed as { summary: { courseCode: <value> } }
export const parseCourseRawDataJson = (items: BulkCourseCreationRawData) =>
  items.map((item) => {
    const result: any = {};
    for (const key in item) {
      const keyParts = key.split('/');
      let currentLevel = result;

      for (let i = 0; i < keyParts.length; i++) {
        const part = keyParts[i];

        if (i === keyParts.length - 1) {
          if (part.match(/^\d+$/)) {
            if (!Array.isArray(currentLevel)) {
              currentLevel = [];
            }
            currentLevel[parseInt(part)] = (item as any)[key];
          } else {
            currentLevel[part] = (item as any)[key];
          }
        } else {
          if (!currentLevel.hasOwnProperty(part)) {
            currentLevel[part] = isNaN(Number(keyParts[i + 1])) ? {} : [];
          }
          currentLevel = currentLevel[part];
        }
      }
    }

    return result;
  });
