import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { getDistinctValues } from "utils/array";
import { capitalise } from "utils/string";
import useListFilter from "../hooks/useListFilter";
import DropDownFilter from "./DropDownFilter";
import Tag from "./Tag";

interface ISelectOption {
  key: string;
  label: string;
}

interface IFilter {
  filterItems?: string[];
  filterProp: string;
  filterPlaceholder: string;
  filterConditionFn?: (
    selectedOption: string,
    propValue: any,
    item: any
  ) => boolean;
  transformDistinct?: (keys: string[], propValue: any, item: any) => string[];
  transformItemLabel?: (str: string) => string;
}

function applySingleFilter(
  item: any,
  filter: IFilter,
  selectedMultipleFilterOptions: { [k: string]: any[] }
) {
  const selectedFilterOptions =
    selectedMultipleFilterOptions[filter.filterProp];
  if (selectedFilterOptions && selectedFilterOptions.length) {
    return selectedFilterOptions.some((fs) => {
      if (filter.filterConditionFn) {
        return filter.filterConditionFn(fs.key, item[filter.filterProp], item);
      }
      return fs.key === item[filter.filterProp];
    });
  }
  return true;
}

enum EFilterLogicOperator {
  AND = "AND",
  OR = "OR",
}

interface IListFilterProps {
  list: any[];
  placeholder?: string;
  searchFilterProps: string[];
  filters?: IFilter[];
  multipleFilterLogicOperator?: EFilterLogicOperator;
  onFilter: (returnList: any[], filterText?: string) => void;

  filterInputProps?: any;
}

export const ListFilter = ({
  list,
  placeholder,
  searchFilterProps,
  // This "filter" props is not even used anywhere.
  // So it's not a good idea to trying define it's type
  filters = [],
  multipleFilterLogicOperator = EFilterLogicOperator.AND,
  onFilter,
  filterInputProps,
}: IListFilterProps) => {
  const [selectedMultipleFilterOptions, setSelectedMultipleFilterOptions] =
    useState<{ [k: string]: any[] }>({});

  const { filteredList, setFilterText, filterText } = useListFilter(
    list,
    searchFilterProps
  );

  const multipleDistinctFilterItems = useMemo(() => {
    return filters?.reduce((map: { [k: string]: any[] }, filter) => {
      const values =
        filter.filterItems ??
        getDistinctValues<string>(
          list,
          filter.filterProp,
          filter.transformDistinct
        );
      map[filter.filterProp] = values.map((v: string) => {
        return {
          key: v,
          label: filter.transformItemLabel?.(v) ?? capitalise(v),
        };
      });
      return map;
    }, {});
  }, [list]);

  const handleMultipleDropDownFilterChanged = (
    filterProp: string,
    selectedOptions: ISelectOption[]
  ) => {
    const newSelectedMultipleFilterOptions = {
      ...selectedMultipleFilterOptions,
    };
    newSelectedMultipleFilterOptions[filterProp] = selectedOptions;
    setSelectedMultipleFilterOptions(newSelectedMultipleFilterOptions);
  };

  useEffect(() => {
    let list;
    if (filters.length > 0) {
      list = filteredList.filter((l: any) => {
        if (
          selectedMultipleFilterOptions &&
          Object.keys(selectedMultipleFilterOptions).length
        ) {
          if (multipleFilterLogicOperator === EFilterLogicOperator.AND) {
            return filters.every((filter) =>
              applySingleFilter(l, filter, selectedMultipleFilterOptions)
            );
          } else if (multipleFilterLogicOperator === EFilterLogicOperator.OR) {
            return filters.some((filter) =>
              applySingleFilter(l, filter, selectedMultipleFilterOptions)
            );
          }
          return true;
        }
        return true;
      });
    } else {
      list = filteredList;
    }

    onFilter(list, filterText);
  }, [selectedMultipleFilterOptions, filteredList]);

  const removeMultipleTypeFilterItem = (filterProp: string, item: string) => {
    const newSelectedMultipleFilterOptions = {
      ...selectedMultipleFilterOptions,
    };
    newSelectedMultipleFilterOptions[filterProp] =
      newSelectedMultipleFilterOptions[filterProp].filter(
        (ft: ISelectOption) => ft.key !== item
      );
    setSelectedMultipleFilterOptions(newSelectedMultipleFilterOptions);
  };

  return (
    <>
      <Input.Group compact>
        <Input
          placeholder={placeholder}
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
          addonAfter={<SearchOutlined />}
          style={{ width: "50%" }}
          allowClear
          {...filterInputProps}
        />
        {filters.length > 0 && (
          <>
            {filters.map((filter) => (
              <DropDownFilter<any>
                key={`filter-${filter.filterProp}`}
                selectedValues={selectedMultipleFilterOptions[
                  filter.filterProp
                ]?.map((ft: ISelectOption) => ft.key)}
                options={multipleDistinctFilterItems[filter.filterProp] ?? []}
                keyName="key"
                labelName="label"
                returnObject
                onChange={(options: ISelectOption[]) =>
                  handleMultipleDropDownFilterChanged(
                    filter.filterProp,
                    options
                  )
                }
                placeholder={filter.filterPlaceholder || "Filter"}
              />
            ))}
          </>
        )}
      </Input.Group>

      <Row style={{ marginTop: 24 }}>
        {filters.length > 0 && (
          <Col>
            {filters.map((filter) => (
              <Row key={filter.filterProp} className="mb-2">
                <Space>
                  {selectedMultipleFilterOptions[filter.filterProp]?.map(
                    (ft: ISelectOption) => (
                      <Tag
                        key={ft.key}
                        label={ft.label}
                        value={ft.key}
                        closable
                        onClose={() =>
                          removeMultipleTypeFilterItem(
                            filter.filterProp,
                            ft.key
                          )
                        }
                      />
                    )
                  )}
                </Space>
              </Row>
            ))}
          </Col>
        )}
      </Row>
    </>
  );
};

export default ListFilter;
