import { handleAPIPromise } from './utils';
import axios from './_utils/axiosInstance';
import { COURSE_ACTION, COURSE_STATUS } from 'model/course';

export interface ICourseDetails {
  courseCode?: string;
  status: COURSE_STATUS;
  data: object;
  publishedUrl?: string;
  published?: boolean;
  history?: ICourseHistory[];
  comments?: ICourseComment[];
}

export interface ICourseHistory {
  action: string;
  user?: string;
  createdAt: number;
}

export interface ICourseComment {
  type: 'RejectReason' | string;
  comment: string;
  createdAt?: number;
}

export type ICourseInfo = Omit<ICourseDetails, 'data'>;

export const create = async (providerId: string, formData: any) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2`, formData));
};

export const update = async (providerId: string, credId: string, formData: any) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}`, formData));
};

export const get = async (providerId: string, credId: string) => {
  return await handleAPIPromise<ICourseDetails>(axios.get(`/provider/${providerId}/cred2/${credId}`));
};

export const list = async (providerId: string) => {
  return await handleAPIPromise(axios.get(`/provider/${providerId}/cred2`));
};

export const listAll = async () => {
  return await handleAPIPromise(axios.get(`/admin/cred`));
};

export const unpublish = async (providerId: string, courseId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${courseId}/Unpublish`, {}));
};

export const republish = async (providerId: string, courseId: string, props?: { force: boolean }) => {
  return await handleAPIPromise(
    axios.post(`/provider/${providerId}/cred2/${courseId}/RePublish`, {
      RePublish: props,
    })
  );
};

export const deleteDraft = async (providerId: string, courseId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${courseId}/Delete`, {}));
};

export const cancelEdit = async (providerId: string, courseId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${courseId}/Cancel`, {}));
};

export const edit = async (providerId: string, courseId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${courseId}/Edit`, {}));
};

export const generatePreviewUrl = async (providerId: string, credId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}/Preview`, {}));
};

export const action = async (providerId: string, credId: string, action: COURSE_ACTION) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}/${action}`, {}));
};

export const reject = async (providerId: string, credId: string, reason: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}/Reject`, { Reject: { reason } }));
};

export const updateCourseCode = async (providerId: string, credId: string, courseCode: string) => {
  return await handleAPIPromise(
    axios.post(`/provider/${providerId}/cred2/${credId}/UpdateCourseCode`, { UpdateCourseCode: { courseCode } })
  );
};

export const getSchema = async () => {
  return await handleAPIPromise(axios.get(`/provider/cred2/schema`));
};

export const lock = async (providerId: string, credId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}/lock`, {}));
};

export const unlock = async (providerId: string, credId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/cred2/${credId}/unlock`, {}));
};

export const generateProfilePreviewUrl = async (providerId: string) => {
  return await handleAPIPromise(axios.post(`/provider/${providerId}/preview`));
};
