import {  ReactNode } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { simpleUniqueId } from 'utils/string';
import useSafeState from 'ahooks/lib/useSafeState';

interface IAction {
  label: string;
  icon?: ReactNode;
  handler: () => void;
  danger?: boolean;
  disabled?: boolean;
}

export enum MENU_TYPES {
  MENU = 'menu',
  ELLIPSIS = 'ellipsis',
}

interface IActionMenuProps {
  id?: string;
  text?: string;
  actions: Array<IAction>;
  type?: 'menu' | 'ellipsis';
  showChevronDown?: boolean;
  ariaLabel?: string;

  className?: string;
  buttonClassName?: string;
}

const ActionMenu = ({
  actions = [],
  id = simpleUniqueId(),
  text = '...',
  type = MENU_TYPES.MENU,
  ariaLabel,
  className,
  buttonClassName,
}: IActionMenuProps) => {
  const [menuOpen, setMenuOpen] = useSafeState(false);

  const menuId = `actionMenu-${id}`;
  const menuContainerId = `actionMenuContainer-${id}`;
  
  const items: MenuProps['items'] = actions.map((a, i) => ({
    key: i,
    label: (
      <Button
        type="text"
        icon={a.icon}
        danger={a.danger}
        style={{ display: 'flex', alignItems: 'center' }}
        disabled={a.disabled}
        onClick={() => {
          setMenuOpen(false)
          a.handler()
        }}
      >
        <span className="text--black" style={{ marginLeft: 5 }}>
          {a.label}
        </span>
      </Button>
    ),
  }));

  if (!actions?.length) {
    return null;
  }

  return (
    <div id={menuContainerId} className={className}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottomRight"
        open={menuOpen}
        onOpenChange={(open) => setMenuOpen(open)}
        getPopupContainer={() => {
          // pop up in same container for keyboard accessibility
          return document.getElementById(menuContainerId) as HTMLElement;
        }}
      >
        <Button
          type={type === MENU_TYPES.ELLIPSIS ? 'text' : 'primary'}
          aria-haspopup="true"
          aria-expanded={menuOpen}
          aria-label={ariaLabel || text}
          aria-controls={menuId}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            // prevent button click from expanding rows on tables
            e.stopPropagation();
          }}
          className={buttonClassName}
        >
          {text}
          {type === MENU_TYPES.MENU && <DownOutlined />}
        </Button>
      </Dropdown>
    </div>
  );
};

export default ActionMenu;
