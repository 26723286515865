import { useState, useMemo, useCallback } from "react";
import { Course } from "types";
import { filterList } from "utils/array";

const useListFilter = (
  list: Array<Course>,
  searchFields: Array<string>,
  debounceTimeout = 300
) => {
  const [filterText, setFilterTextInternal] = useState<string>("");

  const setFilterText = useCallback(
    (filterText: string) =>
      setTimeout(() => {
        setFilterTextInternal(filterText);
      }, debounceTimeout),
    []
  );

  const filteredList = useMemo(() => {
    return filterList(list, filterText, searchFields);
  }, [filterText, list]);

  return { filterText, setFilterText, filteredList };
};

export default useListFilter;
