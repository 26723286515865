import { Button, Card } from 'antd';
import { makeStyles } from 'utils/theme';
import { PreviewButton } from '../course/summaryCard/summaryActions/components/buttons';

type Props = {
  isSaving: boolean;
  isPreview: boolean;
  isDisabled?: boolean;
  isDisableSave?: boolean;
  onPreview(): void;
  onSave(): void;
};

const useStyles = makeStyles()(() => ({
  actionsContainer: {
    width: '100%',
    padding: 24,
  },

  previewButtonContainer: {
    width: '100%',
    marginTop: 24,

    button: {
      width: '100%',
    },
  },

  'profile-history.ant-card-grid': {
    width: '100%',

    h5: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 22,
      marginBottom: 16,
    },

    ul: {
      listStyleType: 'none',
      paddingLeft: 0,
      margin: 0,

      li: {
        span: {
          color: '#6d7175',
        },
      },
    },
  },
}));

const ProfileActions = ({ onPreview, isSaving, isPreview, onSave, isDisabled = false, isDisableSave = false }: Props) => {
  const { classes } = useStyles();

  return (
    <Card>
      <div className={classes.actionsContainer}>
        <Button type="primary" block size="large" loading={!isPreview && isSaving} onClick={onSave} disabled={isDisabled || isDisableSave}>
          Save
        </Button>
        <div className={classes.previewButtonContainer}>
          <PreviewButton pathname="" loading={isPreview && isSaving} onPreview={onPreview} disabled={isDisabled} />
        </div>
      </div>
    </Card>
  );
};

export default ProfileActions;
