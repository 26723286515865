import { useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { Auth } from 'aws-amplify';

import './authComponent.less';
import { useContext } from 'react';
import { GlobalContext, MessageType } from 'contexts/GlobalContext';
import AlertMessageBox from './AlertMessageBox';
import { AuthMessage } from './AuthMessageContants';
import { useNavigate } from 'react-router-dom';
import { IUserForm } from 'model/auth';
import { useSafeState } from 'ahooks';

const { Title } = Typography;

const CompleteNewPasswordForm = () => {
  const [loading, setLoading] = useSafeState(false);
  const { message, setMessage, temporaryUserForNewPassword, setTemporaryUserForNewPassword } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    const backToLogin = () => {
      if (!temporaryUserForNewPassword) {
        navigate('/');
      }
    };
    backToLogin();
  }, [temporaryUserForNewPassword, navigate]);

  const onFormSubmit = ({ password }: Omit<IUserForm, 'username'>) => {
    setLoading(true);

    Auth.completeNewPassword(temporaryUserForNewPassword, password)
      .then(() => {
        setMessage({ type: MessageType.SUCCESS, content: AuthMessage.CHANGE_PASSWORD_SUBMIT_SUCCESS });
        setTemporaryUserForNewPassword(undefined);
        navigate('/');
      })
      .catch((err) => {
        setMessage({ type: MessageType.ERROR, content: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-container__card">
      <Title className="title-signin" level={2}>
        Change your password
      </Title>

      <Form layout="vertical" initialValues={{ password: '' }} onFinish={onFormSubmit}>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Password is required.' },
            { min: 8, message: 'Password must be at least 8 characters.' },
            { max: 256, message: 'Password must be no longer than 256 characters.' },
            {
              pattern: /[A-Z]+/,
              message: 'Password must contain uppercase letters',
            },
            {
              pattern: /[a-z]+/,
              message: 'Password must contain lowercase letters',
            },
            {
              pattern: /[0-9]+/,
              message: 'Password must contain number',
            },
            {
              pattern: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+/g,
              message: 'Password must contain special characters',
            },
          ]}
        >
          <Input.Password className="border-radius-auth" size="large" />
        </Form.Item>

        <AlertMessageBox message={message} closable style={{ marginBottom: '30px' }} />

        <Form.Item>
          <Button className="border-radius-auth" size="large" type="primary" htmlType="submit" block loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompleteNewPasswordForm;
