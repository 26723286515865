import { useSafeState } from 'ahooks';
import { Spin } from 'antd';
import DashboardEnquiryList, { IEnquirySummaryProps } from 'components/provider/dashboard/DashboardEnquiryList';
import { UserContext } from 'components/UserContextProvider';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../api';
import ProviderAPI from '../../../api/provider';
import { IDashboard, IProviderDashboardInfo } from '../../../api/admin';
import DashboardCourseList from './DashboardCourseList';
import Overview from './Overview';
import ProviderList from './ProviderList';
import { formatEnquiryDate } from 'utils/date';

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { currentProvider } = useContext(UserContext);

  const [data, setData] = useSafeState<IDashboard | undefined>();
  const [enquiryData, setEnquiryData] = useSafeState<IEnquirySummaryProps | undefined>();
  const [activeTabKey, setActiveTabKey] = useSafeState<string>();

  const fetchEnquiryData = async () => {
    const [[generalEnquiryData, fetchGeneralEnquiryError], [courseEnquiryData, fetchCourseEnquiryError]] =
      await Promise.all([
        ProviderAPI.generalEnquirySummary(currentProvider, {
          date: formatEnquiryDate(Date.now()),
        }),
        ProviderAPI.courseEnquirySummary(currentProvider, {
          date: formatEnquiryDate(Date.now()),
          publishedOnly: true,
        }),
      ]);

    if (!fetchGeneralEnquiryError && !fetchCourseEnquiryError) {
      setEnquiryData({
        general: generalEnquiryData,
        credential: courseEnquiryData,
      });
    }
  };

  useEffect(() => {
    const resetLocation = () => {
      if (!location.pathname.endsWith('/admin')) {
        navigate('/admin');
      }
    };
    resetLocation();
  }, [location.pathname, navigate]);

  useEffect(() => {
    API.Admin.dashboard().then(([data, error]) => {
       
      if (data && data.providerDashboardInfos.length > 0) {
        const dashboardInfos = data?.providerDashboardInfos.sort((current, next) => current.providerName.toUpperCase() > next.providerName.toUpperCase() ? 1 : -1) as IProviderDashboardInfo[]
        (data.providerDashboardInfos as IProviderDashboardInfo[]) = dashboardInfos
      }
      
      setData(data);
    });

    const provider = currentProvider;
    if (provider) {
      fetchEnquiryData();
    }

    return () => {
      setData(undefined);
    };
  }, [currentProvider]);

  if (!data) {
    return (
      <>
        <Spin />
      </>
    );
  }

  const providerDashboardInfo = currentProvider
    // TODO: Refactoring any
    ? data.providerDashboardInfos?.find((it: any) => it.providerId === currentProvider)
    : undefined;

  return (
    <>
      <h2>Dashboard</h2>
      <Overview
        draftCount={providerDashboardInfo?.draftCount ?? data.totalDraftCount}
        publishedCount={providerDashboardInfo?.publishedCount ?? data.totalPublishedCount}
        reviewingCount={providerDashboardInfo?.reviewingCount ?? data.totalReviewingCount}
      />
      {currentProvider && (
        <>
          <br></br>
          <DashboardEnquiryList general={enquiryData?.general} credential={enquiryData?.credential} />
        </>
      )}
      <br></br>
      {providerDashboardInfo && (
        <DashboardCourseList
          key={`provider-course-list-${providerDashboardInfo.providerId}`}
          activeTabKey={activeTabKey}
          onTabChange={setActiveTabKey}
          dashboardInfo={providerDashboardInfo}
        />
      )}
      {!providerDashboardInfo && <ProviderList list={data.providerDashboardInfos} setActiveTabKey={setActiveTabKey} />}
      <br></br>
    </>
  );
};

export default Dashboard;
