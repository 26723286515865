export interface ActionAlertMessageType {
  [key: string]: {
    message: "string";
    type?: "warning" | "info" | "success" | "error" | undefined;
  };
}

export const ACTION_ALERTS_MESSAGES = {
  New: {
    "message": "Complete all required fields to submit for review and publishing.",
    "type": "warning",
  },
  Saved: {
    "message": "You’ve completed all required fields! When you’re ready, submit for review and publishing.",
    "type": "info"
  },
  Review: {
    "message": "This microcredential is being reviewed. When approved, it will be published automatically.",
    "type": "info",
  },
  Approved: {
    "message": "Do you want to make changes or publish? Click below to enable editing.",
    "type": "info",
  }
}
