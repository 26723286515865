import { Menu } from 'antd';
import { UserContext } from 'components/UserContextProvider';
import { MenuItem } from 'model/ui';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserRole } from 'utils/UserUtils';

let menuItems: MenuItem[] = [
  {
    key: 'provider-dashboard',
    label: (
      <Link to="/provider" data-cy="left-menu-dashboard">
        Dashboard
      </Link>
    ),
  },
  {
    key: 'provider-courses',
    label: (
      <Link to="/provider/courses" data-cy="left-menu-courses">
        Microcredentials
      </Link>
    ),
  },
  {
    key: 'provider-profile',
    label: (
      <Link to="/provider/profile" data-cy="left-menu-profile">
        Profile
      </Link>
    ),
  },
];

const LeftMenu = () => {
  const { role } = useContext(UserContext);
  const location = useLocation();

  if (role === UserRole.PROVIDER_ADMIN) {
    if (!menuItems.find((item) => item.key === `provider-${role}-users`)) {
      menuItems.push({
        key: `provider-${role}-users`,
        label: <Link to="/provider/users">Users</Link>,
      });
    }
  } else {
    // If a user is not an admin, he shouldn't see the Users tab in the left menu
    menuItems = menuItems.filter((value) => value.key !== `provider-${UserRole.PROVIDER_ADMIN}-users`)
  }

  const pathUrl = location.pathname.replace('/provider/', 'provider-').split('/')[0];

  const selectedKeys = menuItems
    .map(({ key }) => key)
    .filter((o) => {
      if (!pathUrl) {
        return o === 'provider-dashboard';
      }

      if (pathUrl === 'provider-users') {
        return o === `provider-${role}-users`;
      }

      if (o) {
        return pathUrl === o;
      }
      return !pathUrl;
    });

  return (
    <>
      <div className="logo-container">
        <img src="/images/logo-white.svg" alt="provider-portal-logo" />
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} items={menuItems} />
    </>
  );
};

export default LeftMenu;
