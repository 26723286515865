import { memo } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { ICredentialSchema } from './schema';
import GenericInput from 'components/form/shared/GenericInput';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useStyles } from './common/useStyles';
import { fromKebabCase } from 'utils/string';
import GenericErrorMessage from 'components/form/shared/GenericErrorMessage';
import CredFormProps from './common/formProps';
import useUpdateDirtyState from 'hooks/useUpdateDirtyState';

enum ConditionType {
  ENTRY_CRITERIA = 'ENTRY-CRITERIA',
  PREREQUISITE = 'PREREQUISITE',
  INHERENT_REQ = 'INHERENT-REQUIREMENT',
  GUIDANCE_NOTE = 'GUIDANCE-NOTE',
  OTHER = 'OTHER',
}

const requirementOptions = Object.values(ConditionType).map((s) => ({ label: fromKebabCase(s), value: s }));

const CourseRequirementsForm = ({ isFormReadOnly }: CredFormProps) => {
  const { handleIsDirtyState: setIsDirty } = useUpdateDirtyState('4');

  const { classes } = useStyles();

  const {
    control,
    formState: { errors },
    getValues,
    ...formFields
  } = useFormContext<ICredentialSchema>();

  const handleFieldDirty = () => setIsDirty(true);

  const rest = {
    ...formFields,
    disabled: isFormReadOnly,
  };

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'conditions.0.conditions',
  });

  return (
    <Card>
      <div>
        <h1>Course requirements</h1>
      </div>
      {fields.length > 0 && (
        <Card>
          <div>
            <p>Select a requirement type</p>
          </div>
          {fields.map((condition, index) => {
            return (
              <Card key={condition.id} className={classes.credCard}>
                <Space />
                <div
                  style={{
                    flexGrow: '1',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    disabled={fields.length === 1 || index === 0 || rest.disabled}
                    onClick={() => {
                      move(index, index - 1);
                      handleFieldDirty();
                    }}
                  />
                  <Button
                    size="small"
                    icon={<ArrowDownOutlined />}
                    disabled={fields.length === 1 || index === fields.length - 1 || rest.disabled}
                    onClick={() => {
                      move(index, index + 1);
                      handleFieldDirty();
                    }}
                  />
                  <Button
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      remove(index);
                      handleFieldDirty();
                    }}
                    disabled={rest.disabled}
                  />
                </div>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      key={condition.id}
                      label="Requirement type"
                      name={`conditions.0.conditions[${index}].type` as const}
                      control={control}
                      options={requirementOptions}
                      required
                      errors={
                        (errors ? errors?.conditions?.[0]?.conditions?.[index]?.type : undefined) as
                          | FieldError
                          | undefined
                      }
                      type="select"
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GenericInput
                      type="quill"
                      key={condition.description}
                      label="Requirement description"
                      name={`conditions.0.conditions[${index}].description` as const}
                      errors={
                        (errors ? errors?.conditions?.[0]?.conditions?.[index]?.description : undefined) as
                          | FieldError
                          | undefined
                      }
                      control={control}
                      required
                      showCount
                      maxLength={2000}
                      handleFieldDirty={handleFieldDirty}
                      {...rest}
                    />
                  </Col>
                </Row>
              </Card>
            );
          })}
          {errors?.conditionsError?.message && <GenericErrorMessage message={errors?.conditionsError?.message} />}
        </Card>
      )}
      <br />
      {fields.length < 5 && (
        <Button
          onClick={() => {
            append({ description: '', type: '' });
            handleFieldDirty();
          }}
          disabled={rest.disabled}
        >
          Add <PlusOutlined />
        </Button>
      )}
    </Card>
  );
};

export default memo(CourseRequirementsForm);
