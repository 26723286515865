import { assessmentsSchema } from './assessments'; // done
import { creditsSchema } from './credits';
import { overviewSchema } from './overview';
import { baseSummarySchema, discountSchema, summarySchema } from './summary';
import { teamSchema } from './team';
import { qualitySkillsSchema } from './quality-skills';
import { enrolmentSchema } from './sessions';
import { conditionsSchema } from './conditions';
import { relatedCredentialsSchema } from './relatedCredentials';
import { z } from 'zod';
import { IProfileSchema } from 'components/provider/profile/form/schema';
import { lockingSchema } from './locking';

export const credentialSchemaLookup: Record<string, z.AnyZodObject | z.ZodEffects<any> | z.ZodIntersection<z.ZodTypeAny, z.ZodTypeAny>> = {
  '1': summarySchema,
  '2': overviewSchema,
  '3': assessmentsSchema,
  '4': conditionsSchema,
  '5': creditsSchema,
  '6': teamSchema,
  '7': qualitySkillsSchema,
  '8': enrolmentSchema,
  '9': relatedCredentialsSchema,
  '10': lockingSchema
};

const baseCredentialSchema = assessmentsSchema
  .merge(baseSummarySchema)
  .merge(creditsSchema.innerType())
  .merge(overviewSchema)
  .merge(teamSchema)
  .merge(qualitySkillsSchema)
  .merge(conditionsSchema.innerType())
  .merge(enrolmentSchema)
  .merge(relatedCredentialsSchema)
  .merge(lockingSchema)

// MCREDMKTP-1623 - Work around to fix discount description validation issue
export const credentialSchema = z.intersection(baseCredentialSchema, discountSchema)

export type ICredentialSchema = z.infer<typeof credentialSchema>;

export type ICreateCredentialSchema = z.infer<typeof summarySchema>;

export type ICourseFormSchema = Partial<ICredentialSchema> & { campuses: IProfileSchema['campuses'] };
