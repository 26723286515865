import { Button, Input, Typography, Form } from 'antd';
import API from 'api';
import { GlobalContext, MessageType } from 'contexts/GlobalContext';
import { useContext, useState } from 'react';
import validator from 'validator';
import AlertMessageBox from './AlertMessageBox';
import { AuthMessage } from './AuthMessageContants';
import { useNavigate } from 'react-router-dom';

import './authComponent.less';
import { IUserForm } from 'model/auth';

const { Title } = Typography;

const RequestResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const { message, setMessage } = useContext(GlobalContext);
  const navigate = useNavigate();

  const onBackToSignIn = () => {
    navigate('/');
  };

  const onFormSubmit = ({ username }: Omit<IUserForm, 'password'>) => {
    setLoading(true);

    API.Provider.forgotPassword(username)
      .then(([, error]) => {
        if (error) throw error;
        setMessage({ type: MessageType.SUCCESS, content: AuthMessage.FORGOT_PASSWORD_SUBMIT_SUCCESS });
      })
      .catch((err) => {
        if (!!err.message?.includes('account is disabled.')) {
          setMessage({ type: MessageType.ERROR, content: err.message });
        } else {
          setMessage({
            type: MessageType.INFO,
            content: 'If the address is registered with us, you will receive an email with your temporary password.',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-container__card">
      <Title className="title-signin" level={2}>
        Forgot your password?
      </Title>
      <Form layout="vertical" initialValues={{ username: '' }} onFinish={onFormSubmit}>
        <Form.Item
          label="Email address"
          name="username"
          rules={[
            {
              message: 'Please enter valid email address.',
              validator: (_, value) => {
                if (validator.isEmail(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Please enter valid email address.');
                }
              },
            },
          ]}
        >
          <Input className="border-radius-auth" size="large" type="text" required />
        </Form.Item>

        <AlertMessageBox message={message} closable style={{ marginBottom: '30px' }} />

        <Form.Item shouldUpdate>
          {(form) => (
            <Button
              className="border-radius-auth"
              size="large"
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              data-cy="request-temp-pass-button"
              disabled={
                !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            >
              Request temporary password
            </Button>
          )}
        </Form.Item>
      </Form>

      <div className="bottom-reset-password">
        <Button type="link" onClick={onBackToSignIn}>
          Back to login
        </Button>
      </div>
    </div>
  );
};

export default RequestResetPassword;
