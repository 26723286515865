import { z } from 'zod';

// zod schema

export const campusesSchema = z.object({
  campuses: z
    .array(
      z.object({
        name: z.string().nonempty({ message: "Required" }).max(100),
        location: z
          .object({
            addressLine1: z.string().nonempty({ message: "Required" }).max(100),
            addressLine2: z.string().max(100).optional(),
            suburb: z.string().nonempty({ message: "Required" }).max(100),
            state: z.union([z.enum(['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']), z.string().nonempty({ message: 'Required' })]),
            postCode: z.string().nonempty({ message: "Required" }).regex(new RegExp('^[0-9]+$'), 'Accepts only numbers').max(10),
            latitude: z
              .union([
                z
                  .string()
                  .regex(new RegExp('^(\\+|-)?((?:[0-9]*)(?:(?:\\.[0-9]*)?))$'), 'Allowed characters are 0-9 + - .')
                  .max(100),
                z.null(),
              ])
              .optional(),
            longitude: z
              .union([
                z
                  .string()
                  .regex(new RegExp('^(\\+|-)?((?:[0-9]*)(?:(?:\\.[0-9]*)?))$'), 'Allowed characters are 0-9 + - .')
                  .max(100),
                z.null(),
              ])
              .optional(),
          })
          .strict()
          .optional(),
      })
    )
    .optional(),
});

export type CampusesSchema = z.infer<typeof campusesSchema>;
