import { Button, message, Row, Space } from 'antd';
import { ConfirmModal } from 'components/ui/modals';
import { ReactFragment, useEffect, useState } from 'react';
import ChangeLogoModal from './ChangeLogoModal';
import Logo from './Logo';

interface ILogoEditableProps {
  readOnly: boolean;
  logoUrl?: string | undefined;
  uploadLogo: (base64File: string) => Promise<[any | null | undefined, any | undefined]>;
  onUpdate: (data: any) => void;
  uploadText?: string;
  altText?: string;
  refreshId?: string;
  disabled?: boolean;
  modalTitle?: string;
  modalOkText?: string;
  removalConfirmationContent?: ReactFragment;
  removalModalTitle?: string;
  removalText?: string;
  removeLogo?: () => Promise<[any | null | undefined, any | undefined]>;
  onRemove?: () => void;
  isRect?: boolean;
}

export const LogoEditable = ({
  logoUrl,
  uploadLogo,
  onUpdate,
  uploadText = 'Change...',
  altText,
  refreshId,
  disabled = false,
  modalTitle,
  modalOkText,
  removalConfirmationContent,
  removalModalTitle = 'Remove logo',
  removalText = 'Remove...',
  removeLogo,
  onRemove,
  isRect,
  readOnly
}: ILogoEditableProps) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const [logoSrc, setLogoSrc] = useState<string | null>();

  const refreshImage = () => {
    // force reload image
    const imgSrc = logoUrl && logoUrl.trim() !== '' && `${logoUrl}?t=${new Date().getTime()}`;

    if (imgSrc) {
      setLogoSrc(imgSrc);
    } else {
      setLogoSrc(null);
    }
  };

  useEffect(() => {
    refreshImage();
  }, [logoUrl]);

  useEffect(() => {
    // a new image may have replaced the old one with the same url.
    // this prevents the logoUrl effect from triggering.
    // Update the refeshId to trigger an image refresh.
    refreshImage();
  }, [refreshId]);

  const handleLogoUpdated = (res: any) => {
    setShowUploadModal(false);
    onUpdate(res);
  };

  const logo = (
    <Logo
      src={logoSrc}
      size={64}
      widthRatio={isRect ? 1.5 : 1}
      altText={altText || 'Logo'}
      className="object-cover max-h-full"
      padding="0px"
    />
  );

  const handleRemove = async () => {
    if (removeLogo) {
      setRemoving(true);
      const [, error] = await removeLogo();
      setRemoving(false);

      if (error) {
        message.error('Cannot remove image ' + error);
        return;
      }
      message.success('Logo removed successfully.');
      onRemove?.();
      setShowRemoveModal(false);
    }
  };

  return (
    <div>
      <Row align="middle" style={{ marginBottom: '24px' }}>
        {disabled && logo}
        {!disabled && (
          <>
            <Space>
              {logo}
              <Button onClick={() => setShowUploadModal(true)} disabled={readOnly}>
                {uploadText}
              </Button>
              {removeLogo != null && logoSrc && (
                <Button onClick={() => setShowRemoveModal(true)} disabled={readOnly}>
                  {removalText}
                </Button>
              )}
            </Space>
            <ChangeLogoModal
              isRect={isRect}
              title={modalTitle}
              okText={modalOkText}
              showModal={showUploadModal}
              onCancel={() => setShowUploadModal(false)}
              onSave={uploadLogo}
              onSaved={handleLogoUpdated}
            />
            <ConfirmModal
              title={removalModalTitle}
              isModalVisible={showRemoveModal}
              confirmText="Remove"
              onConfirm={handleRemove}
              onCancel={() => setShowRemoveModal(false)}
              submitButtonProps={{
                danger: true,
              }}
              confirmLoading={removing}
            >
              {removalConfirmationContent}
            </ConfirmModal>
          </>
        )}
      </Row>
    </div>
  );
};

export default LogoEditable;
