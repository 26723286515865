export enum TAB_HEADING_STATUS {
  ERROR = '#E82121', // red
  WARNING = '#FAAD14', // yellow
  NONE = '',
}

interface ITabHeadingWithStatusProps {
  text: string;
  status?: TAB_HEADING_STATUS;
}
const TabHeadingWithStatus = ({ text, status }: ITabHeadingWithStatusProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div>{text}</div>
      {status && (
        <div
          style={{
            marginLeft: 4,
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            background: status,
          }}
        />
      )}
    </div>
  );
};

export default TabHeadingWithStatus;
