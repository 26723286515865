import { useSafeState } from 'ahooks';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ConfirmModal } from 'components/ui/modals';
import { IUserWithProvider } from 'model/user';
import { UserRole } from 'utils/UserUtils';

interface IEditRoleModalProps {
  role: string;
  openModal: boolean;
  selectedUser: IUserWithProvider;
  selectedOption: DefaultOptionType;
  setSelectedOption: (value: React.SetStateAction<DefaultOptionType>) => void;
  onClose: () => void;
  onUpdate: () => void;
  errorMessage?: string;
  isProcessing?: boolean;
}

export const EditRoleModal = ({
  openModal,
  selectedUser,
  selectedOption,
  setSelectedOption,
  onClose,
  onUpdate,
  role,
  errorMessage,
  isProcessing,
}: IEditRoleModalProps) => {
  const [touched, setTouched] = useSafeState(false);
  const [valueChanged, setValueChanged] = useSafeState(false);
  return (
    <ConfirmModal
      title="Edit role"
      onCancel={onClose}
      isModalVisible={openModal}
      onConfirm={onUpdate}
      confirmText="Save"
      submitButtonProps={{ loading: isProcessing, disabled: !(touched && valueChanged) }}
    >
      <>
        <p>
          Select a role for <strong>{selectedUser.name}</strong>
        </p>
        <span>Role</span>
        <Select
          style={{ width: '100%' }}
          value={selectedOption}
          onChange={(_, option: DefaultOptionType | DefaultOptionType[]) => {
            const currentOption = option as DefaultOptionType;
            if (currentOption.children !== selectedOption.value) {
              setValueChanged(true);
              setSelectedOption(currentOption);
            } else {
              setValueChanged(false);
            }
            setTouched(true);
          }}
          status={errorMessage && 'error'}
        >
          {role === UserRole.ADMIN && (
            <>
              <Select.Option value={UserRole.ADMIN}>UAC Admin</Select.Option>
              <Select.Option value={UserRole.DESE_EDITOR}>DESE Editor</Select.Option>
            </>
          )}
          <Select.Option value={UserRole.PROVIDER_ADMIN}>Provider Admin</Select.Option>
          <Select.Option value={UserRole.PROVIDER_EDITOR}>Provider Editor</Select.Option>
        </Select>
        {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
      </>
    </ConfirmModal>
  );
};
