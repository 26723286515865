import { useContext } from 'react';
import { Col, Form, Modal, Row, notification } from 'antd';
import API from 'api';
import {
  createProviderProfileSchema,
  CreateProviderProfileSchema,
} from 'components/provider/profile/form/schema/overview';
import { UserContext } from 'components/UserContextProvider';
import GenericInput from 'components/form/shared/GenericInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { StateOptions } from 'components/provider/profile/form/common/definitions';
import { enumToArray } from 'components/form/shared/FormHelpers';
import { ProviderType } from 'enums';
import { makeStyles } from 'utils/theme';
import { useSafeState } from 'ahooks';

interface ICreateProfileModal {
  visible: boolean;
  handleCancel: () => void;
  setLoading: (loading: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  modalWrapper: {
    '& .ant-modal-body': {
      padding: '0.5rem 1.5rem',
    },
  },
  formWrapper: {
    '& .ant-form-item-label': {
      paddingBottom: 0,
    },
    '& .ant-form-item': {
      marginBottom: 12,
    },
  },
}));

export const CreateProfileModal = ({ visible, handleCancel, setLoading }: ICreateProfileModal) => {
  const [isCreating, setIsCreating] = useSafeState(false);
  const { reload } = useContext(UserContext);
  const { classes } = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    reset,
    ...rest
  } = useForm<CreateProviderProfileSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(createProviderProfileSchema),
  });

  const onSubmit = async (data: CreateProviderProfileSchema) => {
    setIsCreating(true);
    const [, err] = await API.Admin.create(data);
    if (err) {
      const providerCodeType = data.providerType === "VET" ? "RTO" : "TCSI"

      if (err.message === `Provider [${data.providerType}-${providerCodeType}-${data.orgId}] already exists`) {
        setError('orgId', {
          type: 'custom',
          message: 'This code already exists. The provider code must be unique.',
        });
      } else {
        notification['error']({ message: err.message });
      }

      setIsCreating(false);
      return;
    }

    notification['info']({
      message: (
        <>
          <strong>{data.tradingName}</strong> created.
        </>
      ),
    });

    reset({});
    handleCancel();
    setIsCreating(false);
    setLoading(true);
    reload();
  };

  const onModalCancel = () => {
    reset({});
    handleCancel();
  };

  return (
    <Modal
      title="Create profile"
      open={visible}
      cancelText="Cancel"
      okText="Create profile"
      onCancel={onModalCancel}
      okButtonProps={{ disabled: isCreating, loading: isCreating }}
      onOk={handleSubmit(onSubmit)}
      destroyOnClose
      maskClosable={false}
      className={classes.modalWrapper}
    >
      <Form layout="vertical" className={classes.formWrapper}>
        <Row>
          <Col span={24}>
            <GenericInput
              name="legalName"
              type="text"
              label="Provider legal name"
              control={control}
              showCount
              maxLength={100}
              errors={errors['legalName']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="extId"
              type="text"
              label="Provider abbreviated name"
              control={control}
              required
              showCount
              maxLength={50}
              errors={errors['extId']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="tradingName"
              type="text"
              label="Provider trading name"
              control={control}
              required
              showCount
              maxLength={100}
              errors={errors['tradingName']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="providerType"
              type="radio"
              label="Select provider type"
              control={control}
              options={enumToArray(ProviderType)}
              required
              maxLength={5}
              errors={errors['providerType']}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="orgId"
              type="text"
              label="Provider code (TCSI\RTO)"
              control={control}
              required
              showCount
              maxLength={5}
              errors={errors['orgId']}
              {...rest}
            />
          </Col>
        </Row>

        <div>
          <h2>Provider address</h2>
        </div>

        <Row>
          <Col span={24}>
            <GenericInput
              name="address.addressLine1"
              type="text"
              label="Address line 1"
              control={control}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['addressLine1'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="address.addressLine2"
              type="text"
              label="Address line 2"
              control={control}
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['addressLine2'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="address.suburb"
              type="text"
              label="Suburb"
              control={control}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['suburb'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row style={{ gap: 19 }}>
          <Col span={12}>
            <GenericInput
              name="address.state"
              type="select"
              label="State"
              options={StateOptions}
              control={control}
              required
              showCount
              maxLength={100}
              errors={errors['address'] ? errors['address']['state'] : undefined}
              {...rest}
            />
          </Col>
          <Col span={11}>
            <GenericInput
              name="address.postCode"
              type="text"
              label="Postcode"
              control={control}
              required
              showCount
              maxLength={10}
              errors={errors['address'] ? errors['address']['postCode'] : undefined}
              {...rest}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <GenericInput
              name="enquiryEmail"
              type="email"
              label="Enquiries Email"
              control={control}
              required
              showCount
              maxLength={100}
              errors={errors['enquiryEmail']}
              descriptionAfter="e.g. support@sample.edu.au"
              {...rest}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateProfileModal;
