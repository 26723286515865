import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Tabs,
  TabsProps,
  Typography,
  Alert,
  notification,
  Form,
} from 'antd';
import API from 'api';
import useSWR from 'swr';

import { COURSE_STATUS, DISPLAY_COURSE_STATUS } from 'model/course';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Sticky, StickyContainer } from 'react-sticky';

import { STATUS_TAGS } from './form/common/reference';
import { ICourseFormSchema, credentialSchema, credentialSchemaLookup } from './form/schema';

import CourseActions from './CourseActions';
import { UserContext } from 'components/UserContextProvider';
import { useCourseFetch } from 'hooks/useCourseFetch';
import { debounce } from 'lodash';
import { useCallbackPrompt } from 'hooks';

import './Course.less';
import { useSafeState, useUnmount } from 'ahooks';
import { TabItem } from 'model/ui';
import axios from 'api/_utils/axiosInstance';
import { GENERIC_VALIDATION_ERROR_MESSAGE } from './../../../constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import CourseSummaryForm from './form/CourseSummaryForm';
import CourseOverviewForm from './form/CourseOverviewForm';
import CourseAssessmentsForm from './form/CourseAssessmentsForm';
import CourseRequirementsForm from './form/CourseRequirementsForm';
import CourseCreditRecognitionForm from './form/CourseCreditRecognitionForm';
import CourseTeamForm from './form/CourseTeamForm';
import CourseEnrolmentForm from './form/CourseEnrolmentForm';
import CourseQualitySkillsForm from './form/CourseQualitySkillsForm';
import CourseRelatedCredentialsForm from './form/CourseRelatedCredentialsForm';
import { ZodError } from 'zod';
import { successNotification } from 'utils/notifications';
import DirtyStateContext from 'components/UseDirtyStateContext';
import TabHeadingWithStatus, { TAB_HEADING_STATUS } from 'components/common/tabHeadingWithStatus/TabHeadingWithStatus';
import type { ICredential } from './form/Course.types';

const tabDefinitions = (isFormReadOnly: boolean) => [
  { title: 'Summary', component: <CourseSummaryForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Overview', component: <CourseOverviewForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Assessments', component: <CourseAssessmentsForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Requirements', component: <CourseRequirementsForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Credit & Recognition', component: <CourseCreditRecognitionForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Team', component: <CourseTeamForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Quality & Skills', component: <CourseQualitySkillsForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Enrolment', component: <CourseEnrolmentForm isFormReadOnly={isFormReadOnly} /> },
  { title: 'Related Credentials', component: <CourseRelatedCredentialsForm isFormReadOnly={isFormReadOnly} /> },
];
const numberOfTabs = tabDefinitions(true).length;

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
  return (
    <Sticky>
      {({ style, isSticky }) => {
        const stickyStyling = isSticky && {
          width: '100%',
          marginLeft: '-40px', // negative margin left to make the bar full length
          paddingLeft: '24px',
        };

        return (
          <DefaultTabBar
            {...props}
            className={`${isSticky && 'site-custom-tab-bar'}`}
            style={{
              ...style,
              ...stickyStyling,
            }}
          />
        );
      }}
    </Sticky>
  );
};

const renderActionPanel = (children: React.ReactElement | React.ReactNode | Element) => {
  return (
    <Sticky>
      {({ style, isSticky }) => {
        return <div style={{ ...style, marginTop: isSticky ? '75px' : 0, transition: 'ease 0.2s' }}>{children}</div>;
      }}
    </Sticky>
  );
};

export const Course = () => {
  const [isDirtyStates, setIsDirtyStates] = useSafeState<Record<string, boolean>>({});
  const [tabsStatus, setAllTabStatuses] = useSafeState<TAB_HEADING_STATUS[]>(
    new Array(numberOfTabs).fill(TAB_HEADING_STATUS.NONE)
  );

  const handleIsDirtyChange = (key: string, isDirty: boolean) => {
    setIsDirtyStates((prev) => ({ ...prev, [key]: isDirty }));
  };

  const setTabStatus = (tabIndex: number, status: TAB_HEADING_STATUS) => {
    setAllTabStatuses((prev) => {
      return prev.map((prevStatus, index) => {
        if (index === tabIndex) {
          return status;
        }
        return prevStatus;
      });
    });
  };

  const { currentProvider, isAdmin, userInfo, changeProvider } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { courseId, providerId: _providerId } = useParams();

  if (!courseId) throw Error('Expect courseId');

  const providerId = isAdmin ? _providerId ?? '' : currentProvider;

  const {
    courseCode,
    courseData,
    provider,
    loading,
    loadFailed,
    courseInfo,
    updateCourseInfo,
    reload,
    formsDirty,
    clearFormsDirty,
    lockCourse,
    unlockCourse,
  } = useCourseFetch({
    currentProvider: providerId,
    courseId,
  });

  const {
    title,
    price,
    commitment = '',
    duration,
    level,
    language,
    industries = [],
    assessments = [],
    recognitions,
    syllabuses = [],
    outcome = [],
    about = {},
    deliveryDesc = {},
    discountDesc = {},
    academics,
    quality,
    skills = [],
    fieldOfEducation = null,
    sessions = [],
    apply = {},
    expiration,
    conditions,
    PathName,
    discountIndicator,
    relatedCredentials,
  } = courseData || {};

  const campuses = provider?.campuses;

  const methods = useForm<ICourseFormSchema>({
    mode: 'onSubmit',
    defaultValues: {
      courseCode,
      title,
      price,
      commitment,
      duration: duration ? duration : { unit: undefined, value: undefined },
      level,
      language,
      industries,
      assessments,
      recognitions: recognitions ? recognitions : [],
      syllabuses,
      outcome,
      about,
      deliveryDesc,
      discountDesc,
      academics: academics ? academics : [],
      quality: quality || [],
      skills,
      fieldOfEducation,
      sessions,
      apply,
      expiration: expiration?.expirationP1 ? expiration : { expirationP1: '' },
      conditions: conditions || [{ conditions: [] }],
      discountIndicator,
      relatedCredentials: relatedCredentials?.map((e: string) => ({ value: e })) || [],
      campuses,
      isLocked: false,
    },
    resolver: zodResolver(credentialSchema),
  });

  const debouncedGenericErrorNotification = debounce(() => {
    notification['error']({
      message: GENERIC_VALIDATION_ERROR_MESSAGE,
    });
  }, 500);

  useEffect(() => {
    if (!loading) {
      methods.reset({
        courseCode,
        title,
        price,
        commitment,
        duration: duration ? duration : { unit: undefined, value: undefined },
        level,
        language,
        industries,
        assessments,
        recognitions: recognitions ? recognitions : [],
        syllabuses,
        outcome,
        about,
        deliveryDesc,
        discountDesc,
        academics: academics ? academics : [],
        quality: quality || [],
        skills,
        fieldOfEducation,
        sessions,
        apply,
        expiration: expiration?.expirationP1 ? expiration : { expirationP1: '' },
        conditions: conditions || [{ conditions: [] }],
        discountIndicator,
        relatedCredentials: relatedCredentials?.map((e: string) => ({ value: e })) || [],
        campuses,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, methods, courseData]);

  // only fires when course status is Approved (Publishing), and automatically update the course status
  useSWR(courseInfo?.status === COURSE_STATUS.Approved ? `/provider/${providerId}/cred2/${courseId}` : null, axios, {
    keepPreviousData: true,
    onSuccess: ({ data }) => {
      updateCourseInfo(data);
    },
    onError: (error) => {
      console.error('error', error);
    },
    refreshInterval: 10 * 1000, // 10 seconds
  });

  const { mutate } = useSWR('UNLOCK_COURSE_KEY');

  const [tab, setTab] = useSafeState<string>('1');

  const [, setInit] = useSafeState<boolean | null>(null);
  const [canSubmit, setCanSubmit] = useSafeState<boolean>(false);
  const [, setIsSubmitting] = useSafeState<boolean>(false);
  const [openSubmitModal, setSubmitModal] = useSafeState<boolean>(false);

  const lockedByInfo = courseData?.lockedBy?.split('#');
  const isCourseLockable = courseInfo?.status === COURSE_STATUS.Edit || courseInfo?.status === COURSE_STATUS.New;
  const isCredentialLockedByAnotherUser = userInfo.sub !== lockedByInfo?.[0] && courseData?.locked;

  const tabsRef = useCallback(() => {
    setInit(false);
  }, [setInit]);

  const handleReload = useCallback(
    (maintainForm?: boolean) => {
      setCanSubmit(false);
      reload(maintainForm);
    },
    [reload, setCanSubmit]
  );

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    Object.values(isDirtyStates).filter(Boolean)?.length > 0
  );

  const handleUnlockCourse = async () => {
    return unlockCourse(providerId);
  };

  const handleLockCourse = async () => {
    return lockCourse(providerId);
  };

  const navigateToListCredentials = async () => {
    if (
      !isCredentialLockedByAnotherUser &&
      (courseInfo?.status === COURSE_STATUS.Edit || courseInfo?.status === COURSE_STATUS.New)
    ) {
      await API.Cred2.unlock(providerId, courseId);
    }

    const link = isAdmin ? '/admin/courses' : '/provider/courses';
    navigate(link, { replace: true });
  };

  useEffect(() => {
    // If the user changes the provider
    // Go to list courses
    if (currentProvider) {
      if (providerId !== currentProvider) {
        navigateToListCredentials();
      }
    }
  }, [currentProvider]);

  useEffect(() => {
    // Lock the course anyway because the user can reload the page
    if (!loading) {
      const isFromEmail = searchParams.get('fromEmail');
      if (isFromEmail) {
        changeProvider(providerId);
      }

      if (isCredentialLockedByAnotherUser) {
        methods.setValue('isLocked', true);
      }

      // mutate for logout
      mutate(providerId);
      // If the course isn't locked by anyone
      // -> Lock it
      if (!courseData?.locked && isCourseLockable) {
        handleLockCourse();
      }
    }

    // Initially checks if the form is valid
    // This is to set the initial canSubmit
    const formData = methods.getValues();
    try {
      credentialSchema.transform(preprocessDataWithSchema).parse(formData);
      methods.clearErrors();
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      if (isCourseLockable) {
        handleLockCourse();
      } else {
        handleUnlockCourse();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseInfo?.status]);

  useUnmount(() => {
    // If the credential is not locked
    // Which means I'm the person who is editing it
    // Hence I should be able to unlock that credentials when I'm done with my stuff
    if (
      !isCredentialLockedByAnotherUser &&
      (courseInfo?.status === COURSE_STATUS.Edit || courseInfo?.status === COURSE_STATUS.New)
    ) {
      API.Cred2.unlock(providerId, courseId);
    }
  });

  useEffect(() => {
    // If the credential is not locked
    // Which means I'm the person who is editing it
    // Hence I'm able to unlock that credentials when I'm done with my stuff
    if (
      !isCredentialLockedByAnotherUser &&
      isCredentialLockedByAnotherUser !== undefined &&
      (courseInfo?.status === COURSE_STATUS.Edit || courseInfo?.status === COURSE_STATUS.New)
    ) {
      // Unlock when leaves the page
      window.onbeforeunload = async function (event) {
        await handleUnlockCourse();

        var e = event || window.event;
        e.stopPropagation();
        e.preventDefault();

        if (e) {
          e.returnValue = '';
        }

        return '';
      };

      // This is a fallback for when a user close the browser
      window.onunload = async (event) => {
        await handleUnlockCourse();
        event.stopPropagation();
        event.preventDefault();

        return;
      };
    } else {
      clearFormsDirty();
      // Reset these events when the status changes
      window.onbeforeunload = null;
      window.onunload = null;
    }

    return () => {
      // Reset these events when unmount
      window.onbeforeunload = null;
      window.onunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData, courseInfo]);

  useEffect(() => {
    // prevents submitting when user has changed something in the form
    const isDirty = Object.values(formsDirty)?.some((value) => value);
    if (canSubmit && isDirty) {
      setCanSubmit(false);
    }
  }, [formsDirty, canSubmit, setCanSubmit]);

  const onCancelEdit = () => {
    methods.reset({
      courseCode,
      title,
      price,
      commitment,
      duration: duration ? duration : { unit: undefined, value: undefined },
      level,
      language,
      industries,
      assessments,
      recognitions: recognitions ? recognitions : [],
      syllabuses,
      outcome,
      about,
      deliveryDesc,
      discountDesc,
      academics: academics ? academics : [],
      quality: quality || [],
      skills,
      fieldOfEducation,
      sessions,
      apply,
      expiration: expiration?.expirationP1 ? expiration : { expirationP1: '' },
      conditions: conditions || [{ consitions: [] }],
      discountIndicator,
      relatedCredentials: relatedCredentials?.map((e: string) => ({ value: e })) || [],
      campuses,
    });
    clearFormsDirty();

    // Clear all isDirty states
    Object.keys(isDirtyStates).forEach((key) => {
      handleIsDirtyChange(key, false);
    });

    // Clear all red dots
    setAllTabStatuses(new Array(numberOfTabs).fill(TAB_HEADING_STATUS.NONE));
  };

  const preprocessDataWithSchema = useCallback((val) => {
    if (val.relatedCredentials) {
      return {
        relatedCredentials: val.relatedCredentials.map((e: { value: string }) => e.value),
      };
    }
    return val;
  }, []);

  const update = async (validatedData: ICredential, successMessage: string | undefined) => {
    try {
      setIsSubmitting(true);
      const [, error] = await API.Cred2.update(providerId, courseId, validatedData);

      if (error) {
        throw new Error(error?.message);
      }
      setCanSubmit(true);
      if (successMessage) {
        successNotification(successMessage);
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateAndSave = (successMessage?: string) => {
    methods.clearErrors();
    const formData = methods.getValues();
    let validatedData = {} as ICredential;
    const validatedDataErrors: { error: any }[][] = [];

    // Clear all red dots
    setAllTabStatuses(new Array(numberOfTabs).fill(TAB_HEADING_STATUS.NONE));

    const schemaKeys = Object.keys(credentialSchemaLookup);
    for (const key of schemaKeys) {
      try {
        const validatedDataFromEachTab = credentialSchemaLookup[key]
          .transform(preprocessDataWithSchema)
          .parse(formData);
        validatedData = { ...validatedData, ...validatedDataFromEachTab };
      } catch (err) {
        const tabIndex = +key - 1;

        setTabStatus(tabIndex, TAB_HEADING_STATUS.ERROR);

        if (!validatedDataErrors[tabIndex]) {
          validatedDataErrors[tabIndex] = [];
        }
        validatedDataErrors[tabIndex].push({ error: err });

        const zodError = err as ZodError;
        for (const error of zodError.errors) {
          const path = error.path.join('.');
          methods.setError(path as any, { message: error.message });
        }
      }
    }

    if (validatedDataErrors.some((errors) => errors.length > 0)) {
      setCanSubmit(false);
      debouncedGenericErrorNotification();
      return false;
    }

    return update(validatedData, successMessage)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.error(`Error: Could not update microcredential - ${err}`);
        return false;
      });
  };

  const clearTabStatus = () => {
    // Clear all isDirty states
    Object.keys(isDirtyStates).forEach((key) => {
      handleIsDirtyChange(key, false);
    });

    // Clear all red dots
    setAllTabStatuses(tabsStatus.map(() => TAB_HEADING_STATUS.NONE));
  };

  const onSave = useCallback(async () => {
    try {
      const saved = await validateAndSave('Changes saved.');
      if (saved) {
        clearTabStatus();
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleIsDirtyChange(tab, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, preprocessDataWithSchema]);

  const onSubmit = async () => {
    const saved = await validateAndSave();
    if (saved) {
      try {
        // Clear all isDirty states
        clearTabStatus();
        setSubmitModal(true);
      } catch (err) {
        console.error(err);
      } finally {
        handleIsDirtyChange(tab, false);
      }
    }
  };

  const isDisabledUnsavedModal = useRef(false);

  const onDeleteMicrocredential = (callback: () => void) => {
    isDisabledUnsavedModal.current = true;
    callback();
    confirmNavigation();
    isDisabledUnsavedModal.current = false;
  };

  const handlePreview = useCallback(async () => {
    const [data] = await API.Cred2.generatePreviewUrl(providerId, courseId);

    if (data) {
      window.open(data.previewUrl, '__blank');
    }
  }, [courseId, providerId]);

  if (loading) return <Spin />;

  if (loadFailed) return <div>Could not load course.</div>;

  const isFormReadOnly =
    [
      COURSE_STATUS.Approved,
      COURSE_STATUS.Review,
      COURSE_STATUS.Inactive,
      COURSE_STATUS.Unpublished,
      COURSE_STATUS.Published,
      COURSE_STATUS.PublishError,
      // If the credential is locked by another user, set it as read-only
    ].includes(courseInfo?.status as COURSE_STATUS) || isCredentialLockedByAnotherUser;

  const items: TabItem[] = tabDefinitions(isFormReadOnly).map(({ title, component }, index) => ({
    key: `${index + 1}`,
    label: <TabHeadingWithStatus text={title} status={tabsStatus[index]} />,
    children: component,
  }));

  return (
    <DirtyStateContext.Provider value={{ setIsDirty: handleIsDirtyChange, isDirtyStates }}>
      {isCredentialLockedByAnotherUser ? (
        <Alert
          className="lock-credential-alert"
          message={
            'This microcredential is currently locked for editing by another user. You will not be able to edit until the other user finishes.'
          }
          type={'warning'}
          showIcon
        />
      ) : (
        <></>
      )}

      <Link className="to-courses-link" to={isAdmin ? '/admin/courses' : '/provider/courses'}>
        <Space>
          <ArrowLeftOutlined />
          <span>Microcredentials</span>
        </Space>
      </Link>
      <div className="card-title">
        <h2 className="heading-3">{title}</h2>
        <Tag color={courseInfo?.status ? STATUS_TAGS[courseInfo.status] : 'default'}>
          {DISPLAY_COURSE_STATUS[courseInfo?.status as COURSE_STATUS] || 'none'}
        </Tag>
      </div>

      <StickyContainer>
        <FormProvider {...methods}>
          <Form layout="vertical">
            <div ref={tabsRef}>
              <Row gutter={40}>
                <Col span={16}>
                  <Tabs activeKey={tab} onTabClick={setTab} renderTabBar={renderTabBar} items={items} />
                </Col>
                <Col span={8}>
                  {renderActionPanel(
                    <CourseActions
                      onReloadRequest={handleReload}
                      previewPathName={PathName}
                      onSubmit={onSubmit}
                      title={title}
                      openSubmitModal={openSubmitModal}
                      toggleSubmitModal={(show?: boolean) => setSubmitModal(show ?? !openSubmitModal)}
                      onSave={onSave}
                      canSubmit={canSubmit}
                      loading={loading}
                      onPreview={handlePreview}
                      courseInfo={courseInfo}
                      courseId={courseId}
                      currentProvider={providerId}
                      isAdmin={isAdmin}
                      locked={isCredentialLockedByAnotherUser}
                      onCancelEdit={onCancelEdit}
                      onDeleteMicrocredential={onDeleteMicrocredential}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </FormProvider>
      </StickyContainer>
      <Modal
        maskClosable={false}
        title="Unsaved changes"
        open={!isDisabledUnsavedModal.current ? showPrompt : false}
        footer={[
          <Button key="back" onClick={cancelNavigation}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={confirmNavigation}>
            Ok
          </Button>,
        ]}
        onCancel={cancelNavigation}
      >
        <Typography.Text>
          There are unsaved changes for this microcredential. If you leave now, these will be lost.
        </Typography.Text>
      </Modal>
    </DirtyStateContext.Provider>
  );
};
