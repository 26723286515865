import Modal from "antd/lib/modal/Modal";
import Button from "antd/lib/button";
import { FC } from "react";
import { Typography } from "antd";
import {COURSE_ACTION} from "model/course";

interface Props {
  confirmLoading?: boolean;
  onCancel: () => void;
  onConfirm: (action: string) => void
  isModalVisible: boolean;
  title: string;
}

export const ApproveModal: FC<Props> = ({
  confirmLoading = false,
  onCancel,
  onConfirm,
  isModalVisible,
  title
}) => {
  const handleApprove = () => onConfirm(COURSE_ACTION.Approve);

  return (
    <Modal
      title="Publish"
      open={isModalVisible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={handleApprove}
          type="primary"
          loading={confirmLoading}
        >
          Publish
        </Button>,
      ]}
    >
      <Typography.Text>
        <Typography.Text strong>{title}</Typography.Text>{" "}
        will be available to any viewer of Microcred Seeker. It may take up to a
        few minutes to publish.
      </Typography.Text>
    </Modal>
  );
};
