import { handleAPIPromise, IError } from 'api/utils';
import { OverviewSchema } from 'components/provider/profile/form/schema/overview';
import { UserRole } from 'utils/UserUtils';
import axios from './_utils/axiosInstance';
export interface IProviderDashboardInfo {
  providerId: string;
  providerName: string;

  draftCount: number;
  publishedCount: number;
  reviewingCount: number;

  draftCreds: IDashboardCredItem[];
  reviewCreds: IDashboardCredItem[];
  publishedCreds: IDashboardCredItem[];
}

export interface IDashboard {
  totalDraftCount: number;
  totalPublishedCount: number;
  totalReviewingCount: number;

  providerDashboardInfos: IProviderDashboardInfo[];
}

export interface IDashboardCredItem {
  id: string;
  title: string;
  courseCode: string;
  updatedAt: number;
  publishedAt: number;
}

export interface IProviderData {
  legalName: string;
  extId: string;
  tradingName: string;
  address?: {
    addressLine1: string;
    addressLine2?: string;
    suburb: string;
    state: string;
    postCode: string;
    latitude?: string;
    longitude?: string;
  };
}

export interface IUserCreationRequest {
  email: string;
  name: string;
  defaultProvider?: string;
  role: UserRole;
}

export interface ICourseDumpProps {
  providerId: string;
  code: string;
  title: string;
  level: string;
  unitDuration: string;
  unitValue: string;
  language: string;
  fieldOfEducation: string;
  price: string;
  discountAvailable: string;
  discountDescription: string;
  courseStatus: string;
  commitment: string;
  courseUrl: string;
  industries: string;
  skillName: string;
  sessionDeliveryMode: string;
  sessionCampus: string;
  flexibleSession: string;
  sessionStartDate: string;
  sessionEndDate: string;
  sessionClosingDate: string;
  creditType: string;
  creditDescription: string;
  about: string;
  syllabuses: string;
  assessments: string;
  requirements: string;
  team: string;
  qualityIndicatorTitle: string;
  outcomes: string;
  relatedCredentials: string;
  [key: string]: string | string[];
}

const create = async (payload: Partial<OverviewSchema>) => {
  return await handleAPIPromise(axios.post(`/admin/provider`, payload));
};

const list = async () => {
  return await handleAPIPromise(axios.get(`/admin/provider`));
};

const dashboard = async (): Promise<[IDashboard | undefined, IError | undefined]> => {
  const [data, error] = await handleAPIPromise(axios.get(`/admin/dashboard`)).then();
  return [data, error];
};

const createUser = async (payload: IUserCreationRequest) => {
  return await handleAPIPromise(axios.post(`/admin/user`, payload));
};

const listUser = async () => {
  return await handleAPIPromise(axios.get('/admin/user'));
};

const deactivateUser = async (username: string, role: UserRole) => {
  const apiUrl = `/${role === UserRole.ADMIN ? 'admin' : 'provider'}/user/deactivate/${username}`;
  return await handleAPIPromise(axios.post(apiUrl, {}));
};

// dryRun checks whether an user can be enabled before hitting Cognito & database
const activateUser = async (username: string, role: UserRole, dryRun?: boolean, ignoreCurrentUser = false) => {
  const apiUrl = `/${role === UserRole.ADMIN ? 'admin' : 'provider'}/user/activate/${username}`;
  return await handleAPIPromise(axios.post(apiUrl, { dryRun, ignoreCurrentUser }));
};

const updateUserRole = async (id: string, currentLoggedInUserRole: string, newRole: string) => {
  const apiUrl = currentLoggedInUserRole === UserRole.ADMIN ? `/admin/user/${id}` : `/provider/user/${id}`;
  return await handleAPIPromise(axios.post(apiUrl, { role: newRole }));
};

const listProviderUser = async (providerId: string) => {
  return await handleAPIPromise(axios.get(`/provider/${providerId}/user`));
};

const getProviderUser = async (userID: string) => {
  return await handleAPIPromise(axios.get(`/provider/user/${userID}`));
};

const getUser = async (userID: string) => {
  return await handleAPIPromise(axios.get(`/admin/user/${userID}`));
};

const resendInvitation = async (username: string): Promise<[string | undefined, IError | undefined]> => {
  return await handleAPIPromise(axios.post(`/provider/user/resend-invitation`, { username }));
};

const offboardProvider = async (
  providerId: string,
  dryRun: boolean = true
): Promise<[{ deleteUsers: { total: number }; deleteCourses: { total: number } } | undefined, IError | undefined]> => {
  return await handleAPIPromise(axios.post(`/admin/provider/${providerId}/offboard`, { dryRun }));
};

const generateCourseDump = async (): Promise<[string | undefined, IError | undefined]> => {
  return await handleAPIPromise<string>(axios.get(`/admin/report/course-dump`));
};

const ProviderAPI = {
  create,
  dashboard,
  list,
  createUser,
  listUser,
  activateUser,
  deactivateUser,
  updateUserRole,
  listProviderUser,
  getProviderUser,
  getUser,
  resendInvitation,
  offboardProvider,
  generateCourseDump,
};

export default ProviderAPI;
