import Icon from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { signOut } from 'utils/UserUtils';

import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';

import './headerMenu.less';
import { UserContext } from 'components/UserContextProvider';
import { useNavigate } from 'react-router';
import API from 'api';

const { Title } = Typography;

const HeaderMenu = () => {
  const navigate = useNavigate();

  const { currentProvider } = useContext(UserContext);
  const [extId, setExtId] = useState<string>();

  useEffect(() => {
    const provider = currentProvider;
    if (provider) {
      API.Provider.get(provider).then(([data, error]) => {
        setExtId(data.extId);
      });
    }
    return () => {
      setExtId(undefined);
    };
  }, [currentProvider]);

  return (
    <div className="container">
      <Title level={4}>{extId}</Title>
      <div className="header-end">
        <Button
          style={{ padding: 0 }}
          type="link"
          href="https://uac.helpjuice.com/marketplace/secrets/9dAvPhMhiU9wIcL5Kdf-FQ/"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="help-centre-link"
        >
          Help Centre
          <Icon component={ExternalLink} />
        </Button>
        <Button className="primary-sign-out" onClick={() => signOut(currentProvider, () => navigate('/'))} type="primary">
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default HeaderMenu;
