import { mapValues, groupBy, indexOf, pickBy } from 'lodash';

export const capitalise = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const simpleUniqueId = () => {
  const dateStr = Date.now().toString(36);

  const randomStr = Math.random().toString(36).substring(2, 8);

  return `${dateStr}-${randomStr}`;
};

export const fromKebabCase = (str: string) => {
  const parts = str?.split('-') || [];
  return parts
    .map((_str: string, i: number) => {
      if (i === 0) {
        const initChar = _str.charAt(0).toUpperCase();
        return initChar + _str.substring(1).toLowerCase();
      }
      return _str.toLowerCase();
    })
    .join(' ');
};

/**
 * takes an array of strings as input and returns an object containing the duplicated string values as keys and an array of their indices as values.
 * example: ["a", "b", "c", "a", "b", "c", "d", "e", "f"];
 * results: Output: { a: [0, 3], b: [1, 4], c: [2, 5] }
 */
export const findDuplicateIndices = (arr: string[], removeFirstOccurrence = false): Record<string, number[]> => {
  const grouped = groupBy(arr, (item) => item);
  const duplicates = pickBy(grouped, (value) => value.length > 1);

  if (removeFirstOccurrence) {
    return mapValues(duplicates, (value) => {
      return value
        .slice(1) // Remove the first occurrence
        .map((item) => indexOf(arr, item, indexOf(arr, item) + 1)); // Find index of each duplicate item after the first occurrence
    });
  }

  return mapValues(duplicates, (value) => {
    return value.map((item) => indexOf(arr, item));
  });
};
