import { notification } from 'antd';
import { ReactFragment } from 'react';

export const successNotification = (message: ReactFragment) =>
  notification['success']({
    message,
  });

export const errorNotification = (message: ReactFragment) =>
  notification['error']({
    message,
    style: {
      backgroundColor: '#FFF1F0',
      fontSize: '14px',
      border: '1px solid #FFCCC7',
      borderRadius: '3px',
    },
  });

export const infoNotification = (message: ReactFragment) =>
  notification['info']({
    message,
  });
