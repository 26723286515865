import { createMakeAndWithStyles } from 'tss-react';

function useTheme() {
  return {
    primaryColor: '#10655e',
    lightGray: '#D9D9D9',
    red: '#D72C0D',
    black: '#000000',
    white: '#ffffff',
  };
}

export const { makeStyles, withStyles, useStyles } = createMakeAndWithStyles({ useTheme });
