import { Card, List, Table } from 'antd';
import { IProviderDashboardInfo } from 'api/admin';
import { IDashboardCredItem } from 'api/provider';
import ListItems from 'components/common/listItems/ListItems';
import { UserContext } from 'components/UserContextProvider';
import { useContext } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/date';
import './providerList.less';

const tabList = [
  {
    key: 'drafts',
    tab: 'Drafts',
  },
  {
    key: 'reviewing',
    tab: 'Awaiting review',
  },
  {
    key: 'published',
    tab: 'Recently published',
  },
];

const RenderItem = (navigate: NavigateFunction, currentProvider: string) => (item: IDashboardCredItem) => {
  const onRedirectToItem = (id: string) => {
    navigate(`/admin/courses/${currentProvider}/${id}`)
  }

  return (
    <List.Item className='draft-courses' onClick={() => onRedirectToItem(item.id)}>
      <Link to={`/admin/courses/${currentProvider}/${item.id}`}>{item.title}</Link>
    </List.Item>
  );
};

const contentList = (dashboardInfo: IProviderDashboardInfo, currentProvider: string, navigate: NavigateFunction): Record<string, React.ReactNode> => {
  return {
    drafts: (
      <ListItems
        key="drafts"
        loader={async (pageIndex?: number, pageSize?: number) => {
          return {
            data: dashboardInfo.draftCreds ?? [],
            canLoadMore: false,
          };
        }}
        emptyText="You have no drafts."
        renderItem={RenderItem(navigate, currentProvider)}
      />
    ),
    reviewing: (
      <ListItems
        key="reviewing"
        loader={async (pageIndex?: number, pageSize?: number) => {
          return {
            data: dashboardInfo.reviewCreds ?? [],
            canLoadMore: false,
          };
        }}
        emptyText="You have no microcredentials awaiting review."
        renderItem={RenderItem(navigate, currentProvider)}
      />
    ),
    published: (
      <Table
        key="published"
        rowKey={record => record.id}
        className="published-creds-table"
        // The limit to display is only 10
        // So that we have so sort the default list and take 10 of the newest
        dataSource={dashboardInfo.publishedCreds.sort((a, b) => b.publishedAt - a.publishedAt).slice(0, 10)}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={[
          {
            title: 'Title',
            key: 'title',
            render: (credItem: IDashboardCredItem) => {
              return RenderItem(navigate, currentProvider)(credItem);
            },
          },
          {
            title: 'Date published',
            key: 'date-published',
            dataIndex: 'publishedAt',
            render: (publishedAt: number) => {
              return formatDateTime(publishedAt)
            },
            sorter: (a: IDashboardCredItem, b: IDashboardCredItem) => {
              return a.publishedAt - b.publishedAt
            },
          },
        ]}
        locale={{ emptyText: 'You have no recently published microcredentials.' }}
        pagination={false}
      />
    ),
  };
};

interface IDashboardCourseList {
  activeTabKey?: string;
  onTabChange?: (tabKey: string) => void;
  dashboardInfo: IProviderDashboardInfo;
}

const DashboardCourseList = ({ dashboardInfo, activeTabKey = tabList[0].key, onTabChange }: IDashboardCourseList) => {
  const navigate = useNavigate();
  const { currentProvider } = useContext(UserContext);

  return (
    <>
      <Card
        title="Microcredentials"
        data-cy="microcreds-list-card"
        activeTabKey={activeTabKey}
        tabList={tabList}
        onTabChange={onTabChange}
        tabProps={{
          size: 'small',
        }}
        size="small"
        className={activeTabKey === 'published' ? 'card-published-cred-item' : ''}
      >
        {contentList(dashboardInfo, currentProvider, navigate)[activeTabKey]}
      </Card>
    </>
  );
};

export default DashboardCourseList;
