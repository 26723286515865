import { UserContext } from 'components/UserContextProvider';
import { ICourse } from 'model/course';
import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import axios from 'api/_utils/axiosInstance';
import { IError } from 'api/utils';


interface IProviderData {
  total: number;
  providers: {
    id: string;
    name: string;
  }[]
  credentials: ICourse[]
}

let cache: ICourse[] = [];

function useCourseList(forceReload: boolean = false) {
  const { currentProvider } = useContext(UserContext);
  const [courses, setCourses] = useState<ICourse[]>(cache);
  const [error, setError] = useState<any>();


  const fetchUrl = (): String | void => {
    if (currentProvider) {
      return `/provider/${currentProvider}/cred2`
    }
    return '/admin/cred'
  }

  const { mutate, isLoading } = useSWR(() => fetchUrl(), axios, {
    onError: (error: IError) => {
      setError(error)
    },
    onSuccess: ({ data }: { data: IProviderData }) => {
      if (currentProvider) { 
        setCourses(data as unknown as ICourse[])
        cache = data as unknown as ICourse[];
      } else {
        setCourses(data.credentials)
        cache = data.credentials;
      }
      

    },
  });

  useEffect(() => {
    if (!cache || forceReload) {
      mutate();
    }
  }, [forceReload]);

  const addOrUpdate = (data: ICourse) => {
    const index = courses.findIndex((c: ICourse) => c.id === data.id);
    const newList = [...courses];
    if (index === -1) {
      newList.push(data);
    } else {
      newList.splice(index, 1, data);
    }
    newList.sort((c1: ICourse, c2: ICourse) => (c1.title < c2.title ? -1 : 1));
    setCourses(newList);
    cache = newList;
  };

  const deleted = (data: ICourse) => {
    const deleteIndex = courses.findIndex((c: ICourse) => c.id === data.id);
    const newList = [...courses];
    newList.splice(deleteIndex, 1);
    setCourses(newList);
    cache = newList;
  };

  return {
    list: courses,
    loading: isLoading,
    error: error,
    addOrUpdate,
    deleted,
    revalidate: mutate
  };
}

export default useCourseList;
