import { Card, List, Table } from 'antd';
import API from 'api';
import ListItems from 'components/common/listItems/ListItems';
import { UserContext } from 'components/UserContextProvider';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IDashboardCredItem } from 'api/provider';
import './Dashboard.less';
import { formatDateTime } from 'utils/date';
import { useSafeState } from 'ahooks';

const tabList = [
  {
    key: 'drafts',
    tab: 'Drafts',
  },
  {
    key: 'reviewing',
    tab: 'Awaiting review',
  },
  {
    key: 'published',
    tab: 'Recently published',
  },
];

const renderItem = (item: IDashboardCredItem) => {
  return (
    <List.Item>
      <Link to={`/provider/courses/${item.id}`}>{item.title}</Link>
    </List.Item>
  );
};

const contentList = (
  currentProvider: string,
  loadingPublishedCreds: boolean,
  publishedCreds?: IDashboardCredItem[]
): Record<string, React.ReactNode> => {
  return {
    drafts: (
      <ListItems
        key="drafts"
        loader={(pageIndex?: number, pageSize?: number) => {
          return API.Provider.dashboard(currentProvider).then(([data, error]) => {
            if (data) {
              return {
                data: data.draftCreds,
                canLoadMore: false,
              };
            } else {
              return {
                data: [],
                canLoadMore: false,
              };
            }
          });
        }}
        emptyText="You have no drafts."
        renderItem={renderItem}
      />
    ),
    reviewing: (
      <ListItems
        key="reviewing"
        loader={(pageIndex?: number, pageSize?: number) => {
          return API.Provider.dashboard(currentProvider).then(([data, error]) => {
            return {
              data: data?.reviewCreds ?? [],
              canLoadMore: false,
            };
          });
        }}
        emptyText="You have no microcredentials awaiting review."
        renderItem={renderItem}
      />
    ),
    published: (
      <Table
        className="published-creds-table"
        key="published"
        rowKey={(record) => record.id}
        dataSource={publishedCreds}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={[
          {
            title: 'Title',
            render: (credItem: IDashboardCredItem) => {
              return renderItem(credItem);
            },
          },
          {
            title: 'Date published',
            key: 'date-published',
            dataIndex: 'publishedAt',
            render: (publishedAt: number) => {
              return formatDateTime(publishedAt);
            },
            sorter: (a: IDashboardCredItem, b: IDashboardCredItem) => {
              return a.publishedAt - b.publishedAt;
            },
          },
        ]}
        loading={loadingPublishedCreds}
        locale={{ emptyText: 'You have no recently published microcredentials.' }}
        pagination={false}
      />
    ),
  };
};

const DashboardCourseList = () => {
  const { currentProvider } = useContext(UserContext);
  const [activeTabKey, setActiveTabKey] = useSafeState<string>(tabList[0].key);

  const [publishedCreds, setPublishedCreds] = useSafeState<IDashboardCredItem[]>();
  const [loadingPublishedCreds, setLoadingPublishedCreds] = useSafeState(true);

  useEffect(() => {
    const handleGetPublishedCreds = async () => {
      setLoadingPublishedCreds(true);

      const resp = await API.Provider.dashboard(currentProvider).then(([data, error]) => {
        setLoadingPublishedCreds(false);

        if (data) {
          return {
            // The limit to display is only 10
            data: data.publishedCreds.sort((a, b) => b.publishedAt - a.publishedAt).slice(0, 10),
            canLoadMore: false,
          };
        } else {
          return {
            data: [],
            canLoadMore: false,
          };
        }
      });

      setPublishedCreds(resp.data);
    };

    if (activeTabKey === 'published') {
      handleGetPublishedCreds();
    }
  }, [activeTabKey]);

  return (
    <>
      <Card
        title="Microcredentials"
        data-cy="microcreds-list-card"
        tabList={tabList}
        onTabChange={setActiveTabKey}
        tabProps={{
          size: 'small',
        }}
        size="small"
        className={activeTabKey === 'published' ? 'card-published-cred-item' : ''}
      >
        {contentList(currentProvider, loadingPublishedCreds, publishedCreds)[activeTabKey]}
      </Card>
    </>
  );
};

export default DashboardCourseList;
