import { FC } from 'react';
import { Alert } from 'antd';
import { ACTION_ALERTS_MESSAGES, ActionAlertMessageType } from 'services/SummaryActions';

interface SummaryAlertProps {
  status: string;
  canSubmit: boolean;
}

const SummaryAlert: FC<SummaryAlertProps> = ({ status, canSubmit }) => {
  const internalStatus = (status === 'Edit' && canSubmit) || (status === 'New' && canSubmit) ? 'Saved' : status;
  const actionStatus = (ACTION_ALERTS_MESSAGES as ActionAlertMessageType)[internalStatus];

  if (!actionStatus || internalStatus === 'Saved') return null;

  return (
    <div className="summary-card-actions__status">
      <Alert message={actionStatus.message} type={actionStatus.type} showIcon />
    </div>
  );
};

export default SummaryAlert;
